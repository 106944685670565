import './ProgressCircle.scss'

type ProgressCircleProps = {
	readonly progress: number
	readonly radius: number
	readonly stroke: number
}

export const ProgressCircle = ({ progress, radius, stroke }: ProgressCircleProps) => {
	const normalizedRadius = radius - stroke * 2
	const circumference = normalizedRadius * 2 * Math.PI
	const strokeDashoffset = circumference - (progress / 100) * circumference

	return (
		<svg height={radius * 2} width={radius * 2} className="progress-circle">
			<g>
				<circle
					className="progress-circle__background-circle"
					fill="transparent"
					strokeWidth={stroke}
					r={normalizedRadius}
					cx={radius}
					cy={radius}
				></circle>
				<circle
					className="progress-circle__status"
					fill="transparent"
					strokeWidth={stroke}
					strokeDasharray={circumference + ' ' + circumference}
					style={{ strokeDashoffset }}
					r={normalizedRadius}
					cx={radius}
					cy={radius}
				></circle>
			</g>
		</svg>
	)
}
