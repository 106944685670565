import type { MotivIntegrationIdentity } from '@motiv-shared/server'
import { createSelector } from '@reduxjs/toolkit'
import type { WithIntegrationIdentitiesState } from './integration-identities.slice'

const state$ = <T extends WithIntegrationIdentitiesState>(s: T) => s.integrationIdentities

export const createIdentitiesByIntegrationId = () =>
	createSelector(
		state$,
		(_: any, integrationId: Maybe<string>) => integrationId,
		(state, integrationId): MotivIntegrationIdentity[] =>
			(integrationId && state.data[integrationId]) || [],
	)

export const integrationIdentities$ = /* @__PURE__ */ createSelector(state$, (s) => s.data)
