import './DateRangeHeader.scss'

import type { DateRangeFilter } from '@motiv-shared/reducers'
import { DateRangeFilters } from '@motiv-shared/reducers'
import cls from 'classnames'
import capitalize from 'lodash/capitalize'
import moment from 'moment-timezone'
import type { ReactNode } from 'react'
import { useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { generatePath, useRouteMatch } from 'react-router-dom'
import { LinkContainer } from '../../../widgets/LinkContainer'
import { OutsideCardHeader } from '../../../widgets/OutsideCardHeader'
import type { TeamRouteParams, UserRouteParams } from '../dashboard-routes'

type DateRangeHeaderProps = {
	readonly children?: ReactNode
	readonly className?: string
}

export const DateRangeHeader = ({ children, className }: DateRangeHeaderProps) => {
	const match = useRouteMatch<TeamRouteParams | UserRouteParams>()

	const formatDateRange = (d: DateRangeFilter) =>
		moment.duration(d.value, d.unit).humanize().split(' ').map(capitalize).join(' ')

	const DateRangeButtons = useMemo(
		() =>
			[DateRangeFilters.ONE_WEEK, DateRangeFilters.TWO_WEEKS /*, DateRangeFilters.ONE_MONTH*/].map(
				(d) => (
					<LinkContainer
						key={d.id}
						to={generatePath(match.path, { ...match.params, dateRange: d.id.toLowerCase() })}
					>
						<Button size="sm" variant="primary">
							{formatDateRange(d)}
						</Button>
					</LinkContainer>
				),
			),
		[match.url],
	)

	return (
		<div className={cls('dashboard-date-range-header mt-n4', className)}>
			<OutsideCardHeader>{children}</OutsideCardHeader>

			<ButtonGroup className="grid-gutter-padding">{DateRangeButtons}</ButtonGroup>
		</div>
	)
}
