import './MenuListWithTooltip.scss'
import cls from 'classnames'
import { MenuWithTooltip } from '../MenuWithTooltip'

export type MenuListWithTooltipPropsItem<T> = {
	readonly className?: string
	readonly label: string
	readonly onClick: (data: T) => void
}

export type MenuListWithTooltipProps<T> = {
	readonly data: T
	readonly items: MenuListWithTooltipPropsItem<T>[]
	readonly stopPropagation?: boolean
}

export const MenuListWithTooltip = <T,>({
	data,
	items,
	stopPropagation,
}: MenuListWithTooltipProps<T>) => (
	<MenuWithTooltip stopPropagation={stopPropagation}>
		<div className="d-flex flex-column menu-list-tooltip">
			{items.map((item) => (
				<button
					className={cls('menu-list-tooltip__option', item.className)}
					key={item.label}
					onClick={() => item.onClick(data)}
				>
					{item.label}
				</button>
			))}
		</div>
	</MenuWithTooltip>
)
