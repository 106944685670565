import { useFn } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import { deleteIntegration, fetchTeams, fetchUsers } from '@motiv-shared/reducers'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { setIntegrationsModal } from '../../reducers'
import { useAppDispatch } from '../../store'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { MotivModal } from '../../widgets/Modal'

export type DeleteIntegrationModalProps = {
	readonly integrationId: string
}

export const DeleteIntegrationModal = ({ integrationId }: DeleteIntegrationModalProps) => {
	const dispatch = useAppDispatch()

	const handleClose = useFn(() => {
		dispatch(setIntegrationsModal(null))
	})

	const handleDisconnectClick = useFn(async () => {
		sentryBreadcrumb('Deleting Integration')

		try {
			await dispatch(
				deleteIntegration({
					id: integrationId,
					indicatorRegion: IndicatorRegions.DISCONNECT_INTEGRATION,
				}),
			)

			// Fetch latest teams / users which maybe have been impacted by deletion
			await Promise.all([
				dispatch(fetchTeams({ force: true })).unwrap(),
				dispatch(fetchUsers({ force: true })).unwrap(),
			])

			handleClose()
		} catch (e) {
			sentryError(e, 'Error Deleting Integration')
		}
	})

	return (
		<MotivModal
			onHide={handleClose}
			size="sm"
			title="Are You Sure You Want to Disconnect Integration?"
		>
			<BusyIndicator region={IndicatorRegions.DISCONNECT_INTEGRATION}>
				<Modal.Body>
					<p>This action will delete teams associated with this integration.</p>
				</Modal.Body>

				<Modal.Footer>
					<Button onClick={handleDisconnectClick} size="lg" variant="danger">
						Disconnect
					</Button>

					<Button variant="light-link" size="lg" onClick={handleClose}>
						Cancel
					</Button>
				</Modal.Footer>
			</BusyIndicator>
		</MotivModal>
	)
}
