import { useBoolState, useConstant, useFn, useMounted } from '@eturi/react'
import { sentryError } from '@eturi/sentry'
import { diff, pick } from '@eturi/util'
import { account$, updateAccount } from '@motiv-shared/reducers'
import type {
	Account,
	AccountPatch,
	AccountSettingsData,
	ModalityValue,
} from '@motiv-shared/server'
import {
	DEFAULT_MODALITY,
	DEFAULT_WORK_DAYS,
	DEFAULT_WORK_END_TIME,
	DEFAULT_WORK_START_TIME,
	ModalityValues,
} from '@motiv-shared/server'
import { Form as FormikForm, Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { addSuccessToast } from '../../../reducers'
import { useAppDispatch } from '../../../store'
import type { FormikSubmit } from '../../../types'
import { formikChkProps, formikCtrlProps } from '../../../util'
import { bup } from '../../../util/batchUpdates'
import { loadTimezones } from '../../../util/timezones'
import { IndicatorRegions, LoadingFigure } from '../../../widgets/BusyIndicator'
import { FormValidationText } from '../../../widgets/FormValidationText'
import type { TzOption } from '../../../widgets/TimezoneSelect'
import { createTzOption, TimezoneSelect } from '../../../widgets/TimezoneSelect'
import type { SparseDaysOfWeek } from '../../../widgets/WeekdaySelector'
import {
	convertBitmapToDays,
	convertDaysToBitmap,
	WeekdaySelector,
} from '../../../widgets/WeekdaySelector'

type BusinessInfoFormData = {
	readonly companyName: string
	readonly modality: ModalityValue
	readonly timeZone: Maybe<TzOption>
	readonly workDays: SparseDaysOfWeek
	readonly workEndTime: string
	readonly workStartTime: string
}

type RawBusinessInfoData = MPick<Account, 'name' | 'timeZone'> & AccountSettingsData

const mapAccountToRawBusinessInfoData = ({
	name,
	settings,
	timeZone,
}: Account): RawBusinessInfoData => ({
	name,
	timeZone,
	modality: settings.modality,
	workDays: settings.workDays,
	workEndTime: settings.workEndTime,
	workStartTime: settings.workStartTime,
})

// NOTE: Since we're using this as a diff reference, we need all the keys
const mapRawBusinessInfoDataToFormData = (raw: RawBusinessInfoData): BusinessInfoFormData => ({
	companyName: raw.name,
	modality: DEFAULT_MODALITY,
	timeZone: createTzOption(raw.timeZone || moment.tz.guess()),
	workDays: convertBitmapToDays(raw.workDays || DEFAULT_WORK_DAYS),
	workEndTime: DEFAULT_WORK_END_TIME,
	workStartTime: DEFAULT_WORK_START_TIME,
	...pick(raw, ['modality', 'workEndTime', 'workStartTime']),
})

const mapBusinessInfoFormDataToRaw = (formData: BusinessInfoFormData): RawBusinessInfoData => ({
	name: formData.companyName,
	timeZone: formData.timeZone?.value,
	workDays: convertDaysToBitmap(formData.workDays),
	...pick(formData, ['modality', 'workEndTime', 'workStartTime']),
})

const mapRawBusinessInfoPatchToAccountPatch = (raw: Partial<RawBusinessInfoData>): AccountPatch => {
	const patch: Writable<AccountPatch> = pick(raw, ['name', 'timeZone'])
	const addSettings = pick(raw, ['modality', 'workDays', 'workEndTime', 'workStartTime'])

	if (!isEmpty(addSettings)) {
		patch.addSettings = addSettings
	}

	return patch
}

// NOTE: We wrap the form itself so can ensure that timezones are loaded prior
//  to form init. Otherwise we won't be able to create a timezone option from
//  the account timezone.
export const BusinessInfoForm = () => {
	const [isTzLoaded, setTzLoaded] = useBoolState(false)

	useEffect(() => {
		loadTimezones().then(setTzLoaded)
	}, [])

	return isTzLoaded ? <BusinessInfoFormImpl /> : <LoadingFigure />
}

// TODO: Consider putting Save button right aligned at md+
// TODO: Maybe grid hack for evenly sized work days buttons
const BusinessInfoFormImpl = () => {
	const dispatch = useAppDispatch()
	const account = useSelector(account$)!
	const [rawBusinessInfoData, setRawBusinessInfoData] = useState(() =>
		mapAccountToRawBusinessInfoData(account),
	)
	const [isSaving, startSaving, stopSaving] = useBoolState(false)
	const isMounted = useMounted()

	const initialValues = useMemo(
		() => mapRawBusinessInfoDataToFormData(rawBusinessInfoData),
		[rawBusinessInfoData],
	)

	const COMPANY_MAX_LENGTH = 250
	// Basically, we don't want people to be able to type forever, but we DO want
	// to show validation error. If we set the input max length to the validation
	// max length, the browser won't let people type more than 250 characters and
	// so they'll never see the validation message. So we need to add 1 more to
	// that. Additionally, the 250 limit is trimmed, so we need to add 2 more to
	// account for whitespace at the start and end.
	const COMPANY_INPUT_MAX_LENGTH = COMPANY_MAX_LENGTH + 3

	const VALIDATION_SCHEMA = useConstant(() => {
		// NOTE: This is a more strict validation than the server does, but it's okay
		//  because it's a subset, and this is what the browser will return.
		const timeSchema = Yup.string()
			.required('Enter a valid time.')
			.matches(/^([0-9]|[01][0-9]|2[0123]):([0-5][0-9])(?::?([0-5][0-9]))?$/, 'Enter a valid time.')

		return Yup.object().shape({
			companyName: Yup.string()
				.trim()
				.required('Enter a company name.')
				.min(2, 'Company name should be at least 2 characters.')
				.max(COMPANY_MAX_LENGTH, 'Company name cannot exceed 250 characters.'),

			timeZone: Yup.mixed()
				.test({
					message: 'Select a time zone.',
					name: 'tz',
					test: (v: Maybe<TzOption>) => Boolean(v?.label && v?.value),
				})
				.nullable(),

			workDays: Yup.mixed().test({
				message: 'Select at least one day.',
				name: 'wd',
				test: (v: SparseDaysOfWeek) => v.some(Boolean),
			}),

			workEndTime: timeSchema,
			workStartTime: timeSchema,
		})
	})

	// HTML time step is in seconds and we want 15 minute increments by default
	const TIME_STEP = 15 /* minutes */ * 60 /* seconds */

	const handleSubmit: FormikSubmit<BusinessInfoFormData> = useFn(async (v, formikHelpers) => {
		if (isSaving) return

		const newRawBusinessInfoData = mapBusinessInfoFormDataToRaw(v)
		const rawPatch = diff(rawBusinessInfoData, newRawBusinessInfoData)

		// If these values haven't changed, the form hasn't been modified.
		// Note that we explicitly reset the form here so that the submitCount and
		// therefore, validation styles, aren't applied superfluously.
		if (isEmpty(rawPatch)) return formikHelpers.resetForm()

		startSaving()

		try {
			await dispatch(
				updateAccount({
					accountId: account.id,
					// Convert the raw patch to AccountPatch
					patch: mapRawBusinessInfoPatchToAccountPatch(rawPatch),
					indicatorRegion: IndicatorRegions.ACCOUNT,
				}),
			).unwrap()

			dispatch(addSuccessToast('Account business information was updated successfully.'))

			if (isMounted()) {
				bup(() => {
					stopSaving()
					setRawBusinessInfoData(newRawBusinessInfoData)
				})
			}
		} catch (e) {
			sentryError(e, 'Failed to update account')
			isMounted() && stopSaving()
		}
	})

	return (
		<Formik<BusinessInfoFormData>
			enableReinitialize
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={VALIDATION_SCHEMA}
		>
			{(fp) => {
				const getCtrlProps = formikCtrlProps(fp)
				const getModalityProps = formikChkProps(fp, 'modality', 'radio')

				return (
					<Form as={FormikForm} noValidate>
						<Row>
							<Form.Group as={Col} md={6}>
								<Form.Label>Company Name</Form.Label>

								<Form.Control
									placeholder="Enter company name"
									maxLength={COMPANY_INPUT_MAX_LENGTH}
									{...getCtrlProps('companyName')}
								/>

								<FormValidationText field="companyName" formikProps={fp} />
							</Form.Group>

							<Form.Group as={Col} md={6}>
								<Form.Label className="d-block">Modality</Form.Label>

								<Form.Check
									id="modalityCheck1"
									inline
									label="Remote"
									{...getModalityProps(ModalityValues.REMOTE)}
								/>

								<Form.Check
									id="modalityCheck2"
									inline
									label="In-Office"
									{...getModalityProps(ModalityValues.IN_OFFICE)}
								/>

								<Form.Check
									id="modalityCheck3"
									inline
									label="Hybrid"
									{...getModalityProps(ModalityValues.HYBRID)}
								/>
							</Form.Group>
						</Row>

						<Row>
							<Form.Group as={Col} md={6}>
								<Form.Label>Work Days</Form.Label>

								<WeekdaySelector className="justify-content-md-between w-100" name="workDays" />

								<FormValidationText field="workDays" formikProps={fp} />
							</Form.Group>

							<Form.Group as={Col} md={6}>
								<Row>
									<Col xs={6}>
										<Form.Label>Start Time:</Form.Label>

										<Form.Control type="time" step={TIME_STEP} {...getCtrlProps('workStartTime')} />

										<FormValidationText field="workStartTime" formikProps={fp} />
									</Col>

									<Col xs={6}>
										<Form.Label>End Time:</Form.Label>

										<Form.Control type="time" step={TIME_STEP} {...getCtrlProps('workEndTime')} />

										<FormValidationText field="workEndTime" formikProps={fp} />
									</Col>
								</Row>
							</Form.Group>
						</Row>

						<Row>
							<Form.Group as={Col} md={6}>
								<Form.Label>Time Zone</Form.Label>

								<TimezoneSelect name="timeZone" />

								<FormValidationText field="timeZone" formikProps={fp} />
							</Form.Group>
						</Row>

						<div className="d-flex mt-4">
							<Button
								className="mx-auto"
								disabled={isSaving}
								style={{ minWidth: '200px' }}
								size="lg"
								variant="success"
								type="submit"
							>
								Save
							</Button>
						</div>
					</Form>
				)
			}}
		</Formik>
	)
}
