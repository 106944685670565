import { arrayAddOrUpdate, pick, setIfNotEqual } from '@eturi/util'
import type { MotivUser } from '@motiv-shared/server'
import { isIdentifySuccessRes } from '@motiv-shared/server'
import type { Draft } from '@reduxjs/toolkit'
import { isAnyOf } from '@reduxjs/toolkit'
import { castDraft } from 'immer'
import reject from 'lodash/reject'
import { authIdentifyCompleteMatcher } from '../auth'
import type { CachedState } from '../createCacheSlice'
import { createCacheSlice } from '../createCacheSlice'
import type { HttpInitHandler } from '../createHttpInitHandler'
import { createUser, deleteUser, fetchUser, fetchUsers, updateUser } from './users.asyncActions'

export type UsersState = CachedState<MotivUser[]>
export type WithUsersState = {
	readonly users: UsersState
}

const initialData: MotivUser[] = []

export const createUsersSlice = <State>(httpInitHandler: HttpInitHandler<State>) =>
	createCacheSlice('users', initialData, httpInitHandler, (builder) =>
		builder
			.addCachedCase(fetchUsers.fulfilled, (s, a) => {
				setIfNotEqual(s, 'data', castDraft(a.payload))
				s.isInit = true
			})

			.addCachedCase(fetchUser.fulfilled, (s, a) => {
				storeUser(s, a.payload)
			})

			.addCase(deleteUser.fulfilled, (s, a) => {
				s.data = reject(s.data, { id: a.meta.arg.userId })
			})

			.addMatcher(authIdentifyCompleteMatcher, (s, { payload }) => {
				if (isIdentifySuccessRes(payload)) {
					storeUser(s, payload.user)
				}
			})

			.addMatcher(isAnyOf(createUser.fulfilled, updateUser.fulfilled), (s, a) => {
				storeUser(s, a.payload)
			}),
	)

const storeUser = (s: Draft<UsersState>, user: MotivUser) => {
	s.data = arrayAddOrUpdate(s.data, user, 'id')
}

export const sanitizeMotivUser = (u: Maybe<MotivUser>) =>
	u
		? pick(u, [
				'accountId',
				'id',
				'isInviteAccepted',
				'isSetupInstructionsEmailSent',
				'permissions',
				'roleId',
				'setupInstructionEmailSentAt',
				'settings',
		  ])
		: null

export const sanitizeUsersState = (s: UsersState) => ({
	...s,
	data: s.data.map(sanitizeMotivUser),
})
