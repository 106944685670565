import { createUsePolling } from '@eturi/react'
import { selectUnaryEvery } from '@eturi/util'
import { createSelector } from '@reduxjs/toolkit'
import { canAccessProtected$ } from '../compound-selectors'
import type { HttpExtra } from '../http'
import { hasBrowserFocus$, isNetworkOnline$ } from '../reducers'

export const shouldPoll$ = /* @__PURE__ */ createSelector(
	canAccessProtected$,
	hasBrowserFocus$,
	isNetworkOnline$,
	selectUnaryEvery,
)

export const usePolling = /* @__PURE__ */ createUsePolling(
	shouldPoll$,
	(): HttpExtra => ({ force: 'soft', ignoreIndicator: true }),
)
