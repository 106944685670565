import { useConstant, useFn } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import { updateUserPassword, user$ } from '@motiv-shared/reducers'
import { Form as FormikForm, Formik } from 'formik'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { addErrorToast, addSuccessToast } from '../../../reducers'
import { useAppDispatch } from '../../../store'
import type { FormikSubmit } from '../../../types'
import { formikCtrlProps } from '../../../util'
import { IndicatorRegions } from '../../../widgets/BusyIndicator'
import { FormValidationText } from '../../../widgets/FormValidationText'

const PW_REGEXP =
	/(?=.{8,})((?=.*\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])).*/
const { UPDATE_PASSWORD } = IndicatorRegions

type UpdatePwFormData = {
	readonly confirmPassword: string
	readonly currentPassword: string
	readonly newPassword: string
}

export const UpdatePasswordForm = () => {
	const dispatch = useAppDispatch()
	const { id: userId } = useSelector(user$)!

	const VALIDATION_SCHEMA = useConstant(() =>
		Yup.object().shape({
			confirmPassword: Yup.string()
				.required('This field cannot be empty.')
				.oneOf([Yup.ref('newPassword')], 'Passwords must match.')
				.default(''),

			currentPassword: Yup.string().required('This field cannot be empty.').default(''),

			newPassword: Yup.string()
				.required('This field cannot be empty.')
				.matches(
					PW_REGEXP,
					`8 characters including at least 3 of the following 4 types of
					characters: a lower-case letter, an upper-case letter, a number, a 
					special character (such as !@#$%^&*)`,
				)
				.default(''),
		}),
	)

	const INITIAL_VALUES = useConstant((): UpdatePwFormData => VALIDATION_SCHEMA.cast({}))

	const handleSubmit: FormikSubmit<UpdatePwFormData> = useFn(async (values, { resetForm }) => {
		sentryBreadcrumb('Updating Password')

		const passwordPatch = {
			newPassword: values.newPassword,
			currentPassword: values.currentPassword,
		}

		try {
			const res = await dispatch(
				updateUserPassword({ userId, passwordPatch, region: UPDATE_PASSWORD }),
			).unwrap()

			if (res.isCurrentPasswordInvalid) {
				dispatch(addErrorToast('Current password is invalid!'))
			} else if (res.isCurrentUserPasswordUpdated) {
				dispatch(
					addSuccessToast({
						msg: 'Your password has been changed successfully.',
						title: 'Password Updated',
					}),
				)
			}

			resetForm()
		} catch (e) {
			sentryError(e, 'Failed to update password')
		}
	})

	return (
		<Formik<UpdatePwFormData>
			initialValues={INITIAL_VALUES}
			onSubmit={handleSubmit}
			validationSchema={VALIDATION_SCHEMA}
		>
			{(fp) => {
				const getCtrlProps = formikCtrlProps(fp)

				return (
					<Form as={FormikForm}>
						<Form.Group controlId="currentPwField">
							<Form.Label>Current Password</Form.Label>

							<Form.Control
								placeholder="Current Password"
								type="password"
								{...getCtrlProps('currentPassword')}
							/>

							<FormValidationText field="currentPassword" formikProps={fp} />
						</Form.Group>

						<Form.Group controlId="newPwField">
							<Form.Label>New Password</Form.Label>

							<Form.Control
								placeholder="New Password"
								type="password"
								{...getCtrlProps('newPassword')}
							/>

							<FormValidationText field="newPassword" formikProps={fp} />
						</Form.Group>

						<Form.Group controlId="confirmPwField">
							<Form.Label>Confirm New Password</Form.Label>

							<Form.Control
								placeholder="Confirm Password"
								type="password"
								{...getCtrlProps('confirmPassword')}
							/>

							<FormValidationText field="confirmPassword" formikProps={fp} />
						</Form.Group>

						<Button block disabled={fp.isSubmitting} size="lg" type="submit" variant="success">
							Save
						</Button>
					</Form>
				)
			}}
		</Formik>
	)
}
