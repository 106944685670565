import { useFn } from '@eturi/react'
import { isActiveSubFree$ } from '@motiv-shared/reducers'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useIntegrationFlow } from '../../../IntegrationFlow'
import { setIntegrationsModal } from '../../../reducers'
import { useAppDispatch } from '../../../store'
import type { IntegrationDecorated } from '../../../types'
import { createMotivTableData } from '../../../widgets/Table'
import { IntegrationsModals } from '../../IntegrationsModal'
import { connectIntegrationFormatter, integrationNameFormatter } from './integrations.formatters'

export const useIntegrationsTableProps = (integrations: IntegrationDecorated[]) => {
	const dispatch = useAppDispatch()
	const { connect, disconnect } = useIntegrationFlow()
	const isActiveSubFree = useSelector(isActiveSubFree$)

	const integrationConnect = useFn(({ integrationProvider, isPreConfigurable }) => {
		if (isPreConfigurable) {
			dispatch(
				setIntegrationsModal({ type: IntegrationsModals.PRE_INTEGRATION_URL, integrationProvider }),
			)
		} else {
			connect(integrationProvider)
		}
	})

	const configureIntegration = useFn(({ integrationProvider }: IntegrationDecorated) => {
		dispatch(
			setIntegrationsModal({
				type: IntegrationsModals.POST_INTEGRATION_CONFIG,
				integrationProvider,
			}),
		)
	})

	return useMemo(
		() =>
			createMotivTableData({ data: integrations, keyField: 'integrationProvider' })
				.addColumn('displayName', {
					className: 'align-middle w-50',
					header: 'Name',
					render: integrationNameFormatter,
					sort: true,
				})
				.addColumn('id', {
					className: 'align-middle',
					extra: {
						isActiveSubFree,
						onConfigure: configureIntegration,
						onConnect: integrationConnect,
						// eslint-disable-next-line
						onDisconnect: ({ id }) => disconnect(id!),
					},
					header: '',
					render: connectIntegrationFormatter,
				})
				.props(),
		[integrations, isActiveSubFree],
	)
}
