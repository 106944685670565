import { useBoolState, useFn, useMounted } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import { managedUsers$, updateManagedUser } from '@motiv-shared/reducers'
import { ManagedUserState } from '@motiv-shared/server'
import find from 'lodash/find'
import { useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import { addSuccessToast, setSeatModal } from '../../reducers'
import { useAppDispatch } from '../../store'
import { MotivModal } from '../../widgets/Modal'

export type SuspendSeatsModalProps = {
	readonly ids: string[]
}

export const SuspendSeatsModal = ({ ids }: SuspendSeatsModalProps) => {
	const dispatch = useAppDispatch()
	const managedUsers = useSelector(managedUsers$)
	const [isSaving, startSaving, stopSaving] = useBoolState(false)
	const isMounted = useMounted()

	const hasOne = ids.length === 1
	const managedUser = useMemo(
		() => (hasOne ? find(managedUsers, { id: ids[0] }) : null),
		[ids, managedUsers],
	)

	const handleClose = useFn(() => {
		dispatch(setSeatModal(null))
	})

	const handleRemoveClick = useFn(async () => {
		if (isSaving) return

		sentryBreadcrumb(`Suspending ${ids.length} users`)

		startSaving()

		// TODO: When backend allows modifying status to suspend users, make that
		//  call here. This is a rough draft and should reflect the contract, but
		//  may need to be revisited.
		try {
			await Promise.all(
				ids.map((id) =>
					dispatch(updateManagedUser({ id, patch: { state: ManagedUserState.INACTIVE } })).unwrap(),
				),
			)

			const toastMsg = managedUser
				? `${managedUser.fullName} has been removed from your active seats.`
				: `${ids.length} seats have been removed.`

			dispatch(addSuccessToast(toastMsg))
			handleClose()
		} catch (e) {
			sentryError(e, 'Failed to suspend users')
			isMounted() && stopSaving()
		}
	})

	const confirmationText = useMemo(
		() =>
			`Are you sure you want to remove ${
				managedUser ? managedUser.fullName : 'these seats'
			} from your active seats? Team assignments will not be lost but you won't 
			be able to access reports for ${managedUser ? 'this' : 'these'} team 
			member${managedUser ? '' : 's'}.`,
		[managedUser],
	)

	return (
		<MotivModal onHide={handleClose} size="sm" title={`Suspend Seat${hasOne ? '' : 's'}`}>
			<Modal.Body>
				<p>{confirmationText}</p>
			</Modal.Body>

			<Modal.Footer>
				<Button size="lg" variant="light-link" onClick={handleClose}>
					Cancel
				</Button>

				<Button disabled={isSaving} size="lg" variant="danger" onClick={handleRemoveClick}>
					Remove
				</Button>
			</Modal.Footer>
		</MotivModal>
	)
}
