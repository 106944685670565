import { useFn } from '@eturi/react'
import { float } from '@eturi/util'
import { AxisBottom, AxisLeft } from '@visx/axis'
import { curveMonotoneX } from '@visx/curve'
import { localPoint } from '@visx/event'
import { GridRows } from '@visx/grid'
import { Group } from '@visx/group'
import { LegendOrdinal } from '@visx/legend'
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale'
import { Bar, LinePath } from '@visx/shape'
import { Tooltip, useTooltip } from '@visx/tooltip'
import clamp from 'lodash/clamp'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import type { MouseEvent, TouchEvent } from 'react'
import { useLayoutEffect, useMemo, useState } from 'react'
import Colors from '../../../styles/color-exports.module.scss'
import {
	DEFAULT_AXIS_X_PROPS,
	DEFAULT_AXIS_Y_PROPS,
	DEFAULT_GRID_STROKE_PROPS,
	DEFAULT_LEGEND_MARGIN_PROPS,
	DEFAULT_LINE_STROKE_WIDTH,
	formatDateDefault,
} from '../defaults'
import { useDateAxisFormat } from '../hooks'
import { LegendContainer } from '../LegendContainer'
import type { TooltipLineProps } from '../TooltipLine'
import { TooltipLine } from '../TooltipLine'
import type { Dimensions } from '../types'

const LINE_COLOR = Colors.purple
const STROKE_WIDTH = DEFAULT_LINE_STROKE_WIDTH

type TooltipData = {
	readonly value: string
	readonly x: number
	readonly y: number
}

export type EmailOverviewData = {
	readonly date: string
	readonly value: number
}

export type EmailOverviewChartProps = Dimensions & {
	readonly data: EmailOverviewData[]
	readonly legendLabel: string
}

export const EmailOverviewGraph = ({
	data,
	height,
	innerHeight,
	innerWidth,
	legendLabel,
	margin,
	width,
}: EmailOverviewChartProps) => {
	const dateAxisFormat = useDateAxisFormat()
	const { hideTooltip, showTooltip, tooltipData, tooltipOpen } = useTooltip<TooltipData>()
	const { minX, maxX, minY, maxY, xValues } = useMinMaxValues(data)

	const getMidpointDatum = useFn(() => {
		const midpointIdx = Math.floor(data.length / 2)
		const datum = data[midpointIdx]

		return datum || null
	})

	const [tooltipDate, setTooltipDate] = useState(() => getDate(getMidpointDatum()))

	const legendScale = useMemo(
		() => scaleOrdinal<string>({ domain: [legendLabel], range: [Colors.purple] }),
		[legendLabel],
	)

	const xScale = useMemo(
		() =>
			scaleBand<number>({
				domain: xValues,
				range: [0, innerWidth],
			}),
		[innerWidth, xValues],
	)

	const yScale = useMemo(
		() =>
			scaleLinear<number>({
				domain: [minY, maxY],
				range: [innerHeight, 0],
			}),
		[innerHeight, maxY, minY],
	)

	const getX = useFn((d: EmailOverviewData) => xScale(getDate(d)) || 0)
	const getY = useFn((d: EmailOverviewData) => yScale(getValue(d)) || 0)

	const tickStep = xScale.step()
	// Bottom Axis ticks have a buffer on the left and right side to allow space
	// for the label
	const tickPadding = tickStep / 2

	const handleTooltip = useFn((event: TouchEvent<SVGRectElement> | MouseEvent<SVGRectElement>) => {
		// Get index of the closest data point to the event
		const { x: rawX } = localPoint(event) || { x: 0 }
		const unboundedX = rawX - margin.left - tickPadding
		const unboundedIndex = Math.round(unboundedX / tickStep)
		const datum = data[clamp(unboundedIndex, 0, data.length - 1)]

		if (datum) setTooltipDate(getDate(datum))
	})

	const showTooltipByDate = useFn((date: number) => {
		const datum = data.find((d) => getDate(d) === date)

		if (!datum) {
			const midpointDatum = getMidpointDatum()

			return midpointDatum ? setTooltipDate(getDate(midpointDatum)) : hideTooltip()
		}

		showTooltip({
			tooltipData: {
				value: formatTooltip(date),
				x: getX(datum),
				y: getY(datum),
			},
		})
	})

	const tooltipLineProps = useMemo((): Maybe<TooltipLineProps> => {
		if (!tooltipData) return null

		const x = tooltipData.x + tickPadding

		return {
			points: [{ color: Colors.purple, x, y: tooltipData.y }],
			x,
			y: innerHeight,
		}
	}, [innerHeight, tickPadding, tooltipData])

	useLayoutEffect(() => {
		showTooltipByDate(tooltipDate)
	}, [data, height, innerHeight, innerWidth, tooltipDate, width])

	if (isEmpty(data)) {
		return <div>No Data</div>
	}

	return (
		<>
			<LegendContainer className="justify-content-start">
				<LegendOrdinal
					{...DEFAULT_LEGEND_MARGIN_PROPS}
					direction="row"
					itemDirection="row-reverse"
					labelMargin={0}
					style={{ marginLeft: 6 }}
					scale={legendScale}
					shape={({ fill }) => {
						const yValue = tooltipData == null ? 0 : tooltipData.y

						return (
							<span className="legend-data-value" style={{ backgroundColor: fill }}>
								{float(yScale.invert(yValue))}
							</span>
						)
					}}
				/>
			</LegendContainer>

			<svg width={width} height={height}>
				<Group height={innerHeight} left={margin.left} top={margin.top} width={innerWidth}>
					<GridRows
						{...DEFAULT_GRID_STROKE_PROPS}
						height={innerHeight}
						scale={yScale}
						width={innerWidth}
					/>

					<Group left={tickPadding}>
						<LinePath
							curve={curveMonotoneX}
							data={data}
							stroke={LINE_COLOR}
							strokeWidth={STROKE_WIDTH}
							x={getX}
							y={getY}
						/>

						<Bar
							fill="transparent"
							height={yScale(minY) - yScale(maxY)}
							onMouseMove={handleTooltip}
							onTouchStart={handleTooltip}
							rx={14}
							width={xScale(maxX)! - xScale(minX)!}
						/>
					</Group>

					<AxisBottom
						{...DEFAULT_AXIS_X_PROPS}
						scale={xScale}
						tickFormat={dateAxisFormat}
						top={innerHeight}
					/>

					<AxisLeft {...DEFAULT_AXIS_Y_PROPS} scale={yScale} />

					{tooltipOpen && tooltipLineProps && (
						<TooltipLine key={Math.random()} {...tooltipLineProps} />
					)}
				</Group>
			</svg>

			{tooltipOpen && tooltipData && (
				<Tooltip
					style={{
						left: tooltipData.x + margin.left + tickPadding,
						top: margin.top,
					}}
				>
					{tooltipData.value}
				</Tooltip>
			)}
		</>
	)
}

const useMinMaxValues = (chartData: EmailOverviewData[]) =>
	useMemo(() => {
		const dates = chartData.map(getDate)

		return {
			maxX: Math.max(...dates),
			maxY: Math.max(...map(chartData, 'value')) + 5,
			minX: Math.min(...dates),
			minY: 0,
			xValues: dates,
		}
	}, [chartData])

const getDate = (d: Maybe<EmailOverviewData>) => (d ? Date.parse(d.date) : 0)
const getValue = (d: EmailOverviewData) => d.value
const formatTooltip = formatDateDefault
