import Card from 'react-bootstrap/Card'
import { useSelector } from 'react-redux'
import { currentIntegrationsDecorated$ } from '../../../reducers'
import type { IntegrationDecorated } from '../../../types'
import { OutsideCardHeader } from '../../../widgets/OutsideCardHeader'
import { MotivTable } from '../../../widgets/Table'
import { useIntegrationsTableProps } from './useIntegrationTableProps'

export const CurrentIntegrations = ({ className }: { readonly className?: string }) => {
	const currentIntegrationsDecorated = useSelector(currentIntegrationsDecorated$)

	if (!currentIntegrationsDecorated.length) return null

	return (
		<CurrentIntegrationsImpl
			className={className}
			currentIntegrations={currentIntegrationsDecorated}
		/>
	)
}

const CurrentIntegrationsImpl = ({
	className,
	currentIntegrations,
}: {
	readonly className?: string
	readonly currentIntegrations: IntegrationDecorated[]
}) => {
	const tableProps = useIntegrationsTableProps(currentIntegrations)

	return (
		<div className={className}>
			<OutsideCardHeader>Current Integrations</OutsideCardHeader>

			<Card>
				<MotivTable {...tableProps} />
			</Card>
		</div>
	)
}
