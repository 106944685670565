import type {
	CreateIntegrationInfo,
	Integration,
	IntegrationCatalogData,
	IntegrationConfigPatchBody,
	IntegrationConfigRes,
	IntegrationProvider,
} from '@motiv-shared/server'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState'
import type { HttpExtra } from '../http'
import type { WithIntegrationsState } from './createIntegrationsSlice'

const createAsyncThunk = /* @__PURE__ */ bindCreateAsyncThunkToState<WithIntegrationsState>()

type CreateIntegrationArgs = HttpExtra & {
	readonly provider: IntegrationProvider
	readonly config?: Record<string, any>
}

export const createIntegration = /* @__PURE__ */ createAsyncThunk(
	'integrations/createIntegration',
	({ provider, config, ...extra }: CreateIntegrationArgs, { dispatch, extra: { http } }) =>
		dispatch(
			http.post<CreateIntegrationInfo>(`integrations?provider=${provider}`, {
				data: config ? { config } : undefined,
				errorMessage: 'Unable to start integration. Please try again later.',
				ignoreIndicator: true,
				...extra,
			}),
		),
)

type DeleteIntegrationArgs = HttpExtra & {
	readonly id: string
}

export const deleteIntegration = /* @__PURE__ */ createAsyncThunk(
	'integration/Id',
	({ id, ...extra }: DeleteIntegrationArgs, { dispatch, extra: { http } }) =>
		dispatch(
			http.delete(`integrations/${id}`, {
				errorMessage: 'Unable to disconnect integration. Please try again later.',
				...extra,
			}),
		),
)

export const fetchIntegrations = /* @__PURE__ */ createAsyncThunk(
	'integrations',
	(extra: HttpExtra = {}, { dispatch, extra: { http } }) =>
		dispatch(
			http.get<Integration[]>(`integrations`, {
				errorMessage: 'Unable to load integrations. Please try again later.',
				...extra,
			}),
		),
)

export const fetchIntegrationCatalog = /* @__PURE__ */ createAsyncThunk(
	'integrations-catalog',
	(extra: HttpExtra = {}, { dispatch, extra: { http } }) => {
		return dispatch(
			http.get<IntegrationCatalogData[]>(`integrations-catalog`, {
				errorMessage: 'Unable to load integrations. Please try again later.',
				...extra,
			}),
		)
	},
)

type IntegrationConfigArgs = HttpExtra & {
	readonly integrationId: string
}

export const fetchIntegrationConfig = /*@__PURE__*/ createAsyncThunk(
	'integration-config',
	({ integrationId, ...extra }: IntegrationConfigArgs, { dispatch, extra: { http } }) => {
		return dispatch(
			http.get<IntegrationConfigRes>(`integration-config/${integrationId}?context=true`, {
				errorMessage: 'Unable to load integration information. Please try again later.',
				...extra,
			}),
		)
	},
)

type IntegrationConfigUpdateArgs = HttpExtra & {
	readonly integrationId: string
	readonly update: IntegrationConfigPatchBody
}

export const updateIntegrationConfig = /*@__PURE__*/ createAsyncThunk(
	'integration-config/update',
	(
		{ integrationId, update, ...extra }: IntegrationConfigUpdateArgs,
		{ dispatch, extra: { http } },
	) =>
		dispatch(
			http.patch<IntegrationConfigRes>(`integration-config/${integrationId}`, {
				data: update,
				errorMessage: 'Unable to update integration. Please try again later.',
				...extra,
			}),
		),
)
