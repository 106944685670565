import { useBoolState, useConstant, useFn, useMounted } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import { createUser } from '@motiv-shared/reducers'
import { UserRoles } from '@motiv-shared/server'
import { Form as FormikForm, Formik } from 'formik'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'
import { setUserModal } from '../../reducers'
import { useAppDispatch } from '../../store'
import { formikCtrlProps } from '../../util'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { FormValidationText } from '../../widgets/FormValidationText'
import { MotivModal } from '../../widgets/Modal'

type InviteAdminFormValues = {
	readonly email: string
	readonly firstName: string
	readonly lastName: string
}

export const InviteAdminModal = () => {
	const dispatch = useAppDispatch()

	const isMounted = useMounted()
	const [isSaving, startSaving, stopSaving] = useBoolState(false)

	const VALIDATION_SCHEMA = useConstant(() =>
		Yup.object().shape({
			email: Yup.string().required('Email is required.').email('Invalid email'),

			firstName: Yup.string()
				.trim()
				.required('First Name is required.')
				.max(70, 'Character Limit Reached'),

			lastName: Yup.string()
				.trim()
				.required('Last Name is required.')
				.max(70, 'Character Limit Reached'),
		}),
	)

	const INITIAL_VALUES = useConstant(
		(): InviteAdminFormValues => ({
			email: '',
			firstName: '',
			lastName: '',
		}),
	)

	const handleClose = useFn(() => {
		dispatch(setUserModal(null))
	})

	const handleSave = useFn(async (v: InviteAdminFormValues) => {
		sentryBreadcrumb('Inviting admin')
		startSaving()

		try {
			const validUser = VALIDATION_SCHEMA.validateSync(v)

			const fulfilledUser = await dispatch(
				createUser({ user: { ...validUser, roleId: UserRoles.ADMIN } }),
			).unwrap()

			if (fulfilledUser) handleClose()
		} catch (e) {
			sentryError(e, `Unable to invite admin: ${e}`)
		}

		isMounted() && stopSaving()
	})

	return (
		<Formik<InviteAdminFormValues>
			initialValues={INITIAL_VALUES}
			onSubmit={handleSave}
			validationSchema={VALIDATION_SCHEMA}
		>
			{(p) => {
				const getCtrlProps = formikCtrlProps(p)

				return (
					<MotivModal onHide={handleClose} size="sm" title="Send Email Link to Someone Else">
						<BusyIndicator region={IndicatorRegions.ADD_NEW_USER}>
							<Modal.Body>
								<p>
									Enter the email address of the person who is able to integrate Motiv with your
									team's tools. We’ll send them an email link to join you.
								</p>

								<Form as={FormikForm}>
									<Form.Group>
										<Form.Label>First Name</Form.Label>

										<Form.Control
											maxLength={73}
											placeholder="First Name"
											{...getCtrlProps('firstName')}
										/>

										<FormValidationText field="firstName" formikProps={p} />
									</Form.Group>

									<Form.Group>
										<Form.Label>Last Name</Form.Label>

										<Form.Control
											maxLength={73}
											placeholder="Last Name"
											{...getCtrlProps('lastName')}
										/>

										<FormValidationText field="lastName" formikProps={p} />
									</Form.Group>

									<Form.Group>
										<Form.Label>Email Address</Form.Label>

										<Form.Control
											inputMode="email"
											placeholder="Email Address"
											type="email"
											{...getCtrlProps('email')}
										/>

										<FormValidationText field="email" formikProps={p} />
									</Form.Group>
								</Form>
							</Modal.Body>

							<Modal.Footer>
								<Button disabled={isSaving} onClick={p.submitForm} size="lg" variant="success">
									Send Invite Link
								</Button>
							</Modal.Footer>
						</BusyIndicator>
					</MotivModal>
				)
			}}
		</Formik>
	)
}
