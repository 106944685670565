import cls from 'classnames'
import isFunction from 'lodash/isFunction'
import type { KeyboardClickEvent } from '../../hooks'
import { useKeyboardClick } from '../../hooks'
import type { MotivTableColDesc } from './MotivTableColDesc'
import { MotivTableDataCell } from './MotivTableDataCell'
import type { MotivTableSelectRow, SelectRowKey } from './MotivTableSelectRow'
import { MotivSelectCol } from './MotivTableSelectRow'

export type MotivTableRowClickHandler<Data> = (
	ev: KeyboardClickEvent<HTMLTableRowElement>,
	data: Data,
	isDisabled: boolean,
	isSelected: boolean,
) => void

export type MotivTableRowProps<Data, KeyField extends PlainKey<Data>> = {
	readonly columns: MotivTableColDesc<Data>[]
	readonly data: Data
	readonly id: SelectRowKey<Data, KeyField>
	readonly isDisabled: boolean
	readonly isSelected: boolean
	readonly onClick?: MotivTableRowClickHandler<Data>
	readonly onSelect: (isSelected: boolean, key: SelectRowKey<Data, KeyField>) => void
	readonly selectRow?: MotivTableSelectRow<Data, KeyField>
}

export const MotivTableRow = <Data, KeyField extends PlainKey<Data>>({
	columns,
	data,
	id,
	isDisabled,
	isSelected,
	onClick,
	onSelect,
	selectRow,
}: MotivTableRowProps<Data, KeyField>) => {
	const selectRowClassName = selectRow?.className

	const className = cls(
		'motiv-table-row',
		{
			'motiv-table-row-select': selectRow,
			'motiv-table-row-select--disabled': isDisabled,
			'motiv-table-row-select--selected': isSelected,
		},
		isFunction(selectRowClassName)
			? selectRowClassName(isDisabled, isSelected)
			: selectRowClassName,
	)

	const handleSelectClick = useKeyboardClick<HTMLTableRowElement>((ev) => {
		if (!selectRow || isDisabled) {
			// Always handle onClick if it's defined
			if (onClick) onClick(ev, data, isDisabled, isSelected)

			return
		}

		let nextIsSelected = !isSelected

		nextIsSelected = selectRow.onSelect?.(nextIsSelected, data, ev) ?? nextIsSelected

		if (isSelected === nextIsSelected) return

		onSelect(nextIsSelected, id)

		if (onClick) onClick(ev, data, isDisabled, nextIsSelected)
	})

	const shouldHandleClick = onClick || (selectRow && !isDisabled)
	const rowProps = shouldHandleClick ? handleSelectClick : undefined

	return (
		<tr className={className} {...rowProps}>
			{selectRow && !selectRow.hideSelectCol && (
				<MotivSelectCol isDisabled={isDisabled} isSelected={isSelected} selectRow={selectRow} />
			)}

			{columns.map((c, i) => (
				<MotivTableDataCell key={c.field + i} column={c} data={data} />
			))}
		</tr>
	)
}
