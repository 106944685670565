import './GraphContainer.scss'

import cls from 'classnames'
import type { ReactNode } from 'react'

type GraphContainerProps = {
	readonly children?: ReactNode
	readonly className?: string
}

/**
 * Simple square container meant to hold a graph. This is designed to use
 * ParentResize visx component as a child. At the very least the child needs
 * to be a div.
 */
export const GraphContainer = ({ children, className }: GraphContainerProps) => (
	<div className={cls('graph-container', className)}>
		<div className="graph-container__square">{children}</div>
	</div>
)
