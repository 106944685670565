import { useFn } from '@eturi/react'
import { isActiveSubFree$, user$ } from '@motiv-shared/reducers'
import { hasPermission, UserPermissions } from '@motiv-shared/server'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { BannerWithBtn } from '../../widgets/Banners/BannerWithBtn'

export const ExpiredSubBanner = () => {
	const isActiveSubFree = useSelector(isActiveSubFree$)

	if (!isActiveSubFree) return null

	return <ExpiredSubBannerImpl />
}

const ExpiredSubBannerImpl = () => {
	const user = useSelector(user$)
	const canViewBilling = hasPermission(UserPermissions.CAN_VIEW_BILLING_TAB, user)

	const history = useHistory()

	const handleBannerBtnClick = useFn(() => {
		history.push('/billing')
	})

	const ExpiredSubMsg = useMemo(() => {
		return canViewBilling
			? 'A subscription is required to use Motiv features. Upgrade now to manage your team.'
			: 'A subscription is required to use Motiv features. To access team data, an Account Owner or Admin must upgrade.'
	}, [canViewBilling])

	return <BannerWithBtn btnMsg="Upgrade" msg={ExpiredSubMsg} onClick={handleBannerBtnClick} />
}
