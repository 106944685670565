import { useFn } from '@eturi/react'
import { ImmutSet, notNull } from '@eturi/util'
import cls from 'classnames'
import isFunction from 'lodash/isFunction'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import type { ReactNode } from 'react'
import { useEffect, useMemo, useState } from 'react'
import Form from 'react-bootstrap/Form'
import type { TableProps } from 'react-bootstrap/Table'
import Table from 'react-bootstrap/Table'
import type { KeyboardClickEvent } from '../../hooks'
import { useKeyboardClick } from '../../hooks'
import { bup } from '../../util/batchUpdates'
import type { MotivTableColDesc } from './MotivTableColDesc'
import { MotivTableHeaderCell } from './MotivTableHeaderCell'
import { MotivTablePagination } from './MotivTablePagination'
import type { MotivTableRowClickHandler } from './MotivTableRow'
import { MotivTableRow } from './MotivTableRow'
import type { MotivSelectRowMode, MotivTableSelectRow, SelectRowKey } from './MotivTableSelectRow'

export type MotivTableSortOrder = 'asc' | 'desc' | null

type CreateSelectRow<Data, KeyField extends PlainKey<Data>> = MOmit<
	MotivTableSelectRow<Data, KeyField>,
	'disabled' | 'mode' | 'selected'
> & {
	readonly disabled?: Iterable<SelectRowKey<Data, KeyField>>
	readonly mode?: MotivSelectRowMode
	readonly selected?: Iterable<SelectRowKey<Data, KeyField>>
}

type CreateMotivTableDataAll<Data, KeyField extends PlainKey<Data>> = {
	readonly addColumn: <Field extends PlainKey<Data>, Extra = void>(
		field: Field,
		desc: MOmit<MotivTableColDesc<Data, Field, Extra>, 'field'>,
	) => MPick<CreateMotivTableDataAll<Data, KeyField>, 'addColumn' | 'props'>

	readonly props: () => MotivTableProps<Data, KeyField>

	readonly setSelectRow: (
		props?: CreateSelectRow<Data, KeyField>,
	) => MPick<CreateMotivTableDataAll<Data, KeyField>, 'addColumn'>
}

export type CreateMotivTableDataProps<Data, KeyField extends PlainKey<Data>> = MOmit<
	MotivTableProps<Data, KeyField>,
	'columns' | 'selectRow'
>

export type CreateMotivTableData<Data, KeyField extends PlainKey<Data>> = MPick<
	CreateMotivTableDataAll<Data, KeyField>,
	'addColumn' | 'setSelectRow'
>

export const createMotivTableData = <Data, KeyField extends PlainKey<Data>>(
	tableProps: CreateMotivTableDataProps<Data, KeyField>,
): CreateMotivTableData<Data, KeyField> => {
	const columns: MotivTableColDesc<Data, PlainKey<Data>, any>[] = []
	let selectRow: MotivTableSelectRow<Data, KeyField>

	const props: CreateMotivTableDataAll<Data, KeyField>['props'] = () => ({
		...tableProps,
		columns,
		selectRow,
	})

	const addColumn: CreateMotivTableDataAll<Data, KeyField>['addColumn'] = (field, desc) => {
		columns.push({ field, ...desc })

		return { addColumn, props }
	}

	const setSelectRow: CreateMotivTableDataAll<Data, KeyField>['setSelectRow'] = (p = {}) => {
		const disabled = p?.disabled instanceof ImmutSet ? p.disabled : new ImmutSet(p?.disabled)
		const mode = p.mode || 'checkbox'
		const selected = p.selected instanceof ImmutSet ? p.selected : new ImmutSet(p.selected)

		selectRow = { ...p, disabled, mode, selected }

		return { addColumn }
	}

	return { addColumn, setSelectRow }
}

export type MotivTableProps<Data, KeyField extends PlainKey<Data>> = Omit<
	TableProps,
	'borderless' | 'responsive'
> & {
	readonly bodyClassName?: string
	readonly className?: string
	readonly columns: MotivTableColDesc<Data>[]
	readonly data: Data[] // Main data. This has to be the full list if you're filtering
	readonly filteredData?: Data[] // Used if defined, otherwise use `data`
	readonly headerClassName?: string
	readonly isFixedHeader?: boolean
	readonly isPaginated?: boolean
	readonly keyField: KeyField
	readonly noDataIndicator?: ReactNode | (() => ReactNode)
	readonly onRowClick?: MotivTableRowClickHandler<Data>
	readonly onSelectChange?: (selected: ImmutSet<SelectRowKey<Data, KeyField>>) => void
	readonly perPage?: number
	readonly selectRow?: MotivTableSelectRow<Data, KeyField>
	readonly wrapperClassName?: string
}

export const MotivTable = <Data, KeyField extends PlainKey<Data>>({
	bodyClassName,
	className,
	columns,
	data,
	filteredData = data,
	headerClassName,
	hover,
	isFixedHeader = false,
	isPaginated = false,
	keyField,
	noDataIndicator = 'No data found.',
	onRowClick,
	onSelectChange,
	perPage = 10,
	selectRow,
	wrapperClassName,
	...tableProps
}: MotivTableProps<Data, KeyField>) => {
	const [sortField, setSortField] = useState<PlainKey<Data> | null>(null)
	const [sortOrder, setSortOrder] = useState<MotivTableSortOrder>(null)
	const [pageIdx, setPageIdx] = useState(0)
	const [disabledRows, setDisabledRows] = useState(() => new ImmutSet(selectRow?.disabled))
	const [selectedRows, setSelectedRows] = useState(() => new ImmutSet(selectRow?.selected))

	const dataSize = filteredData.length
	const isAllSelected = Boolean(dataSize) && dataSize - selectedRows.size === disabledRows.size
	const shouldShowNoData = !dataSize
	const totalPages = Math.ceil(dataSize / perPage)
	const lastPageIdx = totalPages - 1
	const shouldPaginate = isPaginated && totalPages > 1
	// Turn hover on by default if there's a row click or select row
	hover ??= Boolean(onRowClick || selectRow)

	const sortedData = useMemo(() => {
		if (!(sortField && sortOrder)) return filteredData

		const sortCol = columns.find((c) => c.field === sortField)

		if (!sortCol) return filteredData

		const { sort, field, extra } = sortCol

		// Should never happen
		if (!sort) return filteredData

		if (sort === true) return orderBy(filteredData, field, sortOrder)

		const sortOrderMultiplier = sortOrder === 'asc' ? 1 : -1

		return [...filteredData].sort(
			(a, b) => sort(a[sortField], b[sortField], a, b, extra) * sortOrderMultiplier,
		)
	}, [columns, filteredData, sortField, sortOrder])

	const pageData = useMemo(
		() =>
			shouldPaginate
				? sortedData.slice(pageIdx * perPage, pageIdx * perPage + perPage)
				: sortedData,
		[pageIdx, perPage, shouldPaginate, sortedData],
	)

	const handleHeaderClick = useFn(
		(ev: KeyboardClickEvent<HTMLTableCellElement>, column: MotivTableColDesc<Data>) => {
			if (column.sort) {
				if (column.field === sortField) {
					const newSortOrder = sortOrder === null ? 'asc' : sortOrder === 'asc' ? 'desc' : null
					setSortOrder(newSortOrder)
				} else {
					setSortField(column.field)
					setSortOrder('asc')
				}
			}

			if (column.onHeaderClick) {
				column.onHeaderClick(column.field, ev)
			}
		},
	)

	const handleSelect = useFn((isSelected: boolean, rowId: SelectRowKey<Data, KeyField>) => {
		const newSelectedRows = isSelected ? selectedRows.add(rowId) : selectedRows.delete(rowId)

		if (newSelectedRows === selectedRows) return

		setSelectedRows(newSelectedRows)
		onSelectChange?.(newSelectedRows)
	})

	const handleSelectAllClick = useKeyboardClick<HTMLTableCellElement>((ev) => {
		const isAllSelectedNext = !isAllSelected

		// New selected from `onSelectAll` handler return, or based on default
		// toggle behavior.
		const newSelected = new ImmutSet(
			selectRow?.onSelectAll?.(isAllSelectedNext, filteredData, ev) ||
				(isAllSelectedNext
					? filteredData
							.map((v) => {
								const key = v[keyField] as SelectRowKey<Data, KeyField>

								return disabledRows.has(key) ? null : key
							})
							.filter(notNull)
					: []),
		)

		if (newSelected === selectedRows) return

		setSelectedRows(newSelected)
		onSelectChange?.(newSelected)
	})

	const NoData = useMemo(
		() =>
			shouldShowNoData ? (
				<tr className="motiv-table-row motiv-table-row--no-data">
					<td className="motiv-table-cell motiv-table-cell--no-data" colSpan={columns.length}>
						{isFunction(noDataIndicator) ? noDataIndicator() : noDataIndicator}
					</td>
				</tr>
			) : null,
		[columns.length, noDataIndicator, shouldShowNoData],
	)

	// The "select all" column header. If `hideSelectAll` is true, we don't render
	// any content or apply the select all handlers.
	const SelectAllColHeader = useMemo(() => {
		if (!selectRow || selectRow.hideSelectCol) return null

		const { headerColClassName, hideSelectAll } = selectRow
		const className = cls(
			'motiv-table-header-cell',
			isFunction(headerColClassName)
				? headerColClassName(false, isAllSelected)
				: headerColClassName,
		)

		const headerContent = hideSelectAll
			? null
			: selectRow.header?.(isAllSelected) || (
					<Form.Check checked={isAllSelected} readOnly tabIndex={-1} />
			  )

		const handleSelectAllProps = hideSelectAll ? null : handleSelectAllClick

		return (
			<th className={className} {...handleSelectAllProps}>
				{headerContent}
			</th>
		)
	}, [isAllSelected, selectRow])

	// Make sure that when data changes we remove any selected ids that no
	// longer exist. Note this always uses `data`, since we only want to do this
	// pruning based on the full list of data. In other words we don't want to
	// deselect items that are simply being filtered out.
	useEffect(() => {
		if (!(selectRow && selectedRows.size)) return

		const idsSet = new Set(map(data, keyField))
		const newDisabledRows = disabledRows.filter((id) => idsSet.has(id))
		const newSelectedRows = selectedRows.filter((id) => idsSet.has(id))

		if (newSelectedRows === selectedRows && newDisabledRows === disabledRows) return

		bup(() => {
			setSelectedRows(newSelectedRows)
			setDisabledRows(newDisabledRows)
			onSelectChange?.(newSelectedRows)
		})
	}, [data])

	useEffect(() => {
		// If `lastPageIdx` changes such that `pageIdx` is out of range, set
		// `pageIdx` to the closest in-range idx.
		if (pageIdx > lastPageIdx) {
			setPageIdx(Math.max(lastPageIdx, 0))
		}
	}, [lastPageIdx])

	useEffect(() => {
		// Set page idx to 0 if we're not paginated
		isPaginated || setPageIdx(0)
	}, [isPaginated])

	useEffect(() => {
		setDisabledRows(selectRow?.disabled || new ImmutSet())
	}, [selectRow?.disabled])

	useEffect(() => {
		setSelectedRows(selectRow?.selected || new ImmutSet())
	}, [selectRow?.selected])

	if (process.env.NODE_ENV === 'development') {
		useEffect(() => {
			if (selectRow && selectRow.mode !== 'checkbox') {
				console.error('Radio selection mode is not yet implemented for this table!')
			}
		}, [selectRow?.mode])
	}

	return (
		<div className={cls('table-wrapper', wrapperClassName, { 'fix-header': isFixedHeader })}>
			<Table className={className} hover={hover} responsive {...tableProps}>
				<thead className={headerClassName}>
					<tr>
						{SelectAllColHeader}
						{columns.map((c, i) => (
							<MotivTableHeaderCell
								key={c.field + i}
								column={c}
								onClick={handleHeaderClick}
								sortOrder={c.field === sortField ? sortOrder : null}
							/>
						))}
					</tr>
				</thead>

				<tbody className={bodyClassName}>
					{shouldShowNoData
						? NoData
						: pageData.map((data) => {
								const rowId = data[keyField] as SelectRowKey<Data, KeyField>

								return (
									<MotivTableRow
										key={rowId}
										columns={columns}
										data={data}
										id={rowId}
										isSelected={selectedRows.has(rowId)}
										isDisabled={disabledRows.has(rowId)}
										onClick={onRowClick}
										onSelect={handleSelect}
										selectRow={selectRow}
									/>
								)
						  })}
				</tbody>
			</Table>

			{shouldPaginate && (
				<MotivTablePagination onPageChange={setPageIdx} pageIdx={pageIdx} totalPages={totalPages} />
			)}
		</div>
	)
}
