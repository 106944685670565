import { arrayAddOrUpdate } from '@eturi/util'
import { resetAction } from '@motiv-shared/reducers'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import isString from 'lodash/isString'
import reject from 'lodash/reject'
import { v4 } from 'uuid'
import type { MotivToast } from '../../types'
import { DEFAULT_ERROR_TOAST, DEFAULT_SUCCESS_TOAST } from '../../types'

// NOTE: showTeamLimitToast is a (temporary) one-off implementation. For custom
//  toasts we need to redo our toast implementation.
export type ToastState = {
	readonly isTeamLimitVisible: boolean
	readonly toasts: MotivToast[]
}

export type WithToastState = {
	readonly toasts: ToastState
}

const initialState: ToastState = {
	isTeamLimitVisible: false,
	toasts: [],
}

export type ToastData = {
	readonly msg: string
	readonly title?: string
}

export type ToastPayload = string | ToastData

const normalizePayload = (p: ToastPayload): ToastData => (isString(p) ? { msg: p } : p)

export const toastSlice = createSlice({
	name: 'toasts',
	initialState,
	reducers: {
		closeToast(s, { payload: id }: PayloadAction<string>) {
			return { ...s, toasts: reject(s.toasts, { id }) }
		},

		addToast(s, { payload }: PayloadAction<MotivToast>) {
			s.toasts = arrayAddOrUpdate(s.toasts, payload, 'id')
		},
	},

	extraReducers: (builder) => {
		builder.addCase(resetAction.type, () => initialState)
	},
})

export const addSuccessToast = (toast: ToastPayload): PayloadAction<MotivToast> => ({
	type: toastSlice.actions.addToast.type,
	payload: {
		id: v4(),
		...DEFAULT_SUCCESS_TOAST,
		...normalizePayload(toast),
	},
})

export const addErrorToast = (toast: ToastPayload): PayloadAction<MotivToast> => ({
	type: toastSlice.actions.addToast.type,
	payload: {
		id: v4(),
		...DEFAULT_ERROR_TOAST,
		...normalizePayload(toast),
	},
})

export const { closeToast } = toastSlice.actions
