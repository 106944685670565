import { sentryError } from '@eturi/sentry'
import type { ErrorInfo, ReactNode } from 'react'
import { Component } from 'react'

type LoadErrorBoundaryState = {
	readonly hasError: boolean
}

type LoadErrorBoundaryProps = {
	readonly children?: ReactNode
}

export class LoadErrorBoundary extends Component<LoadErrorBoundaryProps, LoadErrorBoundaryState> {
	state: LoadErrorBoundaryState = { hasError: false }

	static getDerivedStateFromError(): LoadErrorBoundaryState {
		return { hasError: true }
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		sentryError(error, { ErrBoundaryStack: errorInfo.componentStack.trim() })
	}

	// TODO: Add error page or handling for global errors caught here
	render() {
		return this.props.children
	}
}
