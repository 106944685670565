import './AvatarWithUserName.scss'

import cls from 'classnames'
import type { UserAvatarProps } from './UserAvatar'
import { UserAvatar } from './UserAvatar'

type AvatarWithUserNameProps = UserAvatarProps & {
	readonly avatarClassName?: string
}

export const AvatarWithUserName = ({
	avatarClassName,
	avatarUrl,
	className,
	fullName,
}: AvatarWithUserNameProps) => (
	<div className={cls('avatar-with-user-name', className)}>
		<UserAvatar avatarUrl={avatarUrl} className={avatarClassName} fullName={fullName} />

		<span className="avatar-with-user-name__name" title={fullName}>
			{fullName}
		</span>
	</div>
)
