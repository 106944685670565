import { Polling, useChgState, useCombinedPolling, useFn } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import { timeoutPromise } from '@eturi/util'
import {
	fetchIntegrationCatalog,
	fetchIntegrationConfig,
	fetchIntegrations,
	fetchManagedUsers,
	fetchPurchaseInfo,
	fetchRoles,
	fetchSKUDefs,
	fetchTeams,
	fetchUsers,
	unwrapThunks,
} from '@motiv-shared/reducers'
import random from 'lodash/random'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { canAccessProtected$ } from './compound-selectors'
import { usePolling } from './hooks'
import {
	authInit,
	authLogout,
	authValidate,
	isAccountDeleted$,
	isAuthInit$,
	isInitialFetchComplete$,
	isNetworkOnline$,
	LoginAction,
	setInitialFetchComplete,
	shouldValidateAuth$,
} from './reducers'
import { useAppDispatch } from './store'
import { bup } from './util/batchUpdates'

/**
 * Simply performs authenticated side effects and top-level polling
 */
export const AuthEffects = () => {
	const dispatch = useAppDispatch()
	const canPerformAuthEffects = useSelector(canAccessProtected$)
	const isAccountDeleted = useSelector(isAccountDeleted$)
	const isAuthInit = useSelector(isAuthInit$)
	const isConnected = useSelector(isNetworkOnline$)
	const isInitialFetchComplete = useSelector(isInitialFetchComplete$)
	const shouldValidateAuth = useSelector(shouldValidateAuth$)

	const [hadInitialFetchError, setHadInitialFetchError] = useChgState(false)
	const [initialFetchRetries, setInitialFetchRetries] = useState(0)
	const [isFetchingInitial, setFetchingInitial] = useChgState(false)

	const shouldRetryInitialFetch =
		canPerformAuthEffects &&
		isConnected &&
		hadInitialFetchError &&
		!isFetchingInitial &&
		initialFetchRetries <= 10

	const shouldPerformAuthEffects = canPerformAuthEffects && !isInitialFetchComplete
	const shouldPoll = canPerformAuthEffects && !shouldRetryInitialFetch

	const [pause, resume] = useCombinedPolling(
		usePolling([
			(extra) => dispatch(fetchIntegrations(extra)),
			(extra) => dispatch(fetchManagedUsers(extra)),
			(extra) => dispatch(fetchTeams(extra)),
			(extra) => dispatch(fetchUsers(extra)),
		]),

		usePolling([(extra) => dispatch(fetchPurchaseInfo(extra))], Polling.LONG),

		// Don't need to fetch SKUDefs or roles often
		usePolling(
			[
				(extra) => dispatch(fetchIntegrationCatalog(extra)),
				(extra) => dispatch(fetchRoles(extra)),
				(extra) => dispatch(fetchSKUDefs(extra)),
			],
			Polling.VERY_LONG,
		),
	)

	const performAuthEffects = useFn(async (): Promise<boolean> => {
		if (!canPerformAuthEffects) return false

		sentryBreadcrumb('Performing auth effects fetch')

		setFetchingInitial(true)

		let hadError = false

		try {
			await Promise.all(
				unwrapThunks([
					dispatch(fetchIntegrationCatalog()),
					dispatch(fetchIntegrations()),
					dispatch(fetchManagedUsers()),
					dispatch(fetchPurchaseInfo()),
					dispatch(fetchRoles()),
					dispatch(fetchSKUDefs()),
					dispatch(fetchTeams()),
					dispatch(fetchUsers()),
				]),
			)
		} catch (e) {
			hadError = true
			sentryError(e, 'Auth effects fetch error')
		}

		bup(() => {
			setFetchingInitial(false)
			setHadInitialFetchError(hadError)
			!hadError && dispatch(setInitialFetchComplete(true))
		})

		return !hadError
	})

	const retryInitialFetch = useFn(async () => {
		if (!shouldRetryInitialFetch) return

		sentryBreadcrumb('Performing initial fetch retry')

		setInitialFetchRetries(initialFetchRetries + 1)

		const success = await performAuthEffects()

		if (!success) {
			// Wait between 3 and 15 seconds before retrying again
			await timeoutPromise(random(3_000, 15_000))
			await retryInitialFetch()
		}
	})

	useEffect(() => {
		isAccountDeleted && dispatch(authLogout(LoginAction.SIGNUP_ACCOUNT_DELETED))
	}, [isAccountDeleted])

	useEffect(() => {
		!isAuthInit && dispatch(authInit())
	}, [isAuthInit])

	useEffect(() => {
		shouldPerformAuthEffects && performAuthEffects()
	}, [shouldPerformAuthEffects])

	useEffect(() => {
		shouldPoll ? resume() : pause()
	}, [shouldPoll])

	useEffect(() => {
		shouldRetryInitialFetch && retryInitialFetch()
	}, [shouldRetryInitialFetch])

	useEffect(() => {
		shouldValidateAuth && dispatch(authValidate())
	}, [shouldValidateAuth])

	return null
}
