import './Banner.scss'

import type { ReactNode } from 'react'
import Image from 'react-bootstrap/Image'
import WARNING_ICON from '../../assets/icons/warning.svg'

export type BannerProps = {
	readonly msg: string
	readonly children?: ReactNode
}

export const Banner = (p: BannerProps) => {
	return (
		<div className="banner">
			<div className="banner__info">
				<Image className="banner__icon" src={WARNING_ICON} alt="warning-icon" />
				<p className="banner__message">{p.msg}</p>
			</div>
			{p.children}
		</div>
	)
}
