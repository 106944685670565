import { resetAction } from '@motiv-shared/reducers'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { authLogout, isAuthenticated$ } from '../reducers'
import { useAppDispatch } from '../store'

/**
 * This exists simply to call logout action after auth0 redirects. Without this,
 * our local logout could trigger redirect to login before Auth0 finishes its
 * own logout procedure, which would keep us logged in.
 */
export const LogoutPage = () => {
	const dispatch = useAppDispatch()
	const isAuthenticated = useSelector(isAuthenticated$)

	if (isAuthenticated) {
		dispatch(authLogout())
		return null
	}

	dispatch(resetAction)
	return <Redirect to="/login" />
}
