import './SelectIntegrationModal.scss'

import { useFn } from '@eturi/react'
import type { IntegrationProvider } from '@motiv-shared/server'
import cls from 'classnames'
import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import { useKeyboardClick } from '../../hooks'
import { currentIntegrationsDecorated$, setSeatModal } from '../../reducers'
import { useAppDispatch } from '../../store'
import type { IntegrationDecorated } from '../../types'
import { MotivModal } from '../../widgets/Modal'
import { SeatModals } from './seatModals.constants'

type SelectIntegrationBtnProps = {
	readonly integration: IntegrationDecorated
	readonly isSelected: boolean
	readonly onClick: (provider: IntegrationProvider) => void
}

const SelectIntegrationBtn = ({ integration, isSelected, onClick }: SelectIntegrationBtnProps) => {
	const handleIntegrationClick = useKeyboardClick(() => {
		onClick(integration.integrationProvider)
	})

	return (
		<div
			className={cls('integration-source d-flex flex-column py-5 px-6 mr-6 mb-6', {
				'integration-source--selected': isSelected,
			})}
			{...handleIntegrationClick}
		>
			<Image
				className="integration-source__img align-self-center mt-3"
				src={integration.iconLogoSm}
				alt={integration.displayName}
				height={40}
				width={40}
			/>

			<p className="integration-source__title mt-5 mb-0 align-self-center">
				{integration.shortName}
			</p>
		</div>
	)
}

export const SelectIntegrationModal = () => {
	const dispatch = useAppDispatch()
	const currentIntegrationsDecorated = useSelector(currentIntegrationsDecorated$)
	const [selectedProvider, setSelectedProvider] = useState<Maybe<IntegrationProvider>>(null)

	const closeModal = useFn(() => {
		dispatch(setSeatModal(null))
	})

	const handlePopulateClick = useFn(() => {
		if (!selectedProvider) return

		dispatch(setSeatModal({ type: SeatModals.ADD_TO_SEATS, selectedProvider }))
	})

	const handleSelectClick = useFn((provider: IntegrationProvider) => {
		setSelectedProvider(provider === selectedProvider ? null : provider)
	})

	return (
		<MotivModal onHide={closeModal} size="lg" title="Select Source">
			<Modal.Body>
				<p>
					{`Pick an integration to populate the list people to chose from, we'll automatically link
					accounts from other integrations to the people on that list by email.`}
				</p>

				<div className="d-flex flex-wrap justify-content-center">
					{currentIntegrationsDecorated.map((integration) => (
						<SelectIntegrationBtn
							key={integration.integrationProvider}
							integration={integration}
							isSelected={integration.integrationProvider === selectedProvider}
							onClick={handleSelectClick}
						/>
					))}
				</div>
			</Modal.Body>

			<Modal.Footer>
				<Button
					disabled={!selectedProvider}
					onClick={handlePopulateClick}
					size="lg"
					variant="success"
				>
					Populate List
				</Button>

				<Button variant="light-link" size="lg" onClick={closeModal}>
					Cancel
				</Button>
			</Modal.Footer>
		</MotivModal>
	)
}
