import type { FormikProps } from 'formik'
import Form from 'react-bootstrap/Form'
import * as Yup from 'yup'
import type { FormikCtrlProps } from '../../../util'
import { FormValidationText } from '../../../widgets/FormValidationText'

export const INITIAL_VALUE_TEAM_NAME = (existingTeamName?: string) => ({
	teamName: existingTeamName != null ? existingTeamName : '',
})

export type TeamNameFormData = {
	readonly teamName: string
}

export const normalizeTeamName = (teamName: string) => teamName.toLowerCase().split(/\s+/).join(' ')

export const buildValidateTeamName = (
	existingTeamNames: ReadonlySet<string>,
	startingTeamName?: string,
) => {
	const normalizedStartingTeamName = normalizeTeamName(startingTeamName || '')

	return (teamName: Maybe<string>): boolean => {
		if (!teamName) return false

		const normalizedTeamName = normalizeTeamName(teamName)

		return (
			normalizedStartingTeamName === normalizedTeamName ||
			!existingTeamNames.has(normalizedTeamName)
		)
	}
}

export const VALIDATION_SCHEMA_TEAM_NAME = (
	teamNameValidate: (teamName: Maybe<string>) => boolean,
) => ({
	teamName: Yup.string()
		.trim()
		.required('Team Name cannot be empty.')
		.max(70, 'Character Limit Reached')
		.test('teamName', 'Team Name should be unique.', teamNameValidate),
})

type GetCtrlProps = <K extends keyof TeamNameFormData>(
	name: K,
) => FormikCtrlProps<TeamNameFormData, K>

export const TeamNameInput = ({
	p,
	getCtrlProps,
}: {
	p: FormikProps<TeamNameFormData>
	getCtrlProps: GetCtrlProps
}) => {
	return (
		<>
			<Form.Label>Team Name</Form.Label>

			<Form.Control maxLength={71} placeholder="Enter Team Name..." {...getCtrlProps('teamName')} />

			<FormValidationText field="teamName" formikProps={p} />
		</>
	)
}
