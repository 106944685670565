import type {
	IdentifyCreateData,
	IdentifyRes,
	UserInvitationCheckRes,
	IdentifyResSuccess,
} from '@motiv-shared/server'
import { IdentifyAction } from '@motiv-shared/server'
import { isAnyOf } from '@reduxjs/toolkit'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState'
import type { HttpExtra } from '../http'
import { apiErrorBody } from '../http'
import type { WithIdentityState } from '../identity'

const createAsyncThunk = /* @__PURE__ */ bindCreateAsyncThunkToState<WithIdentityState>()

/**
 * Read-only, safe version of POST /identify for use with demo accounts
 */
export const getAuthIdentify = /* @__PURE__ */ createAsyncThunk(
	'getLoginIdentify',
	async ({ ...extra }: HttpExtra = {}, { dispatch, extra: { http } }) =>
		dispatch(
			http.get<IdentifyResSuccess>(`identify/${IdentifyAction.IDENTIFY}`, {
				...extra,
			}),
		),
)

export const postAuthIdentify = /* @__PURE__ */ createAsyncThunk<
	IdentifyRes,
	WithRequired<IdentifyCreateData, 'clientType'>
>('postLoginIdentify', async (data, { dispatch, extra: { http }, rejectWithValue }) => {
	try {
		// returning await so the error gets caught in this scope
		// if we just return the promise or do dispatch .catch()
		// the error will be ran through a serializer. The
		// serializer will prune out e.body which is needed.
		return await dispatch(
			http.post<IdentifyRes>('identify', {
				data,
				errorMessage: 'Unable to get login identity.',
				force: true,
			}),
		)
	} catch (e) {
		return rejectWithValue(apiErrorBody(e))
	}
})

export const authIdentifyCompleteMatcher = isAnyOf(
	getAuthIdentify.fulfilled,
	postAuthIdentify.fulfilled,
)

type FetchInvitationStatusArgs = HttpExtra & {
	readonly invitationId: string
}

export const fetchInvitationStatus = /* @__PURE__ */ createAsyncThunk(
	'user-invitation-check/invitationId',
	({ invitationId, ...extra }: FetchInvitationStatusArgs, { dispatch, extra: { http } }) =>
		dispatch(
			http.get<UserInvitationCheckRes>(`user-invitation-check/${invitationId}`, {
				...extra,
				isUnauthenticated: true,
			}),
		),
)
