import { pick, timeoutPromise } from '@eturi/util'
import type { ApiError, MotivApiErrorBody } from '@motiv-shared/server'
import type { AnyAction, ThunkAction } from '@reduxjs/toolkit'
import stringify from 'json-stable-stringify'
import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'
import { v4 } from 'uuid'
import { httpUnauthorizedAction } from './actions'

export type HttpReqMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

// Retry
export type RetryOpts = {
	readonly max?: number
	readonly shouldRetry?: boolean | ((status: number) => boolean)
}

export type StandardAuthHeader = { Authorization: string }
export type DemoAuthHeader = { ['X-MotivDemo']: string }
export type AuthorizationHeader = StandardAuthHeader | DemoAuthHeader

export type HttpForce = boolean | 'soft'

type RequiredRetryOpts = Required<RetryOpts>

type NormalizedRetry = {
	readonly max: number
	readonly shouldRetry: (status: number) => boolean
}

const DEFAULT_RETRY_OPTS: RequiredRetryOpts = {
	max: 10,
	shouldRetry: (status) =>
		status === 0 /* Network failure */ ||
		status === 408 /* Client request timeout */ ||
		status === 429 /* Too many requests */ ||
		status >= 502 /* Server-related errors */,
}

const normalizeRetry = (retry: RetryOpts = {}, defaults: Required<RetryOpts>): NormalizedRetry => {
	const shouldRetry = retry.shouldRetry || defaults.shouldRetry

	return {
		max: retry.max ?? defaults.max,
		shouldRetry: isFunction(shouldRetry) ? shouldRetry : () => shouldRetry,
	}
}

// Auth
type HttpAuth = {
	readonly header?: Maybe<AuthorizationHeader>
	readonly isAuthorized: boolean
	readonly requiresAuth: boolean
}

type CreateAuthThunk<State> = (
	url: string,
) => ThunkAction<HttpAuth | Promise<HttpAuth>, State, any, AnyAction>

type APIAuthErrorHandler<State> = () => ThunkAction<any, State, any, AnyAction>

const normalizeHeaders = (headers?: Maybe<Record<string, string>>) => ({
	Accept: 'application/json',
	'Content-Type': 'application/json',
	...headers,
})

const normalizeHttpData = (data?: any): string | undefined =>
	data == null ? undefined : isObject(data) ? JSON.stringify(data) : data

type NormalizeUrl = (pathOrUrl: string) => string | Promise<string>

export type HttpLifecycleHandler<State, Extra> = (
	extra: HttpExtra<State, Extra>,
) => ThunkAction<any, State, any, AnyAction>

export type HttpErrorHandler<State, Extra> = (
	extra: HttpExtra<State, Extra>,
	error: any,
) => ThunkAction<any, State, any, AnyAction>

type HttpOpts = {
	readonly normalizeUrl?: NormalizeUrl
	readonly retry?: RetryOpts
}

type RequiredHttpOpts = Required<MOmit<HttpOpts, 'retry'>> & {
	readonly retry: RequiredRetryOpts
}

type CreateHttpOpts<State, Extra = Record<string, unknown>> = {
	[P in Lowercase<HttpReqMethod>]?: HttpOpts
} & HttpOpts & {
		readonly auth: CreateAuthThunk<State>
		readonly onAfterFetch?: HttpLifecycleHandler<State, Extra>
		readonly onAPIAuthError?: APIAuthErrorHandler<State>
		readonly onBeforeFetch?: HttpLifecycleHandler<State, Extra>
		readonly onError: HttpErrorHandler<State, Extra>
		readonly onPendingFetch?: HttpLifecycleHandler<State, Extra>
	}

const CACHE_DEBOUNCE_MS = 3_000

const DEFAULT_HTTP_OPTS: RequiredHttpOpts = {
	// Identity
	normalizeUrl: (url: string) => url,
	retry: DEFAULT_RETRY_OPTS,
}

const normalizeOpts = (opts: HttpOpts): RequiredHttpOpts =>
	pick(
		{
			...DEFAULT_HTTP_OPTS,
			...opts,
			retry: normalizeRetry(opts.retry, DEFAULT_HTTP_OPTS.retry),
		},
		['normalizeUrl', 'retry'],
	)

// The time limit for an HTTP request to resolve before it's considered lost.
const REQ_TIMEOUT = 45000

// Allow imperfectly typed HttpExtra to accept arbitrary object, but retain
// types on what we've defined (data, force, etc).
type AnyObject = {
	readonly [k: string]: any
}

export type HttpExtra<State = AnyObject, Extra = AnyObject> = {
	readonly data?: any
	readonly force?: HttpForce
	readonly isUnauthenticated?: boolean
	readonly onError?: CreateHttpOpts<State, Extra>['onError']
	readonly retry?: HttpOpts['retry']
} & Extra

type HttpMethod<State, Extra> = <R>(
	pathOrUrl: string,
	extra?: HttpExtra<State, Extra>,
) => ThunkAction<Promise<R>, State, any, AnyAction>

export type MotivHttp<State, Extra> = {
	[P in Lowercase<HttpReqMethod>]: HttpMethod<State, Extra>
}

/**
 * This is meant to be created and passed as the `extraArgument` to the
 * 'redux-thunk`, when creating a store. This makes it available in async
 * thunks via `createAsyncThunk`, and allows us to define async thunks in
 * shared code that still works in environments w/ different instances of
 * this http object.
 *
 * This creates an `http` object w/ all the methods on it that is bound to
 * state and extra that can be passed. It was created to allow us to share the
 * fundamental logic between modules that have differences in the way they
 * handle things like authentication, error handling, normalizing urls, etc.
 *
 * The options require passing a way of getting an `HttpAuth` state as a thunk
 * dispatch, as well as a default error handler. Additional default options can
 * be passed for lifecycle thunks (`onBeforeFetch`, `onAfterFetch`), as well
 * as url normalization, whether to use cache by default, and default retry
 * options.
 * @see CreateHttpOpts
 * @see HttpOpts
 *
 * Additionally the default "extra" options need to be passed. These are the
 * set of options that can be passed as a second argument to any http call.
 * These include whether to `force` a call (ignore caching), and `data` to
 * be included in a POST body, etc.
 * @see HttpExtra
 *
 * The primary purpose of encoding State and Extra is to allow types to flow
 * through any of the thunks that can be called. E.g. `onError`,
 * `onAfterFetch`, etc.
 */
export const createHttp = <State, Extra>(
	opts: CreateHttpOpts<State, Extra>,
	defaultExtra: HttpExtra<State, Extra>,
): MotivHttp<State, Extra> => {
	const { auth, onAfterFetch, onAPIAuthError, onBeforeFetch, onPendingFetch } = opts

	const activeArgsCache = new Map()
	const retryState = new Map<string, number>()
	// NOTE: Currently this compares debounce cache, but if a request is being
	//  retried, it'll be cleared from cache. This was true before the periodic
	//  cache pruning as well when `cache.get()` was called. If we want to keep
	//  retrying requests in cache until they are done, we'll have to put in a
	//  state like `{p, ts, retrying: boolean}`.
	//  We'd then have to add `cache.setRetrying()` and if it's retrying, we
	//  could use a different expiry.
	const pruneExpiredCache = () => {
		const exp = Date.now() - CACHE_DEBOUNCE_MS

		activeArgsCache.forEach((v, k, c) => {
			if (v.ts < exp) c.delete(k)
		})
	}

	// Periodically prune expired cache for memory
	setInterval(pruneExpiredCache, 15_000)

	const httpFactory = (method: HttpReqMethod) => {
		const methodLower = method.toLowerCase() as Lowercase<HttpReqMethod>
		const optsForMethod: Writable<HttpOpts> = opts[methodLower] || {}

		// GET requests use cache by default
		// NOTE: When our main caching solution is finished and this is changed to
		//  be a debounce cache, we could probably use debounce for all requests by
		//  default. I'm not sure it's valuable though.
		const shouldUseCache = method === 'GET'
		const defaults = normalizeOpts({ ...opts, ...optsForMethod })
		// This is a shared promise for waiting on the `handleApiAuthError`. Since,
		// this is specific to API calls, we cut down on some auth0 churning, by
		// only allowing a single one at a time.
		let apiAuthErrorPromise: Promise<void> | null = null

		const cacheForArgs = (url: string, headers: any, data: any) => {
			// Use json-stable-stringify to produce a repeatable cache key based on
			// args. Note that if this stringified data ends up exerting notable
			// memory pressure, we can create a hash of this string.
			const cacheKey = stringify([method, url, headers, data])

			return {
				get: <R>(): Promise<R> | null => {
					pruneExpiredCache()

					return activeArgsCache.get(cacheKey)?.p || null
				},

				set: <R>(p: Promise<R>) => {
					activeArgsCache.set(cacheKey, { p, ts: Date.now() })
				},

				delete: () => activeArgsCache.delete(cacheKey),
			}
		}

		return <R>(
				pathOrUrl: string,
				extra: HttpExtra<State, Extra> = defaultExtra,
			): ThunkAction<Promise<R>, State, any, AnyAction> =>
			async (dispatch) => {
				const { data, force = false, onError = opts.onError } = extra
				const retry = normalizeRetry(extra.retry, defaults.retry)

				const rejectAuth = (error: any = new Error('HTTP authentication error.')) => {
					dispatch(httpUnauthorizedAction)
					dispatch(onError(extra, error))

					return error
				}

				const getHeaders = async (url: string) => {
					const authState: HttpAuth = extra.isUnauthenticated
						? { requiresAuth: false, isAuthorized: false }
						: await dispatch(auth(url))

					if (authState.requiresAuth && !authState.isAuthorized) {
						throw rejectAuth()
					}

					return normalizeHeaders(authState.requiresAuth ? authState.header : undefined)
				}

				const body = normalizeHttpData(data)
				const url = await defaults.normalizeUrl(pathOrUrl)
				let headers = await getHeaders(url)

				const cache = shouldUseCache ? cacheForArgs(url, headers, body) : null
				const activePromiseForArgs = cache?.get<R>()

				const fetchLifecycle = (handler?: HttpLifecycleHandler<State, Extra>) => {
					handler && dispatch(handler(extra))
				}

				// If force is 'soft' or false, then use debounce promise.
				//
				// NOTE: I'm not sure why, but JetBrains thinks this "can be simplified
				//  to `!force`", which is obviously untrue.
				if (force !== true && activePromiseForArgs) {
					fetchLifecycle(onPendingFetch)

					return activePromiseForArgs.finally(() => {
						fetchLifecycle(onAfterFetch)
					})
				}

				const handleApiAuthError = async () => {
					// The handler has to be here or this won't be called.
					await dispatch(onAPIAuthError!())
					headers = await getHeaders(url)
				}

				const handleError = (e: unknown) => {
					if (isApiError(e) && e.status === 401) throw rejectAuth(e)

					dispatch(onError(extra, e))

					throw e
				}

				const reqId = v4()
				let didRetryAuth = false
				let retryInterval = 1000

				const createReqPromise = async (): Promise<R> => {
					// We make sure the request doesn't time out if the retry interval gets
					// too long. This means the timeout is always at least 1 sec after retry
					// interval.
					const reqTimeoutDuration = Math.max(REQ_TIMEOUT, retryInterval + 1000)

					// Set the request timeout.
					// TODO: Use globalThis
					const timeoutId = setTimeout(() => cache?.delete(), reqTimeoutDuration)

					// Define closures for error object, response, and status. Default status
					// is for cases where request fails completely, such as no network.
					let err
					let res: Maybe<Response>
					let status = 0

					// Fetch can fail if there's no network.
					try {
						res = await fetch(url, { body, headers, method })
						status = res.status
					} catch (e) {
						console.error('Fetch error', e)
						err = e
					}

					// Clear the request timeout immediately after we get a response. This
					// doesn't change the response for anything listening to the promise,
					// but does allow new requests of the same type to come in.
					clearTimeout(timeoutId)

					const retries = retryState.get(reqId) || 0
					const hasMaxRetries = retries >= retry.max
					const shouldRetry = !res?.ok && !hasMaxRetries && retry.shouldRetry(status)

					if (!res || shouldRetry) {
						if (!shouldRetry) {
							cache?.delete()

							throw err
						}

						// Set the retry state
						retryState.set(reqId, retries)

						// Wait for the current retry interval before trying again
						await timeoutPromise(retryInterval)

						// Increment the retries
						retryState.set(reqId, retries + 1)

						// Increment the retry interval each time by 125%.
						retryInterval = Math.floor(retryInterval * 1.25)

						// Finally, retry the request.
						return createReqPromise()
					}

					// Clear the retry state if we have one.
					retryState.delete(reqId)

					const parsedRes = await parseResponse(res)

					// Handle request error
					if (!res.ok) {
						if (isMotivApiErrorBody(parsedRes)) {
							// Check for and handle API-specific 401.
							if (status === 401 && !didRetryAuth && onAPIAuthError) {
								// Note that we get one shot to fix auth, otherwise we fail the
								// request, so we set this immediately.
								didRetryAuth = true
								// Wait for (and create if necessary), the auth error handler promise
								await (apiAuthErrorPromise ||= handleApiAuthError())
								apiAuthErrorPromise = null

								return createReqPromise()
							}

							throw new HttpApiError('Request failed', parsedRes, status)
						}

						throw new Error('Request failed')
					}

					return parsedRes
				}

				fetchLifecycle(onBeforeFetch)
				fetchLifecycle(onPendingFetch)

				const newActivePromiseForArgs = createReqPromise()
					.catch(handleError)
					.finally(() => {
						fetchLifecycle(onAfterFetch)
					})

				cache?.set(newActivePromiseForArgs)

				return newActivePromiseForArgs
			}
	}

	return {
		delete: httpFactory('DELETE'),
		get: httpFactory('GET'),
		patch: httpFactory('PATCH'),
		post: httpFactory('POST'),
		put: httpFactory('PUT'),
	}
}

const parseResponse = async (res: Response): Promise<any> => {
	// We attempt to parse response but some responses are empty so we catch
	// parse errors and simply return nothing.
	try {
		// NOTE: We parse text instead of res.json, b/c the JSON method will
		//  throw synchronously causing the debugger to pause as an unhandled
		//  promise rejection (even though it's handled). We can remove this
		//  and go back to using res.json() when we have full native async
		//  / await support. res.text() always returns a string and we can
		//  catch any JSON parse errors ourselves.
		const text = await res.text()

		if (!text) return undefined as any

		return JSON.parse(text)
	} catch {
		/**/
	}

	return undefined as any
}

export const isMotivApiErrorBody = (body: any): body is MotivApiErrorBody =>
	body != null &&
	typeof body == 'object' &&
	body.className != null &&
	body.code != null &&
	body.message != null &&
	body.name != null

export const isApiError = (error: any): error is HttpApiError => error instanceof HttpApiError

export class HttpApiError<T = unknown> extends Error {
	constructor(msg: string, readonly body: MotivApiErrorBody<T>, readonly status: number) {
		super(msg)

		if ((Error as any).captureStackTrace) (Error as any).captureStackTrace(this, HttpApiError)

		this.body = body
		this.name = 'HttpApiError'
		this.status = status
	}
}

export const isApiErrorType = <T extends ApiError>(error: any, type: T): error is HttpApiError<T> =>
	isApiError(error) && error.body.data?.errorCode === type[0]

export const apiErrorBody = (e: unknown) => (isApiError(e) ? e.body : e)
