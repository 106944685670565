import isFunction from 'lodash/isFunction'
import type { MutableRefObject, Ref } from 'react'

export const setFwdRef = <T>(ref: Ref<T>, v: T) => {
	if (isFunction(ref)) {
		ref(v)
	} else if (ref) {
		;(ref as MutableRefObject<T>).current = v
	}
}
