import {
	dataCompatibleIntegrations,
	eventCompatibleIntegrations,
	integrations$,
} from '@motiv-shared/reducers'
import { IntegrationStatuses } from '@motiv-shared/server'
import { createSelector } from '@reduxjs/toolkit'

export const hasDataCompatibleIntegrations$ = /*@__PURE__*/ createSelector(
	integrations$,
	(integrations) =>
		integrations.some(
			(integration) =>
				dataCompatibleIntegrations.has(integration.integrationProvider) &&
				integration.status === IntegrationStatuses.COMPLETE,
		),
)

export const hasEventCompatibleIntegration$ = /*@__PURE__*/ createSelector(
	integrations$,
	(integrations) =>
		integrations.some(
			(integration) =>
				eventCompatibleIntegrations.has(integration.integrationProvider) &&
				integration.status === IntegrationStatuses.COMPLETE,
		),
)
