import { useSelector } from 'react-redux'
import { isNetworkOnline$ } from '../../reducers'
import { Banner } from '../../widgets/Banners/Banner'

export const NoNetworkBanner = () => {
	const isNetworkOnline = useSelector(isNetworkOnline$)

	if (isNetworkOnline) return null

	return <Banner msg="No internet connection" />
}
