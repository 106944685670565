import './column-formatters.scss'

import { notNull } from '@eturi/util'
import type { Team, WithPermissionData } from '@motiv-shared/server'
import { hasPermission, UserPermissions } from '@motiv-shared/server'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import sortBy from 'lodash/sortBy'
import type { IntegrationDecorated } from '../../types'
import type { UserAvatarProps } from '../Avatar'
import { AvatarWithUserName } from '../Avatar'
import type { MenuListWithTooltipPropsItem } from '../MenuListWithTooltip'
import { MenuListWithTooltip } from '../MenuListWithTooltip'
import { TruncatedButtonsList } from '../TruncatedButtonsList'
import type { MotivTableSortFn } from './MotivTableColDesc'

export const emailFormatter = (email: Maybe<string>) => (
	<div className="columns__email-field">
		<span className="text-truncate">{email?.trim() || 'No email available'}</span>
	</div>
)

type ThumbnailUser = MPick<UserAvatarProps, 'avatarUrl' | 'fullName'>

export const nameFormatter = (fullName: string, user: ThumbnailUser) => (
	<AvatarWithUserName
		avatarUrl={user.avatarUrl}
		className="columns__name-field"
		fullName={fullName}
	/>
)

export const integrationsFormatter = (integrations: IntegrationDecorated[]) =>
	isEmpty(integrations) ? (
		<span>No integrations</span>
	) : (
		sortBy(integrations, 'shortName').map(({ iconLogoSm, integrationProvider, shortName }) => (
			<img
				key={integrationProvider}
				alt={`${shortName} logo`}
				src={iconLogoSm}
				className={shortName}
			/>
		))
	)

type GetMenuFormatterItems<T> = (
	| MenuListWithTooltipPropsItem<T>
	| ((data: T) => MenuListWithTooltipPropsItem<T> | null)
	| null
)[]

export const getMenuFormatter =
	<T,>(menuItems: GetMenuFormatterItems<T>) =>
	(id: any, data: T) => {
		const normalizedItems = menuItems.map((v) => (isFunction(v) ? v(data) : v)).filter(notNull)

		return (
			<div className="d-flex justify-content-end">
				<MenuListWithTooltip data={data} items={normalizedItems} />
			</div>
		)
	}

type AssignedTeamsFormatterExtra = {
	readonly disabled?: boolean
	readonly isTextOnly?: boolean
	readonly onClick?: (teamId: string) => void
}

export const assignedTeamsFormatter = (
	teams: Team[],
	_: any,
	{ disabled, isTextOnly = false, onClick }: AssignedTeamsFormatterExtra,
) => {
	if (isEmpty(teams)) return <span className="font-weight-bold">No Team Assignment</span>

	if (isTextOnly) {
		const teamsListText = map(teams, 'name').join(', ')

		return (
			<div className="font-italic max-col-width text-truncate" title={teamsListText}>
				{teamsListText}
			</div>
		)
	}

	return (
		<div className="d-flex flex-column flex-md-row justify-content-space-between">
			<TruncatedButtonsList
				buttons={teams.map((team) => ({ id: team.id, label: team.name }))}
				disabled={disabled}
				labelMaxLength={12}
				maxVisible={1}
				onClick={onClick}
			/>
		</div>
	)
}

const createLengthListObjSort =
	<T,>(compareKey: keyof T): MotivTableSortFn<T[], any, any> =>
	(itemsA, itemsB) => {
		const itemsALen = itemsA.length
		const itemsBLen = itemsB.length

		if (itemsALen === itemsBLen) {
			const propCompareA = orderBy(itemsA, compareKey, 'asc')[0]?.[compareKey]
			const propCompareB = orderBy(itemsB, compareKey, 'asc')[0]?.[compareKey]

			// Not sure why I have to null-assert here. The strict equality should
			// handle it?
			return propCompareA === propCompareB ? 0 : propCompareA! > propCompareB! ? 1 : -1
		}

		return itemsBLen - itemsALen
	}

export const integrationsSort = createLengthListObjSort<IntegrationDecorated>('integrationProvider')

export const userAssignedTeamsFormatter = (
	teams: Team[],
	user: WithPermissionData<unknown>,
	extra: AssignedTeamsFormatterExtra,
) => {
	if (!hasPermission(UserPermissions.CAN_ASSIGN_AS_TEAM_LEAD, user)) {
		return <span className="font-weight-bold">No Permissions</span>
	}

	return assignedTeamsFormatter(teams, user, extra)
}

export const assignedTeamsSort = createLengthListObjSort<Team>('name')

export const nameSortFn = <T extends { readonly fullName: string }>(
	_: any,
	__: any,
	fullNameA: T,
	fullNameB: T,
) => {
	if (fullNameA === fullNameB) return 0

	return fullNameA > fullNameB ? 1 : -1
}
