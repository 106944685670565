import type { FormikProps } from 'formik'

export const formikMultiSelectProps =
	<T>(p: FormikProps<T>) =>
	<K extends string & keyof T>(name: K, validate = true) => {
		const { onBlur, value } = p.getFieldProps(name)
		// Note that we use `v || []` to make sure we never set to null
		const onChange = (v: any) => p.setFieldValue(name, v || [], validate)
		const multiSelectProps = { name, onBlur, onChange, value }

		if (!validate || p.submitCount < 1) return multiSelectProps

		const isInvalid = Boolean(p.errors[name])

		return { isInvalid, isValid: !isInvalid, ...multiSelectProps }
	}
