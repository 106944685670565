import './IntegrationOption.scss'

import { useFn, useToggleState } from '@eturi/react'
import cls from 'classnames'
import every from 'lodash/every'
import { useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { ReactComponent as ChevronUp } from '../../assets/ionicons/chevron-up-outline.svg'
import Colors from '../../styles/color-exports.module.scss'
import type { ConfigOption, ConfigOptionLink, ConfigOptionSection } from './integrationConfigUtils'
import { isConfigSection } from './integrationConfigUtils'

type IntegrationOptionBaseProps = {
	readonly level?: number // Helps calc the amount of padding to add to the nested
	readonly onDeselect: (ids: string[]) => void
	readonly onSelect: (ids: string[]) => void
	readonly selected: Set<string>
}

type IntegrationOptionProps = IntegrationOptionBaseProps & {
	readonly option: ConfigOption
}

export const IntegrationOption = ({
	level = 0,
	option,
	onDeselect,
	onSelect,
	selected,
}: IntegrationOptionProps) => {
	return isConfigSection(option) ? (
		<IntegrationOptionSection
			level={level}
			option={option}
			onDeselect={onDeselect}
			onSelect={onSelect}
			selected={selected}
		/>
	) : (
		<IntegrationOptionItem
			level={level}
			option={option}
			onDeselect={onDeselect}
			onSelect={onSelect}
			selected={selected}
		/>
	)
}

type IntegrationOptionSectionProps = IntegrationOptionBaseProps & {
	readonly option: ConfigOptionSection
}

const IntegrationOptionSection = ({
	level = 0,
	option,
	onDeselect,
	onSelect,
	selected,
}: IntegrationOptionSectionProps) => {
	const [isExpanded, toggleExpanded] = useToggleState(false)
	const isSelected = every(option.ids, (id) => selected.has(id))

	const handleSectionSelect = useFn(() => {
		if (isSelected) {
			onDeselect(option.ids)
		} else {
			onSelect(option.ids)
		}
	})

	const FormattedLabel = useMemo(() => {
		const sections = option.name.split('/')
		if (sections.length < 3) return option.name

		const trim = sections.slice(1, sections.length - 1).join('/')

		return (
			<div>
				{sections[0]}

				<OverlayTrigger
					delay={85}
					overlay={<Tooltip id={option.name}>{trim}</Tooltip>}
					placement="top"
					trigger={['click', 'focus', 'hover']}
				>
					<Button variant="link" className="p-0 pb-1 text-dark">
						/.../
					</Button>
				</OverlayTrigger>

				{sections[sections.length - 1]}
			</div>
		)
		// console.log(sections)
		// return option.name
	}, [option.name])

	return (
		<div className="d-inline-block w-100">
			<div
				className={cls(
					'd-inline-block bg-light w-100',
					!isExpanded && 'border-bottom border-light',
				)}
			>
				<div
					className={cls(
						'd-flex flex-row justify-content-between w-100',
						isExpanded && 'bg-light border-bottom border-light',
					)}
					style={{ paddingLeft: `${level}rem` }}
				>
					<Form.Check
						key={option.name}
						className="m-4"
						checked={isSelected}
						label={FormattedLabel}
						onChange={() => handleSectionSelect()}
						type="checkbox"
					/>

					<Button variant="link" onClick={toggleExpanded}>
						<ChevronUp
							height={30}
							stroke={Colors.gray_700}
							style={{ transform: `rotate(${isExpanded ? 0 : 180}deg)` }}
							width={30}
						/>
					</Button>
				</div>

				{isExpanded && (
					<div className="d-inline-block w-100">
						{option.sections.map((op) => (
							<IntegrationOption
								key={op.name}
								level={level + 1}
								option={op}
								onDeselect={onDeselect}
								onSelect={onSelect}
								selected={selected}
							/>
						))}
					</div>
				)}
			</div>
		</div>
	)
}

type IntegrationOptionItemProps = IntegrationOptionBaseProps & {
	readonly option: ConfigOptionLink
}

const IntegrationOptionItem = ({
	level = 0,
	option,
	onDeselect,
	onSelect,
	selected,
}: IntegrationOptionItemProps) => {
	const isSelected = selected.has(option.id)

	const handleSectionSelect = useFn(() => {
		if (isSelected) {
			onDeselect([option.id])
		} else {
			onSelect([option.id])
		}
	})
	return (
		<div
			className="d-inline-block border-bottom bg-white border-light w-100"
			style={{ paddingLeft: `${level}rem` }}
		>
			<Form.Check
				key={option.name}
				className="m-4"
				checked={isSelected}
				label={option.name}
				onChange={() => handleSectionSelect()}
				type="checkbox"
			/>
		</div>
	)
}
