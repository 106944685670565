import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { SeatModalType } from '../../features/SeatModals'

export type SeatUIState = {
	readonly seatModal: Maybe<SeatModalType>
	readonly isSyncUIComplete: boolean
}

export type WithSeatUIState = {
	readonly seatUI: SeatUIState
}

const initialState: SeatUIState = {
	seatModal: null,
	isSyncUIComplete: false,
}

export const seatUISlice = createSlice({
	name: 'seatUI',
	initialState,
	reducers: {
		setSeatModal: (s, a: PayloadAction<Maybe<SeatModalType>>) => {
			s.seatModal = a.payload
		},
		setSyncUIComplete: (s, a: PayloadAction<boolean>) => {
			s.isSyncUIComplete = a.payload
		},
	},
})

export const { setSeatModal, setSyncUIComplete } = seatUISlice.actions
