import type { DurationInputArg2 } from 'moment'
import type { DateRange } from './DateRange'
import { DateRanges } from './DateRange'

export type DateRangeFilter<T = DateRange> = {
	readonly format: string
	readonly id: T
	readonly unit: DurationInputArg2
	readonly value: number
}

export const DateRangeFilters = {
	[DateRanges.ONE_DAY]: {
		format: 'H',
		id: DateRanges.ONE_DAY,
		unit: 'days',
		value: 1,
	},

	[DateRanges.ONE_WEEK]: {
		format: 'MMM DD',
		id: DateRanges.ONE_WEEK,
		unit: 'days',
		value: 7,
	},

	[DateRanges.TWO_WEEKS]: {
		format: 'MMM DD',
		id: DateRanges.TWO_WEEKS,
		unit: 'days',
		value: 14,
	},

	[DateRanges.ONE_MONTH]: {
		format: 'W',
		id: DateRanges.ONE_MONTH,
		unit: 'months',
		value: 1,
	},

	[DateRanges.SIX_MONTHS]: {
		format: 'MMMM',
		id: DateRanges.SIX_MONTHS,
		unit: 'months',
		value: 6,
	},

	[DateRanges.ONE_YEAR]: {
		format: 'MMMM',
		id: DateRanges.ONE_YEAR,
		unit: 'years',
		value: 1,
	},
} as const

export const shouldShowChartDateLabel = (dateRange: Maybe<DateRangeFilter>, index: number) => {
	if (!dateRange) return true

	//TODO: These values are made up (outside of one week and two weeks) need input from product
	switch (dateRange) {
		case DateRangeFilters.ONE_DAY:
		case DateRangeFilters.ONE_WEEK:
			return true

		case DateRangeFilters.TWO_WEEKS:
			return index % 2 === 0

		case DateRangeFilters.ONE_MONTH:
			return index % 4 === 0

		case DateRangeFilters.SIX_MONTHS:
		case DateRangeFilters.ONE_YEAR:
			return index % 30 === 0

		default:
			console.error(`Unknown date range: ${dateRange}`)
			return true
	}
}
