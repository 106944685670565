import './PlanLineItem.scss'

import cls from 'classnames'
import type { ReactNode } from 'react'

type PlanLineItemProps = {
	readonly className?: string
	readonly children?: ReactNode
}

export const PlanLineItem = (p: PlanLineItemProps) => (
	<li className={cls('plan-line-item', p.className)}>{p.children}</li>
)
