import type {
	HttpApiError,
	HttpErrorHandler as BaseHttpErrorHandler,
	HttpExtra as BaseHttpExtra,
	MotivHttp,
} from '@motiv-shared/reducers'
import { createHttp, fetchIntegrations, isApiErrorType } from '@motiv-shared/reducers'
import { ApiErrors } from '@motiv-shared/server'
import type {
	Action as BaseAction,
	AnyAction,
	ThunkAction as BaseThunkAction,
} from '@reduxjs/toolkit'
import isFunction from 'lodash/isFunction'
import type { ToastPayload } from './reducers'
import { addErrorToast, authValidate, isAuthenticated$, jwtToken$ } from './reducers'
import type { RootState } from './rootReducer'
import { getRequiresAuth, normalizeUrl } from './util'
import type { IndicatorRegion } from './widgets/BusyIndicator'
import { decrementBusyIndicator, incrementBusyIndicator } from './widgets/BusyIndicator'

type ExtraArg = { readonly http: MotivHttp<RootState, HttpExtra> }

export type ThunkAction<
	Return = void,
	State = RootState,
	Action extends BaseAction = AnyAction,
> = BaseThunkAction<Return, State, ExtraArg, Action>

export type HttpErrorMessage =
	| ToastPayload
	| ((extra: HttpExtra, error: Maybe<HttpApiError>) => ThunkAction<ToastPayload | undefined>)

type Extra = {
	readonly errorMessage?: HttpErrorMessage
	readonly ignoreIndicator?: boolean
	readonly indicatorRegion?: IndicatorRegion
}

export type HttpExtra = BaseHttpExtra<RootState, Extra>
export type HttpErrorHandler = BaseHttpErrorHandler<RootState, Extra>

export const defaultOnError: HttpErrorHandler = (extra, error) => (dispatch) => {
	if (isApiErrorType(error, ApiErrors.INTEGRATION_INVALID_AUTH)) {
		dispatch(fetchIntegrations({ force: 'soft' }))
	}

	const errorMessage = extra.errorMessage
	const toastPayload = isFunction(errorMessage)
		? dispatch(errorMessage(extra, error))
		: errorMessage

	if (!toastPayload) return

	dispatch(addErrorToast(toastPayload))
}

export const http = createHttp<RootState, Extra>(
	{
		auth: (url) => (dispatch, getState) => {
			const state = getState()

			return {
				header: { Authorization: `Bearer ${jwtToken$(state)}` },
				requiresAuth: getRequiresAuth(url),
				isAuthorized: isAuthenticated$(state),
			}
		},

		onAfterFetch: (extra) => (dispatch) => {
			if (!extra.ignoreIndicator) {
				dispatch(decrementBusyIndicator(extra.indicatorRegion))
			}
		},

		onAPIAuthError: () => (dispatch) => dispatch(authValidate()),

		onPendingFetch: (extra) => (dispatch) => {
			if (!extra.ignoreIndicator) {
				dispatch(incrementBusyIndicator(extra.indicatorRegion))
			}
		},

		onError: defaultOnError,
		normalizeUrl,
	},
	{
		errorMessage: 'Unable to process request. Please try again later.',
		ignoreIndicator: false,
	},
)
