import { formatPercent } from './formatPercent'

export const formatPercentChange = (n1: number, n2: number) => {
	// NOTE: These are &ndash;, not hyphens ('––' vs '--').
	if (n2 === 0) return '––%'

	const p = n1 !== 0 ? ((n1 - n2) / n2) * 100 : 100

	return formatPercent(p)
}
