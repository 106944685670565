import { createSelector } from '@reduxjs/toolkit'
import type { WithAccountState } from './createAccountSlice'

const state$ = <T extends WithAccountState>(s: T) => s.account

export const account$ = /* @__PURE__ */ createSelector(state$, (s) => s.data)

export const accountTimezone$ = /*@__PURE__*/ createSelector(
	account$,
	(account) => account?.timeZone,
)
