import './AddPeopleToSeatsModal.scss'

import { useBoolState, useConstant, useFn, useMounted } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import { ImmutSet } from '@eturi/util'
import {
	completedIntegrations$,
	createManagedUser,
	emptySeats$,
	managedUsers$,
	updateManagedUser,
} from '@motiv-shared/reducers'
import type {
	IntegrationIdentityId,
	IntegrationProvider,
	ManagedUser,
	MotivIntegrationIdentity,
} from '@motiv-shared/server'
import { createSelector } from '@reduxjs/toolkit'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { useEffect, useMemo, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import { Link } from 'react-router-dom'
import { affiliatedIntegrationIdentityIds$ } from '../../compound-selectors/integration-identities'
import { useDebounceFn } from '../../hooks'
import { useUserTableState } from '../../hooks/useUserTableState'
import type { ManagedUserMatch } from '../../reducers'
import {
	createIdentitiesByIntegrationId,
	currentIntegrationsDecorated$,
	fetchIntegrationIdentities,
	isSyncComplete$,
	matchManagedUsersByIdentities,
	setSeatModal,
	setSyncUIComplete,
} from '../../reducers'
import { useAppDispatch, useSelector } from '../../store'
import type { IntegrationDecorated } from '../../types'
import { MotivModal } from '../../widgets/Modal'
import { LoadingModal } from '../../widgets/Modal/LoadingModal'
import { emailFormatter, MotivTable, nameFormatter, nameSortFn } from '../../widgets/Table'
import type { SeatMatchModalProps } from './SeatMatchModal'
import { SeatMatchModal } from './SeatMatchModal'
import { SeatModals } from './seatModals.constants'

const createIntegrationDecoratedSelector = () =>
	createSelector(
		currentIntegrationsDecorated$,
		(_: any, provider: IntegrationProvider) => provider,
		(integrationsDecorated, integrationProvider) =>
			find(integrationsDecorated, { integrationProvider }),
	)

export type AddPeopleToSeatsModalProps = {
	readonly selectedProvider: IntegrationProvider
}

export const AddPeopleToSeatsModal = ({ selectedProvider }: AddPeopleToSeatsModalProps) => {
	const dispatch = useAppDispatch()
	const isMounted = useMounted()
	const integrationIdentitiesById$ = useConstant(createIdentitiesByIntegrationId)
	const selectedIntegrationDecorated$ = useConstant(createIntegrationDecoratedSelector)

	const affiliatedIntegrationIdentityIds = useSelector(affiliatedIntegrationIdentityIds$)
	const emptySeats = useSelector(emptySeats$)
	const integration = useSelector((s) => selectedIntegrationDecorated$(s, selectedProvider))
	const identities = useSelector((s) => integrationIdentitiesById$(s, integration?.id))
	const [isLoaded, setLoaded] = useBoolState(false)

	const unaffiliatedIdentities = useMemo(
		() => identities.filter((u) => !affiliatedIntegrationIdentityIds.has(u.id)),
		[affiliatedIntegrationIdentityIds, identities],
	)

	const handleClose = () => dispatch(setSeatModal(null))
	const hasNoUnaffiliatedIdentities = isLoaded && isEmpty(unaffiliatedIdentities)

	const loadUsers = useFn(async () => {
		if (!(integration && integration.id)) return setLoaded()

		const { id: integrationId, integrationProvider } = integration

		sentryBreadcrumb(`Loading identities for ${integrationProvider}`)

		try {
			await dispatch(fetchIntegrationIdentities({ integrationId })).unwrap()
		} catch (e) {
			sentryError(e, `Failed to load identities for ${integrationProvider}`)
		}

		isMounted() && setLoaded()
	})

	useEffect(() => {
		loadUsers()
	}, [])

	if (!integration || hasNoUnaffiliatedIdentities) {
		handleClose()
		return null
	}

	if (!isLoaded) return <LoadingModal onHide={handleClose} />

	return (
		<AddPeopleToSeatsImpl
			emptySeats={emptySeats}
			identities={unaffiliatedIdentities}
			integration={integration}
		/>
	)
}

type AddPeopleToSeatsProps = {
	readonly emptySeats: number
	readonly identities: MotivIntegrationIdentity[]
	readonly integration: IntegrationDecorated
}

const AddPeopleToSeatsImpl = ({
	emptySeats,
	identities,
	integration: { iconLogoSm, shortName },
}: AddPeopleToSeatsProps) => {
	const dispatch = useAppDispatch()
	const completedIntegrations = useSelector(completedIntegrations$)
	const isSyncUIComplete = useSelector(isSyncComplete$)
	const managedUsers = useSelector(managedUsers$)
	const integrations = useSelector(currentIntegrationsDecorated$)

	const [loadingProps, setLoadingProps] = useState<Maybe<SeatMatchModalProps>>(null)
	const [matchedManagedUserIds, setMatchedManagedUserIds] = useState<Maybe<string[]>>(null)

	const {
		createTableData,
		deselected: identitiesDeselected,
		filter: { data: filteredIdentities },
		renderSearchRow,
		selected: identitiesSelected,
	} = useUserTableState(identities)

	const emptySeatsRemaining = emptySeats - identitiesSelected.size
	// TODO: If this component updates w/ a new empty seats count,
	//  emptySeatsRemaining could end up less than 0. At that point, should we
	//  deselect all the users?
	const isMaxSelected = emptySeatsRemaining < 1

	// When max users are selected, everyone else is disabled. selectedIds does
	// not need to be a dep, because it's only relevant when isMaxSelected
	// changes, and
	const disabledIds = useMemo(
		() => (isMaxSelected ? identitiesDeselected.ids : new ImmutSet<IntegrationIdentityId>()),
		[identities, isMaxSelected],
	)

	const [handleAddClick, isAdding] = useDebounceFn(async () => {
		const hasMultipleIntegrations = completedIntegrations.length > 1
		const hasManagedUsers = !isEmpty(managedUsers)
		const shouldSync = hasMultipleIntegrations && hasManagedUsers
		const matches = matchManagedUsersByIdentities(managedUsers, identitiesSelected.data)
		const matchedIdentityIds = new Set(matches.map((m) => m.identity.id))
		const unmatchedIdentities = identitiesSelected.data.filter(
			({ id }) => !matchedIdentityIds.has(id),
		)

		const createPromises = unmatchedIdentities.map(
			({ avatarUrl, email, firstName, id, lastName, fullName }) => {
				// Some identities might not have a firstName/lastName
				// but do have a fullName (Microsoft)
				if (!(firstName && lastName) && fullName) {
					;[firstName, lastName] = fullName.split(' ')
				}

				return dispatch(
					createManagedUser({
						user: {
							avatarUrl,
							email: email || null,
							firstName: firstName || '', // required but can be empty
							integrationIds: [id],
							lastName: lastName || '', // required but can be empty
						},
					}),
				).unwrap()
			},
		)

		const patchPromises = matches.map(({ user, identity }) =>
			dispatch(
				updateManagedUser({
					id: user.id,
					patch: { addIntegrationIdentities: [identity.id] },
				}),
			).unwrap(),
		)

		const configs = shouldSync ? transformToConfigs(matches, integrations) : []

		let newOrUpdatedUsers: ManagedUser[] = []
		try {
			newOrUpdatedUsers = await Promise.all([...createPromises, ...patchPromises])
		} catch (e) {
			sentryError(e, 'Error adding people to seats')
		}

		const showModal = shouldSync && !isEmpty(configs) && !isEmpty(newOrUpdatedUsers)

		if (showModal) {
			setLoadingProps({ configs })
			setMatchedManagedUserIds(map(newOrUpdatedUsers, 'id'))
		} else {
			handleHide()
		}
	})

	const handleHide = useFn(() => {
		dispatch(setSeatModal(null))
	})

	const tableProps = useMemo(
		() =>
			createTableData()
				.setSelectRow({
					disabled: disabledIds,
					hideSelectAll: true,
				})

				.addColumn('fullName', {
					header: (
						<div className="d-flex align-items-center">
							<div className="add-people-to-seats__source-icon d-inline-flex mr-3">
								<img alt={`${shortName} logo`} src={iconLogoSm} height={20} width={20} />
							</div>
							{shortName} users
						</div>
					),
					isMaxWidth: true,
					render: nameFormatter,
					sort: nameSortFn,
				})

				.addColumn('email', {
					header: 'Email',
					isMaxWidth: true,
					render: emailFormatter,
					sort: true,
				})

				.props(),
		[disabledIds, filteredIdentities, iconLogoSm, identities, shortName],
	)

	useEffect(() => {
		if (isSyncUIComplete && matchedManagedUserIds != null) {
			dispatch(setSyncUIComplete(false))
			dispatch(setSeatModal({ type: SeatModals.CONFIRM_IDENTITY, matchedManagedUserIds }))
		}
	}, [isSyncUIComplete, matchedManagedUserIds])

	return loadingProps ? (
		<SeatMatchModal {...loadingProps} />
	) : (
		<MotivModal onHide={handleHide} title="Add People to Seats">
			<Modal.Body>
				<p>
					We have populated this list from your {shortName} account. Select the people you would
					like to add to teams.
				</p>

				<p>Empty seats remaining: {emptySeatsRemaining}</p>

				{renderSearchRow()}

				<MotivTable {...tableProps} />

				{isMaxSelected && (
					<Form.Text className="text-danger">
						Limit reached.{' '}
						<Link className="text-danger text-underline" onClick={handleHide} to="/billing">
							Upgrade now
						</Link>{' '}
						to manage more team members.
					</Form.Text>
				)}
			</Modal.Body>

			<Modal.Footer>
				<Button
					disabled={identitiesSelected.isEmpty || isAdding}
					onClick={handleAddClick}
					size="lg"
					variant="success"
				>
					Add to Seats
				</Button>
			</Modal.Footer>
		</MotivModal>
	)
}

const transformToConfigs = (matches: ManagedUserMatch[], integrations: IntegrationDecorated[]) => {
	const configMap = matches.reduce(
		(agg: { [P: string]: { name: string; matches: number } }, match) => {
			const provider = match.provider

			if (!provider) return agg

			if (agg[provider] == null) {
				agg[provider] = {
					matches: 0,
					name:
						integrations.find((int) => int.integrationProvider === provider)?.shortName ||
						'unknown',
				}
			}
			agg[provider].matches++
			return agg
		},
		{},
	)

	return Object.values(configMap)
}
