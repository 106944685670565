import './DashboardPage.scss'

import { DateRanges } from '@motiv-shared/reducers'
import map from 'lodash/map'
import { useSelector } from 'react-redux'
import { generatePath, Redirect, Switch } from 'react-router-dom'
import { canShowDashboardPage$ } from '../../compound-selectors'
import { sortedTeams$ } from '../../compound-selectors/teams'
import { ProtectedRoute } from '../../ProtectedRoute'
import { truncateId } from '../../util/truncateId'
import { DashboardRoutes } from './dashboard-routes'

const DEFAULT_DATE_RANGE_PARAM = DateRanges.ONE_WEEK.toLowerCase()

export const DashboardPage = () => {
	const canShowDashboardPage = useSelector(canShowDashboardPage$)
	const sortedTeams = useSelector(sortedTeams$)

	const firstTeam = sortedTeams[0]

	if (!(canShowDashboardPage && firstTeam)) return <Redirect to="/teams" />

	return (
		<Switch>
			{map(DashboardRoutes, (r) => (
				<ProtectedRoute key={r.path} component={r.view} exact path={r.path} />
			))}

			<Redirect
				to={generatePath(DashboardRoutes.Team.path, {
					dateRange: DEFAULT_DATE_RANGE_PARAM,
					teamId: truncateId(firstTeam.id),
				})}
			/>
		</Switch>
	)
}
