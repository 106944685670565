import { useBoolState, useFn, useMounted } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import {
	activeManagedUsersWithTeams$,
	extraSeats$,
	graceExpiry$,
	nextSeatLimit$,
	updateManagedUser,
} from '@motiv-shared/reducers'
import { ManagedUserState } from '@motiv-shared/server'
import moment from 'moment-timezone'
import { useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import { useUserTableState } from '../../hooks/useUserTableState'
import { addSuccessToast, setActivePurchaseModal } from '../../reducers'
import { useAppDispatch } from '../../store'
import { MotivModal } from '../../widgets/Modal'
import { assignedTeamsFormatter, MotivTable, nameFormatter, nameSortFn } from '../../widgets/Table'

export const DowngradeModal = () => {
	const dispatch = useAppDispatch()
	const extraSeats = useSelector(extraSeats$)
	const assignedManagedUsers = useSelector(activeManagedUsersWithTeams$)
	const graceExpiry = useSelector(graceExpiry$)
	const nextSeatLimit = useSelector(nextSeatLimit$)
	const isMounted = useMounted()

	const {
		createTableData,
		filter: { data: filteredAssignedManagedUsers },
		renderSearchRow,
		selected: usersSelected,
	} = useUserTableState(assignedManagedUsers)

	const [isSaving, startSaving, stopSaving] = useBoolState(false)

	const downgradeDate = useMemo(
		() => moment(graceExpiry).add(14, 'days').format('ll'),
		[graceExpiry],
	)

	const handleClose = useFn(() => {
		dispatch(setActivePurchaseModal(null))
	})

	const handleRemoveUnselected = useFn(async () => {
		sentryBreadcrumb('Deactivating Users Via Downgrade')

		startSaving()

		try {
			await Promise.all(
				[...usersSelected.ids].map((id) =>
					dispatch(updateManagedUser({ id, patch: { state: ManagedUserState.INACTIVE } })).unwrap(),
				),
			)

			dispatch(addSuccessToast(`${usersSelected.size} seats have been suspended.`))

			handleClose()
		} catch (e) {
			sentryError(e, 'Failed to deactivate users')
			isMounted() && stopSaving()
		}
	})

	const tableProps = useMemo(
		() =>
			createTableData()
				.setSelectRow({ hideSelectAll: true })

				.addColumn('fullName', {
					className: 'w-25',
					header: 'Current Seats',
					render: nameFormatter,
					sort: nameSortFn,
				})

				.addColumn('assignedTeams', {
					className: 'w-75',
					extra: { isTextOnly: true },
					header: 'Teams',
					render: assignedTeamsFormatter,
				})

				.props(),
		[filteredAssignedManagedUsers],
	)

	return (
		<MotivModal
			className="downgrade-modal"
			onHide={handleClose}
			size="lg"
			title="Confirm Team Members"
		>
			<Modal.Body>
				<p className="mb-2">
					{`Your subscription has downgraded to ${nextSeatLimit} seats. Please select 
					${extraSeats} team members you would like to remove.`}
				</p>

				<p className="mb-4">
					{`If you don't remove these extra seats, we will remove the most recently 
					added ones on ${downgradeDate}`}
				</p>

				{renderSearchRow()}

				<MotivTable {...tableProps} />
			</Modal.Body>

			<Modal.Footer>
				<Button
					disabled={isSaving || usersSelected.isEmpty}
					onClick={handleRemoveUnselected}
					size="lg"
					variant="success"
				>
					Remove Seats
				</Button>

				<Button variant="light" size="lg" onClick={handleClose}>
					Later
				</Button>
			</Modal.Footer>
		</MotivModal>
	)
}
