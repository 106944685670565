import { Polling } from '@eturi/react'
import { fetchInvoices, fetchPaymentSources, hasInvoices$ } from '@motiv-shared/reducers'
import { useEffect } from 'react'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import ListGroup from 'react-bootstrap/ListGroup'
import Row from 'react-bootstrap/Row'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { usePolling } from '../../hooks'
import { useAppDispatch } from '../../store'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { ListGroupLink } from '../../widgets/ListGroupLink'
import { BillingInvoices } from './BillingInvoices'
import { BillingOverview } from './BillingOverview'
import { BillingPlan } from './BillingPlan'

export const BillingPage = () => {
	const dispatch = useAppDispatch()
	const hasInvoices = useSelector(hasInvoices$)

	const { pathname } = useLocation()
	const isPlanDetailsPage = pathname.includes('/plan')
	const { BILLING: BillingRegion } = IndicatorRegions

	usePolling([(extra) => dispatch(fetchPaymentSources(extra))], Polling.LONG)

	useEffect(() => {
		dispatch(fetchInvoices({ indicatorRegion: BillingRegion }))
		dispatch(fetchPaymentSources({ indicatorRegion: BillingRegion }))
	}, [])

	return (
		<BusyIndicator region={[BillingRegion]}>
			<Row>
				<Col className="mb-4 mb-lg-0" lg={3}>
					<Card className="mb-4">
						<Card.Header as="h3">Billing</Card.Header>

						<ListGroup variant="flush">
							<ListGroupLink to="/billing/plan">Current Plan</ListGroupLink>
							{hasInvoices && <ListGroupLink to="/billing/invoices">Invoices</ListGroupLink>}
						</ListGroup>
					</Card>

					{isPlanDetailsPage && <BillingOverview />}
				</Col>

				<Col lg={9}>
					<Switch>
						<Route path="/billing/plan" component={BillingPlan} />
						<Route path="/billing/invoices" component={BillingInvoices} />
						<Redirect to="/billing/plan" />
					</Switch>
				</Col>
			</Row>
		</BusyIndicator>
	)
}
