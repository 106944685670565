import { setIfNotEqual } from '@eturi/util'
import type { IntegrationCatalogData } from '@motiv-shared/server'
import type { CachedState } from '../createCacheSlice'
import { createCacheSlice } from '../createCacheSlice'
import type { HttpInitHandler } from '../createHttpInitHandler'
import { fetchIntegrationCatalog } from './integrations.asyncActions'

export type IntegrationCatalogState = CachedState<IntegrationCatalogData[]>
export type WithIntegrationCatalogState = {
	readonly integrationCatalog: IntegrationCatalogState
}

const initialData: IntegrationCatalogData[] = []

export const createIntegrationCatalogSlice = <State>(httpInitHandler: HttpInitHandler<State>) =>
	createCacheSlice('integrationCatalog', initialData, httpInitHandler, (builder) =>
		builder.addCachedCase(fetchIntegrationCatalog.fulfilled, (s, a) => {
			setIfNotEqual(s, 'data', a.payload)
			s.isInit = true
		}),
	)
