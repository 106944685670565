import cls from 'classnames'
import Figure from 'react-bootstrap/Figure'
import type { KeyboardClickEvent } from '../../hooks'
import { useKeyboardClick } from '../../hooks'
import type { MotivTableSortOrder } from './MotivTable'
import type { MotivTableColDesc } from './MotivTableColDesc'

const SortCaret = ({ order }: { readonly order: MotivTableSortOrder }) => (
	<Figure
		className="sort-caret"
		title={order === null ? 'No sorting' : order === 'asc' ? 'Ascending order' : 'Descending order'}
	>
		<span className={`sort-caret__icon sort-caret__icon--${order === 'desc' ? 'down' : 'up'}`} />
		{order === null && <span className="sort-caret__icon sort-caret__icon--down" />}
	</Figure>
)

type MotivTableHeaderCellProps<Data, Field extends PlainKey<Data>> = {
	readonly column: MotivTableColDesc<Data, Field>
	readonly onClick: (
		ev: KeyboardClickEvent<HTMLTableCellElement>,
		column: MotivTableColDesc<Data, Field>,
	) => void
	readonly sortOrder: MotivTableSortOrder
}

export const MotivTableHeaderCell = <Data, Field extends PlainKey<Data>>({
	column,
	onClick,
	sortOrder,
}: MotivTableHeaderCellProps<Data, Field>) => {
	const shouldHandleClick = Boolean(column.sort || column.onHeaderClick)

	const handleClick = useKeyboardClick<HTMLTableCellElement>((ev) => {
		if (!shouldHandleClick) return

		onClick(ev, column)
	})

	const handleClickProps = shouldHandleClick ? handleClick : undefined

	return (
		<th
			className={cls(
				'motiv-table-header-cell',
				column.headerClassName,
				column.isMaxWidth && 'max-col-width',
				column.sort && 'motiv-table-header-cell--sortable',
			)}
			{...handleClickProps}
		>
			{column.header}
			{column.sort && <SortCaret order={sortOrder} />}
		</th>
	)
}
