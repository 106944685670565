import { notNull } from '@eturi/util'
import type {
	IntegrationProvider,
	ManagedUser,
	MotivIntegrationIdentity,
} from '@motiv-shared/server'
import {
	isIntegrationProvider,
	parseIntegrationIdentityId,
	splitIntegrationIdentityId,
} from '@motiv-shared/server'

export type ManagedUserMatch = {
	identity: MotivIntegrationIdentity
	provider: Maybe<IntegrationProvider>
	user: ManagedUser
}

export const matchManagedUserByIdentities = (
	user: ManagedUser,
	identities: MotivIntegrationIdentity[],
): Maybe<ManagedUserMatch> => {
	const [match] = matchManagedUsersByIdentities([user], identities)

	return match
}

export const matchManagedUsersByIdentities = (
	users: ManagedUser[],
	identities: MotivIntegrationIdentity[],
): ManagedUserMatch[] => {
	const emailUsers = matchManagedUsersMatchesByEmail(users, identities)
	const usedUserIds = new Set(emailUsers.map(({ user }) => user.id))
	const nameUsers = matchManagedUsersMatchesByName(users, identities).filter(
		({ user }) => !usedUserIds.has(user.id),
	)

	return [...emailUsers, ...nameUsers]
}

export const matchManagedUsersMatchesByEmail = (
	users: ManagedUser[],
	identities: MotivIntegrationIdentity[],
): ManagedUserMatch[] => {
	const identityMap = new Map(identities.map((i) => [i.email, i]))

	return users
		.map((user) => {
			const matchedId = user.integrationIds
				.map((id) => {
					const [provider, email] = splitIntegrationIdentityId(id)
					return { provider, email }
				})
				.find(({ email }) => identityMap.has(email))

			if (!matchedId) return null

			const { provider, email } = matchedId

			if (!isIntegrationProvider(provider)) return null

			const identity = identityMap.get(email)

			if (!identity) return null

			return { identity, provider, user }
		})
		.filter(notNull)
}

export const matchManagedUsersMatchesByName = (
	users: ManagedUser[],
	identities: MotivIntegrationIdentity[],
): ManagedUserMatch[] => {
	const identityMap = new Map(identities.map((i) => [i.fullName, i]))

	return users
		.map((user) => {
			const identity = identityMap.get(user.fullName)

			// To specify a provider for the match when matched by the managed
			// user's name, we use an integration id's provider. There is a
			// case where there are no integration id's (ManagedUser with
			// no identities) in which we do not return a match
			const [integrationId] = user.integrationIds

			const provider = integrationId && parseIntegrationIdentityId(integrationId).provider

			return identity ? { identity, user, provider } : null
		})
		.filter(notNull)
}
