import { useBoolState, useConstant, useMounted } from '@eturi/react'
import { pick } from '@eturi/util'
import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import { LinkContainer } from '../LinkContainer'
import { MotivModal } from '../Modal'

export const EnvPicker = () => {
	if (process.env.APP_ENV !== 'dev') return null

	const [isPickerVisible, showPicker, hidePicker] = useBoolState(false)
	const [formError, setFormError] = useState('')
	const [motivApiHost, _setMotivApiHost] = useState(
		() => sessionStorage.getItem('MOTIV_API_HOST') || process.env.MOTIV_API_HOST,
	)
	const [envOrEndpoint, _setEnvOrEndpoint] = useState(() => {
		return /^api\.([\w\d]+)\.motiv\.team$/.exec(motivApiHost)?.[1] || ''
	})

	const isMounted = useMounted()

	const baseConfig = useConstant(() =>
		pick(process.env, [
			'APP_ENV',
			'APP_VERSION',
			'MOTIV_AUTH0_AUDIENCE',
			'MOTIV_AUTH0_CLIENT_ID',
			'MOTIV_AUTH0_DOMAIN',
			'NODE_ENV',
		]),
	)

	const setMotivApiHost = (host: string) => {
		sessionStorage.setItem('MOTIV_API_HOST', host)

		if (isMounted()) {
			setFormError('')
			_setMotivApiHost(host)
		}
	}

	const setEnvOrEndpoint = (envOrEndpoint: string) => {
		_setEnvOrEndpoint(envOrEndpoint)

		try {
			const urlStr = /^[\w\d-]*$/.exec(envOrEndpoint)
				? `api.${envOrEndpoint ? `${envOrEndpoint}.` : ''}motiv.team`
				: envOrEndpoint
			// This is done to validate the environment.
			const url = new URL(`http://${urlStr}`)
			setMotivApiHost(url.hostname)
		} catch (e) {
			setFormError('Invalid env or endpoint url')
		}
	}

	return (
		<>
			<Button
				className="absolute-v-center"
				onClick={showPicker}
				size="sm"
				style={{ right: `12px` }}
				variant="outline-primary"
			>
				v{process.env.APP_VERSION}
			</Button>

			{isPickerVisible && (
				<MotivModal size="md" onHide={hidePicker}>
					<Modal.Header closeButton>
						<Modal.Title>Environment</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<pre>
							<code className="small">
								{JSON.stringify({ ...baseConfig, MOTIV_API_HOST: motivApiHost }, null, 2)}
							</code>
						</pre>
						<p>Choose an environment:</p>

						<Form>
							<Form.Group as={Row}>
								<Col>
									<Button block onClick={() => setEnvOrEndpoint('local')}>
										local
									</Button>
								</Col>

								<Col>
									<Button block onClick={() => setEnvOrEndpoint('dev')}>
										dev
									</Button>
								</Col>

								<Col>
									<Button block onClick={() => setEnvOrEndpoint('qa')}>
										qa
									</Button>
								</Col>

								<Col>
									<Button block onClick={() => setEnvOrEndpoint('')}>
										prod
									</Button>
								</Col>
							</Form.Group>

							<Form.Group>
								<Form.Control
									placeholder="Custom Endpoint"
									onChange={(e) => setEnvOrEndpoint(e.target.value)}
									value={envOrEndpoint}
								/>

								{formError && <Form.Text className="text-danger">{formError}</Form.Text>}
							</Form.Group>
						</Form>
					</Modal.Body>

					<Modal.Footer>
						<LinkContainer exact to="/ui-demo">
							<Button block onClick={hidePicker}>
								UI Demo
							</Button>
						</LinkContainer>
					</Modal.Footer>
				</MotivModal>
			)}
		</>
	)
}
