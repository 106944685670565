import { useConstant, useFn } from '@eturi/react'
import Button from 'react-bootstrap/Button'
import { useSelector } from 'react-redux'
import { activeManagedUsersDecorated$ } from '../../../compound-selectors'
import { currentIntegrationsDecorated$, setSeatModal } from '../../../reducers'
import { useAppDispatch } from '../../../store'
import { InstructionsWithArrow } from '../../../widgets/InstructionsWithArrow/InstructionsWithArrow'
import { OutsideCardHeader } from '../../../widgets/OutsideCardHeader'
import { SeatModals } from '../../SeatModals'
import { SeatsOverview } from './SeatsOverview'

export const ActiveSeatsOverview = () => {
	const dispatch = useAppDispatch()
	const activeUsers = useSelector(activeManagedUsersDecorated$)
	const integrations = useSelector(currentIntegrationsDecorated$)

	const handleFillSeatsClick = useFn(() => {
		const modalActionPayload =
			integrations.length === 1
				? {
						type: SeatModals.ADD_TO_SEATS,
						selectedProvider: integrations[0].integrationProvider,
				  }
				: { type: SeatModals.SELECT_INTEGRATION }

		dispatch(setSeatModal(modalActionPayload))
	})

	const Header = useConstant(() => (
		<OutsideCardHeader>
			<span className="mr-4">Active Seats</span>

			<Button block className="btn-lg-width" onClick={handleFillSeatsClick} size="lg">
				Fill Seats
			</Button>
		</OutsideCardHeader>
	))

	const NoSeats = useConstant(() => (
		<InstructionsWithArrow
			instructions="To get started, create your team members and assign them to teams!"
			imgAlt="Arrow pointing to 'Fill Seats' button"
		/>
	))

	return (
		<SeatsOverview header={Header} managedUsers={activeUsers} noSeats={NoSeats} type="active" />
	)
}
