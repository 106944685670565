import { setIfNotEqual } from '@eturi/util'
import type { CachedState, HttpInitHandler } from '@motiv-shared/reducers'
import {
	createCacheSlice,
	fetchIntegrationConfig,
	updateIntegrationConfig,
} from '@motiv-shared/reducers'
import type { IntegrationConfigRes } from '@motiv-shared/server'

type IntegrationConfigMap = {
	[integrationId: string]: IntegrationConfigRes
}

export type IntegrationConfigState = CachedState<IntegrationConfigMap>
export type WithIntegrationConfigState = {
	readonly integrationConfig: IntegrationConfigState
}

const initialData: IntegrationConfigMap = {}

export const createIntegrationConfigSlice = <State>(httpInitHandler: HttpInitHandler<State>) =>
	createCacheSlice('integrationConfig', initialData, httpInitHandler, (builder) =>
		builder
			.addCase(fetchIntegrationConfig.fulfilled, (s, a) => {
				setIfNotEqual(s.data, a.meta.arg.integrationId, a.payload)
			})
			.addCase(updateIntegrationConfig.fulfilled, (s, a) => {
				setIfNotEqual(s.data, a.meta.arg.integrationId, a.payload)
			}),
	)
