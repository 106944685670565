import { useRef } from 'react'

/**
 * Use Case:
 * React's `onClick` does not differentiate between a click and a selection. You can use this hook
 * to filter out `onClick` events that result in a selection being created.
 *
 * @param fn
 */
export const useIgnoreSelectionFn = <T extends (...args: any[]) => void>(
	fn: T,
): ((...args: Parameters<T>) => ReturnType<T> | void) => {
	const fnRef = useRef<T>(fn)

	fnRef.current = fn

	return useRef((...args: Parameters<T>) => {
		if (window.getSelection()?.toString()?.length) return

		return fnRef.current(...args)
	}).current
}
