import { useSelector } from 'react-redux'
import { canAccessProtected$ } from '../compound-selectors'
import { IntegrationsModal } from './IntegrationsModal'
import { SeatModal } from './SeatModals'
import { TeamsModal } from './TeamsModal'
import { UserModal } from './UserModal'

export const AuthModals = () => {
	const canAccessProtected = useSelector(canAccessProtected$)

	return canAccessProtected ? (
		<>
			<TeamsModal />
			<UserModal />
			<IntegrationsModal />
			<SeatModal />
		</>
	) : null
}
