import { isTruthy } from '@eturi/util'
import { user$ } from '@motiv-shared/reducers'
import { hasPermission, UserPermissions } from '@motiv-shared/server'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { SideNav } from '../../widgets/SideNav'
import { AccountOwnershipOverview } from './AccountOwnership'
import { BusinessInfoOverview } from './BusinessInfo'
import { IntegrationsOverview } from './Integrations'
import { ProfileOverview } from './Profile/ProfileOverview'
import { UsersOverview } from './Users'

export const SettingsPage = () => {
	const user = useSelector(user$)

	if (!user) return null

	const canViewAccount = hasPermission(UserPermissions.CAN_VIEW_ACCOUNT_TAB, user)
	const canDeleteOrTransferAcct = hasPermission(
		UserPermissions.CAN_TRANSFER_OR_DELETE_ACCOUNT,
		user,
	)

	const links = [
		canViewAccount && { url: '/settings/users', label: 'Users' },
		canViewAccount && { url: '/settings/integrations', label: 'Integrations' },
		canViewAccount && { url: '/settings/business-info', label: 'Business Info' },
		{ url: '/settings/profile', label: 'Profile' },
		canDeleteOrTransferAcct && { url: '/settings/account', label: 'Account Ownership' },
	].filter(isTruthy)

	return (
		<Row>
			<Col className="mb-4 mb-lg-0" lg={3}>
				<SideNav title="Settings" links={links} />
			</Col>

			<Col lg={9}>
				<Switch>
					{canViewAccount && <Route path="/settings/users" component={UsersOverview} />}

					{canViewAccount && (
						<Route path="/settings/integrations" component={IntegrationsOverview} />
					)}

					{canViewAccount && (
						<Route path="/settings/business-info" component={BusinessInfoOverview} />
					)}

					<Route path="/settings/profile" component={ProfileOverview} />

					{canDeleteOrTransferAcct && (
						<Route path="/settings/account" component={AccountOwnershipOverview} />
					)}

					{(canViewAccount && <Redirect to="/settings/users" />) || (
						<Redirect to="/settings/profile" />
					)}
				</Switch>
			</Col>
		</Row>
	)
}
