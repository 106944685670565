import { useBoolState, useConstant, useFn, useMounted } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import { ImmutSet } from '@eturi/util'
import { createTeamByIdSelector, updateTeam } from '@motiv-shared/reducers'
import type { Team } from '@motiv-shared/server'
import { MAX_MEMBERS_PER_TEAM } from '@motiv-shared/server'
import { useEffect, useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import type { ManagedUserDecorated } from '../../compound-selectors'
import {
	createActiveManagedUsersByTeam,
	createAvailableManagedUsersByTeam,
} from '../../compound-selectors'
import { useUserTableState } from '../../hooks/useUserTableState'
import { addSuccessToast, setTeamsModal } from '../../reducers'
import { useAppDispatch, useSelector } from '../../store'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { MotivModal } from '../../widgets/Modal'
import {
	emailFormatter,
	integrationsFormatter,
	MotivTable,
	nameFormatter,
	nameSortFn,
} from '../../widgets/Table'

export type AddToTeamModalProps = {
	readonly teamId: string
}

export const AddToTeamModal = ({ teamId }: AddToTeamModalProps) => {
	const dispatch = useAppDispatch()
	const activeManagedUsersByTeam$ = useConstant(createActiveManagedUsersByTeam)
	const availableManagedUsersByTeam$ = useConstant(createAvailableManagedUsersByTeam)
	const teamById$ = useConstant(createTeamByIdSelector)

	const team = useSelector((s) => teamById$(s, teamId))
	const activeTeamMembers = useSelector((s) => activeManagedUsersByTeam$(s, team))
	const availableManagedUsers = useSelector((s) => availableManagedUsersByTeam$(s, team))

	const numActiveTeamMembers = activeTeamMembers.length

	const handleClose = useFn(() => dispatch(setTeamsModal(null)))

	useEffect(() => {
		!team && handleClose()
	}, [team])

	if (!team) return null

	return (
		<AddToTeamModalImpl
			managedUsers={availableManagedUsers}
			numTeamMembers={numActiveTeamMembers}
			team={team}
		/>
	)
}

type AddToTeamModalImplProps = {
	readonly managedUsers: ManagedUserDecorated[]
	readonly numTeamMembers: number
	readonly team: Team
}

const AddToTeamModalImpl = ({ managedUsers, numTeamMembers, team }: AddToTeamModalImplProps) => {
	const dispatch = useAppDispatch()
	const isMounted = useMounted()

	const [isSaving, startSaving, stopSaving] = useBoolState(false)

	const {
		createTableData,
		deselected: usersDeselected,
		filter: { data: filteredUsers },
		renderSearchRow,
		selected: usersSelected,
	} = useUserTableState(managedUsers)

	const isMaxSelected = usersSelected.size + numTeamMembers >= MAX_MEMBERS_PER_TEAM

	// When max users are selected, everyone else is disabled. `selectedIds` does
	// not need to be a dep, because it's only relevant when `isMaxSelected`.
	const disabledIds = useMemo(
		() => (isMaxSelected ? usersDeselected.ids : new ImmutSet<string>()),
		[isMaxSelected, managedUsers],
	)

	const handleClose = useFn(() => dispatch(setTeamsModal(null)))

	const handleAddToTeamClick = useFn(async () => {
		if (isSaving) return

		startSaving()

		sentryBreadcrumb('Adding managed users to team')

		try {
			const updatedTeam = await dispatch(
				updateTeam({
					indicatorRegion: IndicatorRegions.UPDATE_TEAM,
					patch: { addManagedUsers: [...usersSelected.ids] },
					teamId: team.id,
				}),
			).unwrap()

			const numAddedMembers = usersSelected.size

			dispatch(
				addSuccessToast(
					`${numAddedMembers} team member${numAddedMembers > 1 ? 's have ' : ' has '} 
					been added to ${updatedTeam.name}.`,
				),
			)

			handleClose()
		} catch (e) {
			sentryError(e, `Error adding managed users to team`)
		}

		isMounted() && stopSaving()
	})

	const tableProps = useMemo(
		() =>
			createTableData()
				.setSelectRow({
					disabled: disabledIds,
					hideSelectAll: true,
				})

				.addColumn('fullName', {
					header: 'Name',
					isMaxWidth: true,
					render: nameFormatter,
					sort: nameSortFn,
				})

				.addColumn('email', {
					header: 'Email',
					isMaxWidth: true,
					render: emailFormatter,
					sort: true,
				})

				.addColumn('integrations', {
					className: 'columns__integrations-field',
					header: 'Integrations',
					render: integrationsFormatter,
				})

				.props(),
		[disabledIds, filteredUsers, managedUsers],
	)

	return (
		<MotivModal onHide={handleClose} title="Add People to Team">
			<BusyIndicator region={IndicatorRegions.UPDATE_TEAM}>
				<Modal.Body>
					<p className="pb-4">{`Select the current seat members you would like to add to ${team?.name}`}</p>

					{renderSearchRow()}

					<MotivTable {...tableProps} />

					{isMaxSelected && (
						<Form.Text className="text-danger">Team member limit reached.</Form.Text>
					)}
				</Modal.Body>

				<Modal.Footer>
					<Button
						disabled={usersSelected.isEmpty}
						onClick={handleAddToTeamClick}
						size="lg"
						variant="success"
					>
						Add to Team
					</Button>
				</Modal.Footer>
			</BusyIndicator>
		</MotivModal>
	)
}
