import { useBoolState, useFn, useMounted } from '@eturi/react'
import { deleteManagedUser, managedUsers$ } from '@motiv-shared/reducers'
import find from 'lodash/find'
import { useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import { addSuccessToast, setSeatModal } from '../../reducers'
import { useAppDispatch } from '../../store'
import { MotivModal } from '../../widgets/Modal'

export type DeleteSeatsModalProps = {
	readonly ids: string[]
}

export const DeleteSeatsModal = ({ ids }: DeleteSeatsModalProps) => {
	const dispatch = useAppDispatch()
	const managedUsers = useSelector(managedUsers$)
	const [isSaving, startSaving, stopSaving] = useBoolState(false)
	const isMounted = useMounted()

	const hasOne = ids.length === 1

	const managedUser = useMemo(
		() => (hasOne ? find(managedUsers, { id: ids[0] }) : null),
		[ids, managedUsers],
	)

	const handleClose = useFn(() => {
		dispatch(setSeatModal(null))
	})

	const handleDeleteClick = useFn(async () => {
		if (isSaving) return

		startSaving()

		try {
			const [deletedManagedUser] = await Promise.all(
				ids.map((id) => dispatch(deleteManagedUser({ id })).unwrap()),
			)

			const toastMsg = hasOne
				? `${deletedManagedUser?.fullName} has been removed from your seats.`
				: `${ids.length} seats have been deleted.`

			dispatch(addSuccessToast(toastMsg))
			handleClose()
		} catch (e) {
			isMounted() && stopSaving()
		}
	})

	const confirmationText = useMemo(
		() =>
			`Are you sure you want to remove ${
				managedUser ? `${managedUser.fullName} from your` : 'these'
			} seats? Team assignments will be lost.`,
		[managedUser],
	)

	return (
		<MotivModal onHide={handleClose} size="sm" title={`Delete Seat${hasOne ? '' : 's'}`}>
			<Modal.Body>
				<p>{confirmationText}</p>
			</Modal.Body>

			<Modal.Footer>
				<Button size="lg" variant="light-link" onClick={handleClose}>
					Cancel
				</Button>

				<Button disabled={isSaving} size="lg" variant="danger" onClick={handleDeleteClick}>
					Delete
				</Button>
			</Modal.Footer>
		</MotivModal>
	)
}
