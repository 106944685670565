import { useFn, useThrottle } from '@eturi/react'
import {
	activeSubExpiry$,
	assignedSeats$,
	extraSeats$,
	graceExpiry$,
	hasDowngraded$,
	isDowngradedSub$,
	isOverSeatLimit$,
	nextSeatLimit$,
	user$,
	willBeOverSeatLimit$,
} from '@motiv-shared/reducers'
import { hasPermission, UserPermissions } from '@motiv-shared/server'
import moment from 'moment-timezone'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { setActivePurchaseModal } from '../../reducers'
import { useAppDispatch } from '../../store'
import { PurchaseModalType } from '../../types'
import { Banner } from '../../widgets/Banners/Banner'
import { BannerWithBtn } from '../../widgets/Banners/BannerWithBtn'

export const DowngradeBanner = () => {
	const dispatch = useAppDispatch()
	const activeSubExpiry = useSelector(activeSubExpiry$)
	const assignedSeats = useSelector(assignedSeats$)
	const extraSeats = useSelector(extraSeats$)
	const currentUser = useSelector(user$)
	const graceExpiry = useSelector(graceExpiry$)
	const hasDowngraded = useSelector(hasDowngraded$)
	const isDowngradedSub = useSelector(isDowngradedSub$)
	const nextSeatLimit = useSelector(nextSeatLimit$)
	const isOverSeatLimit = useSelector(isOverSeatLimit$)
	const willBeOverSeatLimit = useSelector(willBeOverSeatLimit$)

	const now = useThrottle(() => Date.now(), 10_000)()

	// If the sub expires in less than a month we want to show the downgrade
	// banner
	const isOneMonthBeforeExpiry = useMemo(
		() => (activeSubExpiry ? moment(activeSubExpiry).subtract(1, 'month').isBefore(now) : false),
		[activeSubExpiry, now],
	)

	const isWithinGracePeriod = useMemo(
		() => (graceExpiry ? moment(graceExpiry).isAfter(now) : false),
		[graceExpiry, now],
	)

	// Even though sub expires on a certain date, users are not removed until
	// after 14 day grace period
	const downgradeDate = useMemo(() => {
		if (!activeSubExpiry) return null

		return moment(graceExpiry || activeSubExpiry)
			.add(14, 'days')
			.format('ll')
	}, [activeSubExpiry, graceExpiry])

	const canViewBilling = hasPermission(UserPermissions.CAN_VIEW_BILLING_TAB, currentUser)

	const showBanner =
		(isDowngradedSub || hasDowngraded) &&
		(willBeOverSeatLimit || isOverSeatLimit) &&
		(isOneMonthBeforeExpiry || isWithinGracePeriod)

	const MaxSeatMsg = useMemo(() => {
		if (canViewBilling) {
			return `Your subscription has downgraded and only allows for ${nextSeatLimit} team members. 
			Please remove ${extraSeats} team member${extraSeats > 1 ? 's' : ''}. If no action is taken, 
			we will remove the most recently added team member on ${downgradeDate}.`
		}

		return `Your subscription has downgraded and only allows for ${nextSeatLimit} team members. 
		Admin or Account Owner action required.`
	}, [assignedSeats, canViewBilling, downgradeDate, nextSeatLimit])

	const handleManageDowngrade = useFn(() => {
		dispatch(setActivePurchaseModal(PurchaseModalType.DOWNGRADE))
	})

	if (!showBanner) return null

	// Team leads do not get a button as they don't have privileges to modify members
	if (!canViewBilling) return <Banner msg={MaxSeatMsg} />

	return <BannerWithBtn btnMsg="Manage Team" msg={MaxSeatMsg} onClick={handleManageDowngrade} />
}
