import './EmailOverviewTypeControl.scss'

import { useFn } from '@eturi/react'
import { formatPercentChange } from '@motiv-shared/react'
import cls from 'classnames'
import Button from 'react-bootstrap/Button'
import Colors from '../../../styles/color-exports.module.scss'
import { ChangeArrow } from '../../../widgets/ChangeArrow'
import type { EmailOverviewDataType } from './EmailOverview'

type EmailOverviewTypeControlProps = {
	readonly className?: string
	readonly current: number
	readonly isActive: boolean
	readonly onSelect: (type: EmailOverviewDataType) => void
	readonly previous: number
	readonly type: EmailOverviewDataType
}

export const EmailOverviewTypeControl = ({
	className,
	current,
	isActive,
	onSelect,
	previous,
	type,
}: EmailOverviewTypeControlProps) => {
	const isIncreasing = current >= previous
	const color = isIncreasing ? Colors.green : Colors.red

	const handleClick = useFn(() => onSelect(type))

	return (
		<Button
			className={cls('email-overview-type-control', className, isActive && 'active')}
			onClick={handleClick}
			variant="light-link"
		>
			<span className="d-flex flex-column py-3">
				<span className="email-overview-type-control__label">{type.label}</span>

				<span className="email-overview-type-control__value">{current}</span>

				<span className="d-flex align-items-center">
					<ChangeArrow isIncreasing={isIncreasing} />

					<span className="email-overview-type-control__percent" style={{ color }}>
						{formatPercentChange(current, previous)}
					</span>
				</span>
			</span>

			<span className="email-overview-type-control__indicator" />
		</Button>
	)
}
