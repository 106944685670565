import { charAtIdx } from '@eturi/util'
import last from 'lodash/last'

export const getUserInitials = (userName: Maybe<string>) => {
	if (!userName) return ' '

	const userNames = userName.trim().split(' ')
	const firstInitial = charAtIdx(userNames[0], 0)
	const lastInitial = userNames.length > 1 ? charAtIdx(last(userNames), 0) : ''

	return firstInitial + lastInitial
}

const PARAM_KEY_SOURCE_URL = 'd'
const DEFAULT_URL_REGEX = /^https:\/\/cdn.auth0.com\/avatars\/[^\b]+\.png$/

export const isDefaultAuth0Avatar = (url: string) => {
	const parsedUrl = new URL(url)
	const sourceUrl = parsedUrl.searchParams.get(PARAM_KEY_SOURCE_URL)

	return sourceUrl ? DEFAULT_URL_REGEX.test(sourceUrl) : false
}
