import { useToggleState } from '@eturi/react'
import cls from 'classnames'
import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import { v4 } from 'uuid'
import { createMotivTableData, MotivTable } from '../widgets/Table'

type DemoTableData = {
	readonly age: number
	readonly email: string
	readonly id: string
	readonly name: string
}

const DEMO_DATA: DemoTableData[] = [
	{
		name: 'Ron Swanson',
		email: 'ron@parksnrec.com',
		id: v4(),
		age: 43,
	},
	{
		age: 120,
		email: 'a@a.com',
		id: v4(),
		name: 'James Bond',
	},
	{
		age: 56,
		email: 'thedude@aol.com',
		id: v4(),
		name: 'The Dude',
	},
	{
		age: 50,
		email: 'fred@gmail.com',
		id: v4(),
		name: 'Fred Smith',
	},
	{
		age: 13,
		email: 'jim@gmail.com',
		id: v4(),
		name: 'Jim Jimmerson',
	},
	{
		age: 63,
		email: 'janet@livingonmars.space',
		id: v4(),
		name: 'Janet Kiff',
	},
	{
		age: 48,
		email: 'isofat@blackhole.com',
		id: v4(),
		name: 'Your Mom',
	},
	{
		age: 97,
		email: 'f@fskljl.com',
		id: v4(),
		name: 'Willsky McFarthander',
	},
	{
		age: 1,
		email: 'bart@thetoddler.com',
		id: v4(),
		name: 'Bartholomew Chinstein',
	},
	{
		age: 70,
		email: 'rickc137@earth.com',
		id: v4(),
		name: 'Rick Sanchez',
	},
	{
		age: 15,
		email: 'morty@earth.com',
		id: v4(),
		name: 'Morty Smith',
	},
]

type DemoTableProps = {
	readonly bordered?: boolean
	readonly className?: string
	readonly isFixedHeader?: boolean
	readonly isPaginated?: boolean
	readonly perPage: number
}

export const DemoTable = (p: DemoTableProps) => {
	const tableProps = createMotivTableData({
		data: DEMO_DATA,
		keyField: 'id',
		onSelectChange: (selected) => {
			console.log(selected)
		},
	})
		.setSelectRow({
			disabled: DEMO_DATA.filter((d) => d.name.startsWith('J')).map((d) => d.id),
		})
		.addColumn('name', {
			header: 'Name',
			// eslint-disable-next-line
			render: (name) => (
				<div>{name.startsWith('J') ? name.split('').reverse().join('') : name}</div>
			),
			sort: (a, b) =>
				a === b ? 0 : a.startsWith('J') ? (b.startsWith('J') ? (a < b ? -1 : 1) : -1) : 1,
		})
		.addColumn('email', { header: 'Email' })
		.addColumn('age', {
			header: 'Age',
			// eslint-disable-next-line
			render: (age) => <div>{age} years old</div>,
			sort: true,
		})
		.props()

	return <MotivTable {...tableProps} {...p} />
}

export const DemoTablePage = () => {
	const [isModalVisible, toggleModalVisibility] = useToggleState(false)
	const [isBordered, toggleBordered] = useToggleState(false)
	const [isFixedHeader, toggleFixedHeader] = useToggleState(false)
	const [isPaginated, togglePaginated] = useToggleState(true)
	const [perPage, setPerPage] = useState(3)

	const demoProps = { bordered: isBordered, isFixedHeader, isPaginated, perPage }

	return (
		<>
			<Card className="mb-5">
				<Card.Header>Controls</Card.Header>

				<Card.Body as={Row}>
					<Col sm={4}>
						<Form.Check
							checked={isBordered}
							id="borderedChk"
							label="Bordered"
							onChange={toggleBordered}
						/>
					</Col>

					<Col sm={4}>
						<Form.Check
							checked={isFixedHeader}
							id="fixedHeaderChk"
							label="Fixed Header"
							onChange={toggleFixedHeader}
						/>
					</Col>

					<Col sm={4}>
						<Form.Check
							checked={isPaginated}
							className={cls(isPaginated && 'mb-3')}
							id="paginatedChk"
							label="Pagination"
							onChange={togglePaginated}
						/>

						{isPaginated && (
							<>
								<Form.Label htmlFor="paginationPerPage">Items per page: {perPage}</Form.Label>

								<Form.Control
									id="paginationPerPage"
									type="range"
									min={1}
									max={DEMO_DATA.length - 2}
									step={1}
									value={perPage}
									onChange={(ev) => {
										const newPerPage = Number.parseInt(ev.target.value) || perPage
										setPerPage(newPerPage)
									}}
								/>
							</>
						)}
					</Col>
				</Card.Body>

				<Card.Footer>
					<Button onClick={toggleModalVisibility}>Show Modal</Button>
				</Card.Footer>
			</Card>

			<Card className="mb-5">
				<Card.Header>Table Card New</Card.Header>

				<DemoTable {...demoProps} />
			</Card>

			<Card className="mb-5">
				<Card.Header>Table Card with Header + Footer</Card.Header>

				<DemoTable {...demoProps} />

				<Card.Footer className="font-weight-bold">Here&apos;s a footer</Card.Footer>
			</Card>

			<h5>Table Card without Header</h5>
			<Card className="mb-5">
				<DemoTable {...demoProps} />
			</Card>

			<Modal
				className="content-light"
				onHide={toggleModalVisibility}
				show={isModalVisible}
				size="lg"
			>
				<Modal.Header closeButton>
					<Modal.Title>Table Modal</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<DemoTable {...demoProps} />
				</Modal.Body>
			</Modal>
		</>
	)
}
