import type { PurchaseInfo } from '@motiv-shared/server'
import { useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import { StripeCardForm } from './StripeCardForm'
import { useStripeForm } from './StripeFormProvider'

type StripeChoosePaymentProps = {
	readonly className?: string
	readonly purchaseInfo: Maybe<PurchaseInfo>
}

export const StripeChoosePayment = ({ className, purchaseInfo }: StripeChoosePaymentProps) => {
	const hasPurchaseInfo = Boolean(purchaseInfo)
	const { isNewPayment, setNewPayment, isSaving } = useStripeForm()

	useEffect(() => setNewPayment(!hasPurchaseInfo), [])

	if (!hasPurchaseInfo) return <StripeCardForm />

	return (
		<div className={className}>
			<Form.Group controlId="currentCardRadio">
				<Form.Check
					checked={!isNewPayment}
					disabled={isSaving}
					label={`Current Card **** **** **** ${purchaseInfo?.last4}`}
					onChange={() => setNewPayment(false)}
					type="radio"
				/>
			</Form.Group>

			<Form.Group controlId="newCardRadio">
				<Form.Check
					checked={isNewPayment}
					disabled={isSaving}
					label="New Card"
					onChange={() => setNewPayment(true)}
					type="radio"
				/>
			</Form.Group>

			{isNewPayment && <StripeCardForm />}
		</div>
	)
}
