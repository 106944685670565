import { safeAssertUnreachable } from '@eturi/util'
import type {
	IntegrationConfigStatus,
	IntegrationProvider,
	IntegrationScope,
	IntegrationStatus,
} from '.'
import { IntegrationProviders } from '.'

export type Integration = {
	readonly id: Maybe<string> // POST returns a null id
	readonly integrationProvider: IntegrationProvider
	readonly scope: IntegrationScope
	readonly status: IntegrationStatus
	readonly configStatus: IntegrationConfigStatus
}

export type CreateIntegrationQuery = { provider?: IntegrationProvider }

export type CreateIntegrationData = {
	provider?: IntegrationProvider
	config?: { [key: string]: any }
}

export type CreateIntegrationInfo = Integration & {
	readonly authUrl: string
}

export const integrationName = (provider: IntegrationProvider) => {
	switch (provider) {
		case IntegrationProviders.GITHUB:
			return 'GitHub'

		case IntegrationProviders.GITLAB:
			return 'GitLab'

		case IntegrationProviders.GOOGLE:
			return 'Google Workspace'

		case IntegrationProviders.MICROSOFT:
			return 'Microsoft 365'

		case IntegrationProviders.SLACK:
			return 'Slack'

		case IntegrationProviders.ZOOM:
			return 'Zoom'
	}

	return safeAssertUnreachable(provider, `Unknown provider ${provider}`, 'Unknown')
}

export const integrationShortName = (provider: IntegrationProvider) =>
	integrationName(provider).split(/\s+/)[0]
