import type { InviteParams } from '@motiv-shared/server'
import { createAction } from '@reduxjs/toolkit'
import isString from 'lodash/isString'
import type { Auth0Data } from '../../types'

export enum LoginAction {
	INVITE = 'inviteSignUp',
	LOGIN = 'webappLogin',
	SIGNUP = 'webappSignup',
	SIGNUP_ACCOUNT_DELETED = 'webappSignupAccountDeleted',
}

// NOTE: This action is defined here so they can be used in async thunks
//  without running into circular dependency issues. It needs to be callable
//  by `authInit` so it can set the data before it calls `doPostAuth`. This
//  allows `authInit.fulfilled` to be a standalone state for determining
//  whether auth is done doing its thing. Otherwise, we'd have to add extra
//  state to handle post auth separately.
export const setAuthData = createAction<Maybe<Auth0Data>>('SET_AUTH_DATA')

const AUTH0_LOGIN_ACTION_KEY = '__MOTIV_AUTH0_LOGIN_ACTION__'
const AUTH0_INVITE_ACTIONS_KEY = '__MOTIV_AUTH0_INVITE_ACTIONS__'

// NOTE: We have to store these actions separate from our usual Redux state.
//  Using our redux persistence to store these values does not work because,
//  before they can be asynchronously stored, the page has already redirected.
//  We could invoke a "wait" to accommodate this, but for this limited use
//  case, it's easier and probably clearer, to just use a sync store object.
export const auth0ActionStore = {
	get login() {
		return (
			(sessionStorage.getItem(AUTH0_LOGIN_ACTION_KEY) as LoginAction | null) || LoginAction.LOGIN
		)
	},

	set login(action: LoginAction | null) {
		action
			? sessionStorage.setItem(AUTH0_LOGIN_ACTION_KEY, action)
			: sessionStorage.removeItem(AUTH0_LOGIN_ACTION_KEY)
	},

	get invite() {
		return sessionStorage.getItem(AUTH0_INVITE_ACTIONS_KEY) || ''
	},

	set invite(params: Maybe<InviteParams> | string) {
		params
			? sessionStorage.setItem(
					AUTH0_INVITE_ACTIONS_KEY,
					isString(params) ? params : JSON.stringify(params),
			  )
			: sessionStorage.removeItem(AUTH0_INVITE_ACTIONS_KEY)
	},
}
