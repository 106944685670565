import type { MotivSubscription, SKUDef, SKUId } from '@motiv-shared/server'
import { PurchaseVendor, SKUIds, SubscriptionPeriod } from '@motiv-shared/server'
import find from 'lodash/find'

const SKU_ID_WEIGHT_MAP: Map<SKUId, number> = new Map([
	[SKUIds.FREE, 0],
	[SKUIds.TRIAL, 1],
	[SKUIds.SEAT, 2],
	[SKUIds.ENTERPRISE, 3],
])

export const getTierWeight = (tierDef: SKUId): number => SKU_ID_WEIGHT_MAP.get(tierDef) || -1

export const getSKUPriceMonthlyPerSeat = (
	sku: SKUDef,
	period: SubscriptionPeriod,
	vendor: PurchaseVendor = PurchaseVendor.STRIPE,
) => {
	const price = find(sku.prices, { period, vendor })

	return (price ? price.priceUSX / 100 : 0) / (period === SubscriptionPeriod.MONTHLY ? 1 : 12)
}

export const getSubPrice = (
	sku: SKUDef,
	{ period, quantity, vendor }: Pick<MotivSubscription, 'period' | 'quantity' | 'vendor'>,
) => getSKUPrice(sku, period, quantity, vendor)

export const getSKUPrice = (
	sku: SKUDef,
	period: SubscriptionPeriod,
	quantity: number,
	vendor: PurchaseVendor = PurchaseVendor.STRIPE,
) => {
	const price = find(sku.prices, { period, vendor })

	return (price ? price.priceUSX / 100 : 0) * quantity
}
