import './TeamsSideNav.scss'

import { doTeamsExist$, hasCompletedIntegrations$, hasManagedUsers$ } from '@motiv-shared/reducers'
import { useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import { useSelector } from 'react-redux'
import { sortedTeams$ } from '../../compound-selectors/teams'
import { LinkContainer } from '../../widgets/LinkContainer'
import { SideNav } from '../../widgets/SideNav'

export const TeamsSideNav = () => {
	const isAccountIntegrated = useSelector(hasCompletedIntegrations$)

	if (!isAccountIntegrated) return null

	return <TeamSideNavImpl />
}

const TeamSideNavImpl = () => {
	const doManagedUsersExist = useSelector(hasManagedUsers$)
	const doTeamsExist = useSelector(doTeamsExist$)
	const sortedTeams = useSelector(sortedTeams$)

	const links = useMemo(
		() =>
			sortedTeams.map((team) => ({
				url: `/teams/team/${team.id}`,
				label: team.name,
			})),
		[sortedTeams],
	)

	if (!doManagedUsersExist) {
		return (
			<SideNav bodyClassName="teams-side-nav__body" title="Teams">
				<h6 className="teams-side-nav__body__header">Create Team Members</h6>

				<p className="teams-side-nav__body__message">
					You must add team members to seats before you can assign teams.
				</p>
			</SideNav>
		)
	} else if (!doTeamsExist) {
		return (
			<SideNav bodyClassName="teams-side-nav__body" title="Teams">
				<h6 className="teams-side-nav__body__header">Create Your First Team</h6>

				<p className="teams-side-nav__body__message">
					Create your first team and assign it to a team lead on your account
				</p>

				<LinkContainer to="/teams/team/new">
					<Button block size="sm">
						Create Team
					</Button>
				</LinkContainer>
			</SideNav>
		)
	} else {
		const supplementalHeader = (
			<LinkContainer to="/teams/team/new">
				<Button className="align-self-center" size="sm">
					New
				</Button>
			</LinkContainer>
		)

		return <SideNav title="Teams" links={links} supplementalHeader={supplementalHeader} />
	}
}
