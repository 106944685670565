import './IntegrationConfigModal.scss'

import { useFn } from '@eturi/react'
import { integrationsCatalog$ } from '@motiv-shared/reducers'
import type { IntegrationProvider } from '@motiv-shared/server'
import find from 'lodash/find'
import type { ChangeEvent } from 'react'
import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
import { useIntegrationFlow } from '../../IntegrationFlow'
import { integrationsDecorated$, setIntegrationsModal } from '../../reducers'
import { useAppDispatch, useSelector } from '../../store'
import { MotivModal } from '../../widgets/Modal'

export type IntegrationUrlModalProps = {
	readonly integrationProvider: IntegrationProvider
}

export const IntegrationUrlModal = ({ integrationProvider }: IntegrationUrlModalProps) => {
	const dispatch = useAppDispatch()
	const integrationsDecorated = useSelector(integrationsDecorated$)
	const integrationsCatalog = useSelector(integrationsCatalog$)
	const { connect } = useIntegrationFlow()

	const [customUrl, setCustomUrl] = useState<string>('')
	const [isDefaultEndpoint, setIsDefaultEndpoint] = useState(true)
	const [hasUrlError, setHasUrlError] = useState(false)

	const canSubmit = isDefaultEndpoint || customUrl.length

	// Gives us information about the integration like name, logo
	const selectedIntegration = integrationsDecorated.find(
		(integration) => integration.integrationProvider === integrationProvider,
	)

	// Give us information about how the integration can be configured
	const selectedConfig = integrationsCatalog.find(
		(integration) => integration.integrationProvider === integrationProvider,
	)

	const handleClose = useFn(() => {
		dispatch(setIntegrationsModal(null))
	})

	const handleUrlChange = useFn((event: ChangeEvent<HTMLInputElement>) => {
		setCustomUrl(event.target.value)
		hasUrlError && setHasUrlError(false)
	})

	const handleSubmit = useFn(() => {
		let integrationConfig: any = undefined

		if (!isDefaultEndpoint && !customUrl.length) {
			if (!customUrl.length) return

			let customDomain = ''

			try {
				customDomain = parseDomain(customUrl)
			} catch (e) {
				setHasUrlError(true)
				return
			}

			const preIntegrationConfig = find(selectedConfig!.configInfo.configItems, 'preIntegration')

			integrationConfig = { [preIntegrationConfig!.name]: customDomain }
		}

		connect(
			integrationProvider,
			integrationConfig,
			`Unable to connect to ${selectedIntegration?.displayName} server. Check your ${selectedIntegration?.displayName} URL.`,
		)

		handleClose()
	})

	if (!(selectedIntegration && selectedConfig)) return null

	const integrationName = selectedIntegration.displayName

	return (
		<MotivModal onHide={handleClose} size="md" title={`Add ${integrationName} Integration`}>
			<Modal.Body>
				<div className="d-flex pb-5 align-content-center">
					<Image
						className="config-integration__img"
						src={selectedIntegration.iconLogoSm}
						alt={`${selectedIntegration.shortName} logo`}
					/>

					<h6 className="ml-5 font-weight-bold mb-0 align-self-center">
						{selectedIntegration.shortName}
					</h6>
				</div>

				<p>
					{`Is your organization using ${integrationName}.com or are you self-hosting ${integrationName} (e.g.
					${integrationName.toLowerCase()}.company.com)?`}
				</p>

				<div className="ml-6 mt-4">
					<Form.Group controlId="defaultUrl">
						<Form.Check
							checked={isDefaultEndpoint}
							label={`Use ${integrationName}.com`}
							onChange={() => setIsDefaultEndpoint(true)}
							type="radio"
						/>
					</Form.Group>

					<Form.Group controlId="customUrl">
						<Form.Check
							checked={!isDefaultEndpoint}
							label={`Self-Hosted ${integrationName}`}
							onChange={() => setIsDefaultEndpoint(false)}
							type="radio"
						/>
					</Form.Group>

					{!isDefaultEndpoint && (
						<Form.Group>
							<Form.Control placeholder="Enter URL" value={customUrl} onChange={handleUrlChange} />

							{hasUrlError && (
								<Form.Text className="text-danger">Please Enter A Valid Url</Form.Text>
							)}
						</Form.Group>
					)}
				</div>

				<Modal.Footer>
					<Button size="lg" variant="success" disabled={!canSubmit} onClick={handleSubmit}>
						Continue
					</Button>
					<Button size="lg" variant="light" onClick={handleClose}>
						Cancel
					</Button>
				</Modal.Footer>
			</Modal.Body>
		</MotivModal>
	)
}

// NOTE: this is a duplicate of the code we used in OP to find domain of whitelist/blacklist sites
//  Server wants only the domain of the url, they will construct the custom url on their end.
// Removes www and any post hostname junk from url but maintains sub-domain if given
const parseDomain = (input: string): string => {
	// We add http:// so that URL will accept it as a valid url. We use URL to get
	// hostname, this trims out anything after the domain such as query params,
	// hashes etc. First we strip out some stuff to normalize.
	const normalizedBaseURL = input
		.replace(STARTS_WITH_PROTOCOL_REGEXP, '') // Strip protocol
		.replace(STARTS_WITH_WWW_REGEXP, '') // Strip www.
		.replace(STARTS_WITH_LOGIN_REGEXP, '') // Strip anything up and including '@'
		.trim()

	if (ONLY_DIGITS_REGEXP.test(normalizedBaseURL)) {
		throw new Error(`Invalid URL: ${normalizedBaseURL}`)
	}

	const { hostname } = new URL(`http://${normalizedBaseURL}`)

	if (!HOSTNAME_REGEXP.test(hostname)) {
		throw new Error(`Invalid URL: ${hostname}`)
	}

	return hostname
}

const HOSTNAME_REGEXP = /^(?!www\.)(?![^\s]*@)(?![^\s]*:\/\/)[^\s]*?[^.]\.[^\s.]+$/i
const ONLY_DIGITS_REGEXP = /^\d+$/
const STARTS_WITH_LOGIN_REGEXP = /^[^\s]*@/
const STARTS_WITH_PROTOCOL_REGEXP = /^https?:\/\//
const STARTS_WITH_WWW_REGEXP = /^www\./i
