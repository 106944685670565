import { isByTeamOrUserPending } from '@motiv-shared/reducers'
import type {
	GeneralEmailsByTeamMemberPeriod,
	GeneralEmailsByTeamPeriod,
} from '@motiv-shared/server'
import { createSelector } from '@reduxjs/toolkit'
import type { DateRangeFilter } from '../types'
import type { WithEmailsState } from './emails.slice'

const state$ = <T extends WithEmailsState>(s: T) => s.emails

export const emailsByTeam$ = /* @__PURE__ */ createSelector(state$, (s) => s.byTeam)
export const emailsByTeamPending$ = /* @__PURE__ */ createSelector(state$, (s) => s.byTeamPending)
export const emailsByUser$ = /* @__PURE__ */ createSelector(state$, (s) => s.byUser)
export const emailsByUserPending$ = /* @__PURE__ */ createSelector(state$, (s) => s.byUserPending)

export type EmailsByTeamPeriods = {
	readonly current: GeneralEmailsByTeamPeriod
	readonly previous: GeneralEmailsByTeamPeriod
}

export const createEmailsByTeamSelector = () =>
	createSelector(
		emailsByTeam$,
		(_: any, dateRange: DateRangeFilter, teamId: string) => ({ dateRange, teamId }),
		(emailsByTeam, { dateRange, teamId }): Maybe<EmailsByTeamPeriods> => {
			const teamEmailsDataForRange = emailsByTeam[dateRange.id + teamId]?.data

			if (!teamEmailsDataForRange) return null

			const [current, previous] = teamEmailsDataForRange.periods

			return { current, previous }
		},
	)

export const createIsEmailsByTeamPendingSelector = () =>
	createSelector(
		emailsByTeam$,
		emailsByTeamPending$,
		(_: any, dateRange: DateRangeFilter, id: string) => ({ dateRange, id }),
		isByTeamOrUserPending,
	)

export type EmailsByUserPeriods = {
	readonly current: GeneralEmailsByTeamMemberPeriod
	readonly previous: GeneralEmailsByTeamMemberPeriod
}

export const createEmailsByUserSelector = () =>
	createSelector(
		emailsByUser$,
		(_: any, dateRange: DateRangeFilter, userId: string) => ({ dateRange, userId }),
		(emailsByUser, { dateRange, userId }): Maybe<EmailsByUserPeriods> => {
			const userEmailsForDateRange = emailsByUser[dateRange.id + userId]?.data

			if (!userEmailsForDateRange) return null

			const [current, previous] = userEmailsForDateRange.periods

			return { current, previous }
		},
	)

export const createIsEmailsByUserPendingSelector = () =>
	createSelector(
		emailsByUser$,
		emailsByUserPending$,
		(_: any, dateRange: DateRangeFilter, id: string) => ({ dateRange, id }),
		isByTeamOrUserPending,
	)
