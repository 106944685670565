import { notEmpty } from '@eturi/util'
import type { IntegrationProvider } from '@motiv-shared/server'
import {
	IntegrationConfigStatuses,
	IntegrationProviders,
	IntegrationStatuses,
} from '@motiv-shared/server'
import { createSelector } from '@reduxjs/toolkit'
import filter from 'lodash/filter'
import type { WithIntegrationsState } from './createIntegrationsSlice'

const state$ = <T extends WithIntegrationsState>(s: T) => s.integrations

export const dataCompatibleIntegrations: ReadonlySet<IntegrationProvider> = new Set([
	IntegrationProviders.GOOGLE,
	IntegrationProviders.MICROSOFT,
	IntegrationProviders.SLACK,
	IntegrationProviders.ZOOM,
])

export const eventCompatibleIntegrations: ReadonlySet<IntegrationProvider> = new Set([
	IntegrationProviders.GITHUB,
	IntegrationProviders.GITLAB,
])

export const integrations$ = /* @__PURE__ */ createSelector(state$, (s) => s.data)

export const completedIntegrations$ = /* @__PURE__ */ createSelector(integrations$, (igs) =>
	filter(igs, { status: IntegrationStatuses.COMPLETE }),
)

export const configuredIntegrations$ = /*@__PURE__*/ createSelector(completedIntegrations$, (igs) =>
	filter(igs, { configStatus: IntegrationConfigStatuses.COMPLETE }),
)

export const hasConfiguredActivityFeedIntegrations$ = /*@__PURE__*/ createSelector(
	integrations$,
	(integrations) =>
		integrations.some(
			(ig) =>
				eventCompatibleIntegrations.has(ig.integrationProvider) &&
				ig.configStatus === IntegrationConfigStatuses.COMPLETE,
		),
)

export const nonConfiguredIntegrations$ = /*@__PURE__*/ createSelector(
	completedIntegrations$,
	(igs) => filter(igs, { configStatus: IntegrationConfigStatuses.INCOMPLETE }),
)

export const isAccountIntegrated$ = /* @__PURE__ */ createSelector(completedIntegrations$, notEmpty)

export const unauthorizedIntegrations$ = /* @__PURE__ */ createSelector(integrations$, (igs) =>
	filter(igs, { status: IntegrationStatuses.UNAUTHORIZED }),
)

export const hasConfiguredIntegrations$ = /*@__PURE__*/ createSelector(
	configuredIntegrations$,
	notEmpty,
)

export const hasNonConfiguredIntegrations$ = /*@__PURE__*/ createSelector(
	nonConfiguredIntegrations$,
	notEmpty,
)

export const hasCompletedIntegrations$ = /* @__PURE__ */ createSelector(
	completedIntegrations$,
	notEmpty,
)

export const hasUnauthorizedIntegrations$ = /* @__PURE__ */ createSelector(
	unauthorizedIntegrations$,
	notEmpty,
)
