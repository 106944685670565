import { useBoolState, useFn, useMounted } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import type {
	Stripe,
	StripeElement,
	StripeElementChangeEvent,
	StripeElementClasses,
	StripeElements,
	StripeElementStyle,
	StripeError,
	Token,
} from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import every from 'lodash/every'
import noop from 'lodash/noop'
import some from 'lodash/some'
import type { FormEvent, ReactNode } from 'react'
import {
	createContext,
	useContext,
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import Form from 'react-bootstrap/Form'
import type { FormGroupProps } from 'react-bootstrap/FormGroup'
import { bup } from '../../util/batchUpdates'

type StripeFormElementType = 'cardCvc' | 'cardExpiry' | 'cardNumber'
type StripeFormErrorType = StripeFormElementType | 'server'

type StripeErrors = {
	readonly [P in StripeFormErrorType]?: string
}

type StripeComplete = {
	readonly [P in StripeFormElementType]: boolean
}

type StripeSubmitErrorRes = {
	readonly declineCode?: string
	readonly detailMsg?: string
	readonly stripeCode?: string
}

type StripeFormCtx = {
	readonly elements: StripeElements | null
	readonly errors: StripeErrors
	readonly handleChange: (type: StripeFormElementType, ev: StripeElementChangeEvent) => void
	readonly isNewPayment: boolean
	readonly isReady: boolean
	readonly isSaving: boolean
	readonly isValid: boolean
	readonly setNewPayment: (isNew: boolean) => void
	readonly setServerError: (msg?: string) => void
	readonly stripe: Stripe | null
	readonly submitForm: (ev?: FormEvent) => Promise<void>
}

const StripeFormContext = createContext<StripeFormCtx>({
	elements: null,
	errors: {},
	handleChange: noop,
	isNewPayment: true,
	isReady: false,
	isSaving: false,
	isValid: false,
	setNewPayment: noop,
	setServerError: noop,
	stripe: null,
	submitForm: () => Promise.resolve(),
})

export const useStripeForm = () => useContext(StripeFormContext)

export type StripeFormSubmit = (token?: Token) => Promise<StripeSubmitErrorRes | void>

type StripeFormProps = {
	readonly children?: (props: StripeFormCtx) => ReactNode
	readonly isNewPayment?: boolean
	readonly onSubmit: StripeFormSubmit
}

let stripePromise: Promise<Stripe | null> | null = null

// FIXME: Stripe loads even without calling this! Might have to use `import()`
const getStripe = () => (stripePromise ||= loadStripe(process.env.MOTIV_STRIPE_API_KEY))

const DEFAULT_STRIPE_COMPLETE: StripeComplete = {
	cardCvc: false,
	cardExpiry: false,
	cardNumber: false,
}

export const StripeFormProvider = ({
	children,
	isNewPayment: _isNewPayment,
	onSubmit,
}: StripeFormProps) => {
	const isMounted = useMounted()

	const [complete, _setComplete] = useState<StripeComplete>(DEFAULT_STRIPE_COMPLETE)
	const [elements, setElements] = useState<StripeElements | null>(null)
	const [errors, _setErrors] = useState<StripeErrors>({})
	const [isNewPayment, _setNewPayment] = useState(_isNewPayment ?? true)
	const [isSaving, startSaving, stopSaving] = useBoolState(false)
	const [stripe, setStripe] = useState<Stripe | null>(null)

	const isReady = Boolean(stripe && elements)

	const setComplete = useFn((type: StripeFormElementType, isComplete: boolean) => {
		// Don't set set unless it's changed
		if (complete[type] !== isComplete) {
			_setComplete({ ...complete, [type]: isComplete })
		}
	})

	const setError = useFn((type: StripeFormErrorType, newErrorMsg?: string) => {
		// Don't set set unless it's changed
		if (errors[type] !== newErrorMsg) {
			_setErrors({ ...errors, [type]: newErrorMsg })
		}
	})

	const handleChange = useFn((type: StripeFormElementType, ev: StripeElementChangeEvent) => {
		if (isSaving) return

		bup(() => {
			setComplete(type, ev.complete)
			setError(type, ev.error?.message)
			setServerError(undefined)
		})
	})

	const setNewPayment = useFn((isNew: boolean) => {
		if (_isNewPayment != null) {
			console.warn(`Setting 'isNewPayment' via context, but it's already set via props`)
		}

		_setNewPayment(isNew)
	})

	const setServerError = useFn((msg?: string) => setError('server', msg))

	const handleSubmitError = useFn((res: StripeSubmitErrorRes) => {
		setError(getStripeCardErrorType(res.stripeCode), res.detailMsg)
	})

	const submitForm = useFn(async () => {
		if (!isNewPayment) {
			startSaving()

			const res = await onSubmit()

			if (res) handleSubmitError(res)

			isMounted() && stopSaving()

			return
		}

		if (!isReady) return

		const cardNumEl = elements!.getElement('cardNumber')

		if (!cardNumEl) {
			sentryError('Failed to get Stripe CardNumberElement')
			return
		}

		startSaving()

		// NOTE create token can take any for element as the parameter
		//  it appears that they use some sort of context state management
		//  so as long as they can get one element they have access to all
		//  fields data
		const { error, token } = await stripe!.createToken(cardNumEl)

		if (error || !token) {
			sentryBreadcrumb(error?.code || 'Error when creating token')
			setServerError(getStripeCardErrorStr(error))
			isMounted() && stopSaving()
			return
		}

		const res = await onSubmit(token)

		if (res) handleSubmitError(res)

		isMounted() && stopSaving()
	})

	useLayoutEffect(() => {
		getStripe().then((stripe) => {
			const elements = stripe?.elements

			if (!(stripe && elements)) return

			bup(() => {
				setElements(elements())
				setStripe(stripe)
			})
		})
	}, [])

	useEffect(() => {
		if (_isNewPayment == null) return
		_setNewPayment(_isNewPayment)
	}, [_isNewPayment])

	const value = useMemo((): StripeFormCtx => {
		const isValid = !isNewPayment || (!some(errors) && every(complete))

		return {
			elements,
			errors,
			handleChange,
			isNewPayment,
			isReady,
			isSaving,
			isValid,
			setNewPayment,
			setServerError,
			stripe,
			submitForm,
		}
	}, [complete, elements, errors, isNewPayment, isSaving, stripe])

	return (
		<StripeFormContext.Provider value={value}>
			{children && children(value)}
		</StripeFormContext.Provider>
	)
}

const STRIPE_ELEMENT_CLASSES: StripeElementClasses = {
	base: 'form-control stripe-input',
	complete: 'is-valid',
	focus: 'focus',
	invalid: 'is-invalid',
}

// TODO: We should get these exported CSS variables at some point
const INPUT_TEXT_COLOR = '#495057'
const INPUT_PLACEHOLDER_COLOR = '#9294a0'

const STRIPE_ELEMENT_STYLES: StripeElementStyle = {
	base: {
		color: INPUT_TEXT_COLOR,
		'::placeholder': { color: INPUT_PLACEHOLDER_COLOR },
	},

	invalid: { color: INPUT_TEXT_COLOR },
}

const STRIPE_ELEMENT_OPTIONS = {
	classes: STRIPE_ELEMENT_CLASSES,
	style: STRIPE_ELEMENT_STYLES,
}

const createStripeElement = <T extends StripeFormElementType>(
	type: T,
	label: string,
): typeof Form.Group => {
	const StripeElement = (p: FormGroupProps) => {
		const stripeForm = useStripeForm()
		const errorMsg = stripeForm.errors?.[type]
		const domRef = useRef<HTMLDivElement | null>(null)
		const stripeElRef = useRef<StripeElement | null>(null)

		useLayoutEffect(() => {
			// Stop if we already created the element instance
			if (stripeElRef.current) return

			const domNode = domRef.current
			const elements = stripeForm.elements

			// Wait until we have the dom now and StripeElements is loaded
			if (!(domNode && elements)) return

			const stripeEl = elements.create(type as any, STRIPE_ELEMENT_OPTIONS)

			stripeEl.on('change', (ev) => stripeForm.handleChange(type, ev))
			stripeEl.mount(domNode)
			stripeElRef.current = stripeEl
		})

		useLayoutEffect(() => () => stripeElRef.current?.destroy(), [])

		return (
			<Form.Group {...p}>
				<Form.Label>{label}</Form.Label>

				<div ref={domRef} />

				{errorMsg && <Form.Text className="text-danger">{errorMsg}</Form.Text>}
			</Form.Group>
		)
	}

	StripeElement.displayName = `StripeElement(${type})`

	return StripeElement
}

export const CardCvc = /* @__PURE__ */ createStripeElement('cardCvc', 'CVC')
export const CardExpiry = /* @__PURE__ */ createStripeElement('cardExpiry', 'Expiration Date')
export const CardNumber = /* @__PURE__ */ createStripeElement('cardNumber', 'Credit Card Number')

const getStripeCardErrorType = (code: Maybe<string>): StripeFormErrorType => {
	switch (code) {
		case 'incorrect_number':
		case 'invalid_number':
			return 'cardNumber'

		case 'incorrect_cvc':
		case 'invalid_cvc':
			return 'cardCvc'

		case 'expired_card':
		case 'invalid_expiry_month':
		case 'invalid_expiry_year':
			return 'cardExpiry'

		default:
			return 'server'
	}
}

const getStripeCardErrorStr = (error: Maybe<StripeError>): string => {
	if (!error?.code) return 'Unknown error occurred. Please try again.'

	switch (error.code) {
		case 'card_not_supported':
		case 'card_velocity_exceeded':
		case 'do_not_honor':
		case 'do_not_try_again':
		case 'generic_decline':
		case 'invalid_account':
		case 'new_account_information_available':
		case 'no_action_take':
		case 'not_permitted':
		case 'pickup_card':
		case 'restricted_card':
		case 'security_violation':
			return 'Please contact your bank or use another card.'
	}

	return 'Please use another card or try again later.'
}
