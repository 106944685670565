import type { MotivUser, Team } from '@motiv-shared/server'
import { createSelector } from '@reduxjs/toolkit'
import find from 'lodash/find'
import { userRoleData$ } from '../roles'
import { withRole } from '../roles/roles-util'
import { teams$ } from '../teams'
import type { WithAssignedTeams } from '../teams/teams-util'
import { withAssignedTeams } from '../teams/teams-util'
import { activeUsers$, inactiveUsers$, users$ } from '../users'

export const createUserByIdSelector = () =>
	createSelector(
		users$,
		teams$,
		userRoleData$,
		(_: any, userId: Maybe<string>) => userId,
		(users, teams, userRoleData, userId) => {
			if (userId == null) return undefined

			const user = find(users, { id: userId })

			return user && withAssignedTeams(withRole(user, userRoleData), teams)
		},
	)

const mapToUsersWithTeamAssignments = (
	users: MotivUser[],
	teams: Team[],
): WithAssignedTeams<MotivUser>[] => users.map((user) => withAssignedTeams(user, teams))

export const activeUsersWithTeamAssignments$ = /* @__PURE__ */ createSelector(
	activeUsers$,
	teams$,
	mapToUsersWithTeamAssignments,
)

export const inactiveUsersWithTeamAssignments$ = /* @__PURE__ */ createSelector(
	inactiveUsers$,
	teams$,
	mapToUsersWithTeamAssignments,
)

export const usersWithTeamAssignments$ = /* @__PURE__ */ createSelector(
	users$,
	teams$,
	mapToUsersWithTeamAssignments,
)
