import { useFn } from '@eturi/react'
import { notEmpty } from '@eturi/util'
import { inactiveUsers$ } from '@motiv-shared/reducers'
import Button from 'react-bootstrap/Button'
import { useSelector } from 'react-redux'
import { setUserModal } from '../../../reducers'
import { useAppDispatch } from '../../../store'
import { UserModals } from '../../UserModal'
import { InvitedUsersTable } from './InvitedUsersTable'
import { UsersTable } from './UsersTable'

export const UsersOverview = () => {
	const dispatch = useAppDispatch()
	const inactiveUsers = useSelector(inactiveUsers$)

	const handleAddUserClick = useFn(() => {
		dispatch(setUserModal({ type: UserModals.ADD_USER }))
	})

	const hasInactive = notEmpty(inactiveUsers)

	const HeaderButton = (
		<Button block className="btn-lg-width" onClick={handleAddUserClick}>
			Add User
		</Button>
	)

	return (
		<div className="users-overview">
			{hasInactive && (
				<InvitedUsersTable
					className="mb-5"
					headerButton={HeaderButton}
					inactiveUsers={inactiveUsers}
				/>
			)}

			<UsersTable headerButton={hasInactive ? undefined : HeaderButton} />
		</div>
	)
}
