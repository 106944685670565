import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { OutsideCardHeader } from '../../../widgets/OutsideCardHeader'
import { UpdatePasswordForm } from './UpdatePasswordForm'
import { UpdateProfileInfoForm } from './UpdateProfileInfoForm'

export const ProfileOverview = () => (
	<div className="users-overview">
		<Row>
			<Col md={6}>
				<OutsideCardHeader>Update Info</OutsideCardHeader>

				<Card className="mb-5">
					<Card.Body>
						<UpdateProfileInfoForm />
					</Card.Body>
				</Card>
			</Col>

			<Col md={6}>
				<OutsideCardHeader>Update Password</OutsideCardHeader>

				<Card className="mb-5">
					<Card.Body>
						<UpdatePasswordForm />
					</Card.Body>
				</Card>
			</Col>
		</Row>
	</div>
)
