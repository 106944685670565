import { useBoolState, useFn, useMounted } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import { deleteUser, loggedInUserId$ } from '@motiv-shared/reducers'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import { authLogout, LoginAction, setUserModal } from '../../reducers'
import { useAppDispatch } from '../../store'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { MotivModal } from '../../widgets/Modal'

export type DeleteUserModalProps = {
	readonly userId: string
}

export const DeleteUserModal = ({ userId }: DeleteUserModalProps) => {
	const dispatch = useAppDispatch()
	const loggedInUserId = useSelector(loggedInUserId$)
	const isMounted = useMounted()

	const [isSaving, startSaving, stopSaving] = useBoolState(false)

	const handleClose = useFn(() => {
		dispatch(setUserModal(null))
	})

	const handleDeleteClick = useFn(async () => {
		if (isSaving || !userId) return

		sentryBreadcrumb('Deleting User')

		startSaving()

		try {
			await dispatch(deleteUser({ userId, indicatorRegion: IndicatorRegions.DELETE_USER })).unwrap()

			if (userId === loggedInUserId) {
				return dispatch(authLogout(LoginAction.SIGNUP_ACCOUNT_DELETED))
			}

			handleClose()
		} catch (e) {
			sentryError(e, 'Error Deleting User')
			isMounted() && stopSaving()
		}
	})

	return (
		<MotivModal onHide={handleClose} size="sm" title="Are You Sure You Want to Delete?">
			<BusyIndicator region={IndicatorRegions.DELETE_USER}>
				<Modal.Body>
					<span className="p-0 d-block text-dark">This action cannot be undone.</span>
				</Modal.Body>

				<Modal.Footer>
					<Button disabled={isSaving} onClick={handleDeleteClick} size="lg" variant="danger">
						Delete
					</Button>

					<Button variant="light-link" onClick={handleClose}>
						Cancel
					</Button>
				</Modal.Footer>
			</BusyIndicator>
		</MotivModal>
	)
}
