import './MeetsWith.scss'

import { useToggleState } from '@eturi/react'
import type { NameMap } from '@motiv-shared/server'
import { ensureFinite } from '@motiv-shared/util'
import cls from 'classnames'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { ReactComponent as ChevronUp } from '../../../assets/ionicons/chevron-up-outline.svg'
import Colors from '../../../styles/color-exports.module.scss'

type MeetsWithProps = {
	readonly meetsWith: NameMap<number>
}

type MeetsWithEntry = {
	readonly hours: number
	readonly userName: string
}

const DEFAULT_LIST_LENGTH = 5
const CHEVRON_SIZE = 32

export const MeetsWith = ({ meetsWith }: MeetsWithProps) => {
	const [isFullListVisible, toggleFullListVisibility] = useToggleState(false)

	const meetsWithEntriesSorted = useMemo(
		(): MeetsWithEntry[] =>
			orderBy(
				map(meetsWith, (minutes, userName) => ({ hours: minutes / 60, userName })),
				['hours', 'userName'],
				['desc', 'asc'],
			),
		[meetsWith],
	)

	const meetsWithEntriesList = useMemo(
		() =>
			isFullListVisible
				? meetsWithEntriesSorted
				: meetsWithEntriesSorted.slice(0, DEFAULT_LIST_LENGTH),
		[isFullListVisible, meetsWithEntriesSorted],
	)

	const hasListOverflow = meetsWithEntriesSorted.length > DEFAULT_LIST_LENGTH
	const maxHours = meetsWithEntriesSorted[0]?.hours || 0

	return (
		<Card>
			<Card.Header as="h6">Meets With</Card.Header>

			<Card.Body className={cls(isFullListVisible && 'pb-3')}>
				{meetsWithEntriesList.map((entry, i) => (
					<MeetsWithItem key={`${entry.userName + entry.hours + i}`} {...entry} max={maxHours} />
				))}
			</Card.Body>

			{hasListOverflow && (
				<Button variant="link" onClick={toggleFullListVisibility}>
					<ChevronUp
						height={CHEVRON_SIZE}
						stroke={Colors.white}
						style={{ transform: `rotate(${isFullListVisible ? 0 : 180}deg)` }}
						width={CHEVRON_SIZE}
					/>
				</Button>
			)}
		</Card>
	)
}

type MeetsWithItemProps = MeetsWithEntry & {
	readonly max: number
}

const MeetsWithItem = ({ hours, max, userName }: MeetsWithItemProps) => (
	<figure className="d-flex meets-with-item">
		<div className="meets-with-item__name text-truncate">{userName}</div>

		<div className="flex-fill">
			<div
				className="meets-with-item__bar"
				style={{ width: `${ensureFinite(hours / max) * 100}%` }}
			>
				{hours.toFixed(hours < 1 ? 0 : 1)}
			</div>
		</div>
	</figure>
)
