import { useBoolState, useConstant, useFn, useMounted } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import { canBeTeamLeadMembers$, createTeam, teams$ } from '@motiv-shared/reducers'
import type { Team } from '@motiv-shared/server'
import { Formik } from 'formik'
import map from 'lodash/map'
import { useCallback, useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { useAppDispatch } from '../../../store'
import type { FormikSubmit } from '../../../types'
import { formikCtrlProps } from '../../../util'
import { formikMultiSelectProps } from '../../../util/formikMultiSelectProps'
import { BusyIndicator, IndicatorRegions } from '../../../widgets/BusyIndicator'
import { OutsideCardHeader } from '../../../widgets/OutsideCardHeader'
import type { MultiSelectOption } from '../../../widgets/SelectDropdown'
import type { TeamLeadsFormData } from './TeamLeadsInput'
import { INITIAL_VALUE_TEAM_LEADS, mapTeamLeadToOpts, TeamLeadsInput } from './TeamLeadsInput'
import type { TeamNameFormData } from './TeamNameInput'
import {
	buildValidateTeamName,
	INITIAL_VALUE_TEAM_NAME,
	normalizeTeamName,
	TeamNameInput,
	VALIDATION_SCHEMA_TEAM_NAME,
} from './TeamNameInput'

type NewTeamFormData = TeamNameFormData & TeamLeadsFormData

const INITIAL_VALUES: NewTeamFormData = {
	...INITIAL_VALUE_TEAM_LEADS(),
	...INITIAL_VALUE_TEAM_NAME(),
}

export const NewTeamOverview = ({ className }: { readonly className?: string }) => {
	const dispatch = useAppDispatch()
	const history = useHistory()
	const isMounted = useMounted()
	const teams = useSelector(teams$)
	const canBeTeamLeadMembers = useSelector(canBeTeamLeadMembers$)
	const [isSaving, startSaving, stopSaving] = useBoolState(false)

	const teamNames: ReadonlySet<string> = useMemo(
		() => new Set(teams.map((t) => normalizeTeamName(t.name))),
		[teams],
	)

	const validateTeamName = useCallback(buildValidateTeamName(teamNames), [teamNames])

	const assignTeamLeadOpts = useMemo(
		(): MultiSelectOption[] => canBeTeamLeadMembers.map(mapTeamLeadToOpts),
		[canBeTeamLeadMembers],
	)

	const VALIDATION_SCHEMA = useConstant(() =>
		Yup.object().shape({
			...VALIDATION_SCHEMA_TEAM_NAME(validateTeamName),
		}),
	)

	const handleSubmit: FormikSubmit<NewTeamFormData> = useFn(async (rawForm) => {
		if (isSaving) return

		// To avoid duplicating field rules (e.g. trimming) we overwrite the raw field form with the
		// result of the validation schema
		const { teamName, teamLeads } = {
			...rawForm,
			...(await VALIDATION_SCHEMA.validate(rawForm)),
		}

		startSaving()

		sentryBreadcrumb('Creating Team')

		const assignedTeamLeads = map(teamLeads, 'value')
		const name = teamName

		let newTeam: Maybe<Team>

		try {
			newTeam = await dispatch(
				createTeam({
					team: { name, assignedTeamLeads },
					indicatorRegion: IndicatorRegions.CREATE_TEAM,
				}),
			).unwrap()
		} catch (e) {
			sentryError(e, `Error creating team`)
		}

		if (isMounted()) {
			stopSaving()

			if (newTeam) {
				history.push(`/teams/team/${newTeam.id}`)
			}
		}
	})

	return (
		<div className={className}>
			<OutsideCardHeader blurb="Create your team and add team members">
				Add New Team
			</OutsideCardHeader>
			<Card>
				<Card.Body>
					<Formik<NewTeamFormData>
						initialValues={INITIAL_VALUES}
						onSubmit={handleSubmit}
						validationSchema={VALIDATION_SCHEMA}
					>
						{(p) => {
							const getCtrlProps = formikCtrlProps(p)
							const getMultiSelectProps = formikMultiSelectProps(p)

							return (
								<BusyIndicator region={IndicatorRegions.CREATE_TEAM}>
									<Row>
										<Form.Group className="mb-5" as={Col} lg={6} controlId="teamNameField">
											<TeamNameInput p={p} getCtrlProps={getCtrlProps} />
										</Form.Group>

										<Form.Group className="mb-5" as={Col} lg={6}>
											<TeamLeadsInput
												getMultiSelectProps={getMultiSelectProps}
												label="Assign to Team Lead(s)"
												placeholder="Enter User Name..."
												teamLeads={assignTeamLeadOpts}
											/>
										</Form.Group>
									</Row>
									<Row>
										<Col className="flex-column flex-center mx-auto my-4" sm={4}>
											<Button
												block
												disabled={isSaving}
												onClick={p.submitForm}
												size="lg"
												variant="success"
											>
												Create Team
											</Button>
										</Col>
									</Row>
								</BusyIndicator>
							)
						}}
					</Formik>
				</Card.Body>
			</Card>
		</div>
	)
}
