import BsListGroup from 'react-bootstrap/ListGroup'

const { defaultProps = {} } = BsListGroup

defaultProps.activeKey = Math.random()

/**
 * This is an alias of ListGroup that disables default `active` state tracking
 * by setting the `activeKey` on `defaultProps` to a random number. Without
 * this hack, we get links that remain active when they shouldn't.
 *
 * To demo the issue, comment out the above code and click between the
 * Seats -> Active sidebar link, and one of the Teams -> [TeamName] links. A
 * couple of clicks back and forth will show two links "active" at the same
 * time, even though the "exact" flags are set to "true".
 *
 * We don't need Bootstrap setting our active states since we manage this via
 * LinkContainer, which uses React Router.
 */
export const ListGroup = BsListGroup
