import { createSentryReduxEnhancer } from '@eturi/sentry'
import {
	createPersistenceEnhancer,
	emailsSlice,
	identitySlice,
	meetingsSlice,
	persistSlice,
} from '@motiv-shared/reducers'
import { configureStore } from '@reduxjs/toolkit'
import type { TypedUseSelectorHook } from 'react-redux'
import { useDispatch, useSelector as defaultUseSelector } from 'react-redux'
import { asyncLocalStorage } from './async-storage'
import { http } from './http'
import { httpInitHandler } from './httpInitHandler'
import { sentryTransformer } from './infrastructure'
import {
	accountSlice,
	integrationCatalogSlice,
	integrationsSlice,
	invoicesSlice,
	managedUsersSlice,
	purchaseInfoSlice,
	rolesSlice,
	skuDefsSlice,
	subscriptionsSlice,
	teamsSlice,
	usersSlice,
} from './reducers'
import type { RootState } from './rootReducer'
import { rootReducer } from './rootReducer'

export const store = configureStore({
	reducer: rootReducer,

	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ thunk: { extraArgument: { http, httpInit: httpInitHandler } } }),

	enhancers: [
		createSentryReduxEnhancer(sentryTransformer),

		createPersistenceEnhancer(
			[
				persistSlice(accountSlice),
				persistSlice(emailsSlice),
				persistSlice(identitySlice),
				persistSlice(integrationCatalogSlice),
				persistSlice(integrationsSlice),
				persistSlice(invoicesSlice),
				persistSlice(meetingsSlice),
				persistSlice(managedUsersSlice),
				persistSlice(purchaseInfoSlice),
				persistSlice(rolesSlice),
				persistSlice(skuDefsSlice),
				persistSlice(subscriptionsSlice),
				persistSlice(teamsSlice),
				persistSlice(usersSlice),
			],
			asyncLocalStorage,
		),
	],
})

export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export const useSelector: TypedUseSelectorHook<RootState> = defaultUseSelector
