import { Line } from '@visx/shape'
import Colors from '../../styles/color-exports.module.scss'

type Point = {
	readonly x: number
	readonly y: number
}

type TooltipLinePoint = Point & {
	readonly color: string
}

export type TooltipLineProps = Point & {
	readonly points: TooltipLinePoint[]
}

const TOOLTIP_LINE_COLOR = Colors.white

export const TooltipLine = ({ points, x, y }: TooltipLineProps) => (
	<g>
		<Line
			from={{ x, y: 0 }}
			pointerEvents="none"
			stroke={TOOLTIP_LINE_COLOR}
			strokeWidth={2}
			to={{ x, y }}
		/>

		{points.map(({ x, y, color }) => (
			<circle
				cx={x}
				cy={y}
				fill={color}
				key={color}
				pointerEvents="none"
				r={4}
				stroke={TOOLTIP_LINE_COLOR}
				strokeWidth={2}
			/>
		))}
	</g>
)
