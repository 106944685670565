import './UserAvatar.scss'

import { getUserInitials } from '@motiv-shared/reducers'
import cls from 'classnames'
import Image from 'react-bootstrap/Image'

export type UserAvatarProps = {
	readonly avatarUrl?: string
	readonly className?: string
	readonly fullName: string
}

export const UserAvatar = ({ avatarUrl, className, fullName }: UserAvatarProps) => (
	<figure className={cls('user-avatar', className, !avatarUrl && 'user-avatar--initials')}>
		{avatarUrl && (
			<Image
				alt={fullName}
				className="user-avatar__image"
				roundedCircle
				src={avatarUrl}
				title={fullName}
			/>
		)}

		{!avatarUrl && (
			<div className="user-avatar__initials" title={fullName}>
				{getUserInitials(fullName)}
			</div>
		)}
	</figure>
)
