import { floatFixed } from '@eturi/util'
import { ensureFinite } from '@motiv-shared/util'
import { AxisBottom, AxisLeft } from '@visx/axis'
import { GridColumns } from '@visx/grid'
import { Group } from '@visx/group'
import { scaleBand, scaleLinear } from '@visx/scale'
import { BarGroupHorizontal, BarRounded } from '@visx/shape'
import capitalize from 'lodash/capitalize'
import range from 'lodash/range'
import { useMemo } from 'react'
import Colors from '../../styles/color-exports.module.scss'
import {
	DEFAULT_AXIS_X_PROPS,
	DEFAULT_AXIS_Y_PROPS,
	DEFAULT_GRID_STROKE_PROPS,
	DEFAULT_LINE_STROKE_DASHARRAY,
	DEFAULT_LINE_STROKE_WIDTH,
	DEFAULT_X_TICK_LABEL_PROPS,
	DEFAULT_Y_TICK_LABEL_PROPS,
} from './defaults'
import type { AttendanceRateData } from './types'

type AttendanceRateChartProps = {
	readonly height: number
	readonly width: number
} & AttendanceRateData

type RateLabel = 'attended' | 'scheduled'

const rateKeys: RateLabel[] = ['attended', 'scheduled']

const margin = { top: 0, right: 6, bottom: 20, left: 80 }

type AttendanceRateBarData = {
	readonly attended: number
	readonly isRecurring: boolean
	readonly scheduled: number
}

export const AttendanceRateChart = ({
	height,
	nonRecurring,
	recurring,
	width,
}: AttendanceRateChartProps) => {
	const innerHeight = height - margin.top - margin.bottom
	const innerWidth = width - margin.left - margin.right

	const data = useMemo(
		(): AttendanceRateBarData[] => [
			{
				attended: recurring.value,
				isRecurring: true,
				scheduled: recurring.total,
			},
			{
				attended: nonRecurring.value,
				isRecurring: false,
				scheduled: nonRecurring.total,
			},
		],
		[nonRecurring, recurring],
	)

	const getRecurring = (d: AttendanceRateBarData) => d.isRecurring

	const recurringScale = scaleBand<boolean>({
		domain: [true, false],
		padding: 0.2,
	})

	const attendedScheduledScale = scaleBand<RateLabel>({
		domain: rateKeys,
		paddingInner: -1,
	})

	const maxRate = Math.max(nonRecurring.total, nonRecurring.value, recurring.total, recurring.value)

	const rateScale = scaleLinear<number>({
		domain: [0, maxRate],
	})

	// NOTE: `color` prop is required for the BarGroup, but we don't use it. This
	//  is a dummy accessor to satisfy that.
	const getColor = () => ''
	const formatXAxis = (minutes: number) => `${floatFixed(ensureFinite(minutes / 60), 1)}`
	const NUM_TICKS = 4

	recurringScale.rangeRound([0, innerHeight])
	attendedScheduledScale.rangeRound([0, recurringScale.bandwidth()])
	rateScale.rangeRound([0, innerWidth])

	// To calculate each tick step, take max rate, convert to hours, divide by
	// number of steps (besides 0), then round. This gives us a round tick value.
	// Then convert back, and make sure each tick is at least one minute.
	const tickValues = useMemo(() => {
		const step = Math.max(1, Math.round(maxRate / 60 / (NUM_TICKS - 1)) * 60)

		return range(0, 4).map((v) => step * v)
	}, [maxRate])

	if (Math.min(innerHeight, innerWidth) < 80) return null

	return (
		<div>
			<svg height={height} width={width}>
				<Group top={margin.top} left={margin.left}>
					<GridColumns
						{...DEFAULT_GRID_STROKE_PROPS}
						height={innerHeight}
						numTicks={NUM_TICKS}
						scale={rateScale}
						strokeDasharray={DEFAULT_LINE_STROKE_DASHARRAY}
						tickValues={tickValues}
					/>

					<BarGroupHorizontal<AttendanceRateBarData>
						color={getColor}
						data={data}
						keys={rateKeys}
						width={innerWidth}
						xScale={rateScale}
						y1Scale={attendedScheduledScale}
						y0={getRecurring}
						y0Scale={recurringScale}
					>
						{(barGroups) => {
							return barGroups.map(({ bars, index: groupIndex, y0 }) => {
								const color = groupIndex === 0 ? Colors.pink : Colors.yellow

								return (
									<Group key={`bar-group-${groupIndex}-${y0}`} top={y0}>
										{bars.map(({ height, index: barIndex, width, x, y }) => {
											if (!width) return null

											const opacity = barIndex === 0 ? 1 : 0.5
											const radius = Math.min(height, width) / 2

											return (
												<BarRounded
													bottomRight
													fill={color}
													height={height}
													key={`bar-group-bar-${groupIndex}-${barIndex}`}
													opacity={opacity}
													radius={radius}
													topRight
													width={width}
													x={x}
													y={y}
												/>
											)
										})}

										<AxisLeft
											{...DEFAULT_AXIS_Y_PROPS}
											scale={attendedScheduledScale}
											tickFormat={capitalize}
											tickLabelProps={(p) => {
												const fill = p === 'attended' ? color : Colors.white

												return {
													...DEFAULT_Y_TICK_LABEL_PROPS,
													dx: 0,
													fill,
													fontSize: 16,
													fontWeight: 600,
												}
											}}
										/>
									</Group>
								)
							})
						}}
					</BarGroupHorizontal>
				</Group>

				<line
					fill="none"
					shapeRendering="crispEdges"
					stroke={Colors.white}
					strokeWidth={DEFAULT_LINE_STROKE_WIDTH}
					x1={margin.left}
					x2={margin.left}
					y1={0}
					y2={innerHeight}
				/>

				<AxisBottom
					{...DEFAULT_AXIS_X_PROPS}
					hideAxisLine
					hideTicks
					left={margin.left}
					numTicks={NUM_TICKS}
					scale={rateScale}
					tickFormat={formatXAxis}
					tickValues={tickValues}
					tickLabelProps={() => ({
						...DEFAULT_X_TICK_LABEL_PROPS,
						dy: -5,
					})}
					top={innerHeight}
				/>
			</svg>
		</div>
	)
}
