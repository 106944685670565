// SKUs are the units clients know about that customers can have or purchase or change in between.

export type SKUId = ValueUnion<typeof SKUIds>
export const SKUIds = {
	FREE: 'free',
	ENTERPRISE: 'team.motiv.sku.enterprise',
	TRIAL: 'team.motiv.sku.trial',
	SEAT: 'team.motiv.sku.seat',
	TIER1: 'team.motiv.sku.tier1', // Obsolete
	TIER2: 'team.motiv.sku.tier2', // Obsolete
	TEST: 'test',
} as const

export const SKUIdsSet = /* @__PURE__ */ new Set(Object.values(SKUIds))

export const isSKUId = (value: any): value is SKUId => SKUIdsSet.has(value)
