import './LegendContainer.scss'

import cls from 'classnames'
import type { ReactNode } from 'react'

type LegendContainerProps = {
	readonly className?: string
	readonly children?: ReactNode
}

export const LegendContainer = ({ className, children }: LegendContainerProps) => (
	<div className={cls('legend-container', className)}>{children}</div>
)
