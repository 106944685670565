import { curry } from '@eturi/util'

/**
 * Creates a sort function based on keys you pass. Sort function takes in the
 * search and the items to compare and sorts based on `startsWith`
 * @param keys
 */

export const createSearchSort = <T>(...keys: (keyof T)[]) =>
	curry((search: string, item1: T, item2: T): number => {
		const searchLower = search.toLowerCase()

		for (const k of keys) {
			const s1 = String(item1[k] ?? '').toLowerCase()
			const s2 = String(item2[k] ?? '').toLowerCase()

			if (s1 === s2) return 0

			const s2StartsWithSearch = s2.startsWith(searchLower)

			if (s1.startsWith(searchLower)) {
				return s2StartsWithSearch ? 0 : -1
			} else if (s2StartsWithSearch) {
				return 1
			}
		}

		return 0
	})
