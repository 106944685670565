import type { UserRoleData } from '@motiv-shared/server'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState'
import type { HttpExtra } from '../http'
import type { WithRolesState } from './createRolesSlice'

const createAsyncThunk = /* @__PURE__ */ bindCreateAsyncThunkToState<WithRolesState>()

export const fetchRoles = /* @__PURE__ */ createAsyncThunk(
	'roles/getAll',
	(extra: HttpExtra = {}, { dispatch, extra: { http } }) =>
		dispatch(
			http.get<UserRoleData[]>('roles', {
				errorMessage: 'Unable to load roles. Please try again later.',
				...extra,
			}),
		),
)
