import './FeatureCheck.scss'

import Image from 'react-bootstrap/Image'
import CheckIcon from '../../assets/icons/check.svg'

type FeatureCheckProps = {
	readonly text: string
}

export const FeatureCheck = ({ text }: FeatureCheckProps) => (
	<div className="feature-check my-2">
		<Image className="feature-check__icon" src={CheckIcon} alt="white-check-icon" />
		<p className="feature-check__text mb-0">{text}</p>
	</div>
)
