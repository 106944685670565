export type IntegrationProvider = ValueUnion<typeof IntegrationProviders>
export const IntegrationProviders = {
	GITHUB: 'GITHUB',
	GITLAB: 'GITLAB',
	GOOGLE: 'GOOGLE',
	MICROSOFT: 'MICROSOFT',
	SLACK: 'SLACK',
	ZOOM: 'ZOOM',
} as const

const ProvidersSet = new Set(Object.values(IntegrationProviders))

export const isIntegrationProvider = (v?: any): v is IntegrationProvider =>
	Boolean(v && ProvidersSet.has(v))
