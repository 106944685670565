import type { Team } from '@motiv-shared/server'

export type WithAssignedTeams<T> = T & { readonly assignedTeams: Team[] }

export const withAssignedTeams = <T extends { readonly id: string }>(
	entity: T,
	teams: Team[],
): WithAssignedTeams<T> => ({
	...entity,
	assignedTeams: teams.filter(
		(team) =>
			team.assignedTeamLeads.includes(entity.id) || team.assignedManagedUsers.includes(entity.id),
	),
})
