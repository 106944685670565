import { setIfNotEqual } from '@eturi/util'
import { deleteAccount, httpUnauthorizedAction, resetAction } from '@motiv-shared/reducers'
import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import type { Auth0Data } from '../../types'
import { authInit, authValidate } from './auth.asyncActions'
import { setAuthData } from './auth.util'

export type AuthState = {
	readonly data: Maybe<Auth0Data>
	readonly isAccountDeleted: boolean
	readonly isInit: boolean
	readonly shouldValidate: boolean
}

export type WithAuthState = {
	readonly auth: AuthState
}

const initialState: AuthState = {
	data: null,
	isAccountDeleted: false,
	isInit: false,
	shouldValidate: false,
}

const authInitCompleteMatcher = /* @__PURE__ */ isAnyOf(authInit.fulfilled, authInit.rejected)
const authValidateCompleteMatcher = /* @__PURE__ */ isAnyOf(
	authValidate.fulfilled,
	authValidate.rejected,
)

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {},

	extraReducers: (builder) => {
		builder
			.addCase(resetAction.type, () => initialState)

			.addCase(deleteAccount.fulfilled, (s) => {
				// NOTE: Logging out returns initialState, so this clears `isAccountDeleted`
				s.isAccountDeleted = true
			})

			.addCase(httpUnauthorizedAction.type, (s) => {
				s.shouldValidate = true
			})

			.addCase(setAuthData, (s, a) => {
				setIfNotEqual(s, 'data', a.payload)
			})

			.addMatcher(authInitCompleteMatcher, (s) => {
				s.isInit = true
			})

			.addMatcher(authValidateCompleteMatcher, (s) => {
				s.shouldValidate = false
			})
	},
})

export const sanitizeAuthState = (_: AuthState) => null

export const typeOnlyAuthActions = /* @__PURE__ */ new Set([setAuthData.type])
