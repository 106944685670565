import { ReactComponent as ArrowUp } from '../assets/icons/arrow-up-outline.svg'
import Colors from '../styles/color-exports.module.scss'

type ChangeArrowProps = {
	readonly color?: string
	readonly isIncreasing: boolean
	readonly size?: number
}

const DEFAULT_ARROW_SIZE = 20

export const ChangeArrow = ({
	color,
	isIncreasing,
	size = DEFAULT_ARROW_SIZE,
}: ChangeArrowProps) => {
	color ??= isIncreasing ? Colors.green : Colors.red

	return (
		<ArrowUp
			height={size}
			stroke={color}
			style={{ transform: `rotate(${isIncreasing ? 0 : 180}deg)` }}
			width={size}
		/>
	)
}
