import { useFn } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import { managedUsersWithTeams$, updateTeam } from '@motiv-shared/reducers'
import map from 'lodash/map'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import { addSuccessToast, setShouldResetTeamMembersTable, setTeamsModal } from '../../reducers'
import { useAppDispatch } from '../../store'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { MotivModal } from '../../widgets/Modal'

export type RemoveTeamAssignmentsModalProps = {
	readonly teamMemberIds: string[]
}

type RemoveTeamAssignmentMap = {
	[teamId: string]: string[]
}

export const RemoveTeamAssignmentsModal = ({ teamMemberIds }: RemoveTeamAssignmentsModalProps) => {
	const dispatch = useAppDispatch()
	const teamMembersWithTeamAssignments = useSelector(managedUsersWithTeams$)

	const resetModal = useFn(() => {
		dispatch(setTeamsModal(null))
	})

	const handleRemoveAssignmentsClick = useFn(async () => {
		sentryBreadcrumb('Removing Team Assignments')

		// Create a map to figure out which team members to remove from teams
		// Map will be [teamId]: teamMembersIdsToRemove[]
		const removeTeamAssignmentMap = teamMembersWithTeamAssignments.reduce(
			(teamMap: RemoveTeamAssignmentMap, teamMember) => {
				if (!teamMemberIds.includes(teamMember.id)) return teamMap

				teamMember.assignedTeams.forEach((assignment) => {
					;(teamMap[assignment.id] ||= []).push(teamMember.id)
				})

				return teamMap
			},
			{},
		)

		try {
			await Promise.all(
				map(removeTeamAssignmentMap, (idsToRemove, teamId) =>
					dispatch(
						updateTeam({
							teamId,
							patch: { removeManagedUsers: idsToRemove },
						}),
					),
				),
			)

			dispatch(addSuccessToast('Team members have been removed from teams.'))
			dispatch(setShouldResetTeamMembersTable(true))
		} catch (e) {
			sentryError(e, 'Failed to remove team assignments')
		}

		resetModal()
	})

	return (
		<MotivModal onHide={resetModal} size="md" title="Remove from teams?">
			<BusyIndicator region={IndicatorRegions.UPDATE_TEAM}>
				<Modal.Body>
					<p>This action will remove these team members from all assigned teams.</p>
				</Modal.Body>

				<Modal.Footer>
					<Button onClick={handleRemoveAssignmentsClick} size="lg" variant="danger">
						Remove
					</Button>

					<Button onClick={resetModal} size="lg" variant="light-link">
						Cancel
					</Button>
				</Modal.Footer>
			</BusyIndicator>
		</MotivModal>
	)
}
