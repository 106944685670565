import { useConstant, useFn } from '@eturi/react'
import { ImmutSet } from '@eturi/util'
import map from 'lodash/map'
import type { ChangeEvent, Dispatch, ReactNode, SetStateAction } from 'react'
import { useEffect, useMemo, useState } from 'react'
import Form from 'react-bootstrap/Form'
import type { FormControlProps } from 'react-bootstrap/FormControl'
import { createSearchFilter } from '../util/createSearchFilter'
import { createSearchSort } from '../util/createSearchSort'
import type { SelectedSearchRowProps } from '../widgets/SelectedSearchRow'
import { SelectedSearchRow } from '../widgets/SelectedSearchRow'
import type { CreateMotivTableData, CreateMotivTableDataProps } from '../widgets/Table'
import { createMotivTableData } from '../widgets/Table'

export type UserLike = {
	readonly email: Maybe<string>
	readonly fullName: string
	readonly id: string
}

type SetState<T> = Dispatch<SetStateAction<T>>

type ImmutSetIds<T extends UserLike> = ImmutSet<T['id']>

type UseUserTableState<T extends UserLike> = {
	readonly createTableData: (
		p?: Partial<MOmit<CreateMotivTableDataProps<T, 'id'>, 'data' | 'keyField'>>,
	) => CreateMotivTableData<T, 'id'>

	readonly filter: {
		readonly data: T[]
		readonly isFiltering: boolean
		readonly setFiltering: SetState<boolean>
	}

	readonly renderSearchRow: (props?: Partial<SelectedSearchRowProps>) => ReactNode

	readonly renderSearchControl: (props?: FormControlProps) => ReactNode

	readonly search: {
		readonly data: string
		readonly set: SetState<string>
	}

	readonly deselected: {
		readonly data: T[]
		readonly ids: ImmutSetIds<T>
		readonly size: number
	}

	readonly selected: {
		readonly data: T[]
		readonly ids: ImmutSetIds<T>
		readonly isEmpty: boolean
		readonly set: SetState<ImmutSetIds<T>>
		readonly size: number
	}
}

/**
 * Maintains state common to UserLike tables. Takes in users and filters and
 * sorts them according to common logic.
 */
export const useUserTableState = <T extends UserLike>(users: T[]): UseUserTableState<T> => {
	const [isFiltering, setFiltering] = useState(false)
	const [searchData, setSearchData] = useState('')
	const [selectedIds, setSelectedIds] = useState((): ImmutSetIds<T> => new ImmutSet())

	const hasSelectedIds = Boolean(selectedIds.size)
	const userSearchFilter = useConstant(() => createSearchFilter<T>('email', 'fullName'))
	const userSearchSort = useConstant(() => createSearchSort<T>('email', 'fullName'))

	const onSearchChange = useFn((ev: ChangeEvent<HTMLInputElement>) =>
		setSearchData(ev.target.value),
	)

	// Remove any selected id's that don't correspond to a user anymore
	useEffect(() => {
		const userIdSet = new Set(users.map((u) => u.id))

		const hasMissingIds = selectedIds.filter((id) => !userIdSet.has(id)).size > 0

		if (hasMissingIds) {
			const newSelectIds = selectedIds.filter(userIdSet.has.bind(userIdSet))
			setSelectedIds(newSelectIds)
		}
	}, [users])

	return useMemo(() => {
		let cachedDeselectedData: T[] | null = null
		let cachedDeselectedIds: ImmutSetIds<T> | null = null
		let cachedSelectedData: T[] | null = null

		const filteredData = users
			.filter((u) => !isFiltering || selectedIds.has(u.id))
			.filter(userSearchFilter(searchData))
			.sort(userSearchSort(searchData))

		const renderSearchControl = (props?: FormControlProps) => (
			<Form.Control
				className="search-control mb-4 mb-md-0"
				onChange={onSearchChange}
				placeholder="Search"
				value={searchData}
				{...props}
			/>
		)

		const renderSearchRow = (props?: Partial<SelectedSearchRowProps>) => (
			<SelectedSearchRow
				hasSelected={hasSelectedIds}
				isFiltering={isFiltering}
				onFilteringChange={setFiltering}
				onSearchChange={setSearchData}
				placeholder="Search"
				searchData={searchData}
				{...props}
			/>
		)

		return {
			createTableData: (p) =>
				createMotivTableData({
					className: 'v-middle',
					data: users,
					filteredData,
					isPaginated: true,
					keyField: 'id',
					onSelectChange: setSelectedIds,
					...p,
				}),

			deselected: {
				get data() {
					return (cachedDeselectedData ||= users.filter((u) => !selectedIds.has(u.id)))
				},

				get ids() {
					return (cachedDeselectedIds ||= new ImmutSet(map(this.data, 'id')))
				},

				get size() {
					return this.data.length
				},
			},

			filter: {
				data: filteredData,
				isFiltering,
				setFiltering,
			},

			renderSearchControl,

			renderSearchRow,

			search: {
				data: searchData,
				set: setSearchData,
			},

			selected: {
				get data() {
					return (cachedSelectedData ||= users.filter((u) => selectedIds.has(u.id)))
				},

				ids: selectedIds,
				isEmpty: !hasSelectedIds,
				set: setSelectedIds,

				get size() {
					return selectedIds.size
				},
			},
		}
	}, [isFiltering, searchData, selectedIds, users])
}
