import { useConstant, useFn } from '@eturi/react'
import { createSelector } from '@reduxjs/toolkit'
import { useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import RIGHT_ARROW from '../../assets/icons/right-arrow-dark.svg'
import type { ManagedUserDecorated } from '../../compound-selectors'
import { managedUsersDecorated$ } from '../../compound-selectors'
import { useUserTableState } from '../../hooks/useUserTableState'
import { setSeatModal } from '../../reducers'
import { useAppDispatch, useSelector } from '../../store'
import { MotivModal } from '../../widgets/Modal'
import { integrationsFormatter, MotivTable, nameFormatter, nameSortFn } from '../../widgets/Table'
import { SeatModals } from './seatModals.constants'

export const createManagedUserByIdsSelector = () =>
	createSelector(
		managedUsersDecorated$,
		(_: any, userIds: string[]) => userIds,
		(managedUsers, userIds) => managedUsers.filter(({ id }) => userIds.includes(id)),
	)

export type ConfirmIdentityModalProps = {
	readonly matchedManagedUserIds: string[]
}
export const ConfirmIdentityModal = ({ matchedManagedUserIds }: ConfirmIdentityModalProps) => {
	const dispatch = useAppDispatch()
	const managedUserByIds$ = useConstant(createManagedUserByIdsSelector)
	const matchedManagedUsers = useSelector((s) => managedUserByIds$(s, matchedManagedUserIds))

	const {
		createTableData,
		filter: { data: filteredManagedUsers },
		renderSearchControl,
	} = useUserTableState(matchedManagedUsers)

	const handleHide = useFn(() => {
		dispatch(setSeatModal(null))
	})

	const handleRowSelection = useFn((_: any, user: ManagedUserDecorated) => {
		dispatch(
			setSeatModal({
				type: SeatModals.EDIT_INTEGRATIONS,
				onBackAction: { type: SeatModals.CONFIRM_IDENTITY, matchedManagedUserIds },
				selectedManagedUserId: user.id,
			}),
		)
	})

	const tableProps = useMemo(
		() =>
			createTableData({ onRowClick: handleRowSelection })
				.addColumn('fullName', {
					header: 'New Seats',
					isMaxWidth: true,
					render: nameFormatter,
					sort: nameSortFn,
				})

				.addColumn('integrations', {
					className: 'columns__integrations-field w-75',
					header: 'Linked Integrations',
					render: (integrations) => (
						<div className="d-flex justify-content-between align-items-center">
							<div>{integrationsFormatter(integrations)}</div>
							<Image src={RIGHT_ARROW} height={20} />
						</div>
					),
				})

				.props(),
		[filteredManagedUsers],
	)

	return (
		<MotivModal onHide={handleHide} title="Identity Confirmation">
			<Modal.Body className="confirm-identity-modal">
				<p>
					These people have been added to your seats. We automatically linked their profiles from
					other integrations by their account emails. You can edit their linked integrations here or
					anytime on the current seats page.
				</p>

				<Row className="justify-content-end">
					<Col className="mb-3" sm={4} md={5}>
						{renderSearchControl()}
					</Col>
				</Row>

				<MotivTable {...tableProps} />
			</Modal.Body>

			<Modal.Footer>
				<Button size="lg" variant="success" onClick={handleHide}>
					Done
				</Button>
			</Modal.Footer>
		</MotivModal>
	)
}
