import { useBoolState, useConstant, useFn, useMounted } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import { diff, pick } from '@eturi/util'
import {
	createUser,
	createUserByIdSelector,
	fetchTeams,
	inactiveUsers$,
	teams$,
	updateTeam,
	updateUser,
	userRoleData$,
} from '@motiv-shared/reducers'
import type { Team, UserRole } from '@motiv-shared/server'
import {
	hasAccountOwnerRole,
	hasAdminRole,
	hasTeamLeadRole,
	isAccountOwnerRole,
	UserRoles,
} from '@motiv-shared/server'
import { createSelector } from '@reduxjs/toolkit'
import { Form as FormikForm, Formik } from 'formik'
import difference from 'lodash/difference'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import * as Yup from 'yup'
import { addErrorToast, setUserModal } from '../../reducers'
import { useAppDispatch, useSelector } from '../../store'
import type { FormikSubmit } from '../../types'
import { formikCtrlProps } from '../../util'
import { formikMultiSelectProps } from '../../util/formikMultiSelectProps'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { FormValidationText } from '../../widgets/FormValidationText'
import { MotivModal } from '../../widgets/Modal'
import type { MultiSelectOption } from '../../widgets/SelectDropdown'
import { MultiSelect } from '../../widgets/SelectDropdown'

type ManageUserFormValues = {
	readonly assignedTeams: MultiSelectOption[]
	readonly email: string
	readonly firstName: string
	readonly lastName: string
	readonly roleId?: UserRole
}

export type AddUserModalProps = Record<string, unknown>

export type EditUserModalProps = {
	readonly userId: string
}

const invitedUsersEmails$ = createSelector(inactiveUsers$, (inactiveUsers) =>
	inactiveUsers.map((u) => u.email),
)

export const ManageUserModal = (props: AddUserModalProps | EditUserModalProps) => {
	const userId = isEditModal(props) ? props.userId : null
	const isAddUserModalOpen = !userId

	const dispatch = useAppDispatch()
	const userById$ = useConstant(createUserByIdSelector)
	const isMounted = useMounted()

	const invitedUsersEmails = useSelector(invitedUsersEmails$)
	const roleData = useSelector(userRoleData$)
	const teams = useSelector(teams$)
	const userToBeEdited = useSelector((s) => userById$(s, userId))

	const [isSaving, startSaving, stopSaving] = useBoolState(false)

	const VALIDATION_SCHEMA = useConstant(() =>
		Yup.object().shape({
			email: Yup.string().trim().email('Invalid email').required('Email is required.'),

			firstName: Yup.string()
				.trim()
				.required('First Name is required.')
				.max(70, 'Character Limit Reached'),

			lastName: Yup.string()
				.trim()
				.required('Last Name is required.')
				.max(70, 'Character Limit Reached'),

			roleId: Yup.mixed<UserRole>()
				.oneOf(Object.values(UserRoles), 'Role is invalid.')
				.required('Role is required.'),
		}),
	)

	const mapAssignedTeamsToOpts = (teams: Team[]) =>
		teams.map((t) => ({
			label: t.name,
			value: t.id,
		}))

	const assignedTeamsOpts = useMemo(() => mapAssignedTeamsToOpts(teams), [teams])

	const INITIAL_VALUES = useConstant(
		(): ManageUserFormValues =>
			userToBeEdited
				? {
						assignedTeams: mapAssignedTeamsToOpts(userToBeEdited.assignedTeams),
						...pick(userToBeEdited, ['email', 'firstName', 'lastName', 'roleId']),
				  }
				: {
						assignedTeams: [],
						email: '',
						firstName: '',
						lastName: '',
				  },
	)

	const handleClose = useFn(() => {
		dispatch(setUserModal(null))
	})

	const handleCreateUser = async (v: Required<ManageUserFormValues>) => {
		startSaving()
		sentryBreadcrumb('Creating user')

		const { assignedTeams, ...user } = v

		await dispatch(
			createUser({
				user: {
					...user,
					// TODO: This doesn't set teams for users with admin-only because they
					//  don't have permissions to be assigned as team leads. What we should
					//  really do is check the role that's assigned and see if that role
					//  has the permissions we're looking for.
					setAssignedTeams: hasAdminRole(v) ? undefined : map(assignedTeams, 'value'),
				},
			}),
		).unwrap()
	}

	const handleUpdateUser = useFn(async (v: Required<ManageUserFormValues>) => {
		// This should never happen
		if (!userId) return

		const valueDiff = diff(INITIAL_VALUES, v)

		// Don't save if nothing changed
		if (isEmpty(valueDiff)) return handleClose()

		startSaving()
		sentryBreadcrumb('Updating user')

		const { assignedTeams, ...user } = valueDiff

		const updates: Promise<any>[] = []

		// Update user if the diff isn't empty
		if (!isEmpty(user)) {
			updates.push(
				dispatch(
					updateUser({
						indicatorRegion: IndicatorRegions.UPDATE_USER,
						userId,
						patch: user,
					}),
				).unwrap(),
			)
		}

		// On edit, we have to assign/remove teams via PATCH teams
		if (assignedTeams) {
			const initialTeamIds = map(INITIAL_VALUES.assignedTeams, 'value')
			const updatedTeamIds = map(assignedTeams, 'value')
			const idList = [userId]

			updates.push(
				// Add user to any team w/ id not in initial team ids, but in updated
				...difference(updatedTeamIds, initialTeamIds).map((teamId) =>
					dispatch(updateTeam({ teamId, patch: { addTeamLeads: idList } })).unwrap(),
				),
				// Remove user from any team w/ id in initial team ids, but not in updated
				...difference(initialTeamIds, updatedTeamIds).map((teamId) =>
					dispatch(updateTeam({ teamId, patch: { removeTeamLeads: idList } })).unwrap(),
				),
			)
		}

		await Promise.all(updates)
	})

	const handleSave: FormikSubmit<ManageUserFormValues> = useFn(async (v) => {
		if (invitedUsersEmails.includes(v.email)) {
			dispatch(
				addErrorToast({
					title: 'User already exists',
					msg: 'This email is registered to another account.',
				}),
			)
			return
		}

		try {
			// At this point, the schema is valid, but this validation helps with
			// types and also runs any trims / transforms
			await (isAddUserModalOpen ? handleCreateUser : handleUpdateUser)({
				...VALIDATION_SCHEMA.validateSync(v),
				assignedTeams: v.assignedTeams,
			})

			dispatch(fetchTeams({ force: true }))

			return handleClose()
		} catch (e) {
			sentryError(e)
		}

		isMounted() && stopSaving()
	})

	return (
		<Formik<ManageUserFormValues>
			initialValues={INITIAL_VALUES}
			onSubmit={handleSave}
			validationSchema={VALIDATION_SCHEMA}
		>
			{(p) => {
				const { values } = p
				const getCtrlProps = formikCtrlProps(p)
				const getAssignedTeamsProps = formikMultiSelectProps(p)
				// Don't show role selection for account owner
				const canShowRoleSelection = !hasAccountOwnerRole(values)
				// TODO: hasPermission(canAssignTeamLead)
				const canAssignToTeams = hasTeamLeadRole(values)

				return (
					<MotivModal
						onHide={handleClose}
						size="lg"
						title={isAddUserModalOpen ? 'Add User' : 'Manage User'}
					>
						<BusyIndicator
							region={
								isAddUserModalOpen ? IndicatorRegions.ADD_NEW_USER : IndicatorRegions.UPDATE_USER
							}
						>
							<Modal.Body>
								<p>{isAddUserModalOpen ? 'Add' : 'Edit'} user details in the fields below.</p>

								<Form as={FormikForm}>
									<Row>
										<Form.Group as={Col} md={6}>
											<Form.Label>First Name</Form.Label>

											<Form.Control
												disabled={!isAddUserModalOpen}
												maxLength={73}
												placeholder="First Name"
												readOnly={!isAddUserModalOpen}
												{...getCtrlProps('firstName')}
											/>

											<FormValidationText field="firstName" formikProps={p} />
										</Form.Group>

										<Form.Group as={Col} md={6}>
											<Form.Label>Last Name</Form.Label>

											<Form.Control
												disabled={!isAddUserModalOpen}
												maxLength={73}
												placeholder="Last Name"
												readOnly={!isAddUserModalOpen}
												{...getCtrlProps('lastName')}
											/>

											<FormValidationText field="lastName" formikProps={p} />
										</Form.Group>
									</Row>

									<Row>
										<Form.Group as={Col} md={6}>
											<Form.Label>Email</Form.Label>

											<Form.Control
												disabled={!isAddUserModalOpen}
												inputMode="email"
												placeholder="Email Address"
												readOnly={!isAddUserModalOpen}
												type="email"
												{...getCtrlProps('email')}
											/>

											<FormValidationText field="email" formikProps={p} />
										</Form.Group>

										{canShowRoleSelection && (
											<Form.Group as={Col} md={6}>
												<Form.Label>Role</Form.Label>

												<Form.Control placeholder="Role" as="select" {...getCtrlProps('roleId')}>
													<option value="">Select Role</option>

													{roleData.map(
														({ id, label }) =>
															!isAccountOwnerRole(id) && (
																<option key={id} value={id}>
																	{label}
																</option>
															),
													)}
												</Form.Control>

												<FormValidationText field="roleId" formikProps={p} />
											</Form.Group>
										)}
									</Row>

									<Row>
										{canAssignToTeams && (
											<Form.Group as={Col} md={6}>
												<Form.Label>Assign Team(s) to User</Form.Label>

												<MultiSelect
													placeholder="Type Team Name..."
													options={assignedTeamsOpts}
													{...getAssignedTeamsProps('assignedTeams')}
												/>

												<FormValidationText field="assignedTeams" formikProps={p} />
											</Form.Group>
										)}
									</Row>
								</Form>
							</Modal.Body>

							<Modal.Footer>
								<Button disabled={isSaving} onClick={p.submitForm} size="lg" variant="success">
									Save
								</Button>

								<Button onClick={handleClose} size="lg" variant="light-link">
									Cancel
								</Button>
							</Modal.Footer>
						</BusyIndicator>
					</MotivModal>
				)
			}}
		</Formik>
	)
}

const isEditModal = (props: AddUserModalProps | EditUserModalProps): props is EditUserModalProps =>
	(props as any).userId != null
