import { useBoolState, useConstant, useFn, useMounted } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import { diff } from '@eturi/util'
import {
	canBeTeamLeadMembers$,
	createTeamByIdSelector,
	fetchTeams,
	teams$,
	updateTeam,
} from '@motiv-shared/reducers'
import type { Team, TeamPatch } from '@motiv-shared/server'
import { Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import { useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'
import { addSuccessToast, setTeamsModal } from '../../reducers'
import { useAppDispatch, useSelector } from '../../store'
import type { FormikSubmit } from '../../types'
import { formikCtrlProps } from '../../util'
import { formikMultiSelectProps } from '../../util/formikMultiSelectProps'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { MotivModal } from '../../widgets/Modal'
import type { MultiSelectOption } from '../../widgets/SelectDropdown'
import type { TeamLeadsFormData, TeamNameFormData } from '../Teams/Team'
import {
	buildValidateTeamName,
	INITIAL_VALUE_TEAM_LEADS,
	INITIAL_VALUE_TEAM_NAME,
	mapTeamLeadToOpts,
	normalizeTeamName,
	TeamLeadsInput,
	TeamNameInput,
	VALIDATION_SCHEMA_TEAM_NAME,
} from '../Teams/Team'
import { TeamsModals } from './teamsModals.constants'

type EditTeamInfoFormData = TeamNameFormData & TeamLeadsFormData

export type EditTeamInfoModalProps = {
	readonly teamId: string
}

export const EditTeamInfoModal = ({ teamId }: EditTeamInfoModalProps) => {
	const dispatch = useAppDispatch()
	const teamById$ = useConstant(createTeamByIdSelector)
	const team = useSelector((s) => teamById$(s, teamId))

	if (!team) {
		dispatch(setTeamsModal(null))
		return null
	}

	return <EditTeamInfoModalImpl team={team} />
}

const EditTeamInfoModalImpl = ({ team }: { readonly team: Team }) => {
	const dispatch = useAppDispatch()
	const isMounted = useMounted()
	const canBeTeamLeadMembers = useSelector(canBeTeamLeadMembers$)
	const teams = useSelector(teams$)
	const [isSaving, startSaving, stopSaving] = useBoolState(false)

	const teamNames: ReadonlySet<string> = useMemo(
		() => new Set(teams.map((t) => normalizeTeamName(t.name))),
		[teams],
	)

	const validateTeamName = useFn(buildValidateTeamName(teamNames, team.name))

	const assignTeamLeadOpts = useMemo(
		(): MultiSelectOption[] => canBeTeamLeadMembers.map(mapTeamLeadToOpts),
		[canBeTeamLeadMembers],
	)

	const INITIAL_VALUES = useConstant(
		(): EditTeamInfoFormData => ({
			...INITIAL_VALUE_TEAM_LEADS(canBeTeamLeadMembers, team.assignedTeamLeads),
			...INITIAL_VALUE_TEAM_NAME(team.name),
		}),
	)

	// Capture initial state when we're in update modal, so we don't need to
	// to save if there aren't changes.
	const INITIAL_TEAM_PATCH = useConstant(
		(): TeamPatch => ({
			name: INITIAL_VALUES.teamName,
			setTeamLeads: map(INITIAL_VALUES.teamLeads, 'value').sort(),
		}),
	)

	const VALIDATION_SCHEMA = useConstant(() =>
		Yup.object().shape({
			...VALIDATION_SCHEMA_TEAM_NAME(validateTeamName),
		}),
	)

	const handleDeleteTeamsClick = useFn(() => {
		dispatch(setTeamsModal({ type: TeamsModals.DELETE_TEAM, teamId: team.id }))
	})

	const handleClose = useFn(() => dispatch(setTeamsModal(null)))

	const handleSubmit: FormikSubmit<EditTeamInfoFormData> = useFn(async (rawForm) => {
		if (isSaving) return

		// To avoid duplicating field rules (e.g. trimming) we overwrite the raw field form with the
		// result of the validation schema
		const { teamName, teamLeads } = {
			...rawForm,
			...(await VALIDATION_SCHEMA.validate(rawForm)),
		}

		startSaving()

		sentryBreadcrumb('Updating Team')

		const assignedTeamLeads = map(teamLeads, 'value')
		const name = teamName

		try {
			const patch = diff(INITIAL_TEAM_PATCH, {
				name,
				setTeamLeads: assignedTeamLeads.sort(),
			})

			// If patch diff is empty, it means there's no changes, so just close
			if (isEmpty(patch)) return handleClose()

			await dispatch(
				updateTeam({
					patch,
					teamId: team.id,
					indicatorRegion: IndicatorRegions.UPDATE_TEAM,
				}),
			).unwrap()

			dispatch(addSuccessToast(`${name} has been updated.`))

			handleClose()
		} catch (e) {
			sentryError(e, `Error updating team`)
			dispatch(fetchTeams({ force: 'soft' }))
		}

		isMounted() && stopSaving()
	})

	return (
		<MotivModal onHide={handleClose} title="Edit Team">
			<Formik<EditTeamInfoFormData>
				initialValues={INITIAL_VALUES}
				onSubmit={handleSubmit}
				validationSchema={VALIDATION_SCHEMA}
			>
				{(p) => {
					const getCtrlProps = formikCtrlProps(p)
					const getMultiSelectProps = formikMultiSelectProps(p)

					return (
						<BusyIndicator region={IndicatorRegions.UPDATE_TEAM}>
							<Modal.Body>
								<Form.Group className="mb-5" as={Col} lg={12} controlId="teamNameField">
									<TeamNameInput p={p} getCtrlProps={getCtrlProps} />
								</Form.Group>
								<Form.Group className="mb-5" as={Col} lg={12}>
									<TeamLeadsInput
										getMultiSelectProps={getMultiSelectProps}
										label="Team Lead(s)"
										placeholder="Enter User Name..."
										teamLeads={assignTeamLeadOpts}
									/>
								</Form.Group>
							</Modal.Body>
							<Modal.Footer>
								<Button
									className="btn-left"
									onClick={handleDeleteTeamsClick}
									size="lg"
									variant="danger"
								>
									Delete Team
								</Button>
								<Button disabled={isSaving} onClick={p.submitForm} size="lg" variant="success">
									Save
								</Button>

								<Button size="lg" onClick={handleClose} variant="light-link">
									Cancel
								</Button>
							</Modal.Footer>
						</BusyIndicator>
					)
				}}
			</Formik>
		</MotivModal>
	)
}
