import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { auth0ActionStore, isAuthenticated$, isAuthInit$, LoginAction } from '../reducers'
import { LoadingFigure } from '../widgets/BusyIndicator'

export const SignUpPage = () => {
	const isAuthInit = useSelector(isAuthInit$)
	const isAuthenticated = useSelector(isAuthenticated$)

	useEffect(() => {
		auth0ActionStore.login = LoginAction.SIGNUP
	}, [])

	// We wait for isAuthInit so that we know for sure if we are logged in or not.
	// If we are we will first logout before doing initiating the invitation flow
	if (!isAuthInit) return <LoadingFigure />

	return <Redirect to={isAuthenticated ? '/logout' : '/login'} />
}
