import moment from 'moment-timezone'

export type TzOption = {
	readonly label: string
	readonly value: string
}

export const createTzOption = (tzName: string): TzOption => {
	const prettyTz = prettifyTz(tzName)
	const offsetStr = moment.tz(tzName).format('Z')

	return {
		label: `${prettyTz} (${offsetStr})`,
		value: tzName,
	}
}

export const prettifyTz = (value: string) => value.replace(/_/g, ' ')
