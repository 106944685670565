import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { FeatureCheck } from './FeatureCheck'

export const BillingOverview = () => (
	<Card>
		<Card.Body as={Row}>
			<Col className="mb-4 mb-sm-0 mb-lg-4" sm={6} lg={12}>
				<h4 className="mb-3">Features:</h4>
				<FeatureCheck text="Schedule analytics" />
				<FeatureCheck text="Email overview" />
				<FeatureCheck text="Google Meet attendance data" />
				<FeatureCheck text="Activity Feed" />
			</Col>

			<Col sm={6} lg={12}>
				<h4 className="mb-3">Integrations:</h4>
				<FeatureCheck text="Google Workspace" />
				<FeatureCheck text="Microsoft 365" />
				<FeatureCheck text="GitLab" />
				<FeatureCheck text="GitHub" />
			</Col>
		</Card.Body>
	</Card>
)
