import type { AddPeopleToSeatsModalProps } from './AddPeopleToSeatsModal'
import type { ConfirmIdentityModalProps } from './ConfirmIdentityModal'
import type { ConfirmNewIntegrationModalProps } from './ConfirmNewIntegrationModal'
import type { DeleteSeatsModalProps } from './DeleteSeatsModal'
import type { EditIntegrationsModalProps } from './EditIntegrationsModal'
import type { ReactivateTeamLimitModalProps } from './ReactivateTeamLimitModal'
import type { SuspendSeatsModalProps } from './SuspendSeatsModal'
import type { SyncIntegrationModalProps } from './SyncIntegrationModal'

export const SeatModals = {
	ADD_TO_SEATS: 'addToSeats',
	CONFIRM_IDENTITY: 'confirmIdentity',
	CONFIRM_INTEGRATION: 'confirmIntegration',
	DELETE_SEATS: 'deleteSeats',
	EDIT_INTEGRATIONS: 'editIntegrations',
	REACTIVATE_TEAM_LIMIT: 'reactivateTeamLimit',
	SELECT_INTEGRATION: 'selectIntegration',
	SUSPEND_SEATS: 'suspendSeats',
	SYNC_INTEGRATION: 'syncIntegration',
} as const

export type SeatModalType =
	| AddPeopleToSeatsModalType
	| ConfirmIdentityModalType
	| ConfirmNewIntegrationModalType
	| DeleteSeatsModalType
	| EditIntegrationsModalType
	| ReactivateTeamLimitModalType
	| SelectIntegrationModalType
	| SuspendSeatsModalType
	| SyncIntegrationModalType

export type AddPeopleToSeatsModalType = {
	readonly type: typeof SeatModals.ADD_TO_SEATS
} & AddPeopleToSeatsModalProps

export type ConfirmIdentityModalType = {
	readonly type: typeof SeatModals.CONFIRM_IDENTITY
} & ConfirmIdentityModalProps

export type ConfirmNewIntegrationModalType = {
	readonly type: typeof SeatModals.CONFIRM_INTEGRATION
} & ConfirmNewIntegrationModalProps

export type DeleteSeatsModalType = {
	readonly type: typeof SeatModals.DELETE_SEATS
} & DeleteSeatsModalProps

export type EditIntegrationsModalType = {
	readonly type: typeof SeatModals.EDIT_INTEGRATIONS
} & EditIntegrationsModalProps

export type ReactivateTeamLimitModalType = {
	readonly type: typeof SeatModals.REACTIVATE_TEAM_LIMIT
} & ReactivateTeamLimitModalProps

export type SelectIntegrationModalType = {
	readonly type: typeof SeatModals.SELECT_INTEGRATION
}

export type SuspendSeatsModalType = {
	readonly type: typeof SeatModals.SUSPEND_SEATS
} & SuspendSeatsModalProps

export type SyncIntegrationModalType = {
	readonly type: typeof SeatModals.SYNC_INTEGRATION
} & SyncIntegrationModalProps
