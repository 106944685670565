import './EditIntegrationsModal.scss'

import { useConstant, useFn, useMounted } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import { notNull } from '@eturi/util'
import { completedIntegrations$, managedUsers$, updateManagedUser } from '@motiv-shared/reducers'
import type {
	Integration,
	IntegrationIdentityId,
	ManagedUser,
	ManagedUserPatch,
	MotivIntegrationIdentity,
} from '@motiv-shared/server'
import { splitIntegrationIdentityId } from '@motiv-shared/server'
import { createSelector } from '@reduxjs/toolkit'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import size from 'lodash/size'
import { useEffect, useMemo, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
import type { OptionProps } from 'react-select'
import Select from 'react-select'
import X_ICON from '../../assets/icons/x.svg'
import { affiliatedIntegrationIdentityIds$ } from '../../compound-selectors/integration-identities'
import {
	fetchIntegrationIdentities,
	integrationIdentities$,
	integrationsDecorated$,
	matchManagedUserByIdentities,
	setSeatModal,
} from '../../reducers'
import { useAppDispatch, useSelector } from '../../store'
import type { IntegrationDecorated } from '../../types'
import { AvatarWithUserName, UserAvatar } from '../../widgets/Avatar'
import { LinkContainer } from '../../widgets/LinkContainer'
import { MotivModal } from '../../widgets/Modal'
import { LoadingModal } from '../../widgets/Modal/LoadingModal'
import type { MultiSelectOption } from '../../widgets/SelectDropdown'
import type { SeatModalType } from './seatModals.constants'

const createSelectedMangedUserSelector = () =>
	createSelector(
		managedUsers$,
		(_: any, selectedMangedUserId: string) => selectedMangedUserId,
		(users, selectedId) => users.find((u) => u.id === selectedId),
	)

export type EditIntegrationsModalProps = {
	readonly canSync?: boolean
	readonly onBackAction?: SeatModalType
	readonly selectedManagedUserId: string
}

export const EditIntegrationsModal = ({
	canSync = false,
	onBackAction,
	selectedManagedUserId,
}: EditIntegrationsModalProps) => {
	const dispatch = useAppDispatch()
	const selectedMangedUser$ = useConstant(createSelectedMangedUserSelector)
	const completedIntegrations = useSelector(completedIntegrations$)
	const selectedManagedUser = useSelector((s) => selectedMangedUser$(s, selectedManagedUserId))
	const isMounted = useMounted()

	const [isLoaded, setLoaded] = useState(false)

	const handleClose = () => dispatch(setSeatModal(null))

	const loadIntegrationUsers = async ({ id, integrationProvider }: Integration) => {
		if (!id) return

		sentryBreadcrumb(`Loading identities for ${integrationProvider}`)

		try {
			await dispatch(fetchIntegrationIdentities({ integrationId: id })).unwrap()
		} catch (e) {
			sentryError(e, `Failed to load identities for ${integrationProvider}`)
		}
	}

	const fetchCompletedIntegrationIdentities = useFn(async () => {
		try {
			await Promise.all(completedIntegrations.map(loadIntegrationUsers))

			isMounted() && setLoaded(true)
		} catch (e) {
			// If we fail to fetch integration identities then
			// user can't really edit them so just don't show modal
			handleClose()
		}
	})

	useEffect(() => {
		fetchCompletedIntegrationIdentities()
	}, [])

	if (!selectedManagedUser) return null

	if (!isLoaded) return <LoadingModal onHide={handleClose} />

	return (
		<EditIntegrationModalImpl
			canSync={canSync}
			onBackAction={onBackAction}
			selectedManagedUser={selectedManagedUser}
		/>
	)
}

type EditIntegrationModalImplProps = {
	readonly canSync: boolean
	readonly onBackAction?: SeatModalType
	readonly selectedManagedUser: ManagedUser
}

const EditIntegrationModalImpl = ({
	canSync,
	onBackAction,
	selectedManagedUser,
}: EditIntegrationModalImplProps) => {
	const dispatch = useAppDispatch()
	const integrationsDecorated = useSelector(integrationsDecorated$)
	const integrationIdentities = useSelector(integrationIdentities$)
	const isMounted = useMounted()

	const { fullName, id: managedUserId, integrationIds } = selectedManagedUser

	// Will be used to set auto save, once calls are working
	const [isSaving, setSaving] = useState(false)
	const [syncError, setSyncError] = useState<Maybe<string>>('')
	const [wasSaveSuccess, setSaveSuccess] = useState(false)

	// Only show sync button if we have integrations with identities not assigned
	// to this seat
	const canShowSyncButton =
		canSync && !isEmpty(integrationIds) && size(integrationIds) < size(integrationIdentities)

	const handleClose = useFn(() => {
		dispatch(setSeatModal(null))
	})

	const handleBackBtn = useFn(() => {
		dispatch(setSeatModal(onBackAction))
	})

	const patchUserIntegrations = useFn(async (patch: ManagedUserPatch) => {
		if (isSaving) return

		setSaving(true)
		setSaveSuccess(false)

		try {
			await dispatch(updateManagedUser({ id: managedUserId, patch })).unwrap()

			isMounted() && setSaveSuccess(true)
		} catch (e) {
			sentryError(e, 'Error updating seat integration')
		}

		isMounted() && setSaving(false)
	})

	const handleIntegrationAdd = useFn((identityId: string) => {
		patchUserIntegrations({ addIntegrationIdentities: [identityId] })
	})

	const handleIntegrationRemove = useFn((identityId: string) => {
		patchUserIntegrations({ removeIntegrationIdentities: [identityId] })
	})

	const handleSeatSync = useFn(() => {
		if (isEmpty(integrationIds)) return

		setSyncError(null)

		const matches = filter(
			map(integrationIdentities, (identities) => {
				const match = matchManagedUserByIdentities(selectedManagedUser, identities)
				const matchedId = match?.identity?.id

				return matchedId && !integrationIds.includes(matchedId) ? matchedId : null
			}),
			notNull,
		)

		if (isEmpty(matches)) {
			setSyncError('No Matches Found.')
			return
		}

		patchUserIntegrations({ addIntegrationIdentities: matches })
	})

	return (
		<MotivModal onHide={handleClose} title={`Edit ${fullName}`} size="lg">
			<Modal.Body>
				{onBackAction && (
					<Button size="lg" onClick={handleBackBtn} className="mb-3" variant="light">
						Back
					</Button>
				)}

				<div className="edit-integration-info border-light border-bottom my-3 pb-6">
					<div className="d-flex min-width-0 flex-item">
						<AvatarWithUserName className="edit-integration-avatar" {...selectedManagedUser} />

						{selectedManagedUser.email && (
							<p className="mx-3 my-auto font-weight-bold">{selectedManagedUser.email}</p>
						)}
					</div>

					{canShowSyncButton && (
						<div className="edit-integration-sync btn-lg-width flex-item">
							<Button className="px-6" variant="primary" onClick={handleSeatSync} size="lg">
								Sync
							</Button>

							{syncError && <p className="text-danger text-center mb-0">{syncError}</p>}
						</div>
					)}
				</div>

				<div className="d-flex mt-5 justify-content-between">
					<p className="font-weight-bold">{`${fullName}'s Integrations`}</p>

					{isSaving && <p className="mr-4 text-secondary">Saving Changes...</p>}
					{wasSaveSuccess && <p className="mr-4 text-secondary">Changes Saved!</p>}
				</div>

				<div className="integration-list px-0 px-sm-4 px-md-6 mb-6">
					{integrationsDecorated.map((i, idx) => (
						<IntegrationField
							key={idx}
							integration={i}
							integrationIdentities={(i.id && integrationIdentities[i.id]) || []}
							onAdd={handleIntegrationAdd}
							onRemove={handleIntegrationRemove}
							userIntegrations={integrationIds}
						/>
					))}
				</div>
			</Modal.Body>
		</MotivModal>
	)
}

type IntegrationFieldProps = {
	readonly integration: IntegrationDecorated
	readonly integrationIdentities: MotivIntegrationIdentity[]
	readonly onAdd: (identityId: string) => void
	readonly onRemove: (identityId: string) => void
	readonly userIntegrations: IntegrationIdentityId[]
}

const IntegrationField = ({
	integration,
	integrationIdentities,
	onAdd,
	onRemove,
	userIntegrations,
}: IntegrationFieldProps) => {
	const dispatch = useAppDispatch()
	const affiliatedIntegrationIdentityIds = useSelector(affiliatedIntegrationIdentityIds$)

	const unaffiliatedIdentities = useMemo(
		() => integrationIdentities.filter((u) => !affiliatedIntegrationIdentityIds.has(u.id)),
		[affiliatedIntegrationIdentityIds, integrationIdentities],
	)

	// Finds the identity assigned to this seat
	const integratedIdentity = useMemo(() => {
		let integratedUserId: Maybe<string> = null

		for (const userIntegration of userIntegrations) {
			const [parsedIntegration] = splitIntegrationIdentityId(userIntegration)

			if (parsedIntegration === integration.integrationProvider) {
				integratedUserId = userIntegration
				break
			}
		}

		if (!integratedUserId) return null

		return integrationIdentities.find((ii) => ii.id === integratedUserId)
	}, [integrationIdentities, integration, userIntegrations])

	const handleRedirect = useFn(() => {
		// When the user redirects to settings page, close modal
		dispatch(setSeatModal(null))
	})

	const handleIdentityChange = useFn((change: MultiSelectOption) => {
		onAdd(change.value)
	})

	// Shows select field so user can select an identity for provider
	const SelectIdentity = useMemo(() => {
		if (integratedIdentity || !integration.isIntegrated) return null

		return (
			<Form.Group className="w-100  align-self-center mb-0">
				<IntegrationInputField
					identities={unaffiliatedIdentities}
					onChange={handleIdentityChange}
				/>
			</Form.Group>
		)
	}, [integratedIdentity, integration.isIntegrated, unaffiliatedIdentities])

	// Shows Currently selected identity and button to remove it
	const CurrentIdentity = useMemo(() => {
		if (!integratedIdentity) return null

		return (
			<div className="d-flex current-identity p-2 w-100 justify-content-between min-width-0">
				<div className="d-flex align-items-center px-1 min-width-0">
					<UserAvatar {...integratedIdentity} />

					<div className="d-flex flex-column ml-4 min-width-0">
						<p className="current-identity__text font-weight-bold">{integratedIdentity.fullName}</p>
						<p className="current-identity__text">{integratedIdentity.email}</p>
					</div>
				</div>

				<Button
					className="current-identity__btn"
					variant="link"
					onClick={() => onRemove(integratedIdentity.id)}
				>
					<Image className="current-identity__btn-icon" src={X_ICON} alt="X icon" />
				</Button>
			</div>
		)
	}, [integratedIdentity])

	return (
		<div className="d-flex justify-content-between min-width-0">
			<Image
				className="align-self-center mr-5"
				alt={integration.shortName}
				src={integration.iconLogoSm}
				title={integration.shortName}
				height={30}
				width={30}
			/>

			<AddIntegration integration={integration} onClick={handleRedirect} />

			{CurrentIdentity}

			{SelectIdentity}
		</div>
	)
}

type IntegrationInputFieldProps = {
	readonly identities: MotivIntegrationIdentity[]
	readonly onChange: (change: MultiSelectOption) => void
}

const IntegrationInputField = ({ identities, onChange }: IntegrationInputFieldProps) => {
	if (!identities) return null

	const options = identities.map(
		(identity): MultiSelectOption => ({
			label: identity.email,
			value: identity.id,
		}),
	)

	const Option = (props: OptionProps<any, any>) => {
		const { innerProps, innerRef, ...p } = props

		const identity = identities.find((i) => i.id === p.data.value)

		if (!identity) return null

		return (
			<div className="identity-option d-flex p-3 pointer" ref={innerRef} {...innerProps}>
				<UserAvatar {...identity} />

				<div className="d-flex flex-column ml-4">
					<p className="identity-option__text mb-1">{identity.fullName}</p>
					<p className="identity-option__text m-0">{identity.email}</p>
				</div>
			</div>
		)
	}

	return (
		<Select
			className="motiv-select"
			classNamePrefix="motiv-select"
			components={{ Option, DropdownIndicator: null }}
			name="select-options"
			onChange={onChange}
			options={options}
			placeholder="Type User Name..."
		/>
	)
}

type AddIntegrationProps = {
	readonly integration: IntegrationDecorated
	readonly onClick: () => void
}

const AddIntegration = ({ integration, onClick }: AddIntegrationProps) => {
	if (integration.isIntegrated) return null

	return (
		<figure className="add-integration">
			<p className="add-integration__text">{integration.shortName} integration is required</p>

			<LinkContainer className="add-integration__btn" onClick={onClick} to="/settings/integrations">
				<Button variant="link">+</Button>
			</LinkContainer>
		</figure>
	)
}
