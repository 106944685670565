import { ensureFinite } from '@motiv-shared/util'
import Colors from '../../../styles/color-exports.module.scss'
import type { AttendanceOverview, AttendanceRateData } from '../types'

export const useAttendanceRateData = (overview: AttendanceOverview): AttendanceRateData => {
	const {
		attendedRecurringMeetingMinutes: attendedRecurring,
		attendedOneTimeMeetingMinutes: attendedNonRecurring,
		meetingRecurringMinutes: recurringMinutes,
		meetingMinutes,
	} = overview

	const nonRecurringMinutes = meetingMinutes - recurringMinutes

	const nonRecurringPercent = ensureFinite(attendedNonRecurring / nonRecurringMinutes) * 100
	const recurringPercent = ensureFinite(attendedRecurring / recurringMinutes) * 100

	return {
		nonRecurring: {
			color: Colors.yellow,
			label: 'Non-recurring',
			percent: nonRecurringPercent,
			value: attendedNonRecurring,
			total: nonRecurringMinutes,
		},
		recurring: {
			color: Colors.pink,
			label: 'Recurring',
			percent: recurringPercent,
			value: attendedRecurring,
			total: recurringMinutes,
		},
	}
}
