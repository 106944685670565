import { useBoolState } from '@eturi/react'
import type { PersistenceExtra } from '@motiv-shared/reducers'
import type { EnhancedStore } from '@reduxjs/toolkit'
import type { ReactNode } from 'react'
import { useEffect } from 'react'
import { LoadingScreen } from './BusyIndicator'

type PersistGateProps = {
	readonly children: ReactNode
	readonly store: EnhancedStore & PersistenceExtra
}

export const PersistGate = ({ children, store }: PersistGateProps) => {
	const [isReady, setReady] = useBoolState(false)

	useEffect(() => {
		store.onReady(setReady)
	}, [])

	if (!isReady) return <LoadingScreen />

	return <>{children}</>
}
