import { useTimeoutReady } from '@eturi/react'
import noop from 'lodash/noop'
import type { ReactNode } from 'react'
import Modal from 'react-bootstrap/Modal'
import { LoadingFigure } from '../BusyIndicator'
import { MotivModal } from './MotivModal'

type LoadingModalProps = {
	readonly children?: ReactNode
	readonly onHide?: () => void
}

export const LoadingModal = ({ children, onHide }: LoadingModalProps) => {
	const [isVisible] = useTimeoutReady(200)

	if (!isVisible()) return null

	return (
		<MotivModal onHide={onHide || noop} size="sm">
			<Modal.Body>{children || <LoadingFigure />}</Modal.Body>
		</MotivModal>
	)
}
