import type { GeneralEmailsByTeam, GeneralEmailsByTeamMember } from '@motiv-shared/server'
import { createSlice } from '@reduxjs/toolkit'
import { resetAction } from '../actions/resetAction'
import type { ByTeamOrUserMap, ByTeamOrUser } from '../util'
import { addPendingMatcher, setByTeamOrUserPending, setByTeamOrUserState } from '../util'
import { fetchGeneralEmailsByTeam, fetchGeneralEmailsByTeamMember } from './emails.asyncActions'

export type EmailsState = {
	readonly byTeam: ByTeamOrUserMap<GeneralEmailsByTeam>
	readonly byTeamPending: ByTeamOrUser<boolean>
	readonly byUser: ByTeamOrUserMap<GeneralEmailsByTeamMember>
	readonly byUserPending: ByTeamOrUser<boolean>
}

export type WithEmailsState = {
	readonly emails: EmailsState
}

const initialState: EmailsState = {
	byTeam: {},
	byTeamPending: {},
	byUser: {},
	byUserPending: {},
}

export const emailsSlice = /*@__PURE__*/ createSlice({
	name: 'emails',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(resetAction.type, () => initialState)
			.addCase(fetchGeneralEmailsByTeam.fulfilled, /*@__PURE__*/ setByTeamOrUserState('byTeam'))
			.addCase(
				fetchGeneralEmailsByTeam.pending,
				/*@__PURE__*/ setByTeamOrUserPending('byTeamPending', true),
			)
			.addCase(
				fetchGeneralEmailsByTeamMember.fulfilled,
				/*@__PURE__*/ setByTeamOrUserState('byUser'),
			)
			.addCase(
				fetchGeneralEmailsByTeamMember.pending,
				/*@__PURE__*/ setByTeamOrUserPending('byUserPending', true),
			)
		addPendingMatcher(builder, fetchGeneralEmailsByTeam, 'byTeamPending')
		addPendingMatcher(builder, fetchGeneralEmailsByTeamMember, 'byUserPending')
		return builder
	},
})

// TODO: Perhaps map / sanitize data
export const sanitizeEmailsState = (s: EmailsState) => null
