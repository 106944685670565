import { useConstant, useMemoEq } from '@eturi/react'
import { createHasPermissionsSelector } from '@motiv-shared/reducers'
import type { UserPermission } from '@motiv-shared/server'
import type { RouteProps } from 'react-router-dom'
import { Redirect, Route, useLocation } from 'react-router-dom'
import { canAccessProtected$ } from './compound-selectors'
import { isAuthInit$, isInitialFetchComplete$ } from './reducers'
import { useSelector } from './store'
import { LoadingScreen } from './widgets/BusyIndicator'

// We want to keep this simple, so remove `children` as an option
type ProtectedRouteProps = MOmit<RouteProps, 'children'> & {
	readonly displayName?: string
	readonly permissions?: UserPermission[]
}

const DEFAULT_PERMISSIONS: UserPermission[] = []

export const ProtectedRoute = ({
	component,
	displayName = component?.displayName || component?.name,
	permissions = DEFAULT_PERMISSIONS,
	render,
	...routeProps
}: ProtectedRouteProps) => {
	const canAccessProtected = useSelector(canAccessProtected$)
	const hasPermissions$ = useConstant(createHasPermissionsSelector)
	// We memoize on equality so that `hasPermissions` is memoized properly as well
	const memoPermissions = useMemoEq(() => permissions, [permissions])

	const hasPermissions = useSelector((s) => hasPermissions$(s, memoPermissions))
	const isAuthInit = useSelector(isAuthInit$)
	const isInitialFetchComplete = useSelector(isInitialFetchComplete$)
	const location = useLocation()

	return (
		<Route
			{...routeProps}
			render={(props) => {
				// Show loading while we're not ready or are logging in / out
				if (!isAuthInit) return <LoadingScreen />

				// If user can't access protected resources, have them log in
				if (!canAccessProtected) {
					return <Redirect to={{ pathname: '/login', state: { from: location.pathname } }} />
				}

				// If user can access protected, but we've yet to do initial fetch,
				// show loading
				if (!isInitialFetchComplete) return <LoadingScreen />

				if (hasPermissions) {
					// If there's a render function use that, otherwise, we have a component
					if (render) return render(props)

					const Component: any = component
					Component.displayName = displayName

					return <Component {...props} />
				}

				return <div>{`You don't have the required permissions for this page.`}</div>
			}}
		/>
	)
}
