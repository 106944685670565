import Card from 'react-bootstrap/Card'
import { useSelector } from 'react-redux'
import { availableKnownIntegrationsDecorated$ } from '../../../reducers'
import type { IntegrationDecorated } from '../../../types'
import { OutsideCardHeader } from '../../../widgets/OutsideCardHeader'
import { MotivTable } from '../../../widgets/Table'
import { useIntegrationsTableProps } from './useIntegrationTableProps'

export const AvailableIntegrations = () => {
	const availableKnownIntegrationsDecorated = useSelector(availableKnownIntegrationsDecorated$)

	if (!availableKnownIntegrationsDecorated.length) return null

	return <AvailableIntegrationsImpl availableIntegrations={availableKnownIntegrationsDecorated} />
}

const AvailableIntegrationsImpl = ({
	availableIntegrations,
}: {
	readonly availableIntegrations: IntegrationDecorated[]
}) => {
	const tableProps = useIntegrationsTableProps(availableIntegrations)

	return (
		<>
			<OutsideCardHeader>Available Integrations</OutsideCardHeader>

			<Card>
				<MotivTable {...tableProps} />
			</Card>
		</>
	)
}
