import './BillingInvoices.scss'

import { fetchInvoices, invoices$ } from '@motiv-shared/reducers'
import type { InvoiceData } from '@motiv-shared/server'
import cls from 'classnames'
import moment from 'moment-timezone'
import { useMemo } from 'react'
import Card from 'react-bootstrap/Card'
import { useSelector } from 'react-redux'
import { usePolling } from '../../hooks'
import { useAppDispatch } from '../../store'
import { MenuWithTooltip } from '../../widgets/MenuWithTooltip'
import { OutsideCardHeader } from '../../widgets/OutsideCardHeader'
import { createMotivTableData, MotivTable } from '../../widgets/Table'

const RECORDS_PER_PAGE = 15

export const BillingInvoices = () => {
	const dispatch = useAppDispatch()
	const invoices = useSelector(invoices$)

	usePolling([(extra) => dispatch(fetchInvoices(extra))])

	// We get invoice numbers as 'ID-0001` and we want to display `1`
	const formatInvoiceNumber = (invoiceNumber: string) => {
		const [, numStr] = /(\d+)$/.exec(invoiceNumber) || []

		const formattedNumber = numStr ? Number.parseInt(numStr).toString() : invoiceNumber

		return <div className="max-col-width">#{formattedNumber}</div>
	}

	const formatInvoiceDate = (invoiceTs: number) => {
		const formattedDate = moment(invoiceTs).format('MMM DD, YYYY')
		return <div className="max-col-width">{formattedDate}</div>
	}

	// Invoice status can be paid, uncollectible, open, void. They don't export
	// those types
	const formatInvoiceStatus = (invoiceStatus: string) => {
		const formattedText =
			invoiceStatus === 'paid'
				? 'Paid'
				: invoiceStatus === 'uncollectible' || invoiceStatus === 'open'
				? 'Failed'
				: invoiceStatus

		return (
			<div
				className={cls('text-capitalize max-col-width', {
					'text-danger': invoiceStatus === 'uncollectible' || invoiceStatus === 'open',
				})}
			>
				{formattedText}
			</div>
		)
	}

	const formatInvoiceDownload = (invoiceUrl: string, data: InvoiceData) => {
		return (
			<MenuWithTooltip>
				<div className="d-flex flex-column">
					<a className="py-4 px-5 text-center font-weight-bolder" href={data.hostedInvoiceUrl}>
						{data.status === 'paid' ? 'View Invoice' : 'Pay Now'}
					</a>
					<a className="py-4 px-5 text-center font-weight-bolder" href={invoiceUrl}>
						Download
					</a>
				</div>
			</MenuWithTooltip>
		)
	}

	const tableProps = useMemo(
		() =>
			createMotivTableData({
				data: invoices,
				keyField: 'invoiceId',
				isPaginated: true,
				perPage: RECORDS_PER_PAGE,
			})
				.addColumn('invoiceNumber', {
					header: 'Number',
					render: formatInvoiceNumber,
					sort: true,
				})
				.addColumn('createdTs', {
					header: 'Date',
					render: formatInvoiceDate,
					sort: true,
				})
				.addColumn('status', {
					header: 'Status',
					render: formatInvoiceStatus,
					sort: true,
				})
				.addColumn('invoicePdfUrl', {
					className: 'invoices__download-col',
					header: '',
					render: formatInvoiceDownload,
				})
				.props(),

		[invoices],
	)

	return (
		<div>
			<OutsideCardHeader>Invoices</OutsideCardHeader>

			<Card>
				<MotivTable {...tableProps} />
			</Card>
		</div>
	)
}
