import { omit, setIfNotEqual } from '@eturi/util'
import type { UserRoleData } from '@motiv-shared/server'
import type { CachedState } from '../createCacheSlice'
import { createCacheSlice } from '../createCacheSlice'
import type { HttpInitHandler } from '../createHttpInitHandler'
import { fetchRoles } from './roles.asyncActions'

export type RolesState = CachedState<UserRoleData[]>
export type WithRolesState = {
	readonly roles: RolesState
}

const initialData: UserRoleData[] = []

export const createRolesSlice = <State>(httpInitHandler: HttpInitHandler<State>) =>
	createCacheSlice('roles', initialData, httpInitHandler, (builder) =>
		builder.addCachedCase(fetchRoles.fulfilled, (s, a) => {
			setIfNotEqual(s, 'data', a.payload)
			s.isInit = true
		}),
	)

export const sanitizeRolesState = (s: RolesState) => ({
	...s,
	data: s.data.map((r) => omit(r, 'id')),
})
