import { useConstant, useFn } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import { updateTeam } from '@motiv-shared/reducers'
import type { Team } from '@motiv-shared/server'
import { MAX_MEMBERS_PER_TEAM } from '@motiv-shared/server'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import type { ManagedUserDecorated } from '../../../compound-selectors'
import {
	createActiveManagedUsersByTeam,
	createAvailableManagedUsersByTeam,
} from '../../../compound-selectors'
import { addSuccessToast, setSeatModal, setTeamsModal } from '../../../reducers'
import { useAppDispatch, useSelector } from '../../../store'
import { InstructionsWithArrow } from '../../../widgets/InstructionsWithArrow/InstructionsWithArrow'
import { OutsideCardHeader } from '../../../widgets/OutsideCardHeader'
import {
	createMotivTableData,
	getMenuFormatter,
	integrationsFormatter,
	MotivTable,
	nameFormatter,
	nameSortFn,
} from '../../../widgets/Table'
import { SeatModals } from '../../SeatModals'
import { TeamsModals } from '../../TeamsModal'

export type TeamMembersProps = {
	readonly team: Team
}

export const TeamMembers = ({ team }: TeamMembersProps) => {
	const dispatch = useAppDispatch()
	const activeManagedUsersByTeam$ = useConstant(createActiveManagedUsersByTeam)
	const availableManagedUsersByTeam$ = useConstant(createAvailableManagedUsersByTeam)

	const activeTeamMembers = useSelector((s) => activeManagedUsersByTeam$(s, team))
	const availableManagedUsers = useSelector((s) => availableManagedUsersByTeam$(s, team))

	const isMaxAssigned = MAX_MEMBERS_PER_TEAM <= activeTeamMembers.length
	const hasNoneAvailable = isEmpty(availableManagedUsers)
	const isAddDisabled = isMaxAssigned || hasNoneAvailable
	const hasTeamMembers = Boolean(activeTeamMembers.length)

	const teamMembers = useMemo(
		() => orderBy(activeTeamMembers, 'fullName', 'asc'),
		[activeTeamMembers, team.assignedManagedUsers],
	)

	const handleAddToTeamClicked = useFn(() => {
		dispatch(setTeamsModal({ type: TeamsModals.ADD_TO_TEAM, teamId: team.id }))
	})

	const handleEditIntegrationClick = useFn((user: ManagedUserDecorated) =>
		dispatch(setSeatModal({ type: SeatModals.EDIT_INTEGRATIONS, selectedManagedUserId: user.id })),
	)

	const handleRemoveFromTeamClicked = useFn(async (user: ManagedUserDecorated) => {
		const { id: teamId, name: teamName } = team
		const { id: userId, fullName: userName } = user

		sentryBreadcrumb(`Removing user (${userId}) from team (${teamId})`)

		try {
			await dispatch(
				updateTeam({
					patch: { removeManagedUsers: [userId] },
					teamId,
				}),
			).unwrap()

			dispatch(addSuccessToast(`${userName} has been removed from ${teamName}.`))
		} catch (e) {
			sentryError(e, `Failed to remove user (${userId}) from team (${teamId})`)
		}
	})

	const tableProps = useMemo(
		() =>
			createMotivTableData({
				className: 'v-middle',
				data: teamMembers,
				keyField: 'id',
			})
				.addColumn('fullName', {
					header: 'Name',
					isMaxWidth: true,
					render: nameFormatter,
					sort: nameSortFn,
				})

				.addColumn('integrations', {
					className: 'columns__integrations-field',
					header: 'Integrations',
					render: integrationsFormatter,
				})

				.addColumn('id', {
					className: 'columns__menu-field',
					header: '',
					render: getMenuFormatter([
						{ label: 'Edit Integration', onClick: handleEditIntegrationClick },
						{ label: 'Remove from team', onClick: handleRemoveFromTeamClicked },
					]),
				})
				.props(),

		[teamMembers],
	)

	return (
		<>
			<OutsideCardHeader>
				<span className="mr-4">Team Members</span>

				<Button
					block
					className="btn-lg-width"
					disabled={isAddDisabled}
					onClick={handleAddToTeamClicked}
				>
					Add to Team
				</Button>
			</OutsideCardHeader>

			{!hasTeamMembers && (
				<InstructionsWithArrow
					instructions={`Add team members by clicking\n“Add to Team”`}
					imgAlt="Arrow pointing to 'Add to Team' button"
				/>
			)}

			{hasTeamMembers && (
				<Card>
					<MotivTable {...tableProps} />
				</Card>
			)}
		</>
	)
}
