import { activeSubExpiry$, nextSub$, skuDefs$ } from '@motiv-shared/reducers'
import { SKUIds, SubscriptionPeriod } from '@motiv-shared/server'
import { createSelector } from '@reduxjs/toolkit'
import find from 'lodash/find'
import moment from 'moment'
import type { ThunkAction } from '../../http'
import type { WithSubscriptionUIState } from './subscription-ui.slice'
import {
	setSubscribingCycle,
	setSubscribingSKU,
	setSubscribingSKUQty,
} from './subscription-ui.slice'

const state$ = <T extends WithSubscriptionUIState>(s: T) => s.subscriptionUI

export const activePurchaseModal$ = /* @__PURE__ */ createSelector(
	state$,
	(s) => s.activePurchaseModal,
)
const _subscribingCycle$ = /* @__PURE__ */ createSelector(state$, (s) => s.subscribingCycle)
export const subscribingSKU$ = /* @__PURE__ */ createSelector(state$, (s) => s.subscribingSKU)
export const subscribingSKUQty$ = /* @__PURE__ */ createSelector(state$, (s) => s.subscribingSKUQty)

export const setSubscribingInfo =
	(quantity: number, cycle: SubscriptionPeriod, id = SKUIds.SEAT): ThunkAction =>
	(dispatch, getState) => {
		const state = getState()
		const defs = skuDefs$(state)
		const subscribingSKU = find(defs, { id })

		dispatch(setSubscribingSKU(subscribingSKU))

		if (subscribingSKU) {
			dispatch(setSubscribingCycle(cycle))
			dispatch(setSubscribingSKUQty(quantity))
		}

		subscribingSKU && dispatch(setSubscribingCycle(cycle))
	}

export const clearSubscribingInfo = (): ThunkAction => (dispatch) => {
	dispatch(setSubscribingSKU(null))
	dispatch(setSubscribingCycle(null))
	dispatch(setSubscribingSKUQty(null))
}

const _expiryStr = (expiry: Maybe<number>) => (expiry ? moment(expiry).format('ll') : null)

// Date string of effect expiry
export const activeSubExpiryStr$ = /* @__PURE__ */ createSelector(activeSubExpiry$, _expiryStr)

// Returns the active cycle that the user will be subscribing to, if they have
// chosen one that will be returned, if they haven't set one but have a
// subscription then that cycle will be return otherwise it default to monthly.
export const subscribingCycle$ = /* @__PURE__ */ createSelector(
	_subscribingCycle$,
	nextSub$,
	(sc, ns) => sc || (ns ? ns.period : SubscriptionPeriod.MONTHLY),
)

// This is the cycle the user is trying to purchase
export const isSubscribingMonthly$ = /* @__PURE__ */ createSelector(
	subscribingCycle$,
	(asc) => asc === SubscriptionPeriod.MONTHLY,
)
