import { useBoolState, useFn, useMounted } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import { updateTeam } from '@motiv-shared/reducers'
import type { Team, TeamPatch } from '@motiv-shared/server'
import { MAX_MEMBERS_PER_TEAM } from '@motiv-shared/server'
import map from 'lodash/map'
import { useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import type { ManagedUserDecorated } from '../../compound-selectors'
import { useActivateSeat } from '../../hooks/useActivateSeat'
import { setSeatModal } from '../../reducers'
import { useAppDispatch } from '../../store'
import { MotivModal } from '../../widgets/Modal'

export type ReactivateTeamLimitModalProps = {
	readonly managedUser: ManagedUserDecorated
}

export const ReactivateTeamLimitModal = ({ managedUser }: ReactivateTeamLimitModalProps) => {
	const dispatch = useAppDispatch()
	const [activateSeat, isActivatingSeat] = useActivateSeat()
	const [isRemovingFromTeams, startRemoving, stopRemoving] = useBoolState(false)
	const isMounted = useMounted()

	const isSaving = isActivatingSeat || isRemovingFromTeams
	const { assignedTeams, fullName } = managedUser

	const handleClose = useFn(() => {
		dispatch(setSeatModal(null))
	})

	// NOTE: teamsAtMemberLimit used in text and to remove use from these teams.
	//  teamsNotAtMemberLimit used in text to describe teams user will stay on.
	const [teamsAtMemberLimit, teamsNotAtMemberLimit] = useMemo(() => {
		const teamsAtMemberLimit: Team[] = []
		const teamsNotAtMemberLimit: Team[] = []

		for (const team of assignedTeams) {
			;(team.assignedManagedUsers.length >= MAX_MEMBERS_PER_TEAM
				? teamsAtMemberLimit
				: teamsNotAtMemberLimit
			).push(team)
		}

		return [teamsAtMemberLimit, teamsNotAtMemberLimit] as const
	}, [assignedTeams])

	const handleReactivateClick = useFn(async () => {
		if (isSaving) return

		startRemoving()
		sentryBreadcrumb('Remove user from teams.')

		// Go through and remove user from all teams that are at the limit.
		try {
			const patch: TeamPatch = { removeManagedUsers: [managedUser.id] }

			await Promise.all(
				teamsAtMemberLimit.map((team) => dispatch(updateTeam({ teamId: team.id, patch })).unwrap()),
			)
		} catch (e) {
			sentryError(e, 'Failed to remove user from teams.')
			return
		} finally {
			isMounted() && stopRemoving()
		}

		if (await activateSeat(managedUser)) handleClose()
	})

	const ModalBody = useMemo(() => {
		const hasMaxMembersForAllTeams = !teamsNotAtMemberLimit.length

		if (hasMaxMembersForAllTeams) {
			return (
				<Modal.Body>
					<p>The following teams assigned to this seat are at their team member limit:</p>

					<ul>
						{assignedTeams.map((t) => (
							<li key={t.id}>{t.name}</li>
						))}
					</ul>

					<p>
						If you reactivate this seat, all team assignments will be removed from this team member.
					</p>
				</Modal.Body>
			)
		}

		const totalAtLimit = teamsAtMemberLimit.length
		const atLimitNames = map(teamsAtMemberLimit, 'name')

		const teamsList =
			totalAtLimit === 1
				? atLimitNames[0]
				: totalAtLimit === 2
				? atLimitNames.join(' and ')
				: atLimitNames
						.map((name, i) => (i === teamsAtMemberLimit.length - 1 ? 'and ' : '') + name)
						.join(', ')

		// Examples:
		// "Team A is at the team limit. James cannot be added to this team."
		// "Team A and Team B are at the team limit. James cannot be added to these teams."
		// "Team A, TeamB, and Team C are at the team limit...", etc.
		return (
			<Modal.Body>
				<p>
					{`${teamsList} ${totalAtLimit === 1 ? 'is' : 'are'} at the team limit. 
					${fullName} cannot be added to ${totalAtLimit === 1 ? 'this team' : 'these teams'}.`}
				</p>

				<p>{`Reactivating this seat will remove this team member from ${teamsList}, but they will 
				still be assigned to the following team${teamsNotAtMemberLimit.length === 1 ? '' : 's'}:`}</p>

				<ul>
					{teamsNotAtMemberLimit.map((t) => (
						<li key={t.id}>{t.name}</li>
					))}
				</ul>
			</Modal.Body>
		)
	}, [assignedTeams, fullName, teamsAtMemberLimit, teamsNotAtMemberLimit])

	return (
		<MotivModal onHide={handleClose} size="md" title="Team Member Limit Reached">
			{ModalBody}

			<Modal.Footer>
				<Button disabled={isSaving} onClick={handleClose} size="lg" variant="light-link">
					Cancel
				</Button>

				<Button disabled={isSaving} onClick={handleReactivateClick} size="lg" variant="success">
					Reactivate
				</Button>
			</Modal.Footer>
		</MotivModal>
	)
}
