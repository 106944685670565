import type { NextMotivSubscription } from '@motiv-shared/reducers'
import {
	activeSub$,
	isNextSubMonthly$,
	nextSeatLimit$,
	nextSKU$,
	nextSub$,
	nextSubCycle$,
} from '@motiv-shared/reducers'
import type { SKUDef } from '@motiv-shared/server'
import { SubscriptionPeriod } from '@motiv-shared/server'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { activeSubExpiryStr$ } from '../../reducers'
import { getSubPrice } from '../../types'
import { PlanLineItem } from './PlanLineItem'

export const UpcomingPlanDetails = () => {
	const activeSub = useSelector(activeSub$)
	const nextSKU = useSelector(nextSKU$)
	const nextSub = useSelector(nextSub$)

	const isChangingQuantityOrPeriod = Boolean(
		activeSub?.changeToPeriod || activeSub?.changeToQuantity,
	)

	if (!((activeSub?.isTrial || isChangingQuantityOrPeriod) && nextSKU && nextSub)) return null

	return <UpcomingPlanDetailsContent nextSKU={nextSKU} nextSub={nextSub} />
}

type UpcomingPlanDetailsContentProps = {
	readonly nextSKU: SKUDef
	readonly nextSub: NextMotivSubscription
}

const UpcomingPlanDetailsContent = ({ nextSKU, nextSub }: UpcomingPlanDetailsContentProps) => {
	const activeSubExpiryStr = useSelector(activeSubExpiryStr$)
	const isNextSubMonthly = useSelector(isNextSubMonthly$)
	const nextSeatLimit = useSelector(nextSeatLimit$)
	const subCycle = useSelector(nextSubCycle$)

	const Cycle = useMemo(
		() =>
			subCycle &&
			subCycle !== SubscriptionPeriod.NON_RECURRING && (
				<PlanLineItem>{`Cycle: ${isNextSubMonthly ? 'Monthly' : 'Annually'}`}</PlanLineItem>
			),
		[subCycle, isNextSubMonthly],
	)

	const Cost = useMemo(() => {
		const cost = getSubPrice(nextSKU, nextSub)
		const cycle = isNextSubMonthly ? 'Month' : 'Year'

		return <PlanLineItem>{`Cost: $${cost}/${cycle}`}</PlanLineItem>
	}, [isNextSubMonthly, nextSub, nextSKU])

	return (
		<>
			<h4 className="mb-4">Upcoming: {nextSeatLimit} Seats</h4>

			<ul className="plan-line-items">
				<PlanLineItem>Starts: {activeSubExpiryStr}</PlanLineItem>

				{Cycle}

				{Cost}
			</ul>
		</>
	)
}
