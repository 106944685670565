import type { ReactNode } from 'react'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import CancelIcon from '../../assets/icons/cancel.svg'
import type { MotivToastType } from '../../types'

export type ToastProps = {
	readonly id: string
	readonly msg: ReactNode
	readonly onClose: (id: string) => void
	readonly title: string
	readonly type: MotivToastType
}

export const Toast = ({ id, msg, onClose, title, type }: ToastProps) => {
	// className is set as motiv-toast to avoid bootstrap style conflicts
	return (
		<div className={`motiv-toast motiv-toast--${type}`}>
			<header className="motiv-toast__header">
				<h6 className="motiv-toast__title text-break" title={title}>
					{title}
				</h6>

				<Button className="motiv-toast__close" onClick={() => onClose(id)} variant="link">
					<Image src={CancelIcon} alt="cancel-icon" />
				</Button>
			</header>

			<p className="motiv-toast__message text-break">{msg}</p>
		</div>
	)
}
