import type { Account, AccountPatch } from '@motiv-shared/server'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState'
import type { HttpExtra } from '../http'
import type { WithIdentityState } from '../identity'
import type { WithUsersState } from '../users'
import { user$ } from '../users'
import type { WithAccountState } from './createAccountSlice'

const createAsyncThunk = /* @__PURE__ */ bindCreateAsyncThunkToState<
	WithAccountState & WithIdentityState & WithUsersState
>()

type DeleteAccountArgs = HttpExtra & {
	readonly accountId: string
}

export const deleteAccount = /* @__PURE__ */ createAsyncThunk(
	'account/Id',
	({ accountId, ...extra }: DeleteAccountArgs, { dispatch, extra: { http } }) =>
		dispatch(
			http.delete(`accounts/${accountId}`, {
				errorMessage: 'Unable to delete account. Please try again later.',
				...extra,
			}),
		),
)

export const fetchAccount = /* @__PURE__ */ createAsyncThunk(
	'account/fetchAccount',
	async (extra: HttpExtra = {}, { dispatch, getState, extra: { http } }) => {
		const state = getState()
		const user = user$(state)

		if (!user) {
			throw new Error(`Can't retrieve account without user accountId`)
		}

		return dispatch(
			http.get<Account>(`accounts/${user.accountId}`, {
				errorMessage: 'Unable to load account. Please try again later.',
				...extra,
			}),
		)
	},
)

type UpdateAccountArgs = HttpExtra & {
	readonly accountId: string
	readonly patch: AccountPatch
}

export const updateAccount = /* @__PURE__ */ createAsyncThunk(
	'account/updateAccount',
	({ accountId, patch, ...extra }: UpdateAccountArgs, { dispatch, extra: { http } }) =>
		dispatch(
			http.patch<Account>(`accounts/${accountId}`, {
				data: patch,
				errorMessage: 'Unable to update account. Please try again later.',
				...extra,
			}),
		),
)
