import { useBoolState, useMounted } from '@eturi/react'
import { fetchInvitationStatus } from '@motiv-shared/reducers'
import type { InviteParams } from '@motiv-shared/server'
import { InvitationStatus } from '@motiv-shared/server'
import fromPairs from 'lodash/fromPairs'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { auth0ActionStore, isAuthenticated$, isAuthInit$, LoginAction } from '../reducers'
import { useAppDispatch } from '../store'
import { LoadingFigure } from '../widgets/BusyIndicator'

/**
 * This is simply a page that handles sign-up invitations and then invokes the
 * appropriate actions.
 */
export const InvitePage = () => {
	const dispatch = useAppDispatch()
	const isMounted = useMounted()
	const isAuthenticated = useSelector(isAuthenticated$)
	const isAuthInit = useSelector(isAuthInit$)

	const [hasStoredParams, setParamsStored] = useBoolState(false)

	const decorateInviteParamsWithStatus = async (params: InviteParams) => {
		try {
			const invitationStatus = await dispatch(
				fetchInvitationStatus({ invitationId: params.invitationId }),
			).unwrap()

			const reason =
				invitationStatus.status === InvitationStatus.INVALID ? invitationStatus.reason : null

			return {
				invitationReason: reason,
				invitationStatus: invitationStatus.status,
				...params,
			}
		} catch (e) {
			return params
		}
	}

	// Stores invitation details and redirect so when we are re-routed
	// back to our app on logout we can continue the invitation flow
	const parseParamsAndSetActions = async () => {
		try {
			const params = fromPairs([...new URLSearchParams(location.search)])

			if (!isInviteParams(params)) {
				auth0ActionStore.login = null
				return
			}

			const paramsWithStatus = await decorateInviteParamsWithStatus(params)

			if (!isMounted()) return

			auth0ActionStore.invite = paramsWithStatus
			auth0ActionStore.login = LoginAction.INVITE
		} finally {
			isMounted() && setParamsStored()
		}
	}

	useEffect(() => {
		parseParamsAndSetActions()
	}, [])

	// We wait for isAuthInit so that we know for sure if we are logged in or not.
	// If we are we will first logout before doing initiating the invitation flow
	if (!(isAuthInit && hasStoredParams)) return <LoadingFigure />

	return isAuthenticated ? <Redirect to="/logout" /> : <Redirect to="/login" />
}

const isInviteParams = (params: any): params is InviteParams => params.invitationId != null
