import './NavBar.scss'

import { user$ } from '@motiv-shared/reducers'
import { hasPermission, UserPermissions } from '@motiv-shared/server'
import cls from 'classnames'
import some from 'lodash/some'
import Image from 'react-bootstrap/Image'
import Navbar from 'react-bootstrap/Navbar'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import LOGO from '../../assets/images/logo.png'
import { canShowDashboardPage$ } from '../../compound-selectors'
import { authUser$, isAuthenticated$ } from '../../reducers'
import { LinkContainer } from '../LinkContainer'
import { Nav } from '../Nav'
import type { NavScreen } from './navBar.constants'
import { NavScreens } from './navBar.constants'

const excludedPaths = ['/welcome']

export const NavBar = () => {
	const { pathname } = useLocation()
	const authUser = useSelector(authUser$)
	const canShowDashboardPage = useSelector(canShowDashboardPage$)
	const isAuthenticated = useSelector(isAuthenticated$)
	const isHomePageUrl = pathname === '/'
	const user = useSelector(user$)

	const isNavHidden = some(excludedPaths, (path) => pathname.includes(path))

	const activeCls = (matchPath: NavScreen, override?: boolean) =>
		cls((override || pathname.includes(matchPath)) && 'active')

	const userName = user?.firstName || authUser?.name || user?.fullName

	if (isNavHidden) return null

	return (
		<Navbar collapseOnSelect expand="md" variant="dark">
			<LinkContainer exact to="/">
				<Navbar.Brand>
					<Image src={LOGO} />
				</Navbar.Brand>
			</LinkContainer>

			<Navbar.Toggle aria-controls="basic-navbar-nav" />

			<Navbar.Collapse className="justify-content-end">
				<Nav as="ul" className="mr-auto">
					<Nav.Item as="li" className={activeCls(NavScreens.TEAMS, isHomePageUrl)}>
						<LinkContainer to="/teams">
							<Nav.Link>Teams</Nav.Link>
						</LinkContainer>
					</Nav.Item>

					{canShowDashboardPage && (
						<Nav.Item as="li" className={activeCls(NavScreens.DASHBOARD)}>
							<LinkContainer to="/dashboard">
								<Nav.Link>Dashboard</Nav.Link>
							</LinkContainer>
						</Nav.Item>
					)}

					{hasPermission(UserPermissions.CAN_VIEW_BILLING_TAB, user) && (
						<Nav.Item as="li" className={activeCls(NavScreens.BILLING)}>
							<LinkContainer to="/billing">
								<Nav.Link>Billing</Nav.Link>
							</LinkContainer>
						</Nav.Item>
					)}

					<Nav.Item as="li" className={activeCls(NavScreens.SETTINGS)}>
						<LinkContainer to="/settings">
							<Nav.Link>Settings</Nav.Link>
						</LinkContainer>
					</Nav.Item>

					<Nav.Item as="li">
						<Link
							className="nav-link"
							to={{ pathname: 'https://support.motiv.team/' }}
							target="_blank"
							rel="noreferrer"
						>
							Help
						</Link>
					</Nav.Item>
				</Nav>

				<Nav as="ul" className="navbar-right">
					{userName && (
						<Navbar.Text className="navbar-welcome text-truncate" as="li" title={userName}>
							Welcome, {userName}
						</Navbar.Text>
					)}

					{isAuthenticated && (
						<Nav.Item as="li" className="mr-0 d-flex align-items-center pointer">
							<LinkContainer to="/logout">
								<Nav.Link>Logout</Nav.Link>
							</LinkContainer>
						</Nav.Item>
					)}
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	)
}
