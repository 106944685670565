import { useBoolState, useConstant, useFn, useMounted } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import { account$, deleteAccount } from '@motiv-shared/reducers'
import { Form as FormikForm, Formik } from 'formik'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { useAppDispatch } from '../../../store'
import type { FormikSubmit } from '../../../types'
import { formikCtrlProps } from '../../../util'
import { BusyIndicator, IndicatorRegions } from '../../../widgets/BusyIndicator'
import { FormValidationText } from '../../../widgets/FormValidationText'
import { OutsideCardHeader } from '../../../widgets/OutsideCardHeader'

type DeleteAccountData = {
	readonly deleteVerify: string
}

export const DeleteAccount = () => {
	const dispatch = useAppDispatch()
	const isMounted = useMounted()
	const account = useSelector(account$)!

	const [isSaving, startSaving, stopSaving] = useBoolState(false)

	const VALIDATION_SCHEMA = useConstant(() =>
		Yup.object().shape({
			deleteVerify: Yup.string()
				.test({ test: (v) => v === 'DELETE', message: `Verification doesn't match.` })
				.default(''),
		}),
	)
	const INITIAL_VALUES = useConstant((): DeleteAccountData => VALIDATION_SCHEMA.cast({}))

	const handleDeleteSubmit: FormikSubmit<DeleteAccountData> = useFn(async () => {
		if (isSaving) return

		startSaving()

		sentryBreadcrumb('Deleting Account')

		try {
			await dispatch(
				deleteAccount({ accountId: account.id, indicatorRegion: IndicatorRegions.DELETE_ACCOUNT }),
			).unwrap()
		} catch (e) {
			sentryError(e, 'Failed to delete account')
		}

		isMounted() && stopSaving()
	})

	return (
		<>
			<OutsideCardHeader>Delete Account</OutsideCardHeader>

			<Card>
				<Card.Body>
					<h6 className="mb-6">Are You Sure You Want to Delete Your Account?</h6>

					<p className="mb-5">Please type the word DELETE to confirm account deletion.</p>

					<Formik<DeleteAccountData>
						initialValues={INITIAL_VALUES}
						onSubmit={handleDeleteSubmit}
						validateOnMount
						validationSchema={VALIDATION_SCHEMA}
					>
						{(p) => {
							const getCtrlProps = formikCtrlProps(p)

							return (
								<BusyIndicator region={IndicatorRegions.DELETE_ACCOUNT}>
									<Form as={FormikForm}>
										<Row>
											<Col className="mb-4 mb-md-0" md={6}>
												<Form.Control placeholder="Type DELETE" {...getCtrlProps('deleteVerify')} />
												<FormValidationText field="deleteVerify" formikProps={p} />
											</Col>

											<Col md={4}>
												<Button
													block
													disabled={!p.isValid || isSaving}
													size="lg"
													type="submit"
													variant="danger"
												>
													Delete Account
												</Button>
											</Col>
										</Row>
									</Form>
								</BusyIndicator>
							)
						}}
					</Formik>
				</Card.Body>
			</Card>
		</>
	)
}
