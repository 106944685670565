import { resetAction } from '@motiv-shared/reducers'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { UserModalType } from '../../features/UserModal'

export type UsersUiState = {
	readonly userModal: Maybe<UserModalType>
}

const initialState: UsersUiState = {
	userModal: null,
}

export type WithUsersUiState = {
	readonly usersUI: UsersUiState
}

export const usersUiSlice = createSlice({
	name: 'usersUI',
	initialState,
	reducers: {
		setUserModal(s, a: PayloadAction<Maybe<UserModalType>>) {
			s.userModal = a.payload
		},
	},

	extraReducers: (builder) => {
		builder.addCase(resetAction.type, () => initialState)
	},
})

export const { setUserModal } = usersUiSlice.actions
