import cls from 'classnames'
import isFunction from 'lodash/isFunction'
import { useMemo } from 'react'
import { useKeyboardClick } from '../../hooks'
import type { MotivTableColDesc } from './MotivTableColDesc'

type MotivTableDataCellProps<Data, Field extends PlainKey<Data>> = {
	readonly column: MotivTableColDesc<Data, Field>
	readonly data: Data
}

export const MotivTableDataCell = <Data, Field extends PlainKey<Data>>({
	column: { className, extra, field, isMaxWidth, onClick, render },
	data,
}: MotivTableDataCellProps<Data, Field>) => {
	const fieldValue = data[field]

	const handleClick = useKeyboardClick<HTMLTableDataCellElement>((ev) => {
		onClick?.(fieldValue, data, ev, extra)
	})

	const content = useMemo(
		() => render?.(fieldValue, data, extra) ?? String(fieldValue),
		[data, extra, fieldValue, render],
	)

	const handlerProps = onClick ? handleClick : undefined

	return (
		<td
			className={cls(
				'motiv-table-cell',
				isMaxWidth && 'max-col-width',
				isFunction(className) ? className(fieldValue, data, extra) : className,
			)}
			{...handlerProps}
		>
			{content}
		</td>
	)
}
