import { ensureFinite } from '@motiv-shared/util'
import { useMemo } from 'react'
import Colors from '../../../styles/color-exports.module.scss'
import type { AttendanceData, AttendanceOverview } from '../types'

// TODO: Generally seems like adhoc is removed when attendance data is not
//  available, so it might make more sense to just make it an optional field,
//  rather than having `isAttendanceVisible`. Keep for now because it may be
//  used in other contexts.
export const useAttendanceData = (overview: AttendanceOverview) =>
	useMemo((): AttendanceData => {
		const {
			attendanceAvailable,
			attendedOneTimeMeetingMinutes,
			attendedRecurringMeetingMinutes,
			attendedAdhocMeetingMinutes,
			meetingMinutes,
			meetingRecurringMinutes,
		} = overview

		const totalAttended =
			attendedOneTimeMeetingMinutes + attendedRecurringMeetingMinutes + attendedAdhocMeetingMinutes
		const isAttendanceVisible = attendanceAvailable && totalAttended > 0

		const nonRecurring = isAttendanceVisible
			? attendedOneTimeMeetingMinutes
			: meetingMinutes - meetingRecurringMinutes

		const recurring = isAttendanceVisible
			? attendedRecurringMeetingMinutes
			: overview.meetingRecurringMinutes

		const adHoc = isAttendanceVisible ? attendedAdhocMeetingMinutes : 0
		const total = isAttendanceVisible ? totalAttended : meetingMinutes

		const recurringPercentage = ensureFinite(recurring / total) * 100
		const nonRecurringPercentage = ensureFinite(nonRecurring / total) * 100
		const adhocPercentage = 100 - recurringPercentage - nonRecurringPercentage

		return {
			adhoc: {
				color: Colors.blue,
				label: 'Ad Hoc',
				percent: adhocPercentage,
				value: adHoc,
			},
			nonRecurring: {
				color: isAttendanceVisible ? Colors.yellow : Colors.purple,
				label: 'Non-recurring',
				percent: nonRecurringPercentage,
				value: nonRecurring,
			},
			recurring: {
				color: isAttendanceVisible ? Colors.pink : Colors.blue,
				label: 'Recurring',
				percent: recurringPercentage,
				value: recurring,
			},
			isAttendanceVisible,
			total,
		}
	}, [overview])
