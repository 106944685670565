/// <reference types="@eturi/ts-helpers" />

import './styles/index.scss'

// NOTE: Store has to be imported first to prevent dependency order issue
// eslint-disable-next-line
import { store } from './store'

import { StrictMode } from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { App } from './App'
import { LoadErrorBoundary } from './infrastructure'
import { IntegrationFlow } from './IntegrationFlow'
import { initSentry } from './sentry'
import * as serviceWorker from './serviceWorker'
import { PersistGate } from './widgets/PersistGate'

initSentry()

render(
	<StrictMode>
		<Provider store={store}>
			<PersistGate store={store as any}>
				<LoadErrorBoundary>
					<IntegrationFlow>
						<Router>
							<App />
						</Router>
					</IntegrationFlow>
				</LoadErrorBoundary>
			</PersistGate>
		</Provider>
	</StrictMode>,
	document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
