import ListGroup from 'react-bootstrap/ListGroup'
import type { ListGroupItemProps } from 'react-bootstrap/ListGroupItem'
import type { NavLinkProps } from 'react-router-dom'
import { LinkContainer } from './LinkContainer'

type ListGroupLinkProps = Pick<ListGroupItemProps, 'active' | 'disabled' | 'variant'> & NavLinkProps

export const ListGroupLink = ({
	active,
	children,
	className,
	disabled,
	variant,
	...p
}: ListGroupLinkProps) => {
	const isActive = active != null ? () => active : undefined

	return (
		<LinkContainer className={className} isActive={isActive} {...p}>
			<ListGroup.Item action disabled={disabled} variant={variant}>
				{children}
			</ListGroup.Item>
		</LinkContainer>
	)
}
