import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export type BrowserInfoState = {
	readonly hasBrowserFocus: boolean
	readonly isNetworkOnline: boolean
}

export type WithBrowserInfoState = {
	readonly browserInfo: BrowserInfoState
}

const initialState: BrowserInfoState = {
	hasBrowserFocus: true,
	isNetworkOnline: true,
}

export const browserInfoSlice = createSlice({
	name: 'browserInfo',
	initialState,
	reducers: {
		setBrowserFocus(s, a: PayloadAction<boolean>) {
			s.hasBrowserFocus = a.payload
		},

		setNetworkOnline(s, a: PayloadAction<boolean>) {
			s.isNetworkOnline = a.payload
		},
	},
})

export const { setBrowserFocus, setNetworkOnline } = browserInfoSlice.actions
