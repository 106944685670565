import { createSelector } from '@reduxjs/toolkit'
import type { WithTeamsUIState } from './teams-ui.slice'

export const uiState$ = <T extends WithTeamsUIState>(s: T) => s.teamsUI

export const shouldResetTeamMembersTable$ = /* @__PURE__ */ createSelector(
	uiState$,
	(s) => s.shouldResetTeamMembersTable,
)
export const teamsModal$ = /* @__PURE__ */ createSelector(uiState$, (s) => s.teamsModal)
export const teamIdBeingDeleted$ = /* @__PURE__ */ createSelector(
	uiState$,
	(s) => s.teamIdBeingDeleted,
)
