import {
	activeCycle$,
	activeSeatLimit$,
	activeSKU$,
	activeSub$,
	assignedSeats$,
	isActiveMonthlySub$,
	isActiveSubFree$,
	isDowngradedSub$,
	isTrialPeriod$,
	isTrialSubSKU$,
	nextSKU$,
	nextSub$,
} from '@motiv-shared/reducers'
import { SubscriptionPeriod } from '@motiv-shared/server'
import cls from 'classnames'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { activeSubExpiryStr$ } from '../../reducers'
import { getSubPrice } from '../../types'
import { PlanLineItem } from './PlanLineItem'

export const CurrentPlanDetails = () => {
	const activeCycle = useSelector(activeCycle$)
	const activeSeatLimit = useSelector(activeSeatLimit$)
	const activeSKU = useSelector(activeSKU$)
	const activeSub = useSelector(activeSub$)
	const activeSubExpiryStr = useSelector(activeSubExpiryStr$)
	const assignedSeats = useSelector(assignedSeats$)
	const isActiveSubFree = useSelector(isActiveSubFree$)
	const isDowngradeSub = useSelector(isDowngradedSub$)
	const isMonthlySub = useSelector(isActiveMonthlySub$)
	const isTrialPeriod = useSelector(isTrialPeriod$)
	const isTriaSubSKU = useSelector(isTrialSubSKU$)
	const nextSku = useSelector(nextSKU$)
	const nextSub = useSelector(nextSub$)

	const isActiveExpiring = Boolean(activeSub?.changeAt)

	const CurrentPlan = useMemo(() => {
		if (isActiveSubFree) return 'N/A'

		return `${isDowngradeSub ? 'Current: ' : ''}${activeSeatLimit} Seats`
	}, [activeSeatLimit, isDowngradeSub, isActiveSubFree])

	const SubDate = useMemo(() => {
		if (isActiveSubFree) return null

		const prefix =
			isTriaSubSKU || isTrialPeriod
				? 'Free Until'
				: isActiveExpiring
				? 'Expires'
				: nextSub
				? 'Renews'
				: null

		if (!prefix) return null

		return (
			<PlanLineItem className={cls(isActiveExpiring && !isTrialPeriod && 'text-danger')}>
				{`${prefix}: ${activeSubExpiryStr}`}
			</PlanLineItem>
		)
	}, [activeSubExpiryStr, isActiveExpiring, isActiveSubFree, isTrialPeriod, isTriaSubSKU])

	const Cycle = useMemo(
		() =>
			!isTrialPeriod &&
			activeCycle &&
			activeCycle !== SubscriptionPeriod.NON_RECURRING && (
				<PlanLineItem>{`Cycle: ${isMonthlySub ? 'Monthly' : 'Annually'}`}</PlanLineItem>
			),
		[activeCycle, isMonthlySub, isTrialPeriod],
	)

	const Cost = useMemo(() => {
		if (!(activeSKU && activeSub) || isActiveSubFree || isTrialPeriod || isTriaSubSKU) return null

		const cost = getSubPrice(activeSKU, activeSub)
		const cycle = isMonthlySub ? 'Month' : 'Year'

		return <PlanLineItem>{`Cost: $${cost}/${cycle}`}</PlanLineItem>
	}, [activeSKU, activeSub, nextSub, nextSku, isMonthlySub, isTrialPeriod])

	const SeatInfo = useMemo(() => {
		if (isActiveSubFree || !activeSeatLimit) return null

		const emptySeats = Math.max(0, activeSeatLimit - assignedSeats)

		return (
			<>
				<PlanLineItem>{`Occupied Seats: ${assignedSeats}`}</PlanLineItem>
				<PlanLineItem>{`Empty Seats: ${emptySeats}`}</PlanLineItem>
			</>
		)
	}, [activeSeatLimit, assignedSeats, isActiveSubFree])

	const hasLineItems = Boolean(SubDate || Cycle || Cost || SeatInfo)

	return (
		<>
			<h4 className={`mb-${hasLineItems ? 4 : 0}`}>{CurrentPlan}</h4>

			{hasLineItems && (
				<ul className="plan-line-items">
					{SubDate}
					{Cycle}
					{Cost}
					{SeatInfo}
				</ul>
			)}
		</>
	)
}
