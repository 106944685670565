import { useConstant, useFn, useMounted } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import { diff, pick } from '@eturi/util'
import { updateUser, user$ } from '@motiv-shared/reducers'
import type { MotivUserPatch } from '@motiv-shared/server'
import { Field, Form as FormikForm, Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'
import { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { addErrorToast, addSuccessToast } from '../../../reducers'
import { useAppDispatch } from '../../../store'
import type { FormikSubmit } from '../../../types'
import { formikCtrlProps } from '../../../util'
import { FormValidationText } from '../../../widgets/FormValidationText'

type ProfileInfoFormData = {
	readonly email: string
	readonly firstName: string
	readonly lastName: string
	readonly marketingOptIn?: boolean
}

export const UpdateProfileInfoForm = () => {
	const dispatch = useAppDispatch()
	const {
		email,
		firstName,
		id: userId,
		lastName,
		settings: { marketingOptIn },
	} = useSelector(user$)!

	const isMounted = useMounted()

	const NAME_MAX = 70
	// Add start/end whitespace (since value is trimmed) + 1, so user can
	// see validation message without browser preventing.
	const NAME_INPUT_MAX = NAME_MAX + 3

	const VALIDATION_SCHEMA = useConstant(() =>
		Yup.object().shape({
			email: Yup.string().trim().required('Email is required.').email('Invalid email').default(''),

			firstName: Yup.string()
				.trim()
				.required('First name is required.')
				.max(NAME_MAX, 'Character Limit Reached.')
				.default(''),

			lastName: Yup.string()
				.trim()
				.required('Last name is required.')
				.max(NAME_MAX, 'Character Limit Reached.')
				.default(''),

			marketingOptIn: Yup.boolean(),
		}),
	)

	const [initialValues, setInitialValues] = useState<ProfileInfoFormData>(() =>
		VALIDATION_SCHEMA.cast({
			email,
			firstName,
			lastName,
			marketingOptIn,
		}),
	)

	const handSubmit: FormikSubmit<ProfileInfoFormData> = useFn(async (v, helpers) => {
		// Normalize / trim
		const newValues = VALIDATION_SCHEMA.cast(v)
		const valuesDiff = diff(initialValues, newValues)

		// Early return (and reset to remove submitCount) if nothing changed.
		if (isEmpty(valuesDiff)) return helpers.resetForm()

		sentryBreadcrumb('Updating User Profile')

		const patch: Writable<MotivUserPatch> = pick(valuesDiff, ['email', 'firstName', 'lastName'])

		if (valuesDiff.marketingOptIn != null) {
			patch.addSettings = { marketingOptIn: valuesDiff.marketingOptIn }
		}

		try {
			await dispatch(updateUser({ patch, userId })).unwrap()

			isMounted() && setInitialValues(newValues)

			dispatch(addSuccessToast('Information updated successfully.'))
		} catch (e) {
			dispatch(addErrorToast('Something went wrong saving your info'))
			sentryError(e, 'Failed to update profile')
		}
	})

	return (
		<Formik<ProfileInfoFormData>
			enableReinitialize
			initialValues={initialValues}
			onSubmit={handSubmit}
			validationSchema={VALIDATION_SCHEMA}
		>
			{(fp) => {
				const getCtrlProps = formikCtrlProps(fp)

				return (
					<FormikForm noValidate={true}>
						<Form.Group controlId="firstNameField">
							<Form.Label>First Name</Form.Label>

							<Form.Control
								maxLength={NAME_INPUT_MAX}
								placeholder="Enter your first name"
								{...getCtrlProps('firstName')}
							/>

							<FormValidationText field="firstName" formikProps={fp} />
						</Form.Group>

						<Form.Group controlId="lastNameField">
							<Form.Label>Last Name</Form.Label>

							<Form.Control
								maxLength={NAME_INPUT_MAX}
								placeholder="Enter your last name"
								{...getCtrlProps('lastName')}
							/>

							<FormValidationText field="lastName" formikProps={fp} />
						</Form.Group>

						<Form.Group controlId="emailField">
							<Form.Label>Email</Form.Label>

							<Form.Control
								inputMode="email"
								placeholder="Enter your email"
								type="email"
								{...getCtrlProps('email')}
							/>

							<FormValidationText field="email" formikProps={fp} />
						</Form.Group>

						<Form.Group controlId="marketingOptInCheck">
							<Field
								as={Form.Check}
								label="Marketing Emails"
								name="marketingOptIn"
								type="checkbox"
							/>
						</Form.Group>

						<Button block disabled={fp.isSubmitting} size="lg" type="submit" variant="success">
							Save
						</Button>
					</FormikForm>
				)
			}}
		</Formik>
	)
}
