import type { Team, TeamPatch, TeamsCreateData } from '@motiv-shared/server'
import { ApiErrors } from '@motiv-shared/server'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState'
import type { HttpExtra } from '../http'
import { isApiErrorType } from '../http'
import type { WithTeamsState } from './createTeamsSlice'

const createAsyncThunk = /* @__PURE__ */ bindCreateAsyncThunkToState<WithTeamsState>()

export const fetchTeams = /* @__PURE__ */ createAsyncThunk(
	'teams/getAll',
	(extra: HttpExtra = {}, { dispatch, extra: { http } }) =>
		dispatch(
			http.get<Team[]>(`teams`, {
				errorMessage: 'Unable to load teams data. Please try again later.',
				...extra,
			}),
		),
)

type FetchTeamArgs = HttpExtra & {
	readonly teamId: string
}

export const fetchTeam = /* @__PURE__ */ createAsyncThunk(
	'teams/teamId',
	({ teamId, ...extra }: FetchTeamArgs, { dispatch, extra: { http } }) =>
		dispatch(
			http.get<Team>(`teams/${teamId}`, {
				errorMessage: 'Unable to load team. Please try again later.',
				...extra,
			}),
		),
)

type CreateTeamArgs = HttpExtra & {
	readonly team: TeamsCreateData
}

export const createTeam = /* @__PURE__ */ createAsyncThunk(
	'teams/createTeam',
	({ team, ...extra }: CreateTeamArgs, { dispatch, extra: { http } }) => {
		return dispatch(
			http.post<Team>(`teams`, {
				data: team,
				errorMessage: (extra: any, error: any) => () => {
					if (isApiErrorType(error, ApiErrors.EXCEEDED_LIMIT_TEAMS)) {
						return { msg: error.body.data.detailMsg, title: 'Limit reached' }
					}

					return 'Unable to add team. Please try again later.'
				},
				...extra,
			}),
		)
	},
)

type DeleteTeamArgs = HttpExtra & {
	readonly teamId: string
}

export const deleteTeam = /* @__PURE__ */ createAsyncThunk(
	'teams/Id',
	({ teamId, ...extra }: DeleteTeamArgs, { dispatch, extra: { http } }) =>
		dispatch(
			http.delete(`teams/${teamId}`, {
				errorMessage: 'Unable to delete team. Please try again later.',
				...extra,
			}),
		),
)

export type UpdateTeamArgs = HttpExtra & {
	readonly teamId: string
	readonly patch: TeamPatch
}

export const updateTeam = /* @__PURE__ */ createAsyncThunk(
	'teams/updateTeam',
	({ teamId, patch, ...extra }: UpdateTeamArgs, { dispatch, extra: { http } }) =>
		dispatch(
			http.patch<Team>(`teams/${teamId}`, {
				data: patch,
				errorMessage: 'Unable to update team. Please try again later.',
				...extra,
			}),
		),
)
