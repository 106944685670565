import { useFn } from '@eturi/react'
import { shouldShowChartDateLabel } from '@motiv-shared/reducers'
import { formatDateDefault } from '../defaults'
import { useDashboardState } from './useDashboardState'

export const useDateAxisFormat = () => {
	const { dateRangeFilter } = useDashboardState()

	return useFn((date: number, index: number) =>
		shouldShowChartDateLabel(dateRangeFilter, index) ? formatDateDefault(date) : undefined,
	)
}
