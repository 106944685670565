import { IntegrationProviders } from '@motiv-shared/server'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { IntegrationsModalType } from '../../features/IntegrationsModal'
import { IntegrationsModals } from '../../features/IntegrationsModal'

export type IntegrationsUiState = {
	readonly integrationsModal: Maybe<IntegrationsModalType>
}

export type WithIntegrationsUIState = {
	readonly integrationsUI: IntegrationsUiState
}

const initialState: IntegrationsUiState = {
	integrationsModal: null,
}

export const integrationsUISlice = createSlice({
	name: 'integrationsUI',
	initialState,
	reducers: {
		setIntegrationsModal: (s, a: PayloadAction<Maybe<IntegrationsModalType>>) => {
			s.integrationsModal = a.payload
		},
	},
})

export const { setIntegrationsModal } = integrationsUISlice.actions
