import { usePrevious } from '@eturi/react'
import { resetAction } from '@motiv-shared/reducers'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { canAccessProtected$ } from '../compound-selectors'
import { authLogin, isAuthenticated$, isAuthInit$ } from '../reducers'
import { useAppDispatch } from '../store'
import { LoadingFigure } from '../widgets/BusyIndicator'

export const LoginPage = () => {
	const dispatch = useAppDispatch()
	const canAccessProtected = useSelector(canAccessProtected$)
	const isAuthenticated = useSelector(isAuthenticated$)
	const isAuthInit = useSelector(isAuthInit$)
	const wasAuthenticated = usePrevious(isAuthenticated)
	const [isLoggingIn, setLoggingIn] = useState(false)

	useEffect(() => {
		if (isLoggingIn) return

		// Allow remaining logged out for UI Demo in dev
		if (process.env.APP_ENV === 'dev' && location.pathname.startsWith('/ui-demo')) return

		// If the user was authenticated and now is not, we have gone through the
		// logout process. Auth0 will redirect to origin once logout is complete
		// at which point wasAuthenticated will be false and login will be called
		if (wasAuthenticated && !isAuthenticated) return

		if (isAuthInit && !isAuthenticated) {
			// Before logging in, we set this flag and reset the state. The state is
			// reset because we need to make sure we don't retain inappropriate state
			// associated with an old session (via persistence enhancer) after
			// logging in to our (potentially different) account. We set isLoggingIn
			// flag because `AuthEffects` will immediately call `authInit` when
			// `isAuthInit` is `false`, and we'll get trapped in a nasty loop.
			setLoggingIn(true)
			dispatch(resetAction)
			dispatch(authLogin())
		}
	}, [isAuthenticated, isAuthInit, wasAuthenticated])

	return canAccessProtected ? <Redirect to="/" /> : <LoadingFigure />
}
