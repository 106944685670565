import './TruncatedButtonsList.scss'

import { useFn } from '@eturi/react'
import cls from 'classnames'
import noop from 'lodash/noop'
import truncate from 'lodash/truncate'
import type { MouseEvent } from 'react'
import { OverlayTrigger } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import Tooltip from 'react-bootstrap/Tooltip'

type TruncatedButtonListProps = {
	readonly buttons?: { readonly id: string; readonly label: string }[]
	readonly className?: string
	readonly disabled?: boolean
	readonly labelMaxLength: number
	readonly maxVisible: number
	readonly onClick?: (id: string) => void
	readonly stopPropagation?: boolean
}

export const TruncatedButtonsList = ({
	className,
	buttons = [],
	labelMaxLength,
	maxVisible,
	onClick = noop,
	disabled = false,
	stopPropagation = true,
}: TruncatedButtonListProps) => {
	const visibleButtons = buttons.slice(0, maxVisible)
	const overflowButtons = buttons.slice(maxVisible)

	const handleButtonClick = useFn((id) => {
		// Use this to make sure that any button click also closes any open tooltips
		// https://stackoverflow.com/questions/38467848/react-bootstrap-how-to-manually-close-overlaytrigger?answertab=votes#answer-47636953
		document.body.click()
		onClick(id)
	})

	const ButtonsContent = visibleButtons.map(({ id, label }, idx) => (
		<Button
			className={cls('truncated-btn-list__button', className)}
			key={idx}
			disabled={disabled}
			onClick={() => handleButtonClick(id)}
		>
			{truncate(label, { length: labelMaxLength, omission: '...' })}
		</Button>
	))

	const stopProp = useFn(
		(ev: MouseEvent<HTMLDivElement>) => stopPropagation && ev.stopPropagation(),
	)

	return (
		<div onClick={stopProp}>
			{ButtonsContent}
			{overflowButtons.length > 0 && (
				<OverlayTrigger
					trigger="click"
					placement="bottom"
					rootClose
					overlay={
						<Tooltip id="button-with-tooltip">
							{overflowButtons.map((b) => (
								<div
									className="truncated-btn-list__menu-item"
									key={b.id}
									onClick={() => handleButtonClick(b.id)}
								>
									{b.label}
								</div>
							))}
						</Tooltip>
					}
				>
					{(props) => (
						<Button
							className={cls('truncated-btn-list__button', className)}
							disabled={disabled}
							{...props}
						>
							+{overflowButtons.length}
						</Button>
					)}
				</OverlayTrigger>
			)}
		</div>
	)
}
