import { UserPermissions } from '@motiv-shared/server'
import { Redirect, Route, Switch } from 'react-router-dom'
import { BillingPage } from './features/Billing'
import { DashboardPage } from './features/Dashboard'
import { InvitePage } from './features/InvitePage'
import { SettingsPage } from './features/Settings'
import { SignUpPage } from './features/SignUpPage'
import { TeamLead } from './features/TeamLead'
import { TeamsPage } from './features/Teams'
import { Welcome } from './features/Welcome'
import { LoginPage } from './pages/LoginPage'
import { LogoutPage } from './pages/LogoutPage'
import { ProtectedRoute } from './ProtectedRoute'
import { UIDemoPage } from './UIDemo'

// NOTE: React router doesn't like fragments in Switch, so please avoid
export const WebAppRoutes = () => (
	<Switch>
		<Route path="/invite" component={InvitePage} />

		<Route path="/login" component={LoginPage} />

		<Route path="/signup" component={SignUpPage} />

		<Route path="/logout" component={LogoutPage} />

		{process.env.MOTIV_DASHBOARD === '1' && (
			<ProtectedRoute path="/dashboard" component={DashboardPage} />
		)}

		<ProtectedRoute path="/settings" component={SettingsPage} />

		<ProtectedRoute path="/teams" component={TeamsPage} />

		<ProtectedRoute path="/team-lead" component={TeamLead} />

		<ProtectedRoute path="/welcome" component={Welcome} />

		<ProtectedRoute
			path="/billing"
			component={BillingPage}
			permissions={[UserPermissions.CAN_VIEW_BILLING_TAB]}
		/>

		{process.env.APP_ENV === 'dev' && <Route path="/ui-demo/:page" component={UIDemoPage} />}
		{process.env.APP_ENV === 'dev' && <Route path="/ui-demo" exact={true} component={UIDemoPage} />}

		<Redirect to="/teams" />
	</Switch>
)
