import type { MomentInput } from 'moment-timezone'
import moment from 'moment-timezone'
import { useMemo } from 'react'

type PrettyDateProps = {
	readonly children?: MomentInput
	readonly className?: string
	readonly format: string
}

export const PrettyDate = ({ children: dateInput, className, format }: PrettyDateProps) => {
	const prettyDate = useMemo(
		() => dateInput != null && moment(dateInput).format(format),
		[dateInput, format],
	)

	return <span className={className}>{prettyDate}</span>
}
