import './AccountStatus.scss'

import { useFn } from '@eturi/react'
import {
	isAccountIntegrated$,
	isUserAccountOwner$,
	latestActiveAdmin$,
	latestInactiveAdmin$,
} from '@motiv-shared/reducers'
import Card from 'react-bootstrap/Card'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

export const AccountStatus = () => {
	const isAccountIntegrated = useSelector(isAccountIntegrated$)
	const isUserAccountOwner = useSelector(isUserAccountOwner$)

	if (isAccountIntegrated || !isUserAccountOwner) return null

	return <AccountStatusImpl />
}

const AccountStatusImpl = () => {
	const history = useHistory()
	const latestActiveAdmin = useSelector(latestActiveAdmin$)
	const latestInactiveAdmin = useSelector(latestInactiveAdmin$)

	const handleLinkClick = useFn(() => {
		history.push('/settings/integrations')
	})

	let body
	if (latestActiveAdmin) {
		body = (
			<>
				<span>{`${latestActiveAdmin.fullName} has created their account but has not set up your integrations.\n\nYou may set up integrations yourself `}</span>
				<u className="account-status__link" onClick={handleLinkClick}>
					here
				</u>
				<span> or invite someone else below.</span>
			</>
		)
	} else if (latestInactiveAdmin) {
		body = `${latestInactiveAdmin.fullName} was sent an invite to integrate your account.\n\nWe are waiting for them to accept your invitation.\n\nYou may set up integrations yourself or invite someone else below.`
	} else {
		return null
	}

	return (
		<Card className="mb-5">
			<Card.Header as="h5">Account Status</Card.Header>
			<Card.Body>
				<p className="account-status my-3">{body}</p>
			</Card.Body>
		</Card>
	)
}
