import { ModalityValues } from './Account'

// Users, invitations
export const INVITE_VALIDITY_MS = 7 * 86400 * 1000

// Teams, managed users (seats) - hard limits
export const MAX_TEAMS_PER_ACCOUNT = 10

export const MAX_MEMBERS_PER_ACCOUNT = 100

export const MAX_MEMBERS_PER_TEAM = 10

// Account settings
export const DEFAULT_MODALITY = ModalityValues.REMOTE
export const DEFAULT_WORK_DAYS = '0111110'
export const DEFAULT_WORK_START_TIME = '09:00'
export const DEFAULT_WORK_END_TIME = '17:00'
