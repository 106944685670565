import type { DeleteIntegrationModalProps } from '../TeamsModal/DeleteIntegrationModal'
import type { IntegrationConfigModalProps } from './IntegrationConfigModal'
import type { IntegrationUrlModalProps } from './IntegrationUrlModal'

export const IntegrationsModals = {
	DELETE_INTEGRATION: 'deleteIntegration',
	PRE_INTEGRATION_URL: 'preIntegrationUrl',
	POST_INTEGRATION_CONFIG: 'postIntegrationConfig',
} as const

export type IntegrationsModalType =
	| DeleteIntegrationModal
	| PreIntegrationModal
	| PostIntegrationModal

export type DeleteIntegrationModal = {
	readonly type: typeof IntegrationsModals.DELETE_INTEGRATION
} & DeleteIntegrationModalProps

export type PreIntegrationModal = {
	readonly type: typeof IntegrationsModals.PRE_INTEGRATION_URL
} & IntegrationUrlModalProps

export type PostIntegrationModal = {
	readonly type: typeof IntegrationsModals.POST_INTEGRATION_CONFIG
} & IntegrationConfigModalProps
