import Card from 'react-bootstrap/Card'
import { BusinessInfoForm } from './BusinessInfoForm'
import { CompanyHeader } from './CompanyHeader'

export const BusinessInfoOverview = () => (
	<>
		<CompanyHeader />

		<Card>
			<Card.Body>
				<BusinessInfoForm />
			</Card.Body>
		</Card>
	</>
)
