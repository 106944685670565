import { resetAction } from '@motiv-shared/reducers'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export type GlobalState = {
	readonly isInitialFetchComplete: boolean
}

export type WithGlobalState = {
	readonly global: GlobalState
}

const initialState: GlobalState = {
	isInitialFetchComplete: false,
}

/**
 * This slice stores global state that isn't specific to some other slice
 */
export const globalSlice = createSlice({
	name: 'global',
	initialState,
	reducers: {
		setInitialFetchComplete(s, a: PayloadAction<boolean>) {
			s.isInitialFetchComplete = a.payload
		},
	},

	extraReducers: (builder) => builder.addCase(resetAction.type, () => initialState),
})

export const { setInitialFetchComplete } = globalSlice.actions
