import './OutsideCardHeader.scss'

import cls from 'classnames'
import type { ReactNode } from 'react'

type OutsideCardHeaderProps = {
	readonly children?: ReactNode
	readonly className?: string
	readonly blurb?: string
}

export const OutsideCardHeader = ({ blurb, children, className }: OutsideCardHeaderProps) => (
	<>
		<h3 className={cls('outside-card-header', className)}>{children}</h3>
		{blurb && <p className="outside-card-header__blurb">{blurb}</p>}
	</>
)
