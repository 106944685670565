import { isAccountIntegrated$, user$ } from '@motiv-shared/reducers'
import { hasPermission, UserPermissions } from '@motiv-shared/server'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import {
	canShowTeamLeadPage$,
	canShowTeamsPage$,
	shouldShowWelcome$,
} from '../../compound-selectors'
import { ProtectedRoute } from '../../ProtectedRoute'
import { AppDownloadCard } from './AppDownloadCard'
import { IntegrateAccountOverview } from './IntegrateAccountOverview'
import { ActiveSeatsOverview, SuspendedSeatsOverview } from './Seats'
import { SeatsSideNav } from './SeatsSideNav'
import { NewTeamOverview, TeamOverview } from './Team'
import { TeamsSideNav } from './TeamsSideNav'

export const TeamsPage = () => {
	const user = useSelector(user$)!
	const shouldShowWelcome = useSelector(shouldShowWelcome$)
	const canShowTeamsPage = useSelector(canShowTeamsPage$)
	const canShowTeamLeadPage = useSelector(canShowTeamLeadPage$)
	const isAccountIntegrated = useSelector(isAccountIntegrated$)

	if (shouldShowWelcome) return <Redirect to="/welcome" />
	if (canShowTeamLeadPage) return <Redirect to="/team-lead" />

	// Should never happen
	if (!canShowTeamsPage) return null

	const needsIntegration = !isAccountIntegrated

	const showAppDownloadCard =
		needsIntegration && hasPermission(UserPermissions.CAN_VIEW_MOTIV_BANNER, user)

	const isFullSize = !showAppDownloadCard && !isAccountIntegrated

	return (
		<Row>
			<Col lg={3}>
				{showAppDownloadCard && <AppDownloadCard />}

				{!needsIntegration && (
					<>
						<SeatsSideNav />
						<TeamsSideNav />
					</>
				)}
			</Col>

			<Col lg={isFullSize ? 12 : 9}>
				{needsIntegration ? (
					<Switch>
						<Route path="/teams/integrate" component={IntegrateAccountOverview} />
						<Redirect to="/teams/integrate" />
					</Switch>
				) : (
					<Switch>
						<ProtectedRoute path="/teams/seats/active" component={ActiveSeatsOverview} />
						<ProtectedRoute path="/teams/seats/suspended" component={SuspendedSeatsOverview} />
						<ProtectedRoute path="/teams/team/new" component={NewTeamOverview} />
						<ProtectedRoute path="/teams/team/:teamId" component={TeamOverview} />
						<Redirect to="/teams/seats/active" />
					</Switch>
				)}
			</Col>
		</Row>
	)
}
