import cls from 'classnames'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import GoogleBadge from '../../assets/images/googleBadge.svg'

type GooglePlayBtnProps = {
	readonly className?: string
}

export const GooglePlayBtn = ({ className }: GooglePlayBtnProps) => (
	<Button
		className={cls('app-store-btn', className)}
		href="https://play.google.com/store/apps/details?id=com.eturi.motiv"
		// @ts-ignore
		rel="noreferrer"
		target="_blank"
		variant="link"
	>
		<Image src={GoogleBadge} alt="Google Play Store Badge" />
	</Button>
)
