import { useFn, useInterval, useMounted } from '@eturi/react'
import { createIntegration, fetchIntegrations, integrations$ } from '@motiv-shared/reducers'
import type { IntegrationProvider } from '@motiv-shared/server'
import {
	IntegrationConfigStatuses,
	IntegrationStatuses,
	isIntegrationProvider,
} from '@motiv-shared/server'
import {
	IntegrationResultCodes,
	isIntegrationResultCode,
	QUERY_PARAM_PROVIDER,
	QUERY_PARAM_RESULT_CODE,
} from '@motiv-shared/server/src/integrations/IntegrationResultCode'
import find from 'lodash/find'
import type { ReactNode } from 'react'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { IntegrationsModals } from './features/IntegrationsModal'
import { SeatModals } from './features/SeatModals'
import { addErrorToast, setIntegrationsModal, setSeatModal } from './reducers'
import { useAppDispatch } from './store'
import { getMotivHost } from './util'

type OAuthCtx = {
	connect(provider: IntegrationProvider, config?: any, errorMsg?: string): void
	disconnect(integrationId: string): void
}

const OAuthContext = createContext<OAuthCtx>(null as any)
export const useIntegrationFlow = () => useContext(OAuthContext)

export const IntegrationFlow = ({ children }: { readonly children?: ReactNode }) => {
	const dispatch = useAppDispatch()
	const isMounted = useMounted()
	const integrations = useSelector(integrations$)

	const [pendingProvider, setPendingProvider] = useState<Maybe<IntegrationProvider>>(null)
	const [customErrorMsg, setCustomErrorMsg] = useState<Maybe<string>>(null)
	const [win, setWin] = useState<Window | null>(null)

	const pendingIntegration = useMemo(
		() => find(integrations, { integrationProvider: pendingProvider } as any),
		[integrations, pendingProvider],
	)

	const connect = useFn(
		async (provider: IntegrationProvider, config?: Record<string, any>, errorMsg?: string) => {
			if (pendingProvider) return

			setPendingProvider(provider)

			errorMsg && setCustomErrorMsg(errorMsg)
			const newIntegration = await dispatch(createIntegration({ provider, config }))
				.unwrap()
				.catch((e) => {
					setPendingProvider(null)
					setCustomErrorMsg(null)
					throw e
				})

			if (isMounted()) {
				setWin(window.open(newIntegration.authUrl, 'popup', buildPopupFeatures()))
			} else {
				setPendingProvider(null)
				setCustomErrorMsg(null)
			}
		},
	)

	const disconnect = useFn((integrationId: string) => {
		dispatch(
			setIntegrationsModal({
				type: IntegrationsModals.DELETE_INTEGRATION,
				integrationId,
			}),
		)
	})

	const onFinished = useFn(() => {
		win?.close()
		setWin(null)
		setPendingProvider(null)
		setCustomErrorMsg(null)
	})

	const showErrorToast = useFn(() =>
		dispatch(
			addErrorToast(
				customErrorMsg
					? customErrorMsg
					: 'There was a problem integrating your account. Please try again.',
			),
		),
	)

	useInterval(
		() => {
			if (win?.closed) {
				// To maintain backwards compatibility show error if the user closes
				// the window manually. This may not be necessary anymore now that the
				// server can return errors.
				showErrorToast()

				onFinished()
			}
		},
		win ? 200 : null,
	)

	useEffect(() => {
		// Monitors for the following cases
		// - User has completed oauth flow (window may still be open)
		if (pendingIntegration?.status === IntegrationStatuses.COMPLETE) {
			onFinished()

			// If Config Status is not complete then we open the integration config modal
			if (pendingIntegration.configStatus === IntegrationConfigStatuses.INCOMPLETE) {
				dispatch(
					setIntegrationsModal({
						type: IntegrationsModals.POST_INTEGRATION_CONFIG,
						integrationProvider: pendingIntegration.integrationProvider,
					}),
				)
				return
			}

			pendingIntegration.id &&
				dispatch(
					setSeatModal({ type: SeatModals.SYNC_INTEGRATION, integrationId: pendingIntegration.id }),
				)
		}
	}, [pendingIntegration])

	const handlePostMessage = useFn((data: string) => {
		const params = new URLSearchParams(data)

		const provider = params.get(QUERY_PARAM_PROVIDER)
		const resultCode = params.get(QUERY_PARAM_RESULT_CODE)

		if (
			!isIntegrationResultCode(resultCode) ||
			!isIntegrationProvider(provider) ||
			provider !== pendingProvider
		) {
			return
		}

		if (resultCode === IntegrationResultCodes.FAILURE) {
			showErrorToast()
			onFinished()
		}

		dispatch(fetchIntegrations({ force: 'soft', ignoreIndicator: true }))
	})

	useEffect(() => {
		const listener = (event: MessageEvent) => {
			const isValid = event.origin === `https://${getMotivHost()}`

			if (!isValid) {
				return
			}

			console.log(`Received message with data: ${event.data}`)

			handlePostMessage(event.data)
		}

		window.addEventListener('message', listener)

		return () => window.removeEventListener('message', listener)
	}, [])

	return <OAuthContext.Provider value={{ connect, disconnect }}>{children}</OAuthContext.Provider>
}

const buildPopupFeatures = (): string => {
	const height = 500
	const width = 500
	const left = screen.width / 2 - width / 2
	const top = screen.height / 2 - height / 2

	return [
		'copyhistory=no',
		'directories=no',
		'location=no',
		'menubar=no,',
		'resizable=no',
		'scrollbars=no',
		'status=no',
		'toolbar=no',
		`height=${height}`,
		`left=${left}`,
		`top=${top}`,
		`width=${width}`,
	].join(', ')
}
