import { curry } from '@eturi/util'
import some from 'lodash/some'

export type UserPermission = ValueUnion<typeof UserPermissions>
export const UserPermissions = {
	CAN_ACCESS_MOBILE_APP: 'canAccessMobileApp',
	CAN_ASSIGN_AS_TEAM_LEAD: 'canAssignAsTeamLead',
	CAN_MODIFY_ACCOUNT: 'canModifyAccount',
	CAN_MODIFY_INTEGRATION: 'canModifyIntegration',
	CAN_MODIFY_PURCHASE: 'canModifyPurchase',
	CAN_MODIFY_TEAM: 'canModifyTeam', // Teams, team members, team leads, managed users
	CAN_MODIFY_USER: 'canModifyUser',
	CAN_TRANSFER_OR_DELETE_ACCOUNT: 'canTransferOrDeleteAccount',
	CAN_VIEW_ACCOUNT_TAB: 'canViewAccountTab',
	CAN_VIEW_BILLING_TAB: 'canViewBillingTab',
	CAN_VIEW_MOTIV_BANNER: 'canViewMotivBanner',
	CAN_VIEW_TEAM_LEAD_VIEW: 'canViewTeamLeadView',
} as const

export type UserPermissionData = {
	readonly id: UserPermission
}

export type WithPermissionData<T> = T & {
	readonly permissions: readonly UserPermissionData[]
}

export const hasPermission = curry(
	(permission: UserPermission, obj: Maybe<WithPermissionData<unknown>>) =>
		some(obj?.permissions, { id: permission }),
)
