import * as Yup from 'yup'
import type { WithPermissionData } from './UserPermission'

export type UserRole = ValueUnion<typeof UserRoles>
export const UserRoles = {
	ACCOUNT_OWNER: 'ACCOUNT_OWNER',
	ADMIN: 'ADMIN',
	ADMIN_TEAM_LEAD: 'ADMIN_TEAM_LEAD',
	TEAM_LEAD: 'TEAM_LEAD',
} as const

export type UserRoleData = {
	readonly id: UserRole
	readonly label: string
}

export type UserRolePermissionData = WithPermissionData<UserRoleData>

const UserRoleValues = /* @__PURE__ */ Object.values(UserRoles)

export const UserRolesSet: ReadonlySet<UserRole> = /* @__PURE__ */ new Set(UserRoleValues)

export type ItAdminRole = typeof UserRoles.ADMIN | typeof UserRoles.ADMIN_TEAM_LEAD
export type TeamLeadRole = typeof UserRoles.ADMIN_TEAM_LEAD | typeof UserRoles.TEAM_LEAD

export const hasRole = (role: UserRole) => (entity: Maybe<{ roleId?: UserRole }>) =>
	entity?.roleId === role

export const isRole =
	<T extends UserRole>(role: T) =>
	(maybeRole: Maybe<UserRole>): maybeRole is T =>
		role === maybeRole

export const isValidRole = (maybeRole: any): maybeRole is UserRole => UserRolesSet.has(maybeRole)

export const hasAccountOwnerRole = /* @__PURE__ */ hasRole(UserRoles.ACCOUNT_OWNER)
export const hasAdminRole = /* @__PURE__ */ hasRole(UserRoles.ADMIN)
export const hasAdminTeamLeadRole = /* @__PURE__ */ hasRole(UserRoles.ADMIN_TEAM_LEAD)
export const hasTeamLeadOnlyRole = /* @__PURE__ */ hasRole(UserRoles.TEAM_LEAD)

export const hasItAdminRole = (entity: Maybe<{ roleId?: UserRole }>) =>
	hasAdminRole(entity) || hasAdminTeamLeadRole(entity)

export const hasTeamLeadRole = (entity: Maybe<{ roleId?: UserRole }>) =>
	hasAdminTeamLeadRole(entity) || hasTeamLeadOnlyRole(entity)

export const isAccountOwnerRole = /* @__PURE__ */ isRole(UserRoles.ACCOUNT_OWNER)
export const isAdminRole = /* @__PURE__ */ isRole(UserRoles.ADMIN)
export const isAdminTeamLeadRole = /* @__PURE__ */ isRole(UserRoles.ADMIN_TEAM_LEAD)
export const isTeamLeadOnlyRole = /* @__PURE__ */ isRole(UserRoles.TEAM_LEAD)

export const isItAdminRole = (role: Maybe<UserRole>): role is ItAdminRole =>
	isAdminRole(role) || isAdminTeamLeadRole(role)

export const isTeamLeadRole = (role: Maybe<UserRole>): role is TeamLeadRole =>
	isAdminTeamLeadRole(role) || isTeamLeadOnlyRole(role)

export const userRoleSchema = /* @__PURE__ */ Yup.mixed().oneOf(UserRoleValues)
