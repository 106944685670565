import type {
	ActionCreatorWithPreparedPayload,
	ActionReducerMapBuilder,
	AnyAction,
	CaseReducer,
} from '@reduxjs/toolkit'
import type { Action } from 'redux'
import type { HttpInitHandler } from './createHttpInitHandler'
import type { HttpExtra } from './http'
import type { InitState } from './types'

type Cacheable = ActionCreatorWithPreparedPayload<
	any,
	any,
	any,
	any,
	{ arg: HttpExtra | undefined }
>

type TypedActionCreator<Type extends string> = {
	(...args: any[]): Action<Type>
	type: Type
}

type ActionMatcher<A extends AnyAction> = (action: AnyAction) => action is A

export type BuilderWithCachedCase<S> = MOmit<
	ActionReducerMapBuilder<S>,
	'addCase' | 'addMatcher'
> & {
	addCachedCase<ActionCreator extends Cacheable>(
		actionCreator: ActionCreator,
		reducer: CaseReducer<S, ReturnType<ActionCreator>>,
		// getCacheKey?: (a: ReturnType<ActionCreator>, s: Draft<S>) => any
	): BuilderWithCachedCase<S>

	addResetCase(type: string, state: S): BuilderWithCachedCase<S>

	addCase<ActionCreator extends TypedActionCreator<string>>(
		actionCreator: ActionCreator,
		reducer: CaseReducer<S, ReturnType<ActionCreator>>,
	): BuilderWithCachedCase<S>

	addCase<T extends string, A extends Action<T>>(
		type: T,
		reducer: CaseReducer<S, A>,
	): BuilderWithCachedCase<S>

	addMatcher<A extends AnyAction>(
		matcher: ActionMatcher<A> | ((action: AnyAction) => boolean),
		reducer: CaseReducer<S, A>,
	): MOmit<BuilderWithCachedCase<S>, 'addCachedCase' | 'addResetCase' | 'addCase'>
}

/**
 * Adds a `addCachedCase` method to builder. This will create and interact
 * w/ a cache slice via httpInit.
 *
 * @param origBuilder
 * @param httpInit
 * @param name Slice name
 */
export const withPersistentCacheCase = <S extends InitState, T>(
	origBuilder: ActionReducerMapBuilder<S>,
	httpInit: HttpInitHandler<T>,
	name: string,
): BuilderWithCachedCase<S> => {
	const cacheSlice = httpInit.createSlice(name)

	const newBuilder: BuilderWithCachedCase<S> = {
		addCachedCase: (actionCreator, reducer) => {
			cacheSlice.addMatcher(actionCreator.match)
			origBuilder.addCase(actionCreator, reducer)

			return newBuilder
		},

		addCase: (actionCreator: any, reducer: any) => {
			origBuilder.addCase(actionCreator, reducer)

			return newBuilder
		},

		addResetCase: (action, state: S) => {
			origBuilder.addCase(action, () => state)

			return newBuilder
		},

		addMatcher: (matcher, reducer) => {
			origBuilder.addMatcher(matcher, reducer)

			return {
				addMatcher: newBuilder.addMatcher,
				addDefaultCase: newBuilder.addDefaultCase,
			}
		},

		addDefaultCase: origBuilder.addDefaultCase,
	}

	return newBuilder
}

/**
 * - HTTP cache has a list of keys to check
 * - Condition will do getState()[key].isInit
 * - Condition will check matching typePrefixes
 * - persistenceEnhancer can save these states and rehydrate isInit and state
 */
