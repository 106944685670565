export type IndicatorRegion = ValueUnion<typeof IndicatorRegions>
export const IndicatorRegions = {
	ACCOUNT: 'account',
	ADD_NEW_USER: 'addNewUser',
	ASSIGN_TO_TEAM: 'assignToTeam',
	BILLING: 'billing',
	CREATE_TEAM: 'createTeam',
	DELETE_ACCOUNT: 'deleteAccount',
	DELETE_TEAM: 'deleteTeam',
	DELETE_USER: 'deleteUser',
	DISCONNECT_INTEGRATION: 'disconnectIntegration',
	FETCH_SUBS: 'fetchSubInfo',
	FETCH_TEAMS: 'fetchTeams',
	GLOBAL: 'global',
	INTEGRATION_CONFIG: 'integrationConfig',
	INTEGRATIONS: 'integrations',
	RESEND_INVITE: 'resendInvite',
	SEND_INSTRUCTIONS: 'sendInstructions',
	TEAM_MEMBERS: 'teamMembers',
	TRANSFER_ACCOUNT: 'transferAccount',
	UPDATE_PASSWORD: 'updatePassword',
	UPDATE_TEAM: 'updateTeam',
	UPDATE_USER: 'updateUser',
	USERS: 'users',
} as const
