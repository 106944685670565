export type UserInvitationCheckQueryArgs = {
	email?: string
}

export type UserInvitationCheckRes = UserInvitationCheckValidRes | UserInvitationCheckInvalidRes

export type UserInvitationCheckValidRes = {
	readonly status: InvitationStatus.VALID
}

export type UserInvitationCheckInvalidRes = {
	readonly status: InvitationStatus.INVALID
	readonly reason: InvitationInvalidReason
}

/**
 * Enum of non-error results of an invitation check request.
 */
export enum InvitationStatus {
	VALID = 'valid',
	INVALID = 'invalid',
}

export type InvitationInvalidReason = ValueUnion<typeof InvitationInvalidReasons> | string
export const InvitationInvalidReasons = {
	ACCEPTED: 'accepted',
	UNKNOWN: 'unknown',
	EXPIRED: 'expired',
} as const
