import { notEmpty } from '@eturi/util'
import { ManagedUserState } from '@motiv-shared/server'
import { createSelector } from '@reduxjs/toolkit'
import type { WithManagedUsersState } from './createManagedUsersSlice'

const state$ = <T extends WithManagedUsersState>(s: T) => s.managedUsers

export const isInactiveOrSuspended = ({ state }: { state: ManagedUserState }) =>
	state === ManagedUserState.INACTIVE || state === ManagedUserState.SUSPENDED

export const managedUsers$ = /* @__PURE__ */ createSelector(state$, (s) => s.data)
export const hasManagedUsers$ = /* @__PURE__ */ createSelector(managedUsers$, notEmpty)
export const hasInactiveManagedUsers$ = /* @__PURE__ */ createSelector(managedUsers$, (users) =>
	users.some(isInactiveOrSuspended),
)
