import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing/BrowserTracing'

type Breadcrumb = Sentry.Breadcrumb

const isLocal = location.hostname.startsWith('app.local')
const isNonLocalDev = !isLocal && process.env.APP_ENV === 'dev'
const environment = `Web-${isLocal ? 'local' : process.env.APP_ENV}`

type ConsoleBreadcrumb = Breadcrumb & { category: 'console'; message: string }

type NavBreadcrumb = Breadcrumb & {
	category: 'navigation'
	data: { from: string; to: string }
}

const isConsoleBreadcrumb = (b: Breadcrumb): b is ConsoleBreadcrumb => b.category === 'console'

const isNavBreadcrumb = (b: Breadcrumb): b is NavBreadcrumb => b.category === 'navigation'

// We filter out auth 0 url because it contains user tokens
const URL_FROM_AUTH0 = /\?code/

const normalizeNavPath = (path: string): string => (URL_FROM_AUTH0.test(path) ? '/auth' : path)

const handleNavBreadcrumb = (b: NavBreadcrumb) => {
	const {
		data: { from, to },
	} = b

	if (from === to) return null

	return {
		...b,
		data: { ...b.data, from: normalizeNavPath(from), to: normalizeNavPath(to) },
	}
}

const normalizeEvent = (ev: Sentry.Event) => {
	const req = ev.request
	const url = req?.url

	if (!url) return ev

	try {
		const parsed = new URL(url)
		parsed.pathname = normalizeNavPath(parsed.pathname)

		return { ...ev, request: { ...req, url: parsed.toString() } }
	} catch {
		return ev
	}
}

export const initSentry = () => {
	Sentry.init({
		beforeBreadcrumb(breadcrumb) {
			// Don't log push state when it's to the same path
			if (isNavBreadcrumb(breadcrumb)) return handleNavBreadcrumb(breadcrumb)

			return breadcrumb
		},
		beforeSend: normalizeEvent,
		denyUrls: [
			// Facebook flakiness
			/graph\.facebook\.com/i,
			// Facebook blocked
			/connect\.facebook\.net\/en_US\/all\.js/i,
			// Woopra flakiness
			/eatdifferent\.com\.woopra-ns\.com/i,
			/static\.woopra\.com\/js\/woopra\.js/i,
			// Chrome extensions
			/extensions\//i,
			/^chrome:\/\//i,
			// Other plugins
			/127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
			/webappstoolbarba\.texthelp\.com\//i,
			/metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
		],
		dsn: process.env.MOTIV_SENTRY_DSN,
		environment,
		ignoreErrors: [
			// Random plugins/extensions
			'top.GLOBALS',
			// See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
			'originalCreateNotification',
			'canvas.contentDocument',
			'MyApp_RemoveAllHighlights',
			'http://tt.epicplay.com',
			"Can't find variable: ZiteReader",
			'jigsaw is not defined',
			'ComboSearch is not defined',
			'http://loading.retry.widdit.com/',
			'atomicFindClose',
			// Facebook borked
			'fb_xd_fragment',
			// ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
			// reduce this. (thanks @acdha)
			// See http://stackoverflow.com/questions/4113268
			'bmi_SafeAddOnload',
			'EBCallBackMessageReceived',
			// See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
			'conduitPage',
		],
		integrations: (integrations) => {
			return [
				...integrations.map((i) =>
					isLocal && i.name === 'Breadcrumbs'
						? new Sentry.Integrations.Breadcrumbs({ console: false })
						: i,
				),
				new BrowserTracing({
					beforeNavigate: (context) => ({ ...context, name: normalizeNavPath(context.name) }),
				}),
			]
		},
		normalizeDepth: 10,
		release: process.env.SENTRY_RELEASE,
		// In QA / dev environments (not local), we sample everything
		sampleRate: isNonLocalDev ? 1 : 0.4, // Sample 40% of errors
		tracesSampleRate: isNonLocalDev ? 1 : 0.1, // Trace 10% of transactions
	})
}
