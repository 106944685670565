import { BillingBanners } from './BillingBanners'
import { FreeTrialExpiringBanner } from './FreeTrialExpiringBanner'
import { InvalidIntegrationsBanner } from './InvalidIntegrationsBanner'
import { NoNetworkBanner } from './NoNetworkBanner'

export const Banners = () => (
	<>
		<BillingBanners />
		<FreeTrialExpiringBanner />
		<NoNetworkBanner />
		<InvalidIntegrationsBanner />
	</>
)
