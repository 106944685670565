import type { DateRange, DateRangeFilter } from '@motiv-shared/reducers'
import { DateRangeFilters } from '@motiv-shared/reducers'
import type { Team } from '@motiv-shared/server'
import some from 'lodash/some'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import type { ManagedUserDecorated } from '../../../compound-selectors'
import { managedUsersDecorated$ } from '../../../compound-selectors'
import { sortedTeams$ } from '../../../compound-selectors/teams'

type DashboardParams = {
	readonly dateRange?: Lowercase<DateRange>
	readonly teamId?: string
	readonly userId?: string
}

type UseDashboardState = {
	readonly dateRangeFilter: Maybe<DateRangeFilter>
	readonly dateRangeParam: Maybe<DashboardParams['dateRange']>
	readonly team: Maybe<Team>
	readonly teams: Team[]
	readonly teamUsers: Maybe<ManagedUserDecorated[]>
	readonly user: Maybe<ManagedUserDecorated>
}

const findEntityWithTruncatedId = <T extends { id: string }>(
	entities: T[],
	id: Maybe<string>,
): Maybe<T> => (id ? entities.find((e) => e.id.startsWith(id)) : null)

export const useDashboardState = (): UseDashboardState => {
	const {
		dateRange: dateRangeLower,
		teamId: truncatedTeamId,
		userId: truncatedUserId,
	} = useParams<DashboardParams>()
	const managedUsersDecorated = useSelector(managedUsersDecorated$)
	const sortedTeams = useSelector(sortedTeams$)

	return useMemo(() => {
		const dateRange = dateRangeLower?.toUpperCase() as Maybe<DateRange>
		const user = findEntityWithTruncatedId(managedUsersDecorated, truncatedUserId)
		const team = findEntityWithTruncatedId(sortedTeams, truncatedTeamId)
		const teamUsers = team
			? managedUsersDecorated.filter((m) => some(m.assignedTeams, { id: team.id }))
			: null

		return {
			dateRangeFilter: dateRange ? DateRangeFilters[dateRange] : null,
			dateRangeParam: dateRangeLower,
			team,
			teams: sortedTeams,
			teamUsers,
			user,
		}
	}, [dateRangeLower, managedUsersDecorated, sortedTeams, truncatedTeamId, truncatedUserId])
}
