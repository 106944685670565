import './RatioBar.scss'

import cls from 'classnames'
import isEmpty from 'lodash/isEmpty'
import sum from 'lodash/sum'
import StyleConstants from './ratiobar-constants-exports.module.scss'

// This serves as the bar radius and also the left offset of the second bar
const BAR_RADIUS_AND_LEFT_OFFSET = Number.parseFloat(StyleConstants.barHeightAndLeftOffset)
const BAR_MIN_WIDTH = Number.parseFloat(StyleConstants.barMinWidth)

// We get a little extra minWidth to second bar for padding
const getMinWidth = (idx: number) => BAR_MIN_WIDTH + (idx > 0 ? 5 : 0)

type RatioBarData = {
	readonly color: string
	readonly label: string
	readonly value: number
}
type RatioBarProps = {
	readonly data: RatioBarData[]
	readonly maxValue: number
	readonly width: number
}

export const RatioBar = ({ data, maxValue, width }: RatioBarProps) => {
	// Don't bother showing bars until we have a layout
	if (!width) return null

	// We add the left offset to the total width since this offset shrinks the
	// bar's actual width.
	const fullWidth = width + BAR_RADIUS_AND_LEFT_OFFSET

	const normalizedData = data.filter((d) => d.value > 0)

	// Don't go further if we don't have any non-zero values
	if (isEmpty(normalizedData)) return null

	// Calculate the base bar widths. Second bar gets it's left value added.
	const barBaseWidths = data.map(
		(d, i) => (d.value / maxValue) * fullWidth + (i > 0 ? BAR_RADIUS_AND_LEFT_OFFSET : 0),
	)
	// Then correct the scaling to fit fullWidth
	const barWidths = scaleToFit(barBaseWidths, getMinWidth, fullWidth)

	const maxZIndex = normalizedData.length

	return (
		<div className="d-flex flex-row mt-2">
			{normalizedData.map((d, i) => {
				const isBottom = i > 0

				return (
					<div
						key={`bar-${i}`}
						className={cls('bar', isBottom ? 'bar-bottom' : null)}
						style={{
							backgroundColor: d.color,
							width: barWidths[i],
							marginLeft: isBottom ? -BAR_RADIUS_AND_LEFT_OFFSET : 0,
							zIndex: maxZIndex - i,
						}}
					>
						<span className="label">{d.label}</span>
					</div>
				)
			})}
		</div>
	)
}

// TODO: The below needs to be properly shared with the Mobile App
type GetMin = (idx: number) => number

/**
 * Scales values to fit a limit w/ a floor for each value. Will only recurse
 * 5 times at most. Due to the nature of this, if the items don't fit after
 * the first pass, they will become less and less accurate for each run.
 */
const scaleToFit = (values: number[], min: GetMin, limit: number, recursion = 0): number[] => {
	const minNormalizedValues = values.map((v, i) => Math.max(v, min(i)))

	const total = sum(minNormalizedValues)

	if (total <= limit) return minNormalizedValues

	const scalableValueIndices: number[] = []
	const newValues: number[] = []
	let remaining = total - limit

	minNormalizedValues.forEach((val, idx) => {
		const minVal = min(idx)
		const ratio = val / limit
		let newVal = val - remaining * ratio

		if (newVal <= minVal) {
			newVal = minVal
			remaining -= val - minVal
		} else {
			remaining -= val - newVal
			scalableValueIndices.push(idx)
		}

		newValues.push(newVal)
	})

	return ++recursion > 5 || remaining < 1 || !scalableValueIndices.length
		? newValues
		: scaleToFit(newValues, min, limit, recursion)
}
