import { notEmpty } from '@eturi/util'
import { ManagedUserState } from '@motiv-shared/server'
import { createSelector } from '@reduxjs/toolkit'
import filter from 'lodash/filter'
import size from 'lodash/size'
import { managedUsers$ } from '../managedUsers'
import { activeSeatLimit$, nextSeatLimit$ } from '../subscription'
import { teams$ } from '../teams'
import { withAssignedTeams } from '../teams/teams-util'

export const managedUsersWithTeams$ = /* @__PURE__ */ createSelector(
	managedUsers$,
	teams$,
	(managedUsers, teams) => managedUsers.map((u) => withAssignedTeams(u, teams)),
)

export const assignedManagedUsersWithTeams$ = /* @__PURE__ */ createSelector(
	managedUsersWithTeams$,
	(tm) => tm.filter((m) => notEmpty(m.assignedTeams)),
)

export const activeManagedUsersWithTeams$ = /* @__PURE__ */ createSelector(
	managedUsersWithTeams$,
	(u) => filter(u, { state: ManagedUserState.ACTIVE }),
)

export const activeManagedUsers$ = /* @__PURE__ */ createSelector(managedUsers$, (u) =>
	filter(u, { state: ManagedUserState.ACTIVE }),
)

export const assignedSeats$ = /* @__PURE__ */ createSelector(activeManagedUsers$, size)

export const extraSeats$ = /* @__PURE__ */ createSelector(
	nextSeatLimit$,
	assignedSeats$,
	(nextSeatLimit, assignedSeats) => Math.max(0, assignedSeats - nextSeatLimit),
)

export const emptySeats$ = /* @__PURE__ */ createSelector(
	activeSeatLimit$,
	assignedSeats$,
	(activeSeatLimit, assignedSeats) => Math.max(0, activeSeatLimit - assignedSeats),
)

export const hasSeatLimit$ = /* @__PURE__ */ createSelector(
	activeSeatLimit$,
	assignedSeats$,
	(activeSeatLimit, assignedSeats) => assignedSeats >= activeSeatLimit,
)

export const isOverSeatLimit$ = /* @__PURE__ */ createSelector(
	activeSeatLimit$,
	assignedSeats$,
	(activeSeatLimit, assignedSeats) => assignedSeats > activeSeatLimit,
)

export const willBeOverSeatLimit$ = /* @__PURE__ */ createSelector(
	assignedSeats$,
	nextSeatLimit$,
	(assignedSeats, nextSeatLimit) => assignedSeats > nextSeatLimit,
)

// Managed user ids that are only assigned to the given team
export const createUniqueManagedUsersByTeamSelector = () =>
	createSelector(
		assignedManagedUsersWithTeams$,
		(_: any, teamId: Maybe<string>) => teamId,
		(teamMembers, teamId) => {
			return teamMembers
				.filter((a) => a.assignedTeams.length === 1 && a.assignedTeams[0].id === teamId)
				.map((b) => b.id)
		},
	)
