import './SeatsOverview.scss'

import { useBoolState, useFn, useMounted } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import { notNull } from '@eturi/util'
import { emptySeats$, fetchTeam, updateTeam } from '@motiv-shared/reducers'
import type { Team } from '@motiv-shared/server'
import { MAX_MEMBERS_PER_TEAM } from '@motiv-shared/server'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import type { ReactNode } from 'react'
import { useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import type { ManagedUserDecorated } from '../../../compound-selectors'
import { useActivateSeat } from '../../../hooks/useActivateSeat'
import { useUserTableState } from '../../../hooks/useUserTableState'
import {
	addErrorToast,
	addSuccessToast,
	currentIntegrationsDecorated$,
	setSeatModal,
} from '../../../reducers'
import { useAppDispatch } from '../../../store'
import {
	assignedTeamsFormatter,
	assignedTeamsSort,
	getMenuFormatter,
	integrationsFormatter,
	integrationsSort,
	MotivTable,
	nameFormatter,
	nameSortFn,
} from '../../../widgets/Table'
import { SeatModals } from '../../SeatModals'

export type SeatOverviewType = 'active' | 'suspended'

type SeatsOverviewProps = {
	readonly header: ReactNode
	readonly managedUsers: ManagedUserDecorated[]
	readonly noSeats: ReactNode
	readonly type: SeatOverviewType
}

export const SeatsOverview = ({ header, managedUsers, noSeats, type }: SeatsOverviewProps) => {
	const dispatch = useAppDispatch()
	const history = useHistory()
	const emptySeats = useSelector(emptySeats$)
	const integrations = useSelector(currentIntegrationsDecorated$)
	const isMounted = useMounted()

	const {
		createTableData,
		filter: { data: filteredUsers },
		renderSearchControl,
		selected: usersSelected,
	} = useUserTableState(managedUsers)
	const [activateSeat, isActivatingSeat] = useActivateSeat()
	const [isRemovingFromTeams, startRemoving, stopRemoving] = useBoolState(false)

	const isActiveOverview = type === 'active'
	const hasManagedUsers = Boolean(managedUsers.length)

	const hasUsersWithTeamsSelected = useMemo(
		() => usersSelected.data.some((user) => !isEmpty(user.assignedTeams)),
		[usersSelected],
	)

	const handleTeamClick = (teamId: string) => {
		dispatch(fetchTeam({ teamId }))
		history.push(`/teams/team/${teamId}`)
	}

	const handleDeleteClick = (user: ManagedUserDecorated) => {
		dispatch(setSeatModal({ type: SeatModals.DELETE_SEATS, ids: [user.id] }))
	}

	const handleDeleteMultiClick = useFn(() => {
		dispatch(setSeatModal({ type: SeatModals.DELETE_SEATS, ids: [...usersSelected.ids] }))
	})

	const handleEditIntegrationsClick = (user: ManagedUserDecorated) => {
		dispatch(
			setSeatModal({
				type: SeatModals.EDIT_INTEGRATIONS,
				canSync: true,
				selectedManagedUserId: user.id,
			}),
		)
	}

	const handleReactivateSeatClick = useFn((user: ManagedUserDecorated) => {
		if (isActivatingSeat) return

		// Check if reactivating a seat will push us over the seat limit
		if (emptySeats - 1 < 0) {
			dispatch(
				addErrorToast({
					title: 'Seat Limit Reached',
					msg: 'Upgrade now to add more seats.',
				}),
			)

			return
		}

		if (user.assignedTeams.some((t) => t.assignedManagedUsers.length >= MAX_MEMBERS_PER_TEAM)) {
			dispatch(setSeatModal({ type: SeatModals.REACTIVATE_TEAM_LIMIT, managedUser: user }))
			return
		}

		activateSeat(user)
	})

	const handleRemoveTeamsClick = (v: ManagedUserDecorated) => removeUsersFromTeams([v])

	const handleRemoveTeamsMultiClick = useFn(() => {
		const usersToRemoveFromTeams = [...usersSelected.ids]
			.map((id) => find(managedUsers, { id }))
			.filter(notNull)

		return removeUsersFromTeams(usersToRemoveFromTeams)
	})

	const handleSuspendSeatClick = (user: ManagedUserDecorated) => {
		dispatch(setSeatModal({ type: SeatModals.SUSPEND_SEATS, ids: [user.id] }))
	}

	const handleSuspendMultiClick = useFn(() => {
		dispatch(setSeatModal({ type: SeatModals.SUSPEND_SEATS, ids: [...usersSelected.ids] }))
	})

	const removeUsersFromTeams = useFn(async (users: ManagedUserDecorated[], teams?: Team[]) => {
		if (isRemovingFromTeams) return

		// Create a map of which user ids to remove from each teamId
		const teamRemovalMap: { [teamId: string]: string[] } = {}

		for (const user of users) {
			for (const team of teams || user.assignedTeams) {
				const idsToRemove = (teamRemovalMap[team.id] ||= [])
				idsToRemove.push(user.id)
			}
		}

		if (isEmpty(teamRemovalMap)) return

		startRemoving()
		sentryBreadcrumb('Removing users from all teams')

		try {
			await Promise.all(
				Object.entries(teamRemovalMap).map(([teamId, userIdsToRemove]) =>
					dispatch(updateTeam({ teamId, patch: { removeManagedUsers: userIdsToRemove } })).unwrap(),
				),
			)

			const totalUsersRemovedFromTeams = users.length

			const toastMsg =
				totalUsersRemovedFromTeams === 1
					? `${users[0].fullName} has been unassigned from all teams.`
					: `${totalUsersRemovedFromTeams} team members have been unassigned from all teams.`

			dispatch(addSuccessToast(toastMsg))
		} catch (e) {
			sentryError(e, 'Failed to remove user(s) from all teams')
		}

		isMounted() && stopRemoving()
	})

	const tableProps = useMemo(() => {
		return createTableData()
			.setSelectRow()

			.addColumn('fullName', {
				header: 'Name',
				isMaxWidth: true,
				render: nameFormatter,
				sort: nameSortFn,
			})

			.addColumn('integrations', {
				className: 'columns__integrations-field',
				header: 'Integrations',
				render: integrationsFormatter,
				sort: integrationsSort,
			})

			.addColumn('assignedTeams', {
				header: 'Assigned to Team(s)',
				sort: assignedTeamsSort,
				render: assignedTeamsFormatter,
				extra: isActiveOverview ? { onClick: handleTeamClick } : { isTextOnly: true },
			})

			.addColumn('id', {
				className: 'columns__menu-field',
				header: '',
				render: getMenuFormatter([
					isActiveOverview
						? { label: 'Edit integrations', onClick: handleEditIntegrationsClick }
						: null,

					isActiveOverview ? null : { label: 'Reactivate', onClick: handleReactivateSeatClick },

					(data) =>
						data.assignedTeams.length
							? { label: 'Remove team(s)', onClick: handleRemoveTeamsClick }
							: null,

					isActiveOverview ? { label: 'Suspend seat', onClick: handleSuspendSeatClick } : null,

					{ className: 'text-danger', label: 'Delete', onClick: handleDeleteClick },
				]),
			})

			.props()
	}, [filteredUsers, integrations, isActiveOverview, managedUsers])

	return (
		<div className="seats-overview">
			{header}

			{!hasManagedUsers && noSeats}

			{hasManagedUsers && (
				<Row className="grid-gutter-padding mb-4">
					<Col md={6}>{renderSearchControl()}</Col>

					{!usersSelected.isEmpty && (
						<Col className="d-flex align-items-center justify-content-end" md={6}>
							{hasUsersWithTeamsSelected && (
								<Button
									className="mr-4"
									disabled={isRemovingFromTeams}
									onClick={handleRemoveTeamsMultiClick}
									variant="dark"
								>
									Remove Teams
								</Button>
							)}

							{isActiveOverview && (
								<Button
									className="mr-4"
									disabled={isRemovingFromTeams}
									onClick={handleSuspendMultiClick}
									variant="dark"
								>
									Suspend
								</Button>
							)}

							<Button
								disabled={isRemovingFromTeams}
								onClick={handleDeleteMultiClick}
								variant="danger"
							>
								Delete
							</Button>
						</Col>
					)}
				</Row>
			)}

			{hasManagedUsers && (
				<Card>
					<MotivTable {...tableProps} />
				</Card>
			)}
		</div>
	)
}
