import { isByTeamOrUserPending } from '@motiv-shared/reducers'
import type {
	GeneralMeetingsByTeamMemberPeriod,
	GeneralMeetingsByTeamPeriod,
} from '@motiv-shared/server'
import { createSelector } from '@reduxjs/toolkit'
import type { DateRangeFilter } from '../types'
import type { WithMeetingsState } from './meetings.slice'

const state$ = <T extends WithMeetingsState>(s: T) => s.meetings

export const meetingsByTeam$ = /* @__PURE__ */ createSelector(state$, (s) => s.byTeam)
export const meetingsByTeamPending$ = /* @__PURE__ */ createSelector(state$, (s) => s.byTeamPending)
export const meetingsByUser$ = /* @__PURE__ */ createSelector(state$, (s) => s.byUser)
export const meetingsByUserPending$ = /* @__PURE__ */ createSelector(state$, (s) => s.byUserPending)

export type MeetingsByTeamPeriods = {
	readonly current: GeneralMeetingsByTeamPeriod
	readonly previous: GeneralMeetingsByTeamPeriod
}

export const createMeetingsByTeamSelector = () =>
	createSelector(
		meetingsByTeam$,
		(_: any, dateRange: DateRangeFilter, teamId: string) => ({ dateRange, teamId }),
		(meetingsByTeam, { dateRange, teamId }): Maybe<MeetingsByTeamPeriods> => {
			const teamMeetingsDataForRange = meetingsByTeam[dateRange.id + teamId]?.data

			if (!teamMeetingsDataForRange) return null

			const [current, previous] = teamMeetingsDataForRange.periods

			return { current, previous }
		},
	)

export const createIsMeetingsByTeamPendingSelector = () =>
	createSelector(
		meetingsByTeam$,
		meetingsByTeamPending$,
		(_: any, dateRange: DateRangeFilter, id: string) => ({ dateRange, id }),
		isByTeamOrUserPending,
	)

export type MeetingsByUserPeriods = {
	readonly current: GeneralMeetingsByTeamMemberPeriod
	readonly previous: GeneralMeetingsByTeamMemberPeriod
}

export const createMeetingsByUserSelector = () =>
	createSelector(
		meetingsByUser$,
		(_: any, dateRange: DateRangeFilter, userId: string) => ({ dateRange, userId }),
		(meetingsByUser, { dateRange, userId }): Maybe<MeetingsByUserPeriods> => {
			const userMeetingsDataForRange = meetingsByUser[dateRange.id + userId]?.data

			if (!userMeetingsDataForRange) return null

			const [current, previous] = userMeetingsDataForRange.periods

			return { current, previous }
		},
	)

export const createIsMeetingsByUserPendingSelector = () =>
	createSelector(
		meetingsByUser$,
		meetingsByUserPending$,
		(_: any, dateRange: DateRangeFilter, id: string) => ({ dateRange, id }),
		isByTeamOrUserPending,
	)
