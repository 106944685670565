import { pushIf } from '@eturi/util'
import { formatMinsToHrs } from '@motiv-shared/react'
import { Group } from '@visx/group'
import { Pie } from '@visx/shape'
import { Text } from '@visx/text'
import Colors from '../../styles/color-exports.module.scss'
import type { AttendanceData } from './types'

type AttendanceRatioChartProps = {
	readonly data: AttendanceData
	readonly height: number
	readonly width: number
}

export const AttendanceRatioChart = ({ data, height, width }: AttendanceRatioChartProps) => {
	const dataArr = [
		data.nonRecurring,
		...pushIf(data.isAttendanceVisible, data.adhoc),
		data.recurring,
	]

	const centerY = height / 2
	const centerX = width / 2
	const radius = Math.min(height, width) / 2
	const donutThickness = width > 400 ? 28 : width > 300 ? 22 : 16

	return (
		<div className="position-relative" style={{ overflow: 'hidden' }}>
			<svg height={height} width={width}>
				<Group top={centerY} left={centerX}>
					<Pie
						data={dataArr}
						innerRadius={radius - donutThickness}
						outerRadius={radius}
						pieSort={null}
						pieSortValues={null}
						pieValue={(d) => d.value}
					>
						{(pie) => {
							return pie.arcs.map((arc) => {
								return <path key={arc.index} d={pie.path(arc) || undefined} fill={arc.data.color} />
							})
						}}
					</Pie>

					<Text
						dy={-(centerY / 6)}
						fill={Colors.white}
						scaleToFit
						textAnchor="middle"
						verticalAnchor="middle"
						width={centerX}
					>
						Meetings Attended
					</Text>

					<Text
						dy={centerY / 10}
						fill={Colors.white}
						scaleToFit
						textAnchor="middle"
						verticalAnchor="middle"
						width={centerX}
					>
						{formatMinsToHrs(data.total)}
					</Text>
				</Group>
			</svg>
		</div>
	)
}
