export type IntegrationStatus = ValueUnion<typeof IntegrationStatuses>
export const IntegrationStatuses = {
	COMPLETE: 'COMPLETE',
	FAILED: 'FAILED',
	INCOMPLETE: 'INCOMPLETE',
	INSUFFICIENT_PERMISSIONS: 'INSUFFICIENT_PERMISSIONS',
	INVALID: 'INVALID',
	RETIRED: 'RETIRED',
	UNAUTHORIZED: 'UNAUTHORIZED',
} as const

export type IntegrationConfigStatus = ValueUnion<typeof IntegrationConfigStatuses>
export const IntegrationConfigStatuses = {
	COMPLETE: 'COMPLETE',
	INCOMPLETE: 'INCOMPLETE',
	INVALID: 'INVALID',
} as const
