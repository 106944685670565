import { notEmpty } from '@eturi/util'
import { createSelector } from '@reduxjs/toolkit'
import find from 'lodash/find'
import type { WithTeamsState } from './createTeamsSlice'

export const state$ = <T extends WithTeamsState>(s: T) => s.teams

export const teams$ = /* @__PURE__ */ createSelector(state$, (s) => s.data)
export const doTeamsExist$ = /* @__PURE__ */ createSelector(teams$, notEmpty)

export const createTeamByIdSelector = () =>
	createSelector(
		teams$,
		(_: any, teamId: Maybe<string>) => teamId,
		(teams, teamId) => find(teams, { id: teamId } as any),
	)
