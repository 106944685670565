import { useSelector } from 'react-redux'
import { teamsModal$ } from '../../reducers'
import { AddToTeamModal } from './AddToTeamModal'
import { DeleteTeamModal } from './DeleteTeamModal'
import { EditTeamInfoModal } from './EditTeamInfoModal'
import { RemoveTeamAssignmentsModal } from './RemoveTeamAssignmentsModal'
import { TeamsModals } from './teamsModals.constants'

export const TeamsModal = () => {
	const teamsModal = useSelector(teamsModal$)

	if (teamsModal == null) return null

	switch (teamsModal.type) {
		case TeamsModals.ADD_TO_TEAM:
			return <AddToTeamModal {...teamsModal} />

		case TeamsModals.DELETE_TEAM:
			return <DeleteTeamModal {...teamsModal} />

		case TeamsModals.EDIT_TEAM:
			return <EditTeamInfoModal {...teamsModal} />

		case TeamsModals.REMOVE_TEAM_ASSIGNMENTS:
			return <RemoveTeamAssignmentsModal {...teamsModal} />
	}

	return null
}
