export const DaysOfWeek = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
] as const

export type DayOfWeek = typeof DaysOfWeek[number]
export type SparseDaysOfWeek = Maybe<DayOfWeek>[]

export const convertDaysToBitmap = (days: SparseDaysOfWeek): string =>
	DaysOfWeek.map((d) => (days.includes(d) ? 1 : 0)).join('')

export const convertBitmapToDays = (bitmap: string): SparseDaysOfWeek =>
	bitmap.split('').map((v, i) => (v === '1' ? DaysOfWeek[i] : null))
