import './SuspendedSeatsOverview.scss'

import { useSelector } from 'react-redux'
import { inactiveManagedUsersDecorated$ } from '../../../compound-selectors'
import { OutsideCardHeader } from '../../../widgets/OutsideCardHeader'
import { SeatsOverview } from './SeatsOverview'

export const SuspendedSeatsOverview = () => (
	<SeatsOverview
		header={<OutsideCardHeader>Suspended Seats</OutsideCardHeader>}
		managedUsers={useSelector(inactiveManagedUsersDecorated$)}
		noSeats={<h4 className="no-suspended-seats">No suspended seats</h4>}
		type="suspended"
	/>
)
