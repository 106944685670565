import './SyncIntegration.scss'

import { useConstant, useFn } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import { hasManagedUsers$, managedUsers$, updateManagedUser } from '@motiv-shared/reducers'
import type { ManagedUser } from '@motiv-shared/server'
import { createSelector } from '@reduxjs/toolkit'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
import {
	fetchIntegrationIdentities,
	integrationIdentities$,
	integrationsDecorated$,
	isSyncComplete$,
	matchManagedUsersByIdentities,
	setSeatModal,
	setSyncUIComplete,
} from '../../reducers'
import { useAppDispatch, useSelector } from '../../store'
import type { IntegrationDecorated } from '../../types'
import { MotivModal } from '../../widgets/Modal'
import { LoadingModal } from '../../widgets/Modal/LoadingModal'
import { IntegrationMatchModal } from './IntegrationMatchModal'
import { SeatModals } from './seatModals.constants'

export type SyncIntegrationModalProps = {
	readonly integrationId: string
}

const createIntegrationByIdSelector = () =>
	createSelector(
		integrationsDecorated$,
		(_: any, newIntegrationId: string) => newIntegrationId,
		(integrations, newIntegrationId) => find(integrations, { id: newIntegrationId }),
	)

export const SyncIntegrationModal = ({ integrationId }: SyncIntegrationModalProps) => {
	const dispatch = useAppDispatch()
	const hasManagedUsers = useSelector(hasManagedUsers$)
	const integrationById$ = useConstant(createIntegrationByIdSelector)
	const integration = useSelector((s) => integrationById$(s, integrationId))

	const [isLoaded, setLoaded] = useState(false)

	const loadIntegrationIdentities = async (integrationId: Maybe<string>, provider: string) => {
		if (!integrationId) return

		sentryBreadcrumb(`Loading identities for ${provider}`)
		try {
			await dispatch(fetchIntegrationIdentities({ integrationId })).unwrap()
			setLoaded(true)
		} catch (e) {
			sentryError(e, `Failed to load identities for ${provider}`)
		}
	}

	const handleClose = useFn(() => {
		dispatch(setSeatModal(null))
	})

	// If no managed users are available don't show the modal
	useEffect(() => {
		if (!hasManagedUsers) {
			dispatch(setSeatModal(null))
		}
	}, [hasManagedUsers])

	useEffect(() => {
		if (!integration) return

		loadIntegrationIdentities(integrationId, integration.integrationProvider)
	}, [integration])

	if (!integration) return null

	if (!isLoaded) return <LoadingModal onHide={handleClose} />

	return <SyncIntegrationModalImpl integration={integration} />
}

type SyncIntegrationModalImplProps = {
	readonly integration: IntegrationDecorated
}

export const SyncIntegrationModalImpl = ({ integration }: SyncIntegrationModalImplProps) => {
	const dispatch = useAppDispatch()
	const integrationIdentities = useSelector(integrationIdentities$)
	const managedUsers = useSelector(managedUsers$)
	const isSyncUIComplete = useSelector(isSyncComplete$)

	const [integrationSyncMatches, setIntegrationSyncMatches] = useState<Maybe<number>>(null)
	const [matchedManagedUserIds, setMatchedManagedUserIds] = useState<Maybe<string[]>>(null)

	const selectedIntegrationIdentities = integration.id ? integrationIdentities[integration.id] : []

	const handleClose = useFn(() => {
		dispatch(setSeatModal(null))
	})

	const handleSyncClick = useFn(async () => {
		const matches = matchManagedUsersByIdentities(managedUsers, selectedIntegrationIdentities)

		setIntegrationSyncMatches(matches.length)

		const patchPromises = matches.map(({ user: { id: userId }, identity: { id: identityId } }) =>
			dispatch(
				updateManagedUser({ id: userId, patch: { addIntegrationIdentities: [identityId] } }),
			).unwrap(),
		)

		let updatedUsers: ManagedUser[] = []

		try {
			updatedUsers = await Promise.all(patchPromises)
		} catch (e) {
			sentryError(e, `Error syncing new integration ${integration.integrationProvider}`)
		}

		if (updatedUsers.length) {
			setMatchedManagedUserIds(updatedUsers.map((m) => m.id))
		}
	})

	useEffect(() => {
		if (isSyncUIComplete) {
			dispatch(setSyncUIComplete(false))
			if (integration.id && !isEmpty(matchedManagedUserIds)) {
				dispatch(
					setSeatModal({ type: SeatModals.CONFIRM_INTEGRATION, newIntegrationId: integration.id }),
				)
			} else {
				handleClose()
			}
		}
	}, [isSyncUIComplete, matchedManagedUserIds])

	if (!integration) return null

	if (integrationSyncMatches != null)
		return <IntegrationMatchModal integration={integration} matches={integrationSyncMatches} />

	return (
		<MotivModal
			onHide={handleClose}
			title={`Sync ${integration.shortName} With Existing Seats`}
			size="md"
		>
			<Modal.Body>
				<div className="d-flex py-4 align-content-center">
					<Image
						className="sync-integration__img"
						src={integration.iconLogoSm}
						alt={`${integration.shortName} logo`}
					/>

					<h6 className="ml-5 font-weight-bold mb-0 align-self-center">{integration.shortName}</h6>
				</div>

				<p>
					{`${integration.shortName} has been successfully added to your account. Would you like to
					automatically sync accounts from ${integration.shortName} to the people in your active seats?`}
				</p>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleSyncClick} size="lg" variant="success">
					Sync Accounts
				</Button>

				<Button variant="light-link" size="lg" onClick={handleClose}>
					Later
				</Button>
			</Modal.Footer>
		</MotivModal>
	)
}
