import createAuth0Client from '@auth0/auth0-spa-js'
import { isApiErrorType, postAuthIdentify } from '@motiv-shared/reducers'
import {
	ApiErrors,
	ClientType,
	IdentifyAction,
	isIdentifyActionRequiredRes,
	isIdentifyInviteRejectedRes,
} from '@motiv-shared/server'
import { createAsyncThunk } from '../../createAsyncThunk'
import type { Auth0User } from '../../types'
import { auth0ActionStore, LoginAction, setAuthData } from './auth.util'

/**
 * NOTE: Calling `createAuth0Client` simply instantiates the `Auth0Client` and
 *  then awaits `auth0Client.checkSession`. This call will call `getTokenSilently`,
 *  which will handle getting a new token using refresh tokens if required. It
 *  also does an early return if its cookie storage doesn't have a
 *  auth0.is.authenticated=true cookie, so that unauthenticated users don't
 *  have to incur the significant cost of making the token call when without a
 *  populated cache. Thus, if we use this, as opposed to the `Auth0Client`
 *  directly, we only have to worry about refresh tokens in the 401 case (token
 *  expires while app is running), which is what `authValidate` takes care of.
 */
const auth0ClientPromise = createAuth0Client({
	audience: process.env.MOTIV_AUTH0_AUDIENCE,
	cacheLocation: 'localstorage',
	client_id: process.env.MOTIV_AUTH0_CLIENT_ID,
	domain: process.env.MOTIV_AUTH0_DOMAIN,
	redirect_uri: `${location.origin}/login`,
	// Current configuration for refresh token expiration is 30 days. We want this
	// value to extend past the refresh token expiration so that we are not
	// prompting the user to log in when they have a valid refresh token.
	sessionCheckExpiryDays: 31,
	useRefreshTokens: true,
})

const isAuth0RedirectCallbackUrl = ({ pathname, searchParams: params }: URL) =>
	pathname === '/login' && params.has('code') && params.has('state')

/**
 * Checks the location for auth0 params, tries to handle the redirect callback,
 * clears that location by replacing the current history record, and returns
 * the auth0 user if all that works.
 */
const parseAuth0Redirect = async (): Promise<Auth0User | undefined> => {
	const auth0Client = await auth0ClientPromise

	try {
		const url = new URL(location.href)

		if (!isAuth0RedirectCallbackUrl(url)) return

		await auth0Client.handleRedirectCallback()

		// Need to clear the query params or there we'll try to parse them again
		// on refresh. Also, the path is dirty w/ all the params.
		history.replaceState({}, document.title, url.pathname)

		return auth0Client.getUser<Auth0User>()
	} catch (e) {
		console.error(e, 'Failed to parse location or handle auth redirect')
	}
}

export const authInit = createAsyncThunk('authInit', async (_: void, { dispatch, getState }) => {
	const auth0Client = await auth0ClientPromise

	try {
		// `auth0Client.isAuthenticated` is basically just `!!(await getUser())`
		//  so there's no reason for us to use it when we're just getting the
		//  user anyways.
		let user = await auth0Client.getUser<Auth0User>()

		// If we don't have a user, try to parse auth0 redirect callback
		user ||= await parseAuth0Redirect()

		// If there's still no user, we don't have auth state, and the login flow
		// will be triggered when this thunk returns and sets auth.isInit.
		if (!user) return

		// If we have a user, get the token.
		const token = await auth0Client.getTokenSilently()

		// Set auth token and user before continuing.
		dispatch(setAuthData({ token, user }))

		// Determine based on new state whether we need to do post auth
		const { auth, identity } = getState()

		if (auth.data && !identity.loggedInUserId) {
			await dispatch(doPostAuth())
		}
	} catch (e: any) {
		if (e?.error !== 'login_required') throw e
	}
})

/**
 * Triggers auth0 login via redirect, passing in the stored actions
 */
export const authLogin = createAsyncThunk('authLogin', () =>
	auth0ClientPromise.then((auth0Client) =>
		auth0Client.loginWithRedirect({
			action: auth0ActionStore.login,
			actionForInvitedUser: auth0ActionStore.invite,
		}),
	),
)

/**
 * Triggers auth0 logout, but accepts first a login action to store so that
 * when auth0 redirects, we show the correct login.
 */
export const authLogout = createAsyncThunk(
	'authLogout',
	async (loginAction: LoginAction | void) => {
		if (loginAction) auth0ActionStore.login = loginAction

		await auth0ClientPromise.then((auth0Client) =>
			auth0Client.logout({ returnTo: `${location.origin}/logout` }),
		)
	},
)

/**
 * When we hit a 401, this will try to get a new token using refresh tokens.
 * If we can't validate for some reason, trigger logout.
 */
export const authValidate = createAsyncThunk('authValidate', async (_: void, { dispatch }) => {
	const auth0Client = await auth0ClientPromise

	try {
		// NOTE: This isn't a Promise.all because `authValidate` is only called if
		//  our redux auth is initialized but we make a call that returns a 401. In
		//  this state, if we were to call `getUser`, it would probably return
		//  undefined because the `getUser` call retrieves the user from Auth0
		//  internal cache, and if we're receiving a 401, the cache entry is likely
		//  expired. It doesn't really matter anyways. The fact that `getUser` is
		//  async at all is only b/c Auth0 allows for an async cache backend to be
		//  used if desired, but in reality, this should be instant.
		// NOTE: Also we're not passing `{ ignoreCache: true }` to the getToken
		//  call because if we hit this 401, we might back up a number of requests
		//  at once and we don't want to hit Auth0 for all of these. If it turns
		//  out that, due to some bad state, the server shows us as expired but
		//  the internal cache doesn't, it's a something we can look at later.
		const token = await auth0Client.getTokenSilently()
		const user = await auth0Client.getUser<Auth0User>()

		if (!(token && user)) {
			return dispatch(authLogout())
		}

		dispatch(setAuthData({ token, user }))
	} catch (e) {
		dispatch(authLogout())
	}
})

const doPostAuth = createAsyncThunk('doPostAuth', async (_: void, { dispatch }) => {
	try {
		const res = await dispatch(postAuthIdentify({ clientType: ClientType.WEB })).unwrap()

		if (isIdentifyActionRequiredRes(res)) {
			// NOTE: We try actions first based on precedence. First we look for
			//  an ACCEPT_INVITATION, then we see if we can CREATE_ACCOUNT. If we
			//  have neither, it's an error. Future UI may allow users to make this
			//  choice, in which case we'll have the choice in state.
			const action = [IdentifyAction.ACCEPT_INVITATION, IdentifyAction.CREATE_ACCOUNT].find(
				(idAction) => res.clientActions.includes(idAction),
			)

			if (!action) {
				throw new Error('Post auth identify is missing a valid action.')
			}

			await dispatch(postAuthIdentify({ action, clientType: ClientType.WEB })).unwrap()
		} else if (isIdentifyInviteRejectedRes(res)) {
			// NOTE: This should never happen until we allow invite rejection
			//  in the UI flow. So we throw an error for now.
			throw new Error(`Invitation rejected, but this shouldn't occur.`)
		}

		// Once post-auth is finished successfully, clear any stored auth0 actions
		// as they are no longer relevant.
		auth0ActionStore.invite = auth0ActionStore.login = null
	} catch (e) {
		if (isApiErrorType(e, ApiErrors.NO_VALID_INVITATION)) {
			await dispatch(authLogout(LoginAction.SIGNUP))
		}
	}
})
