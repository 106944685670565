import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { IndicatorRegion } from './busyIndicator.constants'
import { IndicatorRegions } from './busyIndicator.constants'

export type BusyIndicatorState = { readonly [P in IndicatorRegion]?: number } & {
	readonly [IndicatorRegions.GLOBAL]: number
}

export type WithBusyIndicatorState = {
	readonly busyIndicator: BusyIndicatorState
}

const initialState: BusyIndicatorState = {
	[IndicatorRegions.GLOBAL]: 0,
}

export const busyIndicatorSlice = createSlice({
	name: 'busyIndicator',
	initialState,
	reducers: {
		incrementBusyIndicator: (
			s,
			{ payload: region = IndicatorRegions.GLOBAL }: PayloadAction<IndicatorRegion | undefined>,
		) => {
			s[region] = (s[region] || 0) + 1
		},

		decrementBusyIndicator: (
			s,
			{ payload: region = IndicatorRegions.GLOBAL }: PayloadAction<IndicatorRegion | undefined>,
		) => {
			const currentValue = s[region]

			if (!currentValue) console.warn('Decremented an empty busy indicator')

			s[region] = (currentValue || 1) - 1
		},
	},
})

export const { decrementBusyIndicator, incrementBusyIndicator } = busyIndicatorSlice.actions
