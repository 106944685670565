import type { ManagedUser, ManagedUserPatch, ManagedUserSeed } from '@motiv-shared/server'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState'
import type { HttpExtra } from '../http'
import type { WithManagedUsersState } from './createManagedUsersSlice'

const createAsyncThunk = /* @__PURE__ */ bindCreateAsyncThunkToState<WithManagedUsersState>()

export type CreateManagedUserArgs = HttpExtra & {
	readonly user: ManagedUserSeed
}

export const createManagedUser = /* @__PURE__ */ createAsyncThunk(
	'manged-user/create',
	({ user, ...extra }: CreateManagedUserArgs, { dispatch, extra: { http } }) =>
		dispatch(http.post<ManagedUser>(`managed-users`, { data: user, ...extra })),
)

type DeleteManagedUserArgs = HttpExtra & {
	readonly id: string
}

export const deleteManagedUser = /* @__PURE__ */ createAsyncThunk(
	'managed-users/delete',
	({ id, ...extra }: DeleteManagedUserArgs, { dispatch, extra: { http } }) =>
		dispatch(http.delete<ManagedUser>(`managed-users/${id}`, extra)),
)

export const fetchManagedUsers = /* @__PURE__ */ createAsyncThunk(
	'managed-users',
	(extra: HttpExtra = {}, { dispatch, extra: { http } }) =>
		dispatch(
			http.get<ManagedUser[]>(`managed-users`, {
				errorMessage: 'Unable to load team members. Please try again later.',
				...extra,
			}),
		),
)

export type UpdateManagedUserArgs = HttpExtra & {
	readonly id: string
	readonly patch: ManagedUserPatch
}

export const updateManagedUser = /* @__PURE__ */ createAsyncThunk(
	'managed-users/update',
	({ id, patch, ...extra }: UpdateManagedUserArgs, { dispatch, extra: { http } }) =>
		dispatch(http.patch<ManagedUser>(`managed-users/${id}`, { data: patch, ...extra })),
)
