import { deleteTeam } from '@motiv-shared/reducers'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice, isAnyOf } from '@reduxjs/toolkit'
import type { TeamsModalType } from '../../features/TeamsModal'

export type TeamsUIState = {
	readonly shouldResetTeamMembersTable: boolean
	readonly teamsModal: Maybe<TeamsModalType>
	readonly teamIdBeingDeleted: Maybe<string>
}

export type WithTeamsUIState = {
	readonly teamsUI: TeamsUIState
}

const initialState: TeamsUIState = {
	shouldResetTeamMembersTable: false,
	teamsModal: null,
	teamIdBeingDeleted: null,
}

const deleteTeamCompleteMatcher = /* @__PURE__ */ isAnyOf(deleteTeam.fulfilled, deleteTeam.rejected)
export const teamsUISlice = createSlice({
	name: 'teamsUI',
	initialState,
	reducers: {
		setShouldResetTeamMembersTable: (s, a: PayloadAction<boolean>) => {
			s.shouldResetTeamMembersTable = a.payload
		},

		setTeamsModal: (s, a: PayloadAction<Maybe<TeamsModalType>>) => {
			s.teamsModal = a.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(deleteTeam.pending, (s, a) => {
			s.teamIdBeingDeleted = a.meta.arg.teamId
		})

		builder.addMatcher(deleteTeamCompleteMatcher, (s) => {
			s.teamIdBeingDeleted = null
		})
	},
})

export const { setShouldResetTeamMembersTable, setTeamsModal } = teamsUISlice.actions
