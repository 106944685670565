import { isIdentifySuccessRes } from '@motiv-shared/server'
import { createSlice } from '@reduxjs/toolkit'
import { resetAction } from '../actions'
import { authIdentifyCompleteMatcher } from '../auth'

export type IdentityState = {
	readonly loggedInUserId: Maybe<string>
}

const initialState: IdentityState = {
	loggedInUserId: null,
}

export type WithIdentityState = {
	readonly identity: IdentityState
}

export const identitySlice = createSlice({
	name: 'identity',
	initialState,
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(resetAction.type, () => initialState)

			.addMatcher(authIdentifyCompleteMatcher, (s, { payload }) => {
				if (isIdentifySuccessRes(payload)) {
					s.loggedInUserId = payload.user.id
				}
			}),
})
