import { pick, setIfNotEqual } from '@eturi/util'
import type { Integration } from '@motiv-shared/server'
import reject from 'lodash/reject'
import type { CachedState } from '../createCacheSlice'
import { createCacheSlice } from '../createCacheSlice'
import type { HttpInitHandler } from '../createHttpInitHandler'
import { deleteIntegration, fetchIntegrations } from './integrations.asyncActions'

export type IntegrationsState = CachedState<Integration[]>
export type WithIntegrationsState = {
	readonly integrations: IntegrationsState
}

const initialData: Integration[] = []

export const createIntegrationsSlice = <State>(httpInitHandler: HttpInitHandler<State>) =>
	createCacheSlice('integrations', initialData, httpInitHandler, (builder) =>
		builder
			.addCase(deleteIntegration.fulfilled, (s, a) => {
				s.data = reject(s.data, { id: a.meta.arg.id })
			})

			.addCachedCase(fetchIntegrations.fulfilled, (s, a) => {
				setIfNotEqual(s, 'data', a.payload)
				s.isInit = true
			}),
	)

export const sanitizeIntegration = (i: Maybe<Integration>) =>
	i ? pick(i, ['integrationProvider', 'scope', 'status']) : null

export const sanitizeIntegrationsState = (s: IntegrationsState) => ({
	data: s.data.map(sanitizeIntegration),
})
