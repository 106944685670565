import type { UserSettingsData, UserRole, UserRoleData } from '@motiv-shared/server'
import find from 'lodash/find'
import { isInviteExpired } from './isInviteExpired'

export const inviteStatusFormatter = (settings: UserSettingsData) => {
	const isPending = !isInviteExpired(settings?.invitationTs)

	return (
		<div className="users-table-status">
			{isPending ? <span>Pending</span> : <span className="text-danger">Expired</span>}
		</div>
	)
}

export const roleFormatter = (userRole: UserRole, _: any, roleData: UserRoleData[]) =>
	find(roleData, { id: userRole })?.label || null
