import { Polling, useConstant, useFn } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import type { ByTeamOrUserArgs, DateRangeFilter } from '@motiv-shared/reducers'
import {
	createEmailsByTeamSelector,
	createIsEmailsByTeamPendingSelector,
	createIsMeetingsByTeamPendingSelector,
	createMeetingsByTeamSelector,
	fetchGeneralEmailsByTeam,
	fetchGeneralMeetingsByTeam,
	hasIntegrationData$,
	isApiErrorType,
	unwrapThunks,
} from '@motiv-shared/reducers'
import type { Team } from '@motiv-shared/server'
import { ApiErrors } from '@motiv-shared/server'
import { useEffect } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Redirect } from 'react-router-dom'
import { usePolling } from '../../hooks'
import type { HttpErrorHandler, HttpExtra } from '../../http'
import { defaultOnError } from '../../http'
import { useAppDispatch, useSelector } from '../../store'
import { LoadingFigure } from '../../widgets/BusyIndicator'
import { AttendanceBreakdown } from './AttendanceBreakdown'
import { AttendanceByOccurrence } from './AttendanceByOccurrence'
import { DashboardNoDataView } from './DashboardNoDataView'
import { DashboardTeamMembersList } from './DashboardTeamMembersList'
import { DateRangeHeader } from './DateRangeHeader'
import { EmailOverview } from './EmailOverview'
import { useDashboardState } from './hooks'
import { MeetingHours } from './MeetingHours'
import { MeetingsAndAttendance } from './MeetingsAndAttendance'
import { TeamDropdownNav } from './TeamDropdownNav'

export const DashboardTeamView = () => {
	const { dateRangeFilter, team, teamUsers } = useDashboardState()

	if (!(dateRangeFilter && team && teamUsers)) return <Redirect to="/teams" />

	return <DashboardTeamViewImpl dateRangeFilter={dateRangeFilter} team={team} />
}

type DashboardTeamViewImplProps = {
	readonly dateRangeFilter: DateRangeFilter
	readonly team: Team
}

const DashboardTeamViewImpl = ({ team, dateRangeFilter }: DashboardTeamViewImplProps) => {
	const dispatch = useAppDispatch()
	const meetingsByTeam$ = useConstant(createMeetingsByTeamSelector)
	const emailsByTeam$ = useConstant(createEmailsByTeamSelector)
	const isMeetingsByTeamPending$ = useConstant(createIsMeetingsByTeamPendingSelector)
	const isEmailsByTeamPending$ = useConstant(createIsEmailsByTeamPendingSelector)

	const hasIntegrationData = useSelector(hasIntegrationData$)
	const meetingsByTeam = useSelector((s) => meetingsByTeam$(s, dateRangeFilter, team.id))
	const emailsByTeam = useSelector((s) => emailsByTeam$(s, dateRangeFilter, team.id))
	const isMeetingsByTeamPending = useSelector((s) =>
		isMeetingsByTeamPending$(s, dateRangeFilter, team.id),
	)
	const isEmailsByTeamPending = useSelector((s) =>
		isEmailsByTeamPending$(s, dateRangeFilter, team.id),
	)

	const isLoading = isMeetingsByTeamPending || isEmailsByTeamPending

	const fetchEmailAndMeetingData = useFn(async (extra: HttpExtra = {}) => {
		sentryBreadcrumb('Fetching team email and meeting data')

		const fetchArgs: ByTeamOrUserArgs<HttpExtra> = {
			dateRange: dateRangeFilter,
			id: team.id,
			...extra,
		}

		try {
			await Promise.all(
				unwrapThunks([
					dispatch(fetchGeneralMeetingsByTeam(fetchArgs)),
					dispatch(fetchGeneralEmailsByTeam(fetchArgs)),
				]),
			)
		} catch (e) {
			sentryError(e, 'Failed to fetch team email and meeting data')
		}
	})

	const onError: HttpErrorHandler = (extra, error) => (dispatch) => {
		// Since some services don't provide email/meetings data, 331 errors are expected.
		// When we get those we don't show a toast, otherwise we display the normal toast
		if (!isApiErrorType(error, ApiErrors.DATA_SERVICE_NOT_FOUND)) {
			dispatch(defaultOnError(extra, error))
		}
	}

	usePolling([(args) => fetchEmailAndMeetingData({ ...args, onError })], Polling.VERY_LONG, false)

	useEffect(() => {
		fetchEmailAndMeetingData({ onError })
	}, [dateRangeFilter, team])

	if (!hasIntegrationData) return <DashboardNoDataView />

	return (
		<>
			<DateRangeHeader>
				<TeamDropdownNav />
			</DateRangeHeader>

			{isLoading ? (
				<LoadingFigure />
			) : (
				<Row>
					<Col className="mb-5" md={6}>
						<MeetingsAndAttendance meetings={meetingsByTeam?.current.summaryByDate} />
					</Col>

					<Col className="mb-5" md={6}>
						<AttendanceBreakdown meetings={meetingsByTeam?.current.summaryByDate} />
					</Col>

					<Col className="mb-5" md={12}>
						<AttendanceByOccurrence overview={meetingsByTeam?.current.total} />
					</Col>

					<Col className="mb-5" md={6}>
						<MeetingHours className="mb-5" summaryByUser={meetingsByTeam?.current.summaryByUser} />
						<DashboardTeamMembersList />
					</Col>

					<Col className="mb-5" md={6}>
						<EmailOverview emails={emailsByTeam} />
					</Col>
				</Row>
			)}
		</>
	)
}
