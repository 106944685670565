import { selectUnaryEvery } from '@eturi/util'
import { loggedInUserId$ } from '@motiv-shared/reducers'
import { createSelector } from '@reduxjs/toolkit'
import { isAuthenticated$, isAuthInit$ } from '../reducers'

export const canAccessProtected$ = /* @__PURE__ */ createSelector(
	isAuthenticated$,
	isAuthInit$,
	loggedInUserId$,
	selectUnaryEvery,
)
