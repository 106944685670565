import './UsersTable.scss'

import {
	activeUsersWithTeamAssignments$,
	fetchTeam,
	hasUnauthorizedIntegrations$,
	userRoleData$,
} from '@motiv-shared/reducers'
import type { MotivUser } from '@motiv-shared/server'
import { hasAccountOwnerRole } from '@motiv-shared/server'
import type { ReactNode } from 'react'
import { useMemo } from 'react'
import Card from 'react-bootstrap/Card'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useIgnoreSelectionFn } from '../../../hooks'
import { setUserModal } from '../../../reducers'
import { useAppDispatch } from '../../../store'
import { BusyIndicator, IndicatorRegions } from '../../../widgets/BusyIndicator'
import { OutsideCardHeader } from '../../../widgets/OutsideCardHeader'
import {
	createMotivTableData,
	emailFormatter,
	getMenuFormatter,
	MotivTable,
	nameFormatter,
	nameSortFn,
	userAssignedTeamsFormatter,
} from '../../../widgets/Table'
import { UserModals } from '../../UserModal'
import { roleFormatter } from './users.formatters'

export const UsersTable = ({ headerButton }: { readonly headerButton?: ReactNode }) => {
	const dispatch = useAppDispatch()
	const history = useHistory()
	const hasUnauthorizedIntegrations = useSelector(hasUnauthorizedIntegrations$)
	const userRoleData = useSelector(userRoleData$)
	const users = useSelector(activeUsersWithTeamAssignments$)

	const handleTeamClick = (teamId: string) => {
		dispatch(fetchTeam({ teamId }))
		history.push(`/teams/team/${teamId}`)
	}

	const handleEditClick = <T extends MotivUser>({ id: userId }: T) =>
		dispatch(setUserModal({ type: UserModals.EDIT_USER, userId }))

	const handleDeleteClick = <T extends MotivUser>({ id: userId }: T) =>
		dispatch(setUserModal({ type: UserModals.DELETE_USER, userId }))

	const handleRowClick = useIgnoreSelectionFn((userId: string) =>
		dispatch(setUserModal({ type: UserModals.EDIT_USER, userId })),
	)

	const tableProps = useMemo(
		() =>
			createMotivTableData({
				className: 'users-table v-middle',
				data: users,
				keyField: 'id',
				onRowClick: (_, user) => handleRowClick(user.id),
			})
				.addColumn('fullName', {
					header: 'Name',
					isMaxWidth: true,
					render: nameFormatter,
					sort: nameSortFn,
				})

				.addColumn('email', {
					header: 'Email',
					isMaxWidth: true,
					render: emailFormatter,
					sort: true,
				})

				.addColumn('roleId', {
					extra: userRoleData,
					header: 'Role',
					render: roleFormatter,
					sort: true,
				})

				.addColumn('assignedTeams', {
					extra: { disabled: hasUnauthorizedIntegrations, onClick: handleTeamClick },
					header: 'Teams Assigned',
					render: userAssignedTeamsFormatter,
				})

				.addColumn('id', {
					className: 'columns__menu-field',
					header: '',
					render: getMenuFormatter([
						{ label: 'Edit', onClick: handleEditClick },
						(data) =>
							!hasAccountOwnerRole(data)
								? {
										className: 'text-danger',
										label: 'Delete',
										onClick: handleDeleteClick,
								  }
								: null,
					]),
				})

				.props(),
		[users, hasUnauthorizedIntegrations, userRoleData],
	)

	return (
		<>
			<OutsideCardHeader>
				<span className="mr-4">Users</span> {headerButton}
			</OutsideCardHeader>

			<Card>
				<BusyIndicator region={IndicatorRegions.USERS}>
					<MotivTable {...tableProps} />
				</BusyIndicator>
			</Card>
		</>
	)
}
