import { useBoolState } from '@eturi/react'
import startCase from 'lodash/startCase'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal'
import type { ButtonVariant } from 'react-bootstrap/types'
import { MotivModal } from '../widgets/Modal'

const variants: (ButtonVariant | 'light-link')[] = [
	'primary',
	'success',
	'danger',
	'dark',
	'secondary',
	'warning',
	'info',
	'light',
	'light-link',
	'link',
]

export const DemoButtonsPage = () => {
	return (
		<>
			<SmallButtonsCard />
			<DefaultButtonsCard />
			<LargeButtonsCard />
		</>
	)
}

const createBtnsCard = (size?: 'sm' | 'lg') => {
	const sizeTxt = size === 'sm' ? 'Small' : size === 'lg' ? 'Large' : 'Default'
	const modalTitle = `${sizeTxt} Buttons Light`
	const cardTitle = `${sizeTxt} Buttons`
	const Buttons = getBtns(size)

	const btnComponent = () => {
		const [isModalVisible, showModal, hideModal] = useBoolState(false)

		return (
			<Card className="mb-5">
				<Card.Header>{cardTitle}</Card.Header>

				<Card.Body>{Buttons}</Card.Body>

				<Card.Footer>
					<Button size={size} onClick={showModal}>
						Light View Modal
					</Button>
				</Card.Footer>

				{isModalVisible && (
					<MotivModal onHide={hideModal} size="sm" title={modalTitle}>
						<Modal.Body>{Buttons}</Modal.Body>
					</MotivModal>
				)}
			</Card>
		)
	}

	btnComponent.displayName = `${sizeTxt}ButtonsCard`

	return btnComponent
}

const getBtns = (size?: 'sm' | 'lg') =>
	variants.map((v) => (
		<Button className="m-2" key={v} size={size} variant={v}>
			{startCase(v)}
		</Button>
	))

const SmallButtonsCard = createBtnsCard('sm')
const DefaultButtonsCard = createBtnsCard()
const LargeButtonsCard = createBtnsCard('lg')
