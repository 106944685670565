import './CancelModal.scss'

import { useBoolState, useFn, useMounted } from '@eturi/react'
import { sentryError } from '@eturi/sentry'
import { activeSubExpiry$, cancelSub, nextSKU$ } from '@motiv-shared/reducers'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import { activeSubExpiryStr$, addSuccessToast, setActivePurchaseModal } from '../../reducers'
import { useAppDispatch } from '../../store'
import { MotivModal } from '../../widgets/Modal'
import { PrettyDate } from '../../widgets/PrettyDate'

export const CancelSubModal = () => {
	const dispatch = useAppDispatch()
	const activeSubExpiry = useSelector(activeSubExpiry$)
	const activeSubExpiryStr = useSelector(activeSubExpiryStr$)
	const nextSku = useSelector(nextSKU$)
	const isMounted = useMounted()

	const [isSaving, startSaving, stopSaving] = useBoolState(false)

	const handleCancellation = useFn(async () => {
		if (!nextSku) return

		startSaving()

		try {
			await dispatch(cancelSub({ sku: nextSku.id }))

			dispatch(
				addSuccessToast({
					msg: `You will continue to have access until the current billing cycle ends on ${activeSubExpiryStr}.`,
					title: 'Cancellation Confirmation',
				}),
			)

			handleClose()
		} catch (e) {
			sentryError(e, 'Failed to cancel sku')
		}
		isMounted() && stopSaving()
	})

	const handleClose = useFn(() => {
		dispatch(setActivePurchaseModal(null))
	})

	return (
		<MotivModal className="cancel-modal" onHide={handleClose} size="sm" title="Cancel Subscription">
			<Modal.Body>
				<p className="mb-0">
					Would you like to cancel? Your subscription will end on{' '}
					<PrettyDate className="text-nowrap" format="MMM D, YYYY">
						{activeSubExpiry}
					</PrettyDate>
					.
				</p>
			</Modal.Body>

			<Modal.Footer>
				<Button disabled={isSaving} onClick={handleCancellation} size="lg" variant="danger">
					Cancel Subscription
				</Button>

				<Button disabled={isSaving} onClick={handleClose} size="lg" variant="light">
					Nevermind
				</Button>
			</Modal.Footer>
		</MotivModal>
	)
}
