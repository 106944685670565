import type { FieldInputProps, FormikProps } from 'formik'

export type FormikCtrlProps<T, K extends keyof T> = MOmit<FieldInputProps<T>, 'name' | 'value'> & {
	readonly isInvalid?: boolean
	readonly isValid?: boolean
	readonly name: K
	readonly value: T[K]
}

export const formikCtrlProps =
	<T>(p: FormikProps<T>) =>
	<K extends keyof T>(name: K, validate = true): FormikCtrlProps<T, K> => {
		const ctrlProps = p.getFieldProps<T[K]>(name)

		if (!validate || p.submitCount < 1) return { ...ctrlProps, name }

		const isInvalid = Boolean(p.errors[name])

		return { isInvalid, isValid: !isInvalid, ...ctrlProps, name }
	}
