import './SideNav.scss'

import cls from 'classnames'
import type { ReactNode } from 'react'
import Card from 'react-bootstrap/Card'
import { ListGroup } from '../ListGroup'
import { ListGroupLink } from '../ListGroupLink'

export type SideNavProps = {
	readonly bodyClassName?: string
	readonly className?: string
	readonly children?: ReactNode
	readonly links?: { readonly url: string; readonly label: string }[]
	readonly supplementalHeader?: ReactNode
	readonly title: string
}

export const SideNav = ({
	bodyClassName,
	className,
	children,
	links,
	supplementalHeader,
	title,
}: SideNavProps) => (
	<Card className={cls('side-nav', className)}>
		<Card.Header className="d-flex">
			<h3 className="flex-grow-1 my-auto">{title}</h3>
			{supplementalHeader}
		</Card.Header>
		{children ? (
			<Card.Body className={bodyClassName}>{children}</Card.Body>
		) : links ? (
			<ListGroup variant="flush">
				{links.map((link, idx) => (
					<ListGroupLink key={link.label + idx} exact to={link.url}>
						{link.label}
					</ListGroupLink>
				))}
			</ListGroup>
		) : null}
	</Card>
)
