import type { MotivUser, UserPermission } from '@motiv-shared/server'
import {
	hasAccountOwnerRole,
	hasAdminRole,
	hasAdminTeamLeadRole,
	hasItAdminRole,
	hasPermission,
	hasTeamLeadOnlyRole,
	hasTeamLeadRole,
	UserPermissions,
} from '@motiv-shared/server'
import { createSelector } from '@reduxjs/toolkit'
import difference from 'lodash/difference'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import some from 'lodash/some'
import { loggedInUserId$ } from '../identity'
import type { WithUsersState } from './createUsersSlice'

const state$ = <T extends WithUsersState>(s: T) => s.users

export const users$ = /* @__PURE__ */ createSelector(state$, (s) => s.data)
export const user$ = /* @__PURE__ */ createSelector(
	loggedInUserId$,
	users$,
	(loggedInUserId, users) => find(users, { id: loggedInUserId } as any),
)

export const userPermissionsData$ = /* @__PURE__ */ createSelector(
	user$,
	(u) => u?.permissions || [],
)

export const userPermissions$ = /* @__PURE__ */ createSelector(userPermissionsData$, (p) =>
	map(p, 'id'),
)

export const accountId$ = /* @__PURE__ */ createSelector(user$, (u) => u?.accountId)

export const activeUsers$ = /* @__PURE__ */ createSelector(users$, (s) =>
	s.filter((u) => u.isInviteAccepted),
)
export const inactiveUsers$ = /* @__PURE__ */ createSelector(users$, (s) =>
	s.filter((u) => !u.isInviteAccepted && u.settings?.invitationTs),
)

const findLastInvitedAdmin = (users: MotivUser[]): Maybe<MotivUser> =>
	users
		.filter(hasItAdminRole)
		.sort((u1, u2) => (u1.settings?.invitationTs || 0) - (u2.settings?.invitationTs || 0))[0]

export const latestActiveAdmin$ = /* @__PURE__ */ createSelector(activeUsers$, findLastInvitedAdmin)

export const latestInactiveAdmin$ = /* @__PURE__ */ createSelector(
	inactiveUsers$,
	findLastInvitedAdmin,
)

export const createHasPermissionsSelector = () =>
	createSelector(
		userPermissions$,
		(_: any, reqPermissions: UserPermission[]) => reqPermissions,
		(permissions, reqPermissions) => isEmpty(difference(reqPermissions, permissions)),
	)

export const canBeTeamLeadMembers$ = /* @__PURE__ */ createSelector(users$, (users) =>
	users.filter(hasPermission(UserPermissions.CAN_ASSIGN_AS_TEAM_LEAD)),
)

export const createUsersByIdsSelector = () =>
	createSelector(
		users$,
		(_: any, userIds: string[]) => userIds,
		(users, userIds) => users.filter((u) => userIds.includes(u.id)),
	)

export const isUserAccountOwner$ = /* @__PURE__ */ createSelector(user$, hasAccountOwnerRole)
export const isUserAdminOnly$ = /* @__PURE__ */ createSelector(user$, hasAdminRole)
export const isUserTeamLeadOnly$ = /* @__PURE__ */ createSelector(user$, hasTeamLeadOnlyRole)
export const isUserAdminTeamLead$ = /* @__PURE__ */ createSelector(user$, hasAdminTeamLeadRole)
export const isUserItAdmin$ = /* @__PURE__ */ createSelector(user$, hasItAdminRole)
export const isUserTeamLead$ = /* @__PURE__ */ createSelector(user$, hasTeamLeadRole)

export const hasItAdminUser$ = /* @__PURE__ */ createSelector(users$, (users) =>
	some(users, hasItAdminRole),
)

export const isSetupInstructionsEmailSent$ = /* @__PURE__ */ createSelector(user$, (u) =>
	Boolean(u?.isSetupInstructionsEmailSent),
)
