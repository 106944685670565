import { integrations$, integrationsCatalog$ } from '@motiv-shared/reducers'
import {
	IntegrationConfigStatuses,
	IntegrationStatuses,
	isIntegrationProvider,
} from '@motiv-shared/server'
import { createSelector } from '@reduxjs/toolkit'
import find from 'lodash/find'
import some from 'lodash/some'
import type { IntegrationDecorated } from '../../types'
import { AvailableIntegrations } from '../../types'
import { getIntegrationDisplayData } from './integration.util'
import type { WithIntegrationsUIState } from './integrations-ui.slice'

const state$ = <T extends WithIntegrationsUIState>(s: T) => s.integrationsUI

export const integrationsModal$ = /* @__PURE__ */ createSelector(state$, (s) => s.integrationsModal)

export const integrationsDecorated$ = /* @__PURE__ */ createSelector(
	integrations$,
	integrationsCatalog$,
	(integrations, catalog) =>
		catalog.map(({ integrationProvider, configInfo }): IntegrationDecorated => {
			const displayData = getIntegrationDisplayData(integrationProvider)
			const integration = find(integrations, { integrationProvider })
			const status = integration?.status

			return {
				...displayData,
				...integration,
				configStatus: integration?.configStatus || IntegrationConfigStatuses.COMPLETE,
				integrationProvider,
				isAvailable: AvailableIntegrations.has(integrationProvider),
				isConfigurable: !configInfo.isGeneric,
				isIntegrated: status === IntegrationStatuses.COMPLETE,
				isPreConfigurable: some(configInfo.configItems, 'preIntegration'),
				isUnauthorized: status === IntegrationStatuses.UNAUTHORIZED,
			}
		}),
)

export const currentIntegrationsDecorated$ = /* @__PURE__ */ createSelector(
	integrationsDecorated$,
	(igs) => igs.filter((ig) => ig.isIntegrated || ig.isUnauthorized),
)

export const unauthorizedIntegrationsDecorated$ = /* @__PURE__ */ createSelector(
	integrationsDecorated$,
	(igs) => igs.filter((ig) => ig.isUnauthorized),
)

export const availableIntegrationsDecorated$ = /* @__PURE__ */ createSelector(
	integrationsDecorated$,
	(igs) => igs.filter((ig) => !ig.isIntegrated && !ig.isUnauthorized),
)

export const availableKnownIntegrationsDecorated$ = /* @__PURE__ */ createSelector(
	availableIntegrationsDecorated$,
	(igs) => igs.filter((ig) => isIntegrationProvider(ig.integrationProvider)),
)
