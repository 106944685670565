import { useFn } from '@eturi/react'
import { sentryError } from '@eturi/sentry'
import { updateUser, userRoleData$ } from '@motiv-shared/reducers'
import type { MotivUser } from '@motiv-shared/server'
import isEmpty from 'lodash/isEmpty'
import type { ReactNode } from 'react'
import { useMemo } from 'react'
import Card from 'react-bootstrap/Card'
import { useSelector } from 'react-redux'
import { useIgnoreSelectionFn } from '../../../hooks'
import { addSuccessToast, setUserModal } from '../../../reducers'
import { useAppDispatch } from '../../../store'
import { BusyIndicator, IndicatorRegions } from '../../../widgets/BusyIndicator'
import { OutsideCardHeader } from '../../../widgets/OutsideCardHeader'
import {
	createMotivTableData,
	emailFormatter,
	getMenuFormatter,
	MotivTable,
	nameFormatter,
	nameSortFn,
} from '../../../widgets/Table'
import { UserModals } from '../../UserModal'
import { inviteStatusFormatter, roleFormatter } from './users.formatters'

type InvitedUsersTableProps = {
	readonly className?: string
	readonly headerButton: ReactNode
	readonly inactiveUsers: MotivUser[]
}

export const InvitedUsersTable = ({
	className,
	inactiveUsers,
	headerButton,
}: InvitedUsersTableProps) => {
	const dispatch = useAppDispatch()
	const userRoleData = useSelector(userRoleData$)

	const handleResendInviteClick = useFn(async (user: MotivUser) => {
		try {
			await dispatch(
				updateUser({
					indicatorRegion: IndicatorRegions.RESEND_INVITE,
					query: { resendInvite: true },
					userId: user.id,
					errorMessage: {
						title: 'Invite failed',
						msg: 'We encountered a problem. Please try again.',
					},
				}),
			).unwrap()

			dispatch(
				addSuccessToast({
					title: 'Invite sent',
					msg: `${user.fullName} has been invited to join your account.`,
				}),
			)
		} catch (e) {
			sentryError(e, 'Failed to resend invite.')
		}
	})

	const handleViewClick = ({ id: userId }: MotivUser) =>
		dispatch(setUserModal({ type: UserModals.VIEW_INVITED_USER, userId }))

	const handleDeleteClick = ({ id: userId }: MotivUser) =>
		dispatch(setUserModal({ type: UserModals.DELETE_USER, userId }))

	const handleRowClick = useIgnoreSelectionFn((_: any, { id: userId }: MotivUser) =>
		dispatch(setUserModal({ type: UserModals.VIEW_INVITED_USER, userId })),
	)

	const tableProps = useMemo(
		() =>
			createMotivTableData({
				className: 'v-middle',
				data: inactiveUsers,
				keyField: 'id',
				onRowClick: handleRowClick,
			})
				.addColumn('fullName', {
					header: 'Name',
					isMaxWidth: true,
					sort: nameSortFn,
					render: nameFormatter,
				})

				.addColumn('email', {
					header: 'Email',
					isMaxWidth: true,
					render: emailFormatter,
					sort: true,
				})

				.addColumn('roleId', {
					extra: userRoleData,
					header: 'Role',
					render: roleFormatter,
					sort: true,
				})

				.addColumn('settings', {
					header: 'Status',
					render: inviteStatusFormatter,
				})

				.addColumn('id', {
					className: 'columns__menu-field',
					header: '',
					render: getMenuFormatter([
						{ label: 'View', onClick: handleViewClick },
						{ label: 'Resend Invite', onClick: handleResendInviteClick },
						{ className: 'text-danger', label: 'Delete', onClick: handleDeleteClick },
					]),
				})

				.props(),
		[inactiveUsers, userRoleData],
	)

	if (isEmpty(inactiveUsers)) return null

	return (
		<div className={className}>
			<OutsideCardHeader>
				<span className="mr-4">Invited Users</span> {headerButton}
			</OutsideCardHeader>

			<Card>
				<BusyIndicator region={[IndicatorRegions.RESEND_INVITE, IndicatorRegions.USERS]}>
					<MotivTable {...tableProps} />
				</BusyIndicator>
			</Card>
		</div>
	)
}
