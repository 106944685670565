import { useFn } from '@eturi/react'
import type { MouseEvent, ReactNode } from 'react'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import MENU_ICON from '../../assets/icons/menu.svg'

type MenuWithTooltipProps = {
	readonly children: ReactNode
	readonly stopPropagation?: boolean
}

export const MenuWithTooltip = ({ children, stopPropagation = true }: MenuWithTooltipProps) => {
	const stopProp = useFn(
		(ev: MouseEvent<HTMLDivElement>) => stopPropagation && ev.stopPropagation(),
	)

	const MenuOptions = <Popover id="menu-tooltip">{children}</Popover>

	return (
		<div onClick={stopProp}>
			<OverlayTrigger trigger="focus" placement="bottom" overlay={MenuOptions} rootClose>
				{(props) => (
					<Button variant="link" className="p-0" {...props}>
						<Image
							src={MENU_ICON}
							alt="Menu Icon"
							onClick={(event) => {
								// set focus on this button when the image is clicked
								;(event.target as Node).parentElement?.focus()
							}}
						/>
					</Button>
				)}
			</OverlayTrigger>
		</div>
	)
}
