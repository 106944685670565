import './PlanDetails.scss'

import { useFn } from '@eturi/react'
import {
	activeSub$,
	isActiveSubFree$,
	isCancelledSub$,
	isTrialSubSKU$,
	nextSKU$,
} from '@motiv-shared/reducers'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useSelector } from 'react-redux'
import { setActivePurchaseModal, setSubscribingInfo } from '../../reducers'
import { useAppDispatch } from '../../store'
import { PurchaseModalType } from '../../types'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { OutsideCardHeader } from '../../widgets/OutsideCardHeader'
import { CurrentPlanDetails } from './CurrentPlanDetails'
import { UpcomingPlanDetails } from './UpcomingPlanDetails'

export const PlanDetails = ({ className }: { readonly className?: string }) => {
	const dispatch = useAppDispatch()
	const activeSub = useSelector(activeSub$)
	const isActiveSubFree = useSelector(isActiveSubFree$)
	const isCancelledSub = useSelector(isCancelledSub$)
	const isTrialSubSKU = useSelector(isTrialSubSKU$)
	const nextSku = useSelector(nextSKU$)

	const areBtnsVisible = isCancelledSub || nextSku

	const handleCancellation = useFn(() => {
		dispatch(setActivePurchaseModal(PurchaseModalType.CANCEL))
	})

	const handlePaymentUpdate = useFn(() => {
		dispatch(setActivePurchaseModal(PurchaseModalType.UPDATE_PAYMENT))
	})

	const handleResub = useFn(() => {
		const seatCount = activeSub!.quantity
		const cycle = activeSub!.period

		dispatch(setSubscribingInfo(seatCount, cycle))
		dispatch(setActivePurchaseModal(PurchaseModalType.PURCHASE))
	})

	return (
		<div className={className}>
			<OutsideCardHeader>{isTrialSubSKU ? 'Free Trial' : 'Plan Details'}</OutsideCardHeader>

			<Card>
				<Card.Body as={Row}>
					<Col xs={12} md={8}>
						<Row className="mb-5 mb-md-0">
							<Col className="mb-4 mb-sm-0" xs={12} sm={6}>
								<BusyIndicator region={IndicatorRegions.TEAM_MEMBERS}>
									<CurrentPlanDetails />
								</BusyIndicator>
							</Col>

							{nextSku && (
								<Col className="plan-details__center" xs={12} sm={6}>
									<UpcomingPlanDetails />
								</Col>
							)}
						</Row>
					</Col>

					{areBtnsVisible && (
						<Col md={4}>
							{nextSku && (
								<>
									<Button block size="lg" onClick={handlePaymentUpdate}>
										Update Payment
									</Button>

									<Button block className="mt-3" size="lg" onClick={handleCancellation}>
										Cancel Subscription
									</Button>
								</>
							)}

							{isCancelledSub && (
								<Button block size="lg" onClick={handleResub}>
									Resubscribe
								</Button>
							)}
						</Col>
					)}

					{isActiveSubFree && (
						<p className="ml-4 mt-5 mb-0">Subscribe now to start using Motiv for your team.</p>
					)}
				</Card.Body>
			</Card>
		</div>
	)
}
