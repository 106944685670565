//TODO: This should all be moved into a shared workspace to be shared with Mobile

import range from 'lodash/range'

const SCALE_Y_MAX_FIRST = 3
const SCALE_Y_MAX_LAST = 192
export const NUM_LABELS = 6

// This method recursively iterates through to find the max hours
// to be displayed on the y axis. Each cycle we double the value
// until the limit of 192 hours
export const getMaxYAxisHours = (hours: number, max: number = SCALE_Y_MAX_FIRST): number => {
	// If the hours are less than the max then we have our max
	if (hours <= max || hours >= SCALE_Y_MAX_LAST) return max

	// Each iteration me double the max, 3 > 6 > 12 > 24 etc
	return getMaxYAxisHours(hours, max * 2)
}

// Create a base list of the labels for increment multiplier mapping. Currently
// just [0, 1, 2, 3, 4, 5, 6]
const BASE_LABEL_LIST = range(0, NUM_LABELS + 1)

export const getTimeLabels = (maxMinutes: number): number[] =>
	maxMinutes > 60 ? getTimeLabelsHours(maxMinutes) : getTimeLabelsMinutes()

export const getTimeLabelsMinutes = (): number[] => BASE_LABEL_LIST.map((n) => n * 10)
export const getTimeLabelsHours = (maxMinutes: number): number[] => {
	const maxHours = getMaxYAxisHours(maxMinutes / 60)
	const increment = maxHours / NUM_LABELS

	return BASE_LABEL_LIST.map((n) => n * increment)
}
