import { useFn } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import { loggedInUserId$, updateUser } from '@motiv-shared/reducers'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import MOTIV_LOGO from '../assets/images/logo.svg'
import WELCOME_PAGE_IMG from '../assets/images/welcome-page.svg'
import { shouldShowWelcome$ } from '../compound-selectors'
import { setUserModal } from '../reducers'
import { useAppDispatch } from '../store'
import { BusyIndicator, IndicatorRegions } from '../widgets/BusyIndicator'
import { UserModals } from './UserModal'

export const Welcome = () => {
	const dispatch = useAppDispatch()
	const loggedInUserId = useSelector(loggedInUserId$)
	const shouldShowWelcome = useSelector(shouldShowWelcome$)

	const handleCurrentUserHasPermissions = useFn(async () => {
		if (!loggedInUserId) return

		sentryBreadcrumb(`'Saving user 'shouldSendSetupInstructions:true'`)

		try {
			await dispatch(
				updateUser({
					query: { shouldSendSetupInstructions: true },
					indicatorRegion: IndicatorRegions.SEND_INSTRUCTIONS,
					userId: loggedInUserId,
				}),
			).unwrap()
		} catch (e) {
			sentryError(e, `Failed to save user 'shouldSendSetupInstructions:true'`)
		}
	})

	const handleInviteAdminClick = useFn(() => {
		dispatch(setUserModal({ type: UserModals.INVITE_ADMIN }))
	})

	if (!shouldShowWelcome) return <Redirect to="/teams" />

	return (
		<div className="welcome-screen p-3 d-flex align-content-center flex-grow-1 flex-column flex-lg-row">
			<BusyIndicator region={IndicatorRegions.SEND_INSTRUCTIONS}>
				<div
					className="justify-content-between card p-4 mb-6 mb-lg-0 mr-lg-6"
					style={{ height: 'min-content' }}
				>
					<div className="mb-6">
						<Image
							alt="Motiv Logo"
							className="mb-6"
							src={MOTIV_LOGO}
							style={{ maxWidth: '65px' }}
						/>

						<h2 className="mb-6">Welcome To Motiv!</h2>

						<p>
							You’re just a few minutes away from receiving excellent team insights from anywhere!
						</p>

						<p>Next, Motiv needs to be integrated with your team’s tools.</p>

						<p>Who has the correct permissions to integrate these accounts?</p>
					</div>

					<div className="d-flex flex-column my-6">
						<Button className="mb-5" variant="success" size="lg" onClick={handleInviteAdminClick}>
							Invite someone else
						</Button>

						<Button variant={'outline-light'} size="lg" onClick={handleCurrentUserHasPermissions}>
							I have permissions
						</Button>
					</div>
				</div>

				<Image
					alt="Welcome page image"
					className="w-100 align-self-center mb-lg-7 ml-lg-7"
					src={WELCOME_PAGE_IMG}
					style={{ maxHeight: '600px', maxWidth: '670px' }}
				/>
			</BusyIndicator>
		</div>
	)
}
