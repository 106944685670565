import * as Yup from 'yup'
import type { IntegrationProvider } from '.'
import { isIntegrationProvider } from '.'

export type IntegrationIdentityId = `${IntegrationProvider}|${string}`

export const buildIntegrationIdentityId = (
	id: string,
	provider: IntegrationProvider,
): IntegrationIdentityId => `${provider}|${id}` as const

export const splitIntegrationIdentityId = (value: string): string[] => value.split(/\|(.+)/, 2)

export const isIntegrationIdentityId = (value: string): value is IntegrationIdentityId => {
	const [provider, id] = splitIntegrationIdentityId(value)
	return !!(provider && id && isIntegrationProvider(provider))
}

export const parseIntegrationIdentityId = (
	integrationIdentityId: IntegrationIdentityId | string,
): { id: string; provider: Maybe<IntegrationProvider> } => {
	const [providerStr, id] = splitIntegrationIdentityId(integrationIdentityId)
	if (isIntegrationProvider(providerStr)) {
		return { id, provider: providerStr }
	}
	return { id, provider: null }
}

export const integrationIdSchema = /* @__PURE__ */ Yup.string().test('id', isIntegrationIdentityId)
export const integrationIdListSchema = /* @__PURE__ */ Yup.array().of(integrationIdSchema)
