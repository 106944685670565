import { hasDataCompatibleIntegrations$ } from '@motiv-shared/reducers'
import Col from 'react-bootstrap/Col'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

export const DashboardNoDataView = () => {
	const hasDataCompatibleIntegrations = useSelector(hasDataCompatibleIntegrations$)

	return (
		<Col className="mt-7">
			<h2 className="text-center mb-5">No Data</h2>
			{!hasDataCompatibleIntegrations && (
				<Col className="text-center">
					<h5 className="mb-6">Your connected integrations do not provide calendar data.</h5>

					<h5 className="mb-6">Please connect another integration to use this feature.</h5>

					<Link className="btn btn-primary" to="/settings/integrations">
						Go to Settings
					</Link>
				</Col>
			)}

			{hasDataCompatibleIntegrations && (
				<h5 className="text-center">
					There is no calendar data available from your connected integrations.
				</h5>
			)}
		</Col>
	)
}
