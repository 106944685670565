import { microtask, pick } from '@eturi/util'
import type { Slice, StoreEnhancer } from '@reduxjs/toolkit'
import every from 'lodash/every'
import identity from 'lodash/identity'
import type { AsyncStorage } from './types'

export type PersistenceOnReady = () => void

export type PersistenceExtra = {
	readonly onReady: (cb: PersistenceOnReady) => void
}

export type PersistenceRehydrate<T = any> = (partialState: Partial<T>) => T

export type PersistenceConfig<State = any> = {
	readonly blacklist?: (keyof State)[]
	readonly rehydrate?: PersistenceRehydrate<State>
	readonly storage?: AsyncStorage
	readonly storeKey?: string
	readonly storePrefix?: string
	readonly whitelist?: (keyof State)[]
}

/**
 * Helper to infer State from slice to get a `PersistConfig<State>`
 */
type GetPersistConfigFromSlice<S extends Slice> = S extends Slice<infer State, any, any>
	? PersistenceConfig<State>
	: never

/**
 * Helper to get a typed NormalizedPersistenceConfig from a slice
 */
type NormalizedConfigWithSlice<S extends Slice> = S extends Slice<infer State, any, infer Name>
	? NormalizedPersistenceConfig<State, Name>
	: NormalizedPersistenceConfig

/**
 * Defines a function that takes in default storage and returns a normalized
 * persistence config.
 */
export type GetPersistConf<State = any, Name extends string = string> = (
	defaultStorage: AsyncStorage,
) => NormalizedPersistenceConfig<State, Name>

/**
 * Helper function that takes in a slice name and an optional config and
 * returns a function that takes in the default storage and returns the
 * normalized config object. This function as well as `persistState` are mainly
 * used to provide good types, since getting types from a list of configs was
 * something I found impossible.
 * @param slice
 * @param conf
 */
export const persistSlice =
	<S extends Slice>(slice: S, conf?: GetPersistConfigFromSlice<S>) =>
	(defaultStorage: AsyncStorage): NormalizedConfigWithSlice<S> =>
		normalizeConf(slice.name, defaultStorage, conf) as NormalizedConfigWithSlice<S>

/**
 * Serves the same function as `persistSlice`, as a TypeScript helper.
 * @see persistSlice
 * @param stateKey
 * @param conf
 */
export const persistState =
	<State = any, Name extends string = string>(stateKey: Name, conf?: PersistenceConfig<State>) =>
	(defaultStorage: AsyncStorage): NormalizedPersistenceConfig<State, Name> =>
		normalizeConf(stateKey, defaultStorage, conf)

const normalizeConf = <State = any, Name extends string = string>(
	stateKey: Name,
	storage: AsyncStorage,
	conf: Partial<PersistenceConfig<State>> = {},
): NormalizedPersistenceConfig<State, Name> => ({
	rehydrate: identity,
	storage: conf.storage || storage,
	storeKey: (conf.storePrefix || DEFAULT_STORE_PREFIX) + stateKey,
	stateKey,
	...conf,
})

const DEFAULT_STORE_PREFIX = '__PERSIST__'

type NormalizedPersistenceConfig<State = any, Name extends string = string> = {
	readonly stateKey: Name
} & WithRequired<PersistenceConfig<State>, 'rehydrate' | 'storage' | 'storeKey'>

export type PersistenceEnhancer = StoreEnhancer<PersistenceExtra>

/**
 * Takes in the state and a config and returns the slice after applying any
 * whitelist / blacklist filters.
 */
const getFilteredSlice = <S, K extends keyof S>(
	state: S,
	{ blacklist, whitelist }: NormalizedPersistenceConfig<S>,
) => {
	if (!(blacklist || whitelist)) return state

	let pickKeys = whitelist || (Object.keys(state) as K[])

	if (blacklist) pickKeys = pickKeys.filter((k) => !blacklist.includes(k))

	return pick(state, pickKeys)
}

const serialize = <T>(value: T) => JSON.stringify(value)
const deserialize = <T = any>(value: string): T => JSON.parse(value)

const REHYDRATE = 'persistence/rehydrate'

/**
 * Main enhancer creator takes in a list of `getConfig` functions. These are
 * the return value of `persistSlice` or `persistState`. Also takes in the
 * default storage instance.
 * @param opts
 * @param storage
 */
export const createPersistenceEnhancer = <T extends GetPersistConf>(
	opts: T[],
	storage: AsyncStorage,
): PersistenceEnhancer => {
	return (createStore) => {
		// Maps all the `getConf` options to actual config objects
		const configs = opts.map((getConf) => getConf(storage))

		// Set of `onReady` listeners
		const listeners = new Set<PersistenceOnReady>()

		// Locally track whether we've done a rehydrate so we can call `onReady`
		// listeners when ready.
		let isReady = false

		/**
		 * The main persistence handler. This takes in the old state and a
		 * `getState` function, and asynchronously gets the new state and compares
		 * it to the old state. If there are changes for any of the slices that
		 * have been configured, those slices are persisted. This is currently on a
		 * 200ms throttle to keep persistence thrashing lower.
		 *
		 * NOTE: Consider testing / tuning the throttle duration.
		 */
		const doPersist = (currentState: any, newState: any) => {
			// If the whole state is unchanged, do nothing
			if (currentState === newState) return

			// Iterate all the configs to compare slices
			configs.forEach(async (config) => {
				const stateKey = config.stateKey
				const s1Slice = currentState[stateKey]
				const s2Slice = newState[stateKey]

				// If slices are unchanged, do nothing
				if (s1Slice === s2Slice) {
					return
				}

				const s2FilteredSlice = getFilteredSlice(s2Slice, config)

				// If filtered slice isn't the same object as original slice, it means
				// we're using blacklist/whitelist to create a partial slice. If so,
				// we don't save if the picked state is the same as s1Slice values.
				// Because the redux store is immutable, all checks are strict equality.
				if (s2FilteredSlice !== s2Slice && every(s2FilteredSlice, (v, k) => v === s1Slice[k])) {
					return
				}

				try {
					await config.storage.setItem(config.storeKey, serialize(s2FilteredSlice))
				} catch (e) {
					console.error('Error persisting state', e)
				}
			})
		}

		/**
		 * Initial function to read the slices from storage. This creates a map of
		 * slice key -> stored string | null, and this map is dispatched to rehydrate.
		 */
		const readSlices = async () => {
			const sliceMap: any = {}

			await Promise.all(
				configs.map(async ({ storage, stateKey, storeKey }) => {
					let val: string | null = null

					try {
						val = await storage.getItem(storeKey)
					} catch (e) {
						console.error(e, 'Error reading storage')
					}

					sliceMap[stateKey] = val
				}),
			)

			return sliceMap
		}

		/**
		 * Main rehydrate function that is called when REHYDRATE is dispatched with
		 * the persisted slices.
		 * @param state
		 * @param slices
		 */
		const rehydrate = <S>(state: S, slices: any) => {
			if (isReady) return state

			const newState = { ...state }

			// Iterate the configs and merge the persisted slices into the new state
			// if they are found.
			configs.forEach((config) => {
				const stateKey = config.stateKey as keyof S & string
				const s = slices[stateKey] ? deserialize(slices[stateKey]) : null

				// Only rehydrate if the slice is found in persistence.
				if (!s) return

				newState[stateKey] = config.rehydrate({ ...newState[stateKey], ...s })
			})

			// Set isReady and call all onReady listeners.
			isReady = true

			microtask(() => {
				listeners.forEach((cb) => cb())
				listeners.clear()
			})

			return newState
		}

		return (reducer, preloadedState) => {
			// Our new reducer is a simple guard that catches a `REHYDRATE` action
			// and calls `rehydrate`, otherwise it just calls the original reducer
			const persistReducer = (state: any, action: any) => {
				if (action.type === REHYDRATE) return rehydrate(state, action.payload)

				return reducer(state, action)
			}

			const newStore = createStore(persistReducer, preloadedState)
			const origDispatch = newStore.dispatch

			// Our new dispatch intercepts actions to call `doPersist` w/ `oldState`
			// and the `getState` for getting new state.
			const dispatch: typeof origDispatch = (action) => {
				const currentState = newStore.getState()

				const ret = origDispatch(action)

				doPersist(currentState, newStore.getState())

				return ret
			}

			// One-time slice read
			readSlices().then((slices) => {
				dispatch({ type: REHYDRATE, payload: slices })
			})

			// New store has our new dispatch as well as an `onReady` function to
			// allow listeners to be notified when our state has been rehydrated.
			// This allows us to show a loading spinner / delay app loading until
			// the rehydrated state is ready.
			return {
				...newStore,
				dispatch,
				onReady: (cb) => {
					// Immediately call the callback if onReady handler is added after
					// we're already rehydrated.
					if (isReady) return cb()

					listeners.add(cb)
				},
			}
		}
	}
}
