import { useConstant, useFn } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import {
	assignedSeats$,
	fetchInvoices,
	isCancelledSub$,
	isMotivApiErrorBody,
	isPaidSub$,
	isTrialSubSKU$,
	nextSeatLimit$,
	purchaseInfo$,
	purchaseSKU,
} from '@motiv-shared/reducers'
import { SubscriptionPeriod } from '@motiv-shared/server'
import { createSelector } from '@reduxjs/toolkit'
import type { Token } from '@stripe/stripe-js'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'
import {
	activeSubExpiryStr$,
	addErrorToast,
	addSuccessToast,
	clearSubscribingInfo,
	setActivePurchaseModal,
	subscribingCycle$,
	subscribingSKU$,
	subscribingSKUQty$,
} from '../../reducers'
import { useAppDispatch } from '../../store'
import { getSKUPrice } from '../../types'
import { LoadingFigure } from '../../widgets/BusyIndicator'
import { MotivModal } from '../../widgets/Modal'
import type { StripeFormSubmit } from '../../widgets/Stripe'
import { StripeChoosePayment, StripeFormProvider } from '../../widgets/Stripe'

const createSubStringsSelector = () =>
	createSelector(
		activeSubExpiryStr$,
		assignedSeats$,
		isCancelledSub$,
		isPaidSub$,
		isTrialSubSKU$,
		nextSeatLimit$,
		subscribingCycle$,
		subscribingSKU$,
		subscribingSKUQty$,
		(
			activeExpiryStr,
			assignedSeats,
			isCancelled,
			isPaid,
			isTrial,
			seatLimit,
			subCycle,
			subSKU,
			subQty,
		) => {
			const seats = subSKU?.seats

			if (!(subSKU && subQty && seats)) return null

			const subscribingSeatLimit = seats * subQty
			const isMonthly = subCycle === SubscriptionPeriod.MONTHLY
			const isDowngrade = subscribingSeatLimit < seatLimit
			const price = getSKUPrice(subSKU, subCycle, subQty)

			// E.g. 'You are changing your plan from 14 seats to 10 seats. You will
			// need to remove 4 team members or we will remove the ones most recently
			// added when your new plan becomes active on Jan, 12 2022.'
			const getDowngrade = () => {
				if (!isDowngrade) return null

				let downgrade = `You are changing your plan from ${seatLimit} seats to 
				${subscribingSeatLimit} seats. `

				if (assignedSeats > subscribingSeatLimit) {
					downgrade += `You will need to remove ${assignedSeats - subscribingSeatLimit} 
					team members or we will remove the ones most recently added when your 
					new plan becomes active on ${activeExpiryStr}.`
				}

				return downgrade
			}

			// E.g. 'At the end of your free trial period, you will be billed an
			// annual subscription fee of $500. You may cancel your membership at
			// any time.'
			const description = `${isTrial ? 'At the end of your free trial period, you' : 'You'} 
			will be billed ${isMonthly ? 'a monthly' : 'an annual'} subscription fee of $${price}. 
			You may cancel your membership at any time.`

			// E.g. '10 Team Members Annually. Plan: $500 / Year'
			const tierInfoTitle = `${subscribingSeatLimit} Team Members ${
				isMonthly ? 'Monthly' : 'Annually'
			} 
			Plan: $${price} / ${isMonthly ? 'Month' : 'Year'}`

			return {
				action: isDowngrade ? 'Downgrade' : 'Save',
				description,
				downgrade: getDowngrade(),
				tierInfoTitle,
				title: isPaid || isCancelled ? 'Update Plan' : 'Subscribe Now',
			}
		},
	)

export const SubscribeModal = () => {
	const subStrings$ = useConstant(createSubStringsSelector)
	const dispatch = useAppDispatch()

	const subStrings = useSelector(subStrings$)
	const purchaseInfo = useSelector(purchaseInfo$)
	const subscribingCycle = useSelector(subscribingCycle$)
	const subscribingSku = useSelector(subscribingSKU$)
	const subscribingSKUQty = useSelector(subscribingSKUQty$)!

	const handleCancel = useFn(() => {
		dispatch(clearSubscribingInfo())
		dispatch(setActivePurchaseModal(null))
	})

	const handleSubmit: StripeFormSubmit = useFn(async (token?: Token) => {
		sentryBreadcrumb(`${token ? 'Subscribing to' : 'Upgrading'} Plan`)

		try {
			await dispatch(
				purchaseSKU({
					errorMessage: false,
					period: subscribingCycle,
					quantity: subscribingSKUQty,
					sku: subscribingSku!.id,
					token: token?.id,
				}),
			).unwrap()

			dispatch(addSuccessToast('Your plan has been updated.'))

			// We fetch invoices when we successfully update our subs
			// since that will most likely cause a new invoice to be created
			dispatch(fetchInvoices({ force: true }))

			// This will close the modal if purchase was successful
			handleCancel()
		} catch (e) {
			sentryError(e, `Failed to ${token ? 'subscribe' : 'update'}`)

			let msg
			let data

			if (isMotivApiErrorBody(e)) {
				data = e.data
				msg = data?.detailMsg
			}

			dispatch(addErrorToast(msg || 'Unable to complete purchase.'))

			return data
		}
	})

	if (!subStrings) return null

	const { action, description, downgrade, tierInfoTitle, title } = subStrings

	return (
		<StripeFormProvider onSubmit={handleSubmit}>
			{(sp) => (
				<MotivModal onHide={handleCancel} size="lg" title={title}>
					<Modal.Body>
						{downgrade && <p>{downgrade}</p>}

						<p className="h6 mb-4">{tierInfoTitle}</p>

						{sp.isReady ? <StripeChoosePayment purchaseInfo={purchaseInfo} /> : <LoadingFigure />}

						<p className="mb-0">{description}</p>
					</Modal.Body>

					{sp.isReady && (
						<Modal.Footer>
							<Button
								disabled={sp.isSaving || !sp.isValid}
								onClick={sp.submitForm}
								size="lg"
								variant={downgrade ? 'danger' : 'success'}
							>
								{action}
							</Button>

							<Button disabled={sp.isSaving} onClick={handleCancel} size="lg" variant="light">
								Cancel
							</Button>
						</Modal.Footer>
					)}
				</MotivModal>
			)}
		</StripeFormProvider>
	)
}
