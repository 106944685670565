import { useFn } from '@eturi/react'
import { floatFixed, pushIf } from '@eturi/util'
import type { DateMap, MeetingsSummary } from '@motiv-shared/server'
import { AxisBottom, AxisLeft } from '@visx/axis'
import { curveMonotoneX } from '@visx/curve'
import { localPoint } from '@visx/event'
import { GridRows } from '@visx/grid'
import { Group } from '@visx/group'
import { LegendOrdinal } from '@visx/legend'
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale'
import { Bar, LinePath } from '@visx/shape'
import { Tooltip, useTooltip } from '@visx/tooltip'
import clamp from 'lodash/clamp'
import last from 'lodash/last'
import orderBy from 'lodash/orderBy'
import type { MouseEvent, TouchEvent } from 'react'
import { useLayoutEffect, useMemo, useState } from 'react'
import Card from 'react-bootstrap/Card'
import Colors from '../../styles/color-exports.module.scss'
import { ParentSize } from '../../widgets/ParentSize'
import {
	DEFAULT_AXIS_X_PROPS,
	DEFAULT_AXIS_Y_PROPS,
	DEFAULT_GRID_STROKE_PROPS,
	DEFAULT_LEGEND_MARGIN_PROPS,
	DEFAULT_MARGIN,
	formatDateDefault,
} from './defaults'
import { GraphContainer } from './GraphContainer'
import { useDateAxisFormat } from './hooks'
import { LegendContainer } from './LegendContainer'
import type { TooltipLineProps } from './TooltipLine'
import { TooltipLine } from './TooltipLine'
import { getTimeLabelsHours, getTimeLabelsMinutes } from './utils'

type MeetingsAndAttendanceProps = {
	readonly meetings: Maybe<DateMap<MeetingsSummary>>
}

const margin = { ...DEFAULT_MARGIN, top: 48 }

export const MeetingsAndAttendance = ({ meetings }: MeetingsAndAttendanceProps) => {
	const chartData = useDatedSummaryChartData(meetings)

	const hasAttendance = useMemo(() => chartData.some((d) => d.attendedMinutes > 0), [chartData])

	const hasData = chartData.length > 0

	// TODO: Add in loading and proper "No Data" messaging/format
	if (!hasData) return <div>No Data</div>

	const header = hasAttendance ? 'Calendar Meetings & Attendance' : 'Calendar Meetings'

	return (
		<Card>
			<Card.Header as="h6">{header}</Card.Header>

			<Card.Body className="px-0 px-sm-3">
				<GraphContainer>
					<ParentSize>
						{({ height, width }) => {
							const innerWidth = width - margin.left - margin.right
							const innerHeight = height - margin.top - margin.bottom

							if (Math.min(innerHeight, innerWidth) <= 0) return null

							return (
								<MeetingsAndAttendanceChart
									chartData={chartData}
									hasAttendance={hasAttendance}
									innerHeight={innerHeight}
									innerWidth={innerWidth}
									height={height}
									width={width}
								/>
							)
						}}
					</ParentSize>
				</GraphContainer>
			</Card.Body>
		</Card>
	)
}

type DatedMeetingsSummary = MeetingsSummary & {
	readonly attendedMinutes: number
	readonly date: string
}

const SCHEDULED_COLOR = Colors.green
const ATTENDED_COLOR = Colors.orange
const LINE_STROKE_WIDTH = 2

const getDate = (d: Maybe<DatedMeetingsSummary>) => (d ? Date.parse(d.date) : 0)
const getScheduled = (d: DatedMeetingsSummary) => d.meetingMinutes
const getAttended = (d: DatedMeetingsSummary) => d.attendedMinutes

const formatTooltip = formatDateDefault

type LegendItem = 'Scheduled' | 'Attended'

type TooltipData = {
	readonly attended: number
	readonly color1: string
	readonly color2: string
	readonly scheduled: number
	readonly value: string
	readonly x: number
}

type MeetingsAndAttendanceChartProps = {
	readonly chartData: DatedMeetingsSummary[]
	readonly hasAttendance: boolean
	readonly innerHeight: number
	readonly innerWidth: number
	readonly height: number
	readonly width: number
}

const MeetingsAndAttendanceChart = ({
	chartData,
	hasAttendance,
	innerHeight,
	innerWidth,
	height,
	width,
}: MeetingsAndAttendanceChartProps) => {
	const dateAxisFormat = useDateAxisFormat()
	const { hideTooltip, showTooltip, tooltipData, tooltipOpen } = useTooltip<TooltipData>()
	const { minX, maxX, minY, maxY, xValues, yValues } = useMinMaxChartValues(chartData)

	const getMidpointDatum = useFn(() => {
		const midpointIdx = Math.floor(chartData.length / 2)
		const datum = chartData[midpointIdx]

		return datum || null
	})

	const [tooltipDate, setTooltipDate] = useState(() => getDate(getMidpointDatum()))

	const colorScale = useMemo(
		() =>
			scaleOrdinal<LegendItem>({
				domain: ['Scheduled', ...pushIf<LegendItem>(hasAttendance, 'Attended')],
				range: [Colors.green, ...pushIf(hasAttendance, Colors.orange)],
			}),
		[hasAttendance],
	)

	const xScale = useMemo(
		() =>
			scaleBand({
				domain: xValues,
				range: [0, innerWidth],
			}),
		[innerWidth, xValues],
	)

	const yScale = useMemo(
		() =>
			scaleLinear<number>({
				domain: [minY, maxY],
				range: [innerHeight, 0],
			}),
		[innerHeight, minY, maxY],
	)

	const tickStep = xScale.step()
	// Bottom Axis ticks have a buffer on the left and right side to allow space
	// for the label
	const tickPadding = tickStep / 2

	const getX = useFn((d: DatedMeetingsSummary) => xScale(getDate(d)) || 0)
	const getAttendedY = useFn((d: DatedMeetingsSummary) => yScale(getAttended(d)) || 0)
	const getScheduledY = useFn((d: DatedMeetingsSummary) => yScale(getScheduled(d)) || 0)
	const yTickFormat = (minutes: number) =>
		(maxY > 60 ? floatFixed(minutes / 60, 1) : minutes).toString()

	const handleTooltip = useFn((event: TouchEvent<SVGRectElement> | MouseEvent<SVGRectElement>) => {
		// Get index of the closest data point to the event
		const { x: rawX } = localPoint(event) || { x: 0 }
		const unboundedX = rawX - margin.left - tickPadding
		const unboundedIndex = Math.round(unboundedX / tickStep)
		const datum = chartData[clamp(unboundedIndex, 0, chartData.length - 1)]

		if (datum) setTooltipDate(getDate(datum))
	})

	const showTooltipByDate = useFn((date: number) => {
		// Find data item by date
		const datum = chartData.find((d) => getDate(d) === date)

		// If we don't have the required data for the date, try to get the midpoint
		// data item. This should always exist because the MeetingsAndAttendance
		// wrapper checks for `hasData`. But if it doesn't find it, we hide the
		// tooltip. Note that we do `setTooltipDate` and return, rather than using
		// the midpoint item to show the tooltip.
		// We do this for a number of reasons. Primarily, we have to keep the
		// `tooltipDate` state in sync, and we can't easily use `tooltipData` as
		// the sole state based on how the component is constructed. So we have to
		// set the tooltipDate regardless. We could both set the tooltip state and
		// the date, and these would be reconciled on the next render, but there's
		// no reason to, since we'll be back here again, anyway.
		// There are really a number of ways to handle tooltip state. Index doesn't
		// work, but date does. We could also try to use the tooltip data to hold
		// all the state. This is definitely tricky to do.
		if (!datum) {
			const midpointDatum = getMidpointDatum()

			return midpointDatum ? setTooltipDate(getDate(midpointDatum)) : hideTooltip()
		}

		showTooltip({
			tooltipData: {
				attended: getAttendedY(datum),
				color1: SCHEDULED_COLOR,
				color2: ATTENDED_COLOR,
				scheduled: getScheduledY(datum),
				value: formatTooltip(date),
				x: getX(datum),
			},
		})
	})

	const tooltipLineProps = useMemo((): Maybe<TooltipLineProps> => {
		if (!tooltipData) return null

		const x = tooltipData.x + tickPadding

		return {
			points: [
				{ color: tooltipData.color1, x, y: tooltipData.scheduled },
				...pushIf(hasAttendance, { color: tooltipData.color2, x, y: tooltipData.attended }),
			],
			x,
			y: innerHeight,
		}
	}, [hasAttendance, innerHeight, tickPadding, tooltipData])

	useLayoutEffect(() => {
		showTooltipByDate(tooltipDate)
	}, [chartData, height, innerHeight, innerWidth, tooltipDate, width])

	return (
		<>
			<LegendContainer>
				<LegendOrdinal
					{...DEFAULT_LEGEND_MARGIN_PROPS}
					direction="row"
					itemDirection="row-reverse"
					labelMargin={0}
					scale={colorScale}
					shape={(p) => {
						const yValue =
							tooltipData == null
								? 0
								: p.item === 'Scheduled'
								? tooltipData.scheduled
								: tooltipData.attended

						const minutes = yScale.invert(yValue)

						return (
							<span className="legend-data-value" style={{ backgroundColor: p.fill }}>
								{formatLegendDataValue(minutes)}
							</span>
						)
					}}
				/>
			</LegendContainer>

			<svg width={width} height={height}>
				<Group height={innerHeight} left={margin.left} top={margin.top} width={innerWidth}>
					<GridRows
						{...DEFAULT_GRID_STROKE_PROPS}
						height={innerHeight}
						scale={yScale}
						tickValues={yValues}
						width={innerWidth}
					/>

					<Group left={tickPadding}>
						<LinePath
							curve={curveMonotoneX}
							data={chartData}
							stroke={SCHEDULED_COLOR}
							strokeWidth={LINE_STROKE_WIDTH}
							x={getX}
							y={getScheduledY}
						/>

						{hasAttendance && (
							<LinePath
								curve={curveMonotoneX}
								data={chartData}
								stroke={ATTENDED_COLOR}
								strokeWidth={LINE_STROKE_WIDTH}
								x={getX}
								y={getAttendedY}
							/>
						)}

						<Bar
							fill="transparent"
							height={yScale(minY) - yScale(maxY)}
							onMouseMove={handleTooltip}
							onTouchStart={handleTooltip}
							rx={14}
							width={xScale(maxX)! - xScale(minX)!}
						/>
					</Group>

					<AxisBottom
						{...DEFAULT_AXIS_X_PROPS}
						scale={xScale}
						tickFormat={dateAxisFormat}
						top={innerHeight}
					/>

					<AxisLeft
						{...DEFAULT_AXIS_Y_PROPS}
						scale={yScale}
						tickFormat={yTickFormat}
						tickValues={yValues}
					/>

					{tooltipOpen && tooltipLineProps && (
						<TooltipLine key={Math.random()} {...tooltipLineProps} />
					)}
				</Group>
			</svg>

			{tooltipOpen && tooltipData && (
				<Tooltip
					style={{
						left: tooltipData.x + margin.left + tickPadding,
						top: margin.top,
					}}
				>
					{tooltipData.value}
				</Tooltip>
			)}
		</>
	)
}

const formatLegendDataValue = (minutes: number) => (minutes / 60).toFixed(2)

const sumAttended = ({
	attendedAdhocMeetingMinutes,
	attendedOneTimeMeetingMinutes,
	attendedRecurringMeetingMinutes,
}: MeetingsSummary) =>
	attendedAdhocMeetingMinutes + attendedOneTimeMeetingMinutes + attendedRecurringMeetingMinutes

const useDatedSummaryChartData = (
	meetings: Maybe<DateMap<MeetingsSummary>>,
): DatedMeetingsSummary[] =>
	useMemo(
		() =>
			orderBy(
				Object.entries(meetings || {}).map(([date, data]) => ({
					...data,
					date,
					attendedMinutes: sumAttended(data),
				})),
				'date',
				'asc',
			),
		[meetings],
	)

const useMinMaxChartValues = (chartData: DatedMeetingsSummary[]) =>
	useMemo(() => {
		const dates = chartData.map(getDate)
		const maxScheduled = Math.max(...chartData.map(getScheduled))
		const maxAttended = Math.max(...chartData.map(getAttended))
		const maxMinutes = Math.max(maxScheduled, maxAttended)

		const isHours = maxMinutes > 60

		const yValuesMinutes = isHours
			? getTimeLabelsHours(maxMinutes).map((h) => h * 60)
			: getTimeLabelsMinutes()

		return {
			maxX: Math.max(...dates),
			maxY: last(yValuesMinutes)!,
			minX: Math.min(...dates),
			minY: 0,
			xValues: dates,
			yValues: yValuesMinutes,
		}
	}, [chartData])
