import { setIfNotEqual } from '@eturi/util'
import type { MotivSubscription } from '@motiv-shared/server'
import type { CachedState } from '../createCacheSlice'
import { createCacheSlice } from '../createCacheSlice'
import type { HttpInitHandler } from '../createHttpInitHandler'
import { fetchPurchaseInfo } from './subscription.asyncActions'

export type SubscriptionsState = CachedState<MotivSubscription[]>
export type WithSubscriptionsState = {
	readonly subscriptions: SubscriptionsState
}

const initialData: MotivSubscription[] = []

export const createSubscriptionsSlice = <State>(httpInitHandler: HttpInitHandler<State>) =>
	createCacheSlice('subscriptions', initialData, httpInitHandler, (builder) =>
		builder.addCachedCase(fetchPurchaseInfo.fulfilled, (s, a) => {
			setIfNotEqual(s, 'data', a.payload)
			s.isInit = true
		}),
	)
