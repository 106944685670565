import { accountId$ } from '@motiv-shared/reducers'
import { setUser } from '@sentry/react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AuthEffects } from './AuthEffects'
import { AuthModals } from './features/AuthModals'
import { Banners } from './features/Banners/Banners'
import { PurchaseModals } from './features/PurchaseModals'
import { ToastsContainer } from './infrastructure'
import { WebAppRoutes } from './WebAppRoutes'
import { BrowserInfo } from './widgets/BrowserInfo'
import { Footer } from './widgets/Footer'
import { NavBar } from './widgets/NavBar'

export const App = () => {
	const accountId = useSelector(accountId$)

	// Set Sentry user to account id. We can make this more granular
	//  to the actual user if desired later.
	useEffect(() => {
		setUser(accountId ? { id: accountId } : null)
	}, [accountId])

	return (
		<div className="wrapper">
			<NavBar />
			<Banners />

			<main className="py-5">
				<WebAppRoutes />
			</main>

			<Footer />

			<AuthEffects />
			<AuthModals />
			<BrowserInfo />
			<PurchaseModals />
			<ToastsContainer />
		</div>
	)
}
