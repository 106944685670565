import './SyncIntegration.scss'

import { useFn, useMounted } from '@eturi/react'
import { timeoutPromise } from '@eturi/util'
import random from 'lodash/random'
import { useEffect, useRef, useState } from 'react'
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
import { setSeatModal, setSyncUIComplete } from '../../reducers'
import { useAppDispatch } from '../../store'
import type { IntegrationDecorated } from '../../types'
import { MotivModal } from '../../widgets/Modal'
import { ProgressCircle } from '../../widgets/ProgressCircle'

type IntegrationMatchModalProps = {
	readonly integration: IntegrationDecorated
	readonly matches: number
}

const MIN_TIME = 2000
const MAX_TIME = 3500

export const IntegrationMatchModal = ({
	integration,
	matches: rawMatches,
}: IntegrationMatchModalProps) => {
	const dispatch = useAppDispatch()
	const isMounted = useMounted()

	// Increase by 1 to show progress for when there is 0 matches
	const matches = rawMatches + 1

	const matchDuration = random(MIN_TIME, MAX_TIME) / matches

	const [completed, setCompleted] = useState(0)
	const completedMatchesRef = useRef<number>(0)

	const progress = (completed / matches) * 100
	const foundMatches = Math.max(completed - 1, 0)

	// Goes through each match and creates a fake promise.
	// Then it awaits it's given duration before updating progress
	const resolveMatchPromises = useFn(async () => {
		for (let i = 0; i < matches; i++) {
			await timeoutPromise(matchDuration / 2)

			if (isMounted()) {
				completedMatchesRef.current = completedMatchesRef.current + 1
				setCompleted(completedMatchesRef.current)
			}

			await timeoutPromise(matchDuration / 2)
		}

		// This is a minor pause to give the circle
		// animation time to finish and the user a
		// chance to read the matches text
		await timeoutPromise(450)

		dispatch(setSyncUIComplete(true))
	})

	useEffect(() => {
		resolveMatchPromises()
	}, [])

	const handleHide = () => {
		dispatch(setSeatModal(null))
	}

	if (!integration) return null

	return (
		<MotivModal onHide={handleHide} title={`Sync With Existing Seats`} size="md">
			<Modal.Body>
				<div className="d-flex py-4 align-content-center">
					<Image
						className="sync-integration__img"
						src={integration.iconLogoSm}
						alt={`${integration.shortName} logo`}
					/>

					<h6 className="ml-5 font-weight-bold mb-0 align-self-center">{integration.shortName}</h6>
				</div>
				<p className="text-center">Searching for matching accounts...</p>

				<div className="text-center">
					<ProgressCircle progress={progress} radius={110} stroke={10} />
				</div>

				<div className="text-center">
					<p>{`We found ${foundMatches} matching accounts.`}</p>
				</div>
			</Modal.Body>
		</MotivModal>
	)
}
