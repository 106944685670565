import { toQueryStr } from '@eturi/react'
import type {
	InvoiceData,
	MotivSubscription,
	PurchaseInfo,
	SKUId,
	SKUsGetResultDefs,
	SubscriptionPeriod,
} from '@motiv-shared/server'
import { PurchaseVendor } from '@motiv-shared/server'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState'
import type { HttpExtra } from '../http'
import { apiErrorBody } from '../http'
import type { CombinedState } from './subscription.selectors'

const createAsyncThunk = /* @__PURE__ */ bindCreateAsyncThunkToState<CombinedState>()

export const fetchSKUDefs = /* @__PURE__ */ createAsyncThunk(
	'subscriptions/fetchSKUDefs',
	(extra: HttpExtra = {}, { dispatch, extra: { http } }) =>
		dispatch(
			http.get<SKUsGetResultDefs>('purchases/skus/defs', {
				errorMessage: 'Unable to get SKU information.',
				...extra,
			}),
		),
)

export const fetchPurchaseInfo = /* @__PURE__ */ createAsyncThunk(
	'subscriptions/fetchPurchaseInfo',
	(extra: HttpExtra = {}, { dispatch, extra: { http } }) =>
		dispatch(
			http.get<MotivSubscription[]>('purchases/subscriptions', {
				errorMessage: 'Unable to get subscription information.',
				...extra,
			}),
		),
)

export const fetchPaymentSources = /* @__PURE__ */ createAsyncThunk(
	'subscriptions/fetchPaymentSources',
	(extra: HttpExtra = {}, { dispatch, extra: { http } }) =>
		dispatch(
			http.get<PurchaseInfo[]>('purchases/payment-sources', {
				errorMessage: 'Unable to get payment sources.',
				...extra,
			}),
		),
)

export type FetchInvoicesArgs = HttpExtra & {
	readonly vendor?: PurchaseVendor
}

export const fetchInvoices = /* @__PURE__ */ createAsyncThunk(
	'purchases/invoices',
	(
		{ vendor = PurchaseVendor.STRIPE, ...extra }: FetchInvoicesArgs,
		{ dispatch, extra: { http } },
	) => {
		const query = toQueryStr({ vendor })

		return dispatch(
			http.get<InvoiceData[]>(`purchases/invoices${query}`, {
				errorMessage: 'Unable to get invoices.',
				...extra,
			}),
		)
	},
)

// token will be undefined when we want to use existing payment to buy new sku
export type PurchaseSKUArgs = HttpExtra & {
	readonly period: SubscriptionPeriod
	readonly quantity: number
	readonly sku: SKUId
	readonly token: string | undefined
}

export const purchaseSKU = /* @__PURE__ */ createAsyncThunk(
	'subscriptions/purchase',
	async (
		{ period, quantity, sku, token, ...extra }: PurchaseSKUArgs,
		{ dispatch, extra: { http }, rejectWithValue },
	) => {
		// We do not retry on this call because stripe tokens are valid for one
		// attempt, thus retries will fail regardless.
		// TODO: We could probably retry this depending on the status code
		try {
			await dispatch(
				http.post<MotivSubscription | Record<string, unknown>>('purchases/subscriptions', {
					data: { period, quantity, sku, token, vendor: PurchaseVendor.STRIPE },
					errorMessage: 'Unable to complete purchase.',
					retry: { shouldRetry: false },
					...extra,
				}),
			)

			await dispatch(syncSubscriptionInfo({ force: true })).unwrap()
		} catch (e) {
			// NOTE: We rejectWithValue because createAsyncThunk runs errors through
			//  a serializer, which removes extra properties (i.e e.body), so the
			//  server error body was being lost at this point, rejectWithValue lets
			//  us get around the serializer
			return rejectWithValue(apiErrorBody(e))
		}
	},
)

export type UpdatePaymentArgs = HttpExtra & {
	readonly token: string
}

export const updatePayment = /* @__PURE__ */ createAsyncThunk<void, UpdatePaymentArgs>(
	'subscriptions/update-payment',
	async ({ token, ...extra }, { dispatch, extra: { http }, rejectWithValue }) => {
		// We do not retry on this call because stripe tokens are valid for one attempt,
		// thus retries will fail regardless
		try {
			await dispatch(
				http.post('purchases/subscriptions', {
					data: { token, vendor: PurchaseVendor.STRIPE },
					errorMessage: 'Unable to complete purchase.',
					retry: { shouldRetry: false },
					...extra,
				}),
			)

			await dispatch(syncSubscriptionInfo({ force: true })).unwrap()
		} catch (e) {
			return rejectWithValue(apiErrorBody(e))
		}
	},
)

type CancelSubArgs = HttpExtra & {
	readonly sku: SKUId
	readonly vendor?: PurchaseVendor
}

export const cancelSub = /* @__PURE__ */ createAsyncThunk(
	'subscriptions/cancel',
	async (
		{ sku, vendor = PurchaseVendor.STRIPE, ...extra }: CancelSubArgs,
		{ dispatch, extra: { http } },
	) => {
		const query = toQueryStr({ sku, vendor })

		await dispatch(
			http.delete(`purchases/subscriptions/${query}`, {
				...extra,
			}),
		)

		await dispatch(syncSubscriptionInfo({ force: true }))
	},
)

export const syncSubscriptionInfo = /* @__PURE__ */ createAsyncThunk(
	'subscriptions/sync',
	async (extra: HttpExtra = {}, { dispatch }) => {
		await Promise.all([dispatch(fetchPurchaseInfo(extra)), dispatch(fetchPaymentSources(extra))])
	},
)
