import { emailsByTeam$, meetingsByTeam$ } from '@motiv-shared/reducers'
import { createSelector } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'
import some from 'lodash/some'

export const hasIntegrationData$ = /*@__PURE__*/ createSelector(
	emailsByTeam$,
	meetingsByTeam$,
	(emailData, meetingsData) => {
		// Go through each team's email/meetings data and see if
		// at least one team has some period of data
		return (
			some(emailData, (teamEmailData) =>
				some(teamEmailData.data.periods, (periodData) => !isEmpty(periodData.summaryByDate)),
			) ||
			some(meetingsData, (teamMeetingData) =>
				some(teamMeetingData.data.periods, (periodData) => !isEmpty(periodData.summaryByDate)),
			)
		)
	},
)
