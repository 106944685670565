/**
 * API server used error HTTP status codes
 */
export const ApiErrorStatusCodes = {
	BadRequest: 400,
	NotAuthenticated: 401,
	PaymentError: 402,
	Forbidden: 403,
	NotFound: 404,
	MethodNotAllowed: 405,
	NotAcceptable: 406,
	Timeout: 408,
	Conflict: 409,
	LengthRequired: 411,
	Unprocessable: 422,
	TooManyRequests: 429,
	GeneralError: 500,
	NotImplemented: 501,
	BadGateway: 502,
	Unavailable: 503,
} as const

/**
 * Mirrors FeathersErrorJSON but is more specific for the Motiv API.
 */
export type MotivApiErrorBody<T = unknown> = {
	readonly name: string // Error class name, e.g. BadRequest
	readonly message: string // A somewhat generic error specific message
	readonly code: number // The HTTP status code
	readonly className: string // Internal error class name, e.g. bad-request
	readonly errors?: any // Unused
} & (T extends ApiError
	? { readonly data: MotivApiErrorData<T> }
	: { readonly data?: MotivApiErrorData })

/**
 * The object nested in the JSON error under data.
 */
export type MotivApiErrorData<T = unknown> = {
	readonly [key: string]: any
	readonly detailMsg: T extends ApiError ? T[1] : string
	readonly errorCode: T extends ApiError ? T[0] : number
	readonly origin?: string
}

/**
 * Server known detailed error codes, for current and past server versions.
 * An error code may be raised in different conditions.
 */
export type ApiError = ValueUnion<typeof ApiErrors>
export const ApiErrors = {
	// 1xx: Internal
	UNKNOWN: [111, 'Unknown error'],

	// 2xx: API request processing (authentication, hooks, users, invites)
	UNAUTHENTICATED: [200, 'Identity and user is required'],
	INVALID_IDENTITY: [201, 'Identity attributes are invalid'],
	USER_NOT_ACTIVE: [202, 'User is not active'],
	NOT_ALLOWED: [205, 'User has insufficient permissions'],
	EMAIL_IN_USE: [211, 'A user with that email already exists'],
	INVITATION_ACCEPT_REQUIRES_WEB: [231, 'Cannot accept invitation on non-Web client'],
	NO_VALID_INVITATION: [232, 'No valid invitation found'],
	INVITATION_PENDING: [233, 'An invitation is pending'],

	// 3xx: Not found
	NOT_FOUND: [301, 'Object could not be found'],
	USER_NOT_FOUND: [310, 'User could not be found'],
	ACCOUNT_NOT_FOUND: [311, 'Account could not be found'],
	ACCOUNT_OWNER_NOT_FOUND: [312, 'Account owner could not be found'],
	TEAM_NOT_FOUND: [315, 'Team could not be found'],
	MANAGED_USER_NOT_FOUND: [320, 'Managed user could not be found'],
	INTEGRATION_NOT_FOUND: [330, 'Integration could not be found'],
	DATA_SERVICE_NOT_FOUND: [331, 'Integration data service could not be found'],

	// 4xx: API params and validations
	INVALID_PARAMETERS: [400, 'Invalid parameters'],
	MISSING_PARAMETERS: [401, 'Missing parameters'],
	INVALID_ID: [402, 'Invalid ID'],
	INVALID_ROLE: [410, 'Invalid value for roleId'],
	INVALID_TIME_ZONE: [411, 'Invalid value for timeZone'],
	INVALID_INTEGRATION_PROVIDER: [430, 'Invalid integration provider'],
	INVALID_INTEGRATION_IDENTITY: [431, 'Invalid integration identity'],
	INVALID_INTEGRATION_DATA_TYPE: [435, 'Invalid integration data type'],

	// 5xx: API service level errors
	CURRENT_USER_ONLY: [510, 'Must be called by current user'],
	CANNOT_ACCESS_ACCOUNT: [511, 'Cannot access a different account'],
	CANNOT_CREATE_OWNER_USER: [520, 'Cannot create user with ACCOUNT_OWNER role'],
	USER_HAS_INVITATION: [521, 'User with email has an open invitation'],
	CANNOT_CHANGE_PASSWORD_FOR_USER: [522, 'Cannot change password for a different user'],
	CANNOT_CHANGE_EMAIL_FOR_USER: [523, 'Cannot change email for a different user'],
	ONLY_OWNER_CAN_TRANSFER: [524, 'Only an account owner can transfer the account'],
	CANNOT_SET_OWNER_ROLE: [525, 'Cannot set ACCOUNT_OWNER role without account transfer'],
	CANNOT_REMOVE_OWNER_ROLE: [526, 'Cannot remove ACCOUNT_OWNER role without account transfer'],
	EXCEEDED_LIMIT_TEAMS: [527, 'Exceeded the limit for the number of teams.'],
	INTEGRATION_INVALID_AUTH: [528, 'Integration is unauthorized.'],
	EXCEEDED_LIMIT_TEAM_MEMBERS: [529, 'Exceeded the limit for the number of team members.'],
	EXCEEDED_LIMIT_SEATS: [530, 'Exceeded the limit for the number of seats.'],
	INVALID_STATE_CHANGE: [531, 'Cannot change the state.'],
	USER_IS_ACTIVE: [532, 'User is active.'],
	EXCEEDED_LIMIT: [533, 'Exceeded the limit for the resource.'],
	MANAGED_USER_NOT_ACTIVE: [535, 'Managed user is not active'],
	INTEGRATION_IDENTITY_IN_USE: [540, 'Integration identity already in use'],
	INTEGRATION_CONFIG_INVALID: [541, 'Integration config is invalid'],

	// 6xx: Purchase errors
	SKU_INVALID: [600, 'SKU invalid'],
	SKU_NOT_PURCHASABLE: [601, 'SKU cannot be purchased'],
	SKU_IS_OWNED: [602, 'SKU and quantity already owned'],
	SKU_MIN_QUANTITY_REQUIRED: [611, 'Cannot purchase less than minimum quantity'],
	SKU_MAX_QUANTITY_EXCEEDED: [612, 'Cannot purchase more than maximum quantity'],
	SKU_QUANTITY_CHANGE_FORBIDDEN: [613, 'Cannot change quantity'],
	SKU_UNPAID: [614, 'Cannot change SKU because it is unpaid'],

	INVALID_VENDOR: [620, 'Vendor not accepted'],
	PURCHASE_NOT_FOUND: [621, 'No purchase exists for vendor'],
	CUSTOMER_NOT_FOUND: [622, 'Customer could not be found'],
	INVOICE_NOT_FOUND: [625, 'Invoice could not be found'],
	CARD_ERROR: [630, 'Purchase card error'],

	RATE_LIMIT_EXCEEDED: [701, 'Rate limit exceeded'],

	// 9xx: Externally caused errors, visible to clients
	SERVER_ERROR: [900, 'Internal server error'],
	DATABASE_ERROR: [911, 'Server database error'],
	NETWORK_ERROR: [912, 'Server network error'],
} as const
