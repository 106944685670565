import { useFn } from '@eturi/react'
import { sentryError } from '@eturi/sentry'
import cls from 'classnames'
import { useField, useFormikContext } from 'formik'
import moment from 'moment-timezone'
import { useRef } from 'react'
import AsyncSelect from 'react-select/async'
import { loadTimezones } from '../../util/timezones'
import type { TzOption } from './TzOption'
import { createTzOption } from './TzOption'

type TimezoneSelectProps = {
	readonly name: string
}

/**
 * NOTE: This is pretty flawed right now. First, the performance of
 *  react-select when there is a large list of options is pretty bad. There
 *  are a couple of ways we can mitigate or fix this:
 *  1. Implement something like react-virtualized for the MenuList component.
 *  There is prior art on this in the react-select-virtualized package. It's
 *  not a well-written package (much like react-select itself), but it shows
 *  how this is done.
 *  2. We could store both the full list of options and an abbreviated list
 *  that includes just the guess and US timezones by default. This will make
 *  the list small by default. Then if the user searches we can return the
 *  filtered list based on the query of the full list.
 *  -
 *  In addition to performance issues, there's the issue of loading the list if
 *  there's an error. As is, if `fetchTzList` fails, we return an empty list of
 *  values, and `loadTzOptions` never gets called again until the user
 *  searches. To make this solution robust, we should have some logic to retry
 *  fetching of the timezone list.
 */
export const TimezoneSelect = ({ name }: TimezoneSelectProps) => {
	const tzListRef = useRef<TzOption[] | null>(null)
	const shortTzListRef = useRef<TzOption[] | null>(null)
	const [field, meta, helpers] = useField<TzOption>(name)
	const form = useFormikContext()

	const fetchTzList = async () => {
		try {
			await loadTimezones()

			// Create the tz list. We use Set to create a unique list, starting w/ the
			// guess, then some common US timezones, and finally the rest. Then map it
			// all to options.
			const shortList = [
				moment.tz.guess(),
				'US/Alaska',
				'US/Arizona',
				'US/Central',
				'US/Eastern',
				'US/Hawaii',
				'US/Mountain',
				'US/Pacific',
			]

			const fullList = moment.tz.names()

			shortTzListRef.current = shortList.map(createTzOption)
			tzListRef.current = [...new Set([...shortList, ...fullList])].map(createTzOption)
		} catch (e) {
			sentryError(e, 'Failed to load timezones')
		}
	}

	const loadTzOptions = useFn(async (query: string): Promise<TzOption[]> => {
		// If the user hasn't typed anything, we show the short list. Otherwise,
		// we show the first 25 results based on their input.
		const getTzList = (query: string): Maybe<TzOption[]> => {
			if (!query) return shortTzListRef.current

			const tzList = tzListRef.current

			if (!tzList) return

			const qLower = query.toLowerCase()

			// 1. Filter based on query.
			// 2. Sort so that options that start with query are first
			// 3. Return the first 25 results.
			return tzList
				.filter((tz) => tz.label.toLowerCase().includes(qLower))
				.sort((a, b) =>
					a.label.toLowerCase().startsWith(qLower)
						? 0
						: b.label.toLowerCase().startsWith(qLower)
						? 1
						: 0,
				)
				.slice(0, 25)
		}

		let tzList = getTzList(query)

		if (!tzList) {
			await fetchTzList()

			tzList = getTzList(query)
		}

		return tzList || []
	})

	const isInvalid = Boolean(form.submitCount > 0 && meta.error)
	const isValid = Boolean(form.submitCount > 0 && !meta.error)
	const onChange = useFn((v: any) => helpers.setValue(v, true))

	// NOTE: This POS won't re-render if we clear the value (e.g. form reset) so
	//  we have to give it a key based on the value so it's forced to re-render.
	return (
		<AsyncSelect<TzOption>
			key={`crap-select-${field.value?.value}`}
			className={cls('motiv-select', isInvalid && 'is-invalid', isValid && 'is-valid')}
			classNamePrefix="motiv-select"
			defaultOptions
			isSearchable
			loadOptions={loadTzOptions}
			placeholder="Find your time zone"
			{...field}
			onChange={onChange}
		/>
	)
}
