import { toQueryStr } from '@eturi/react'
import type { GeneralMeetingsByTeam, GeneralMeetingsByTeamMember } from '@motiv-shared/server'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState'
import type { ByTeamOrUserArgs } from '../util'
import { byTeamOrUserCond, getDateRangeDays } from '../util'
import type { WithMeetingsState } from './meetings.slice'

const createAsyncThunk = /*@__PURE__*/ bindCreateAsyncThunkToState<WithMeetingsState>()

export const fetchGeneralMeetingsByTeam = /* @__PURE__ */ createAsyncThunk(
	'integration-data/general_meeting_team',
	({ dateRange, id, ...extra }: ByTeamOrUserArgs, { dispatch, extra: { http } }) => {
		const queryStr = toQueryStr({ teamId: id, periodDays: getDateRangeDays(dateRange) })

		return dispatch(
			http.get<GeneralMeetingsByTeam>(`integration-data/general_meeting_team${queryStr}`, {
				errorMessage: 'Unable to load teams meeting data. Please try again later.',
				...extra,
			}),
		)
	},
	{ condition: /*@__PURE__*/ byTeamOrUserCond((s) => s.meetings.byTeam) },
)

export const fetchGeneralMeetingsByTeamMember = /* @__PURE__ */ createAsyncThunk(
	'integration-data/general_meeting_team_member',
	({ dateRange, id, ...extra }: ByTeamOrUserArgs, { dispatch, extra: { http } }) => {
		const queryStr = toQueryStr({ managedUserId: id, periodDays: getDateRangeDays(dateRange) })

		return dispatch(
			http.get<GeneralMeetingsByTeamMember>(
				`integration-data/general_meeting_team_member${queryStr}`,
				{
					errorMessage: 'Unable to load team member meeting data. Please try again later.',
					...extra,
				},
			),
		)
	},
	{ condition: /*@__PURE__*/ byTeamOrUserCond((s) => s.meetings.byUser) },
)
