import { setIfNotEqual } from '@eturi/util'
import type { InvoiceData } from '@motiv-shared/server'
import type { CachedState } from '../createCacheSlice'
import { createCacheSlice } from '../createCacheSlice'
import type { HttpInitHandler } from '../createHttpInitHandler'
import { fetchInvoices } from './subscription.asyncActions'

export type InvoicesState = CachedState<InvoiceData[]>
export type WithInvoicesState = {
	readonly invoices: InvoicesState
}

const initialData: InvoiceData[] = []

export const createInvoicesSlice = <State>(httpInitHandler: HttpInitHandler<State>) =>
	createCacheSlice('invoices', initialData, httpInitHandler, (builder) =>
		builder.addCachedCase(fetchInvoices.fulfilled, (s, { payload }) => {
			setIfNotEqual(s, 'data', payload)
			s.isInit = true
		}),
	)

export const sanitizeInvoicesState = (s: InvoicesState) => ({
	...s,
	invoices: [],
})
