import { safeAssertUnreachable } from '@eturi/util'
import type { IntegrationProvider } from '@motiv-shared/server'
import { integrationName, IntegrationProviders, integrationShortName } from '@motiv-shared/server'
import GitHubIcon from '../../assets/logos/github-icon-logo.svg'
import GitLabIcon from '../../assets/logos/gitlab-icon-rgb.svg'
// FIXME: There should NOT be any PNGs in use here!
import GoogleIconLogoSm from '../../assets/logos/google-icon-logo-sm.png'
import GoogleIconLogo from '../../assets/logos/google-icon-logo.svg'
import MicrosoftLogo from '../../assets/logos/microsoft-icon-logo-sm.svg'
import OfficeIconLogo from '../../assets/logos/office-icon-logo.svg'
import SlackIconLogoSm from '../../assets/logos/slack-icon-logo-sm.png'
import SlackIconLogo from '../../assets/logos/slack-icon-logo.svg'
import ZoomIconLogoSm from '../../assets/logos/zoom-icon-logo-sm.png'
import ZoomIconLogo from '../../assets/logos/zoom-icon-logo.svg'
import type { IntegrationDisplayData } from '../../types'

export const getIntegrationDisplayData = (
	provider: IntegrationProvider,
): IntegrationDisplayData => {
	const displayName = integrationName(provider)
	const shortName = integrationShortName(provider)

	switch (provider) {
		// TODO add this
		case IntegrationProviders.GITHUB:
			return {
				btnClass: 'btn-github',
				displayName,
				iconLogo: GitHubIcon,
				iconLogoSm: GitHubIcon,
				shortName,
			}

		case IntegrationProviders.GITLAB:
			return {
				btnClass: 'btn-gitlab',
				displayName,
				iconLogo: GitLabIcon,
				iconLogoSm: GitLabIcon,
				shortName,
			}

		case IntegrationProviders.GOOGLE:
			return {
				btnClass: 'btn-google',
				displayName,
				iconLogo: GoogleIconLogo,
				iconLogoSm: GoogleIconLogoSm,
				shortName,
			}

		case IntegrationProviders.MICROSOFT:
			return {
				btnClass: 'btn-microsoft',
				displayName,
				iconLogo: OfficeIconLogo,
				iconLogoSm: MicrosoftLogo,
				shortName,
			}

		case IntegrationProviders.SLACK:
			return {
				btnClass: 'btn-slack',
				displayName,
				iconLogo: SlackIconLogo,
				iconLogoSm: SlackIconLogoSm,
				shortName,
			}

		case IntegrationProviders.ZOOM:
			return {
				btnClass: 'btn-zoom',
				displayName,
				iconLogo: ZoomIconLogo,
				iconLogoSm: ZoomIconLogoSm,
				shortName,
			}
	}

	// TODO: Have product/UX design stand-in logos for when the integration is unknown
	return safeAssertUnreachable(provider, `Unknown provider ${provider}`, {
		btnClass: '', // doesn't matter because an unknown integration won't show in TeamMemberCard
		displayName,
		iconLogo: '',
		iconLogoSm: '',
		shortName,
	})
}
