import { useSelector } from 'react-redux'
import { integrationsModal$ } from '../../reducers'
import { DeleteIntegrationModal } from '../TeamsModal/DeleteIntegrationModal'
import { IntegrationConfigModal } from './IntegrationConfigModal'
import { IntegrationsModals } from './integrationsModals.constants'
import { IntegrationUrlModal } from './IntegrationUrlModal'

export const IntegrationsModal = () => {
	const integrationsModal = useSelector(integrationsModal$)

	if (integrationsModal == null) return null

	switch (integrationsModal.type) {
		case IntegrationsModals.DELETE_INTEGRATION:
			return <DeleteIntegrationModal {...integrationsModal} />

		case IntegrationsModals.PRE_INTEGRATION_URL:
			return <IntegrationUrlModal {...integrationsModal} />

		case IntegrationsModals.POST_INTEGRATION_CONFIG:
			return <IntegrationConfigModal {...integrationsModal} />
	}

	return null
}
