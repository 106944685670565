import { useBoolState, useConstant, useFn } from '@eturi/react'
import type { FormikProps } from 'formik'
import { Form as FormikForm, Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import type { Ref } from 'react'
import { forwardRef, useMemo, useRef } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'
import type { FormikSubmit } from '../types'
import { setFwdRef } from '../util'
import { formikMultiSelectProps } from '../util/formikMultiSelectProps'
import { FormValidationText } from '../widgets/FormValidationText'
import { MotivModal } from '../widgets/Modal'
import type { MultiSelectOption } from '../widgets/SelectDropdown'
import { MultiSelect } from '../widgets/SelectDropdown'

type DemoMultiSelectData = {
	readonly colors: MultiSelectOption[]
	readonly states: MultiSelectOption[]
}

type DemoColor = {
	readonly hex: string
	readonly name: string
}

type DemoFormRef = MPick<FormikProps<DemoMultiSelectData>, 'isValid' | 'resetForm' | 'submitForm'>

const DEFAULT_FORM_REF: DemoFormRef = {
	isValid: false,
	resetForm: noop,
	submitForm: () => Promise.resolve(),
}

const DemoMultiSelectForm = forwardRef((_, ref: Ref<DemoFormRef>) => {
	const validationSchema = useConstant(() =>
		Yup.object().shape({
			colors: Yup.array()
				.min(2, 'Please choose at lease 2 colors')
				.max(8, 'Max of 8 colors!')
				.default([]),
			states: Yup.array()
				.min(3, 'Please choose your favorite 3 states')
				.max(3, 'You can only choose 3!')
				.default([]),
		}),
	)

	const INITIAL_VALUES = useConstant((): DemoMultiSelectData => validationSchema.cast({}))

	const demoColors = useConstant(() => [
		{ name: 'Blue', hex: '#1CBBFB' },
		{ name: 'Cyan', hex: '#07F0E7' },
		{ name: 'Green', hex: '#38CF82' },
		{ name: 'Indigo**', hex: '#6610F2' },
		{ name: 'Orange**', hex: '#FD7E14' },
		{ name: 'Pink', hex: '#EF31AD' },
		{ name: 'Purple', hex: '#A460F7' },
		{ name: 'Red', hex: '#FF497E' },
		{ name: 'Teal**', hex: '#20C997' },
		{ name: 'Yellow', hex: '#FACF29' },
	])

	const demoStates = useConstant(() => [
		{ value: 'AL', label: 'Alabama' },
		{ value: 'AK', label: 'Alaska' },
		{ value: 'AS', label: 'American Samoa' },
		{ value: 'AZ', label: 'Arizona' },
		{ value: 'AR', label: 'Arkansas' },
		{ value: 'CA', label: 'California' },
		{ value: 'CO', label: 'Colorado' },
		{ value: 'CT', label: 'Connecticut' },
		{ value: 'DE', label: 'Delaware' },
		{ value: 'DC', label: 'District Of Columbia' },
		{ value: 'FM', label: 'Federated States Of Micronesia' },
		{ value: 'FL', label: 'Florida' },
		{ value: 'GA', label: 'Georgia' },
		{ value: 'GU', label: 'Guam' },
		{ value: 'HI', label: 'Hawaii' },
		{ value: 'ID', label: 'Idaho' },
		{ value: 'IL', label: 'Illinois' },
		{ value: 'IN', label: 'Indiana' },
		{ value: 'IA', label: 'Iowa' },
		{ value: 'KS', label: 'Kansas' },
		{ value: 'KY', label: 'Kentucky' },
		{ value: 'LA', label: 'Louisiana' },
		{ value: 'ME', label: 'Maine' },
		{ value: 'MH', label: 'Marshall Islands' },
		{ value: 'MD', label: 'Maryland' },
		{ value: 'MA', label: 'Massachusetts' },
		{ value: 'MI', label: 'Michigan' },
		{ value: 'MN', label: 'Minnesota' },
		{ value: 'MS', label: 'Mississippi' },
		{ value: 'MO', label: 'Missouri' },
		{ value: 'MT', label: 'Montana' },
		{ value: 'NE', label: 'Nebraska' },
		{ value: 'NV', label: 'Nevada' },
		{ value: 'NH', label: 'New Hampshire' },
		{ value: 'NJ', label: 'New Jersey' },
		{ value: 'NM', label: 'New Mexico' },
		{ value: 'NY', label: 'New York' },
		{ value: 'NC', label: 'North Carolina' },
		{ value: 'ND', label: 'North Dakota' },
		{ value: 'MP', label: 'Northern Mariana Islands' },
		{ value: 'OH', label: 'Ohio' },
		{ value: 'OK', label: 'Oklahoma' },
		{ value: 'OR', label: 'Oregon' },
		{ value: 'PW', label: 'Palau' },
		{ value: 'PA', label: 'Pennsylvania' },
		{ value: 'PR', label: 'Puerto Rico' },
		{ value: 'RI', label: 'Rhode Island' },
		{ value: 'SC', label: 'South Carolina' },
		{ value: 'SD', label: 'South Dakota' },
		{ value: 'TN', label: 'Tennessee' },
		{ value: 'TX', label: 'Texas' },
		{ value: 'UT', label: 'Utah' },
		{ value: 'VT', label: 'Vermont' },
		{ value: 'VI', label: 'Virgin Islands' },
		{ value: 'VA', label: 'Virginia' },
		{ value: 'WA', label: 'Washington' },
		{ value: 'WV', label: 'West Virginia' },
		{ value: 'WI', label: 'Wisconsin' },
		{ value: 'WY', label: 'Wyoming' },
	])

	// NOTE: I'm aware memo will never change here. It's just for illustration
	//  of the pattern of mapping data
	const colorOpts = useMemo(
		() =>
			demoColors.map((d) => ({
				label: d.name,
				value: d.hex,
			})),
		[demoColors],
	)

	const mapOptionsToColors = (opts: MultiSelectOption[]) =>
		opts.map((opt) => ({
			hex: opt.value,
			name: demoColors.find((c) => c.hex === opt.value)!.name,
		}))

	const mapOptionsToStatesValues = (opts: MultiSelectOption[]) =>
		opts.map((opt) => opt.value).sort()

	const handleSubmit: FormikSubmit<DemoMultiSelectData> = useFn((v, helpers) => {
		console.log(v)
		alert(`${JSON.stringify(v.colors)}\n\n${JSON.stringify(v.states)}`)
		helpers.resetForm()
	})

	return (
		<Formik<DemoMultiSelectData>
			initialValues={INITIAL_VALUES}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
		>
			{(p) => {
				setFwdRef(ref, {
					isValid: p.isValid,
					resetForm: p.resetForm,
					submitForm: p.submitForm,
				})

				const { values } = p
				const getMultiSelectProps = formikMultiSelectProps(p)
				const colorsList = mapOptionsToColors(values.colors)
				const statesList = mapOptionsToStatesValues(values.states)

				return (
					<Form as={FormikForm}>
						<Form.Group>
							<Form.Label htmlFor="colorSelect">Theme Colors</Form.Label>

							<MultiSelect
								id="colorSelect"
								options={colorOpts}
								placeholder="Select a color"
								{...getMultiSelectProps('colors')}
							/>

							<FormValidationText field="colors" formikProps={p} valid="Nice colors!" />

							<Form.Text>
								**Indicates a Bootstrap default color that has not been overridden because our style
								guide lacks a suitable replacement.
							</Form.Text>
						</Form.Group>

						{!isEmpty(colorsList) && (
							<Form.Group>
								<Form.Label>Your Colors</Form.Label>

								<div className="d-flex flex-wrap">
									{colorsList.map((c) => (
										<DemoFormColor key={c.hex} {...c} />
									))}
								</div>
							</Form.Group>
						)}

						<Form.Group>
							<Form.Label htmlFor="stateSelect">Favorite States</Form.Label>

							<MultiSelect
								id="stateSelect"
								options={demoStates}
								placeholder="Select favorite states"
								{...getMultiSelectProps('states')}
							/>

							<FormValidationText field="states" formikProps={p} valid="Interesting!" />
						</Form.Group>

						{!isEmpty(statesList) && (
							<Form.Group>
								<Form.Label>Your States</Form.Label>

								<ul
									className="d-flex flex-wrap"
									style={{ listStyle: 'none', margin: '0', padding: '0' }}
								>
									{statesList.map((s) => (
										<li key={s} style={{ padding: '.5rem' }}>
											{s}
										</li>
									))}
								</ul>
							</Form.Group>
						)}
					</Form>
				)
			}}
		</Formik>
	)
})

DemoMultiSelectForm.displayName = 'DemoMultiSelectForm'

export const DemoMultiSelectPage = () => {
	const [isModalVisible, showModal, hideModal] = useBoolState(false)
	const formRef = useRef<DemoFormRef>(DEFAULT_FORM_REF)

	const submitForm = useFn(async () => {
		try {
			await formRef.current.submitForm()

			if (formRef.current.isValid) {
				hideModal()
			}
		} catch (e) {
			console.error('Uh oh', e)
		}
	})

	const resetForm = useFn(() => formRef.current.resetForm())

	return (
		<Card>
			<Card.Header>Multi-select</Card.Header>

			<Card.Body>
				<DemoMultiSelectForm ref={formRef} />

				<Button className="mr-3" variant="success" onClick={submitForm}>
					Submit
				</Button>

				<Button variant="light-link" onClick={resetForm}>
					Reset
				</Button>
			</Card.Body>

			<Card.Footer>
				<Button onClick={showModal}>Open Light View Modal</Button>
			</Card.Footer>

			{isModalVisible && (
				<MotivModal title="Multi-Select Light View" size="md" onHide={hideModal}>
					<Modal.Body>
						<DemoMultiSelectForm ref={formRef} />
					</Modal.Body>

					<Modal.Footer>
						<Button className="mr-3" variant="success" onClick={submitForm}>
							Submit
						</Button>

						<Button variant="light-link" onClick={resetForm}>
							Reset
						</Button>
					</Modal.Footer>
				</MotivModal>
			)}
		</Card>
	)
}

const DemoFormColor = (p: DemoColor) => (
	<div
		className="d-flex flex-column align-items-center justify-content-center mb-3"
		style={{ width: '62px' }}
	>
		<div style={{ height: '30px', width: '30px', borderRadius: '50%', backgroundColor: p.hex }} />
		<Form.Text>{p.name}</Form.Text>
	</div>
)
