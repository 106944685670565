import type { DeleteUserModalProps } from './DeleteUserModal'
import type { InvitedUserModalProps } from './InvitedUserModal'
import type { AddUserModalProps, EditUserModalProps } from './ManageUserModal'

export const UserModals = {
	ADD_USER: 'addUser',
	DELETE_USER: 'deleteUser',
	EDIT_USER: 'editUser',
	INVITE_ADMIN: 'inviteAdmin',
	VIEW_INVITED_USER: 'viewInvitedUser',
} as const

export type UserModalType =
	| AddUserModal
	| DeleteUserModal
	| EditUserModal
	| InviteAdminModal
	| ViewInvitedUserModal

export type AddUserModal = {
	readonly type: typeof UserModals.ADD_USER
} & AddUserModalProps

export type DeleteUserModal = {
	readonly type: typeof UserModals.DELETE_USER
} & DeleteUserModalProps

export type EditUserModal = {
	readonly type: typeof UserModals.EDIT_USER
} & EditUserModalProps

export type InviteAdminModal = {
	readonly type: typeof UserModals.INVITE_ADMIN
}

export type ViewInvitedUserModal = {
	readonly type: typeof UserModals.VIEW_INVITED_USER
} & InvitedUserModalProps
