import { useFn } from '@eturi/react'
import cls from 'classnames'
import isFunction from 'lodash/isFunction'
import isString from 'lodash/isString'
import type { MouseEvent, ReactElement } from 'react'
import { Children, cloneElement } from 'react'
import type { NavLinkProps } from 'react-router-dom'
import { useHistory, useRouteMatch } from 'react-router-dom'

type LinkContainerProps = MOmit<NavLinkProps, 'children'> & {
	readonly children: ReactElement
}

const defaultIsActive: NavLinkProps['isActive'] = (match) => Boolean(match)

/**
 * This is a rewrite of react-router-bootstrap rr-v4 branch. It uses a function
 * component (as opposed to class), hooks (as opposed to Route context), and
 * makes a few other small changes (setting tabIndex: -1 and short-circuiting
 * when the route is active).
 *
 * I wrote this because the original package doesn't properly support the props
 * it says it does, and this also cleans up some unneeded crap.
 *
 * I wanted to do the fix and push it upstream, but they don't use SemVer major
 * versions yet (0.x) and have already moved on to React Router v6.
 */
export const LinkContainer = ({
	activeClassName = 'active',
	activeStyle,
	children,
	className: classNameProp,
	exact,
	innerRef: _, // eslint-disable-line @typescript-eslint/no-unused-vars
	isActive: isActiveProp = defaultIsActive,
	onClick,
	replace,
	sensitive,
	strict,
	style: styleProp,
	tabIndex,
	to: toProp,
	...props
}: LinkContainerProps) => {
	const history = useHistory()
	const location = history.location
	const to = isFunction(toProp) ? toProp(location) : toProp
	const path = isString(to) ? to : to.pathname!
	const match = useRouteMatch({
		exact,
		path,
		sensitive,
		strict,
	})

	const child = Children.only(children)
	const href = history.createHref({ pathname: path })
	const isActive = isActiveProp(match, location)
	const className = cls(
		child.props.className,
		isActive && activeClassName,
		isFunction(classNameProp) ? classNameProp(isActive) : classNameProp,
	)
	const style = { ...styleProp, ...(isActive ? activeStyle : null) }

	const handleClick = useFn((ev: MouseEvent<any>) => {
		child.props.onClick?.(ev)
		onClick?.(ev)

		if (
			!ev.defaultPrevented && // Previous handlers prevented default
			ev.button === 0 && // Ignore anything other than left clicks
			!(ev.altKey || ev.ctrlKey || ev.shiftKey || ev.metaKey) // Ignore click modified
		) {
			ev.preventDefault()

			// This deviates from react-router-bootstrap and NavLink. We don't
			// trigger anything if we're already active
			if (isActive) return

			if (replace) {
				history.replace(to)
			} else {
				history.push(to)
			}
		}
	})

	return cloneElement(child, {
		...props,
		className,
		href,
		onClick: handleClick,
		style,
		tabIndex: tabIndex || (isActive ? -1 : undefined),
	})
}
