import './WeekdaySelector.scss'

import cls from 'classnames'
import { FieldArray, useFormikContext } from 'formik'
import Button from 'react-bootstrap/Button'
import type { SparseDaysOfWeek } from './DayOfWeek'
import { DaysOfWeek } from './DayOfWeek'

type WeekdaySelectorProps<K extends string> = {
	readonly className?: string
	readonly labelledby?: string
	readonly name: K
}

export const WeekdaySelector = <K extends string>({
	className,
	labelledby,
	name,
}: WeekdaySelectorProps<K>) => {
	const form = useFormikContext<{ [P in K]: SparseDaysOfWeek }>()
	const selectedDays = form.values[name] || []
	const error = form.errors?.[name]

	const isValid = form.submitCount > 0 && !error
	const isInvalid = form.submitCount > 0 && error

	return (
		<div aria-labelledby={labelledby} className={cls('weekday-selector', className)} role="group">
			<FieldArray name={name}>
				{(arrayHelpers) =>
					DaysOfWeek.map((day, idx) => {
						const isChecked = selectedDays.includes(day)

						return (
							<Button
								key={day}
								className={cls('btn-checkbox form-control', {
									'is-checked': isChecked,
									'is-invalid': isInvalid,
									'is-valid': isValid,
								})}
								onClick={() => {
									arrayHelpers.replace(idx, isChecked ? null : day)
								}}
								role="checkbox"
								title={day}
								variant="light"
							>
								{day.charAt(0)}
							</Button>
						)
					})
				}
			</FieldArray>
		</div>
	)
}
