import { setIfNotEqual } from '@eturi/util'
import type { PayloadAction, AsyncThunk } from '@reduxjs/toolkit'
import { isAnyOf } from '@reduxjs/toolkit'
import type { ActionReducerMapBuilder } from '@reduxjs/toolkit/dist/mapBuilders'
import type { HttpExtra } from '../http'
import type { DateRange, DateRangeFilter } from '../types'

// Don't fetch the same data more than once every 5 minutes, unless force: true
//  This includes 'soft' force as well.
const MIN_REFRESH_MS = 5 * 60 * 1000

export const byTeamOrUserCond =
	<S = unknown>(getPath: (s: S) => ByTeamOrUserMap<any>) =>
	<T extends ByTeamOrUserArgs>(arg: T, api: { getState: () => S }): boolean | undefined => {
		if (arg.force === true) return

		const state = api.getState()
		const byState = getPath(state)[toKey(arg.dateRange.id, arg.id)]

		return byState && Date.now() - MIN_REFRESH_MS < byState.lastFetchTs ? false : undefined
	}

export const setByTeamOrUserState =
	<
		P extends 'byTeam' | 'byUser',
		S extends { readonly [K in P]: any },
		A extends PayloadAction<GetByTeamOrUserPayload<P, S>, string, { arg: ByTeamOrUserArgs }>,
	>(
		path: P,
	) =>
	(s: Writable<S>, { meta, payload }: A) => {
		const { dateRange, id } = meta.arg
		const currentState = (s[path][toKey(dateRange.id, id)] ||= {})

		setIfNotEqual(currentState, 'data', payload)
		currentState.lastFetchTs = Date.now()
	}

export const setByTeamOrUserPending =
	<
		P extends 'byTeamPending' | 'byUserPending',
		S extends { readonly [K in P]: any },
		A extends PayloadAction<GetByTeamOrUserPayload<any, any>, string, { arg: ByTeamOrUserArgs }>,
	>(
		path: P,
		isPending: boolean,
	) =>
	(s: Writable<S>, { meta }: A) => {
		const { dateRange, id } = meta.arg
		s[path][toKey(dateRange.id, id)] = isPending
	}

export const addPendingMatcher = <
	T extends {
		readonly byTeamPending: ByTeamOrUser<boolean>
		readonly byUserPending: ByTeamOrUser<boolean>
	},
>(
	builder: ActionReducerMapBuilder<T>,
	thunk: AsyncThunk<any, ByTeamOrUserArgs, any>,
	path: 'byTeamPending' | 'byUserPending',
) => {
	builder.addMatcher(
		/* @__PURE__ */ isAnyOf(thunk.fulfilled, thunk.rejected),
		setByTeamOrUserPending(path, false),
	)

	return builder
}

export const isByTeamOrUserPending = (
	byTeamOrUserData: ByTeamOrUserMap<any>,
	byTeamOrUserPending: ByTeamOrUser<boolean>,
	{ dateRange, id }: { readonly dateRange: DateRangeFilter; readonly id: string },
) => {
	const key = toKey(dateRange.id, id)
	return Boolean(byTeamOrUserPending[key]) && byTeamOrUserData[key] == null
}

const toKey = (range: DateRange, id: string) => range + id

export type ByTeamOrUserState<T> = {
	readonly data: T
	readonly lastFetchTs: number
}

export type ByTeamOrUserMap<T> = {
	readonly [dateFilterIdAndTeamOrUserId: string]: ByTeamOrUserState<T>
}

export type ByTeamOrUser<T> = {
	readonly [dateFilterIdAndTeamOrUserId: string]: T
}

export type ByTeamOrUserArgs<T = Record<string, unknown>> = HttpExtra & {
	readonly dateRange: DateRangeFilter
	readonly id: string
} & T

type GetByTeamOrUserPayload<
	P extends 'byTeam' | 'byUser',
	S extends { readonly [K in P]: ByTeamOrUserMap<any> },
> = S extends {
	readonly [K in P]: ByTeamOrUserMap<infer V>
}
	? V
	: never
