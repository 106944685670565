import './AttendanceSummaryItem.scss'

import { formatMinsToHrs, formatPercent } from '@motiv-shared/react'
import type { AttendanceItem } from '../types'

type AttendanceSummaryItemProps = AttendanceItem & { readonly total: number }

export const AttendanceSummaryItem = ({
	color,
	label,
	percent,
	total,
	value,
}: AttendanceSummaryItemProps) => (
	<figure className="attendance-summary-item">
		<p className="attendance-summary-item__label">{label}</p>

		<p className="attendance-summary-item__percent" style={{ color }}>
			{formatPercent(percent)}
		</p>

		<p className="attendance-summary-item__time">
			<span style={{ color }}>{formatMinsToHrs(value)}</span>
			<span>/{formatMinsToHrs(total)}</span>
		</p>
	</figure>
)
