import { isOnline, PASSIVE_CAPTURE, useFn, useThrottle, useWindowEvent } from '@eturi/react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
	hasBrowserFocus$,
	isNetworkOnline$,
	setBrowserFocus,
	setNetworkOnline,
} from '../../reducers'
import { useAppDispatch } from '../../store'

export const BrowserInfo = () => {
	const dispatch = useAppDispatch()
	const hasBrowserFocus = useSelector(hasBrowserFocus$)
	const isNetworkOnline = useSelector(isNetworkOnline$)

	const handleNetworkChange = useFn(() => {
		if (isNetworkOnline !== isOnline()) dispatch(setNetworkOnline(!isNetworkOnline))
	})

	// We do a visibility check on Android Parent since it may have issues w/
	// focus states, and we don't want to penalize other browsers w/ increased
	// polling for visibility.
	const handleVisibilityChange = useFn(() => {
		const hasFocus = document.hasFocus()
		if (hasBrowserFocus !== hasFocus) dispatch(setBrowserFocus(hasFocus))
	})

	// We throttle the online and offline events in case the internet connection
	// might toggle on and off very quickly
	const handleNetworkChangeThrottle = useThrottle(handleNetworkChange, 3000, {
		leading: true,
		trailing: true,
	})

	useEffect(() => {
		handleNetworkChange()
		handleVisibilityChange()
	}, [])

	useWindowEvent('online', handleNetworkChangeThrottle, PASSIVE_CAPTURE)
	useWindowEvent('offline', handleNetworkChangeThrottle, PASSIVE_CAPTURE)
	useWindowEvent('focus', handleVisibilityChange, PASSIVE_CAPTURE)
	useWindowEvent('blur', handleVisibilityChange, PASSIVE_CAPTURE)

	useEffect(() => {
		document.addEventListener('visibilitychange', handleVisibilityChange, PASSIVE_CAPTURE)
		return () =>
			document.removeEventListener('visibilitychange', handleVisibilityChange, PASSIVE_CAPTURE)
	}, [])

	return null
}
