import './DashboardNav.scss'

import cls from 'classnames'
import Dropdown from 'react-bootstrap/Dropdown'
import { LinkContainer } from '../../../widgets/LinkContainer'

type DashboardLink = {
	readonly label: string
	readonly url: string
}

type DashboardNavProps = {
	readonly className?: string
	readonly label: string
	readonly links: DashboardLink[]
}

// FIXME: Need to scroll to top on navigation
export const DashboardNav = ({ className, label, links }: DashboardNavProps) => (
	<Dropdown className={cls('dashboard-nav', className)}>
		<Dropdown.Toggle className="text-truncate" id="team-dropdown" size="sm" variant="light-link">
			{label}
		</Dropdown.Toggle>

		<Dropdown.Menu>
			{links.map(({ label, url }, i) => (
				<LinkContainer key={url + i} exact to={url}>
					<Dropdown.Item>{label}</Dropdown.Item>
				</LinkContainer>
			))}
		</Dropdown.Menu>
	</Dropdown>
)
