import moment from 'moment-timezone'

let dataPromise: Promise<any> | null = null

export const loadTimezones = () => {
	// NOTE: Don't want to have to patch moment types just for this
	if ((moment.tz as any).dataVersion) return Promise.resolve()

	// Return same promise for any call so moment.tz.load is only called once
	// Set dataPromise back to null when done so we don't hang on to the
	// reference. This would also allow retry behavior if the call fails.
	dataPromise ||= import(
		/* webpackChunkName: 'moment-timezone-data' */
		/* webpackPrefetch: -1000 */ 'moment-timezone-data'
	)
		.then((data) => {
			moment.tz.load(data)
		})
		.finally(() => {
			dataPromise = null
		})

	return dataPromise
}
