import './IntegrationConfigModal.scss'

import { useConstant, useFn } from '@eturi/react'
import {
	fetchIntegrationCatalog,
	fetchIntegrations,
	integrationConfigMap$,
	integrations$,
	updateIntegrationConfig,
} from '@motiv-shared/reducers'
import type { IntegrationConfigItem, IntegrationProvider } from '@motiv-shared/server'
import { createSelector } from '@reduxjs/toolkit'
import remove from 'lodash/remove'
import type { ChangeEvent } from 'react'
import { useMemo, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
import { integrationsDecorated$, integrationsModal$, setIntegrationsModal } from '../../reducers'
import { useAppDispatch, useSelector } from '../../store'
import { createSearchFilter } from '../../util/createSearchFilter'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { MotivModal } from '../../widgets/Modal'
import type { ConfigOption, ConfigOptionItem } from './integrationConfigUtils'
import { collapseEmptyGroupSections, getSubGroups, isConfigLink } from './integrationConfigUtils'
import { IntegrationOption } from './IntegrationOption'
import { IntegrationsModals } from './integrationsModals.constants'

export type IntegrationConfigModalProps = {
	readonly integrationProvider: IntegrationProvider
}

type PostIntegrationConfigMap = {
	readonly configItem: IntegrationConfigItem
	readonly configOptions: ConfigOptionItem[]
	readonly currentConfig: string[]
}

// NOTE: We need these three selectors to figure out how to configure the integration
//  We use the integration modal to know which integration we are trying to configure
//  We use integrations to find the id of the integration, lastly we use the
//  integrationConfigMap to find the options and current selected value if it exists
const postIntegrationConfigItem$ = createSelector(
	integrationsModal$,
	integrations$,
	integrationConfigMap$,
	(integrationModal, integrations, integrationConfigMap): Maybe<PostIntegrationConfigMap> => {
		if (!integrationModal || integrationModal.type !== IntegrationsModals.POST_INTEGRATION_CONFIG)
			return null

		const selectedIntegration = integrations.find(
			(integration) => integration.integrationProvider === integrationModal.integrationProvider,
		)

		if (!selectedIntegration?.id) return null

		const integrationConfig = integrationConfigMap[selectedIntegration.id]

		if (!integrationConfig) return null

		const configItem = integrationConfig.configInfo.configItems?.find(
			(item) => !item.preIntegration && item.allowChange,
		)

		if (configItem && integrationConfig.context?.[configItem.name]) {
			return {
				configItem,
				configOptions: integrationConfig.context[configItem.name],
				currentConfig: integrationConfig.config[configItem.name],
			}
		}

		return null
	},
)

export const IntegrationConfigModal = ({ integrationProvider }: IntegrationConfigModalProps) => {
	const dispatch = useAppDispatch()
	const integrationsDecorated = useSelector(integrationsDecorated$)
	const postIntegrationConfig = useSelector(postIntegrationConfigItem$)

	const optionSearchFilter = useConstant(() => createSearchFilter<ConfigOptionItem>('name'))
	const [searchData, setSearchData] = useState('')
	const [isSaving, setIsSaving] = useState(false)
	const [selectedOptions, setSelectedOptions] = useState<Set<string>>(
		() => new Set(postIntegrationConfig?.currentConfig),
	)

	const hasReachedMax = selectedOptions.size === (postIntegrationConfig?.configItem?.maxValues || 1)

	const filteredOptionItems = postIntegrationConfig
		? postIntegrationConfig.configOptions.filter(optionSearchFilter(searchData))
		: []

	const filteredMappedOptions = useMemo(() => {
		const configOptions: ConfigOption[] = []

		// Create a graph of the flat list
		for (const option of filteredOptionItems) {
			getSubGroups('', configOptions, option)
		}

		// Flattens empty sections of the graph
		for (const option of configOptions) {
			collapseEmptyGroupSections(option.name, configOptions, option)
		}

		return configOptions
	}, [filteredOptionItems])

	// Gives us information about the integration like name, logo
	const selectedIntegration = integrationsDecorated.find(
		(integration) => integration.integrationProvider === integrationProvider,
	)

	const handleClose = useFn(() => {
		dispatch(setIntegrationsModal(null))
	})

	const handleSubmit = useFn(async () => {
		if (!(postIntegrationConfig && selectedIntegration?.id)) return

		setIsSaving(true)

		const update = {
			addConfig: { [postIntegrationConfig.configItem.name]: [...selectedOptions] },
			addSettings: { pushEnabled: true },
		}

		try {
			await dispatch(
				updateIntegrationConfig({ integrationId: selectedIntegration.id, update }),
			).unwrap()

			// We need to fetch integration and catalog by force since they are cached
			// and we use them to determine if the integration status
			dispatch(fetchIntegrationCatalog({ force: true }))
			dispatch(fetchIntegrations({ force: true }))

			handleClose()
		} catch (e) {
			setIsSaving(false)
		}
	})

	const onSelect = useFn((ids: string[]) => {
		setSelectedOptions(new Set([...selectedOptions, ...ids]))
	})

	const onDeselect = useFn((ids: string[]) => {
		setSelectedOptions(new Set(remove([...selectedOptions], (id) => !ids.includes(id))))
	})

	const handleSearchChange = useFn((ev: ChangeEvent<HTMLInputElement>) =>
		setSearchData(ev.target.value),
	)

	const ConfigOptions1 = useMemo(
		() =>
			filteredMappedOptions.map((option) => {
				return (
					<IntegrationOption
						key={isConfigLink(option) ? option.id : option.name}
						option={option}
						onDeselect={onDeselect}
						onSelect={onSelect}
						selected={selectedOptions}
					/>
				)
			}),
		[filteredMappedOptions, selectedOptions, onSelect, onDeselect],
	)

	if (!selectedIntegration) return null

	const integrationName = selectedIntegration.displayName

	return (
		<MotivModal
			onHide={handleClose}
			size="md"
			title={`Select Group or Project for ${integrationName}`}
		>
			<Modal.Body>
				<BusyIndicator region={IndicatorRegions.INTEGRATION_CONFIG}>
					<div className="d-flex pb-5 align-content-center">
						<Image
							className="config-integration__img"
							src={selectedIntegration.iconLogoSm}
							alt={`${selectedIntegration.shortName} logo`}
						/>

						<h6 className="ml-5 font-weight-bold mb-0 align-self-center">
							{selectedIntegration.shortName}
						</h6>
					</div>

					<p>Please select the group or project that you would like to connect with.</p>

					<Form.Control
						className="search-control mb-4 w-50"
						onChange={handleSearchChange}
						placeholder="Search"
						value={searchData}
					/>

					<div className="config-integration__list overflow-auto">
						<Form.Group controlId="customUrl" className="d-inline-block w-100">
							{ConfigOptions1}
						</Form.Group>

						{hasReachedMax && <p className="text-danger">Project limit reached</p>}
					</div>

					<Modal.Footer>
						<Button
							size="lg"
							variant="success"
							disabled={!selectedOptions.size || hasReachedMax || isSaving}
							onClick={handleSubmit}
						>
							Connect
						</Button>

						<Button size="lg" variant="light" onClick={handleClose}>
							Later
						</Button>
					</Modal.Footer>
				</BusyIndicator>
			</Modal.Body>
		</MotivModal>
	)
}
