import { curry } from '@eturi/util'

/**
 * Creates a filter. Pass in the keys of the object you want to search on and
 * get back a function that takes in the search and item and returns whether
 * any of the values match the search.
 */

export const createSearchFilter = <T>(...keys: (keyof T)[]) =>
	curry((search: string, item: T) => {
		const searchLower = search.toLowerCase()
		return keys.some((k) => {
			return String(item[k] ?? '')
				.toLowerCase()
				.includes(searchLower)
		})
	})
