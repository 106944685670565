import { fetchIntegrationConfig, integrations$ } from '@motiv-shared/reducers'
import { useEffect } from 'react'
import { useAppDispatch, useSelector } from '../../../store'
import { BusyIndicator, IndicatorRegions } from '../../../widgets/BusyIndicator'
import { AvailableIntegrations } from './AvailableIntegrations'
import { CurrentIntegrations } from './CurrentIntegrations'

export const IntegrationsOverview = () => {
	const dispatch = useAppDispatch()
	const integrations = useSelector(integrations$)

	// We fetch the configs for current integrations
	useEffect(() => {
		for (const integration of integrations) {
			if (!integration.id) return

			dispatch(
				fetchIntegrationConfig({
					integrationId: integration.id,
					indicatorRegion: IndicatorRegions.INTEGRATION_CONFIG,
				}),
			)
		}
	}, [integrations])

	return (
		<div>
			<BusyIndicator region={IndicatorRegions.INTEGRATIONS}>
				<CurrentIntegrations className="mb-5" />
				<AvailableIntegrations />
			</BusyIndicator>
		</div>
	)
}
