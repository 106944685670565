import { useMemo } from 'react'
import { generatePath, useRouteMatch } from 'react-router-dom'
import { truncateId } from '../../util/truncateId'
import type { TeamRouteParams } from './dashboard-routes'
import { DashboardRoutes } from './dashboard-routes'
import { DashboardNav } from './DashboardNav'
import { useDashboardState } from './hooks'

export const TeamDropdownNav = () => {
	const match = useRouteMatch<TeamRouteParams>()
	const { team, teams } = useDashboardState()

	const links = useMemo(
		() =>
			teams.map((team) => ({
				label: team.name,
				url: generatePath(DashboardRoutes.Team.path, {
					...match.params,
					teamId: truncateId(team.id),
				}),
			})),
		[match.url, teams],
	)

	if (!team) return null

	return <DashboardNav label={team.name} links={links} />
}
