import { createSlice } from '@reduxjs/toolkit'
import { resetAction } from './actions'
import type { HttpInitHandler } from './createHttpInitHandler'
import type { InitState } from './types'
import type { BuilderWithCachedCase } from './withPersistentCacheCase'
import { withPersistentCacheCase } from './withPersistentCacheCase'

export type CachedState<State> = InitState & {
	readonly data: State
}

export const createCacheSlice = <State, Name extends string = string>(
	name: Name,
	initialData: State,
	httpInitHandler: HttpInitHandler<any>,
	handleBuilder: (builder: BuilderWithCachedCase<CachedState<State>>) => void,
) => {
	const initialState: CachedState<State> = {
		data: initialData,
		isInit: false,
	}

	return createSlice({
		name,
		initialState,
		reducers: {},
		extraReducers: (builder) => {
			const persistentBuilder = withPersistentCacheCase(
				builder,
				httpInitHandler,
				name,
			).addResetCase(resetAction.type, initialState)
			handleBuilder(persistentBuilder)
		},
	})
}
