import type {
	WithAccountState,
	WithEmailsState,
	WithIdentityState,
	WithIntegrationCatalogState,
	WithIntegrationsState,
	WithInvoicesState,
	WithManagedUsersState,
	WithMeetingsState,
	WithPurchaseInfoState,
	WithRolesState,
	WithSKUDefsState,
	WithSubscriptionsState,
	WithTeamsState,
	WithUsersState,
	WithIntegrationConfigState,
} from '@motiv-shared/reducers'
import { emailsSlice, identitySlice, meetingsSlice } from '@motiv-shared/reducers'
import { combineReducers } from 'redux'
import type {
	WithAuthState,
	WithBrowserInfoState,
	WithGlobalState,
	WithIntegrationIdentitiesState,
	WithIntegrationsUIState,
	WithSeatUIState,
	WithSubscriptionUIState,
	WithTeamsUIState,
	WithToastState,
	WithUsersUiState,
} from './reducers'
import {
	accountSlice,
	authSlice,
	browserInfoSlice,
	globalSlice,
	integrationCatalogSlice,
	integrationConfigSlice,
	integrationsIdentitySlice,
	integrationsSlice,
	integrationsUISlice,
	invoicesSlice,
	managedUsersSlice,
	purchaseInfoSlice,
	rolesSlice,
	seatUISlice,
	skuDefsSlice,
	subscriptionsSlice,
	subscriptionUISlice,
	teamsSlice,
	teamsUISlice,
	toastSlice,
	usersSlice,
	usersUiSlice,
} from './reducers'
import type { WithBusyIndicatorState } from './widgets/BusyIndicator'
import { busyIndicatorSlice } from './widgets/BusyIndicator'

type FlatIntersection<T> = {
	readonly [P in keyof T]: T[P]
}

export type RootState = FlatIntersection<
	WithAccountState &
		WithAuthState &
		WithBrowserInfoState &
		WithBusyIndicatorState &
		WithEmailsState &
		WithGlobalState &
		WithIdentityState &
		WithIntegrationCatalogState &
		WithIntegrationConfigState &
		WithIntegrationIdentitiesState &
		WithIntegrationsState &
		WithIntegrationsUIState &
		WithInvoicesState &
		WithManagedUsersState &
		WithMeetingsState &
		WithPurchaseInfoState &
		WithRolesState &
		WithSeatUIState &
		WithSKUDefsState &
		WithSubscriptionsState &
		WithSubscriptionUIState &
		WithTeamsState &
		WithTeamsUIState &
		WithToastState &
		WithUsersState &
		WithUsersUiState
>

export const rootReducer = combineReducers({
	[accountSlice.name]: accountSlice.reducer,
	[authSlice.name]: authSlice.reducer,
	[browserInfoSlice.name]: browserInfoSlice.reducer,
	[busyIndicatorSlice.name]: busyIndicatorSlice.reducer,
	[emailsSlice.name]: emailsSlice.reducer,
	[globalSlice.name]: globalSlice.reducer,
	[identitySlice.name]: identitySlice.reducer,
	[integrationCatalogSlice.name]: integrationCatalogSlice.reducer,
	[integrationConfigSlice.name]: integrationConfigSlice.reducer,
	[integrationsIdentitySlice.name]: integrationsIdentitySlice.reducer,
	[integrationsSlice.name]: integrationsSlice.reducer,
	[integrationsUISlice.name]: integrationsUISlice.reducer,
	[invoicesSlice.name]: invoicesSlice.reducer,
	[managedUsersSlice.name]: managedUsersSlice.reducer,
	[meetingsSlice.name]: meetingsSlice.reducer,
	[purchaseInfoSlice.name]: purchaseInfoSlice.reducer,
	[rolesSlice.name]: rolesSlice.reducer,
	[seatUISlice.name]: seatUISlice.reducer,
	[skuDefsSlice.name]: skuDefsSlice.reducer,
	[subscriptionsSlice.name]: subscriptionsSlice.reducer,
	[subscriptionUISlice.name]: subscriptionUISlice.reducer,
	[teamsSlice.name]: teamsSlice.reducer,
	[teamsUISlice.name]: teamsUISlice.reducer,
	[toastSlice.name]: toastSlice.reducer,
	[usersSlice.name]: usersSlice.reducer,
	[usersUiSlice.name]: usersUiSlice.reducer,
})
