import type { UserRole, UserRoleData } from '@motiv-shared/server'
import find from 'lodash/find'

export type WithRoleData<T> = T & { readonly role?: UserRoleData }

export const withRole = <T extends { readonly roleId: UserRole }>(
	entity: T,
	roleData: UserRoleData[],
): WithRoleData<T> => ({
	...entity,
	role: find(roleData, { id: entity.roleId }),
})
