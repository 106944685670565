import { pick, setIfNotEqual } from '@eturi/util'
import { deleteIntegration, resetAction } from '@motiv-shared/reducers'
import type { MotivIntegrationIdentity } from '@motiv-shared/server'
import { createSlice } from '@reduxjs/toolkit'
import { fetchIntegrationIdentities } from './integration-identities.asyncActions'

export type IntegrationsIdentitiesState = {
	readonly data: {
		readonly [integrationId: string]: MotivIntegrationIdentity[]
	}
}

export type WithIntegrationIdentitiesState = {
	readonly integrationIdentities: IntegrationsIdentitiesState
}

const initialState: IntegrationsIdentitiesState = {
	data: {},
}

export const integrationsIdentitySlice = createSlice({
	name: 'integrationIdentities',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(resetAction.type, () => initialState)

			.addCase(fetchIntegrationIdentities.fulfilled, (s, a) => {
				const integrationId = a.meta.arg.integrationId
				setIfNotEqual(s.data, integrationId, a.payload)
			})

			.addCase(deleteIntegration.fulfilled, (s, a) => {
				const integrationId = a.meta.arg.id
				const { [integrationId]: _, ...newData } = s.data
				s.data = newData
			})
	},
})

export const sanitizeIntegrationIdentity = (i: MotivIntegrationIdentity) => ({
	...pick(i, ['integrationProvider']),
	id: i.id,
})

export const sanitizeIntegrationIdentitiesState = (s: IntegrationsIdentitiesState) => ({
	...s,
	data: Object.keys(s.data).reduce((a: { [integrationId: string]: any }, v) => {
		a[v] = s.data[v].map(sanitizeIntegrationIdentity)
		return a
	}, {}),
})
