import { pick, setIfNotEqual } from '@eturi/util'
import type { Account } from '@motiv-shared/server'
import { isIdentifySuccessRes } from '@motiv-shared/server'
import { authIdentifyCompleteMatcher } from '../auth'
import type { CachedState } from '../createCacheSlice'
import { createCacheSlice } from '../createCacheSlice'
import type { HttpInitHandler } from '../createHttpInitHandler'
import { fetchAccount, updateAccount } from './account.asyncActions'

export type AccountState = CachedState<Maybe<Account>>
export type WithAccountState = {
	readonly account: AccountState
}

let initialData: Maybe<Account>

export const createAccountSlice = <State>(httpInitHandler: HttpInitHandler<State>) =>
	createCacheSlice('account', initialData, httpInitHandler, (builder) =>
		builder
			.addCachedCase(fetchAccount.fulfilled, (s, a) => {
				setIfNotEqual(s, 'data', a.payload)
				s.isInit = true
			})

			.addCase(updateAccount.fulfilled, (s, a) => {
				s.data = a.payload
			})

			.addMatcher(authIdentifyCompleteMatcher, (s, { payload }) => {
				if (isIdentifySuccessRes(payload)) {
					setIfNotEqual(s, 'data', payload.account)
					s.isInit = true
				}
			}),
	)

export const sanitizeAccount = (a: Maybe<Account>) =>
	a ? pick(a, ['createdAt', 'id', 'updatedAt']) : null

// Removes confidential information from state when sent to Sentry
export const sanitizeAccountState = (s: AccountState) => ({
	...s,
	data: sanitizeAccount(s.data),
})
