import { Polling, useFn } from '@eturi/react'
import {
	fetchInvoices,
	fetchPurchaseInfo,
	hasPaymentError$,
	latestInvoice$,
	user$,
} from '@motiv-shared/reducers'
import { hasPermission, UserPermissions } from '@motiv-shared/server'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { usePolling } from '../../hooks'
import { useAppDispatch } from '../../store'
import { Banner } from '../../widgets/Banners/Banner'
import { BannerWithBtn } from '../../widgets/Banners/BannerWithBtn'

export const FailedPaymentBanner = () => {
	const user = useSelector(user$)
	const hasPaymentError = useSelector(hasPaymentError$)
	const canViewBilling = hasPermission(UserPermissions.CAN_VIEW_BILLING_TAB, user)

	if (!hasPaymentError) return null

	return canViewBilling ? (
		<FailedPaymentBannerImpl />
	) : (
		<Banner msg="There is a problem with your subscription. Please contact the account owner or admin of your account." />
	)
}

const FailedPaymentBannerImpl = () => {
	const dispatch = useAppDispatch()
	const latestInvoice = useSelector(latestInvoice$)
	const [polledTimes, setPolledTimes] = useState(0)

	usePolling(
		[
			(extra) => dispatch(fetchInvoices(extra)),
			(extra) => dispatch(fetchPurchaseInfo(extra)),
			() => setPolledTimes(polledTimes + 1),
		],
		polledTimes > 5 ? Polling.LONG : undefined,
		false,
	)

	const handlePaymentFix = useFn(() => {
		setPolledTimes(0)
		if (latestInvoice) window.open(latestInvoice.hostedInvoiceUrl)
	})

	return (
		<BannerWithBtn
			msg="There was a problem with your payment. Please reattempt the charge or try a new card."
			btnMsg="Fix This"
			onClick={handlePaymentFix}
		/>
	)
}
