import type { ExtractRouteParams } from 'react-router'
import { DashboardTeamView } from './DashboardTeamView'
import { DashboardUserView } from './DashboardUserView'

export const DashboardRoutes = {
	Team: {
		path: '/dashboard/:dateRange/team/:teamId',
		view: DashboardTeamView,
	},

	User: {
		path: '/dashboard/:dateRange/team/:teamId/user/:userId',
		view: DashboardUserView,
	},
} as const

type ExtractParamsAsString<T extends string> = {
	[P in keyof ExtractRouteParams<T>]: string
}

export type TeamRouteParams = ExtractParamsAsString<typeof DashboardRoutes.Team.path>
export type UserRouteParams = ExtractParamsAsString<typeof DashboardRoutes.User.path>
