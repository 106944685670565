import './Banner.scss'

import Button from 'react-bootstrap/Button'
import type { BannerProps } from './Banner'
import { Banner } from './Banner'

type BannerWithBtnProps = Omit<BannerProps, 'children'> & {
	readonly btnMsg: string
	readonly onClick: () => void
}

export const BannerWithBtn = (p: BannerWithBtnProps) => {
	return (
		<Banner msg={p.msg}>
			<Button className="banner__btn" variant="light" onClick={p.onClick}>
				{p.btnMsg}
			</Button>
		</Banner>
	)
}
