import type { MotivUser } from '@motiv-shared/server'
import Form from 'react-bootstrap/Form'
import type { MultiSelectOption, MultiSelectProps } from '../../../widgets/SelectDropdown'
import { MultiSelect } from '../../../widgets/SelectDropdown'

export const INITIAL_VALUE_TEAM_LEADS = (
	canBeTeamLeadMembers?: MotivUser[],
	assignedTeamLeadIds?: string[],
) => {
	const teamLeads =
		canBeTeamLeadMembers && assignedTeamLeadIds
			? canBeTeamLeadMembers
					.filter((u) => assignedTeamLeadIds.includes(u.id))
					.map(mapTeamLeadToOpts)
			: []

	return {
		teamLeads,
	}
}

export type TeamLeadsFormData = {
	readonly teamLeads: MultiSelectOption[]
}

export const mapTeamLeadToOpts = (u: MotivUser): MultiSelectOption => ({
	label: u.fullName,
	value: u.id,
})

type GetMultiSelectCtrlProps = <K extends keyof TeamLeadsFormData>(
	name: K,
) => MOmit<MultiSelectProps, 'placeholder' | 'options'>

export const TeamLeadsInput = ({
	getMultiSelectProps,
	label,
	placeholder,
	teamLeads,
}: {
	getMultiSelectProps: GetMultiSelectCtrlProps
	label: string
	placeholder: string
	teamLeads: MultiSelectOption[]
}) => {
	return (
		<>
			<Form.Label>{label}</Form.Label>

			<MultiSelect
				noOptionsMessage="No Team Leads Available"
				options={teamLeads}
				placeholder={placeholder}
				{...getMultiSelectProps('teamLeads')}
			/>
		</>
	)
}
