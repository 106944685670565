import cls from 'classnames'
import isString from 'lodash/isString'
import truncate from 'lodash/truncate'
import { useMemo } from 'react'
import type {
	MultiValueProps,
	NamedProps as SelectProps,
	OptionTypeBase,
	ValueType,
} from 'react-select'
import Select from 'react-select'
import { BasePillLabel } from '../PillLabel'

export type MultiSelectNoOptsMsg<T> =
	| SelectProps<MultiSelectOption<T>, true>['noOptionsMessage']
	| string

export type MultiSelectOption<T = void> = {
	readonly label: string
	readonly value: string
} & (T extends void ? Record<string, unknown> : { readonly data: T })

export type MultiSelectProps<T = void> = MOmit<
	SelectProps<MultiSelectOption<T>, true>,
	'noOptionsMessage' | 'options' | 'value'
> &
	Required<Pick<SelectProps<MultiSelectOption<T>, true>, 'name' | 'onChange' | 'placeholder'>> & {
		readonly isInvalid?: boolean
		readonly isValid?: boolean
		readonly noOptionsMessage?: MultiSelectNoOptsMsg<T>
		readonly options: MultiSelectOption<T>[]
		readonly value?: ValueType<MultiSelectOption<T>, true>
	}

export const getDefaultSelectLabel = (v: string) => truncate(v, { length: 33, omission: '...' })

// FIXME: Remove react-select when possible as it's bloated garbage
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const MultiSelect = <T extends unknown = void>({
	isInvalid,
	isValid,
	noOptionsMessage,
	options,
	value,
	...p
}: MultiSelectProps<T>) => {
	const _noOptionsMsg = useMemo(
		() => (isString(noOptionsMessage) ? () => noOptionsMessage : noOptionsMessage),
		[noOptionsMessage],
	)

	return (
		<Select<MultiSelectOption<T>, true>
			className={cls('motiv-select', isInvalid && 'is-invalid', isValid && 'is-valid')}
			classNamePrefix="motiv-select"
			closeMenuOnSelect={false}
			components={{
				ClearIndicator: null,
				DropdownIndicator: null,
				MultiValue,
			}}
			isClearable
			isMulti
			isSearchable
			noOptionsMessage={_noOptionsMsg}
			options={options}
			value={value}
			{...p}
		/>
	)
}

const MultiValue = <T extends OptionTypeBase>(p: MultiValueProps<T>) => {
	return <BasePillLabel {...p.removeProps}>{p.children}</BasePillLabel>
}
