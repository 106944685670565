import { useConstant } from '@eturi/react'
import { charAtIdx, floatFixed, notNull } from '@eturi/util'
import type { ManagedUser, MeetingsSummary, UserMap } from '@motiv-shared/server'
import { LegendOrdinal } from '@visx/legend'
import { scaleOrdinal } from '@visx/scale'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'
import Card from 'react-bootstrap/Card'
import Colors from '../../../styles/color-exports.module.scss'
import { ParentSize } from '../../../widgets/ParentSize'
import { DEFAULT_LEGEND_MARGIN_PROPS } from '../defaults'
import { useDashboardState } from '../hooks'
import { RatioBar } from './RatioBar'

type MeetingHoursProps = {
	readonly className?: string
	readonly summaryByUser: Maybe<UserMap<MeetingsSummary>>
	readonly userId?: string
}

type BarType = 'participant' | 'organizer'
type Minutes = {
	readonly color: string
	readonly key: keyof MPick<MeetingHoursData, 'participantMinutes' | 'organizerMinutes'>
	readonly label: string
	readonly type: BarType
}
const getColor = (m: Minutes) => m.color
const getLabel = (m: Minutes) => m.label

const minutesTypes: Minutes[] = [
	{
		color: Colors.blue,
		key: 'participantMinutes',
		label: 'Participant',
		type: 'participant',
	},
	{
		color: Colors.pink,
		key: 'organizerMinutes',
		label: 'Organizer',
		type: 'organizer',
	},
]

export const MeetingHours = ({ className, summaryByUser, userId }: MeetingHoursProps) => {
	const { teamUsers } = useDashboardState()

	const data = useMemo(
		() => toMeetingHoursData(teamUsers, summaryByUser, userId),
		[teamUsers, summaryByUser, userId],
	)

	const legendScale = useConstant(() =>
		scaleOrdinal({
			domain: [...minutesTypes.map(getLabel)],
			range: [...minutesTypes.map(getColor)],
		}),
	)

	if (isEmpty(data)) return <div>No Data</div>

	return (
		<Card className={className}>
			<Card.Header as="h6">Meeting Hours</Card.Header>

			<Card.Body>
				<LegendOrdinal
					{...DEFAULT_LEGEND_MARGIN_PROPS}
					className="mb-5 justify-content-center"
					direction="row"
					scale={legendScale}
					shape="circle"
				/>

				<ParentSize>{({ width }) => <MeetingHoursBars data={data} width={width} />}</ParentSize>
			</Card.Body>
		</Card>
	)
}

const getMinutes = (d: MeetingHoursData) => d.minutes

type MeetingHoursBarsProps = {
	readonly data: MeetingHoursData[]
	readonly width: number
}

type MeetingHoursData = {
	readonly name: Maybe<string>
	readonly minutes: number
	readonly organizerMinutes: number
	readonly participantMinutes: number
}

const MeetingHoursBars = ({ data, width }: MeetingHoursBarsProps) => {
	const maxValue = Math.max(...data.map(getMinutes))

	return (
		<>
			{data.map((d, i) => {
				const ratioData = minutesTypes.map((m) => toRatioBarData(d, m))
				return (
					<div className="my-4" key={i}>
						{!isEmpty(d.name) && <span className="mx-3">{d.name}</span>}
						<RatioBar data={ratioData} maxValue={maxValue} width={width} />
					</div>
				)
			})}
		</>
	)
}

const toMeetingHoursData = (
	users: Maybe<ManagedUser[]>,
	summaryByUser: Maybe<UserMap<MeetingsSummary>>,
	userId: Maybe<string>,
): MeetingHoursData[] => {
	if (!users || !summaryByUser) return []

	return orderBy(
		users
			.filter((u) => isEmpty(userId) || u.id === userId)
			.map(({ firstName, fullName, lastName, id }) => {
				const summary = summaryByUser[id]

				if (!summary) return null

				const { meetingMinutes, meetingMinutesOrganizer } = summary
				const participantMinutes = meetingMinutes - meetingMinutesOrganizer
				const name =
					!isEmpty(firstName) && !isEmpty(lastName)
						? `${firstName} ${charAtIdx(lastName, 0)}.`
						: fullName

				return {
					minutes: meetingMinutes,
					// don't show name if showing data for only the selected team member
					name: isEmpty(userId) ? name : undefined,
					organizerMinutes: meetingMinutesOrganizer,
					participantMinutes,
				}
			})
			.filter(notNull),
		'minutes',
		'desc',
	)
}

const toRatioBarData = (d: MeetingHoursData, m: Minutes) => {
	const value = d[m.key]
	return {
		color: m.color,
		label: floatFixed(value / 60, 1).toString(),
		value,
	}
}
