import type { IntegrationIdentityId } from './integrations'

/**
 * Returned in the GET /managed-users
 */
export type ManagedUser = {
	readonly accountId: string
	readonly avatarUrl: string // Note: This should be Maybe
	readonly email: Maybe<string>
	readonly firstName: string
	readonly fullName: string
	readonly id: string
	readonly integrationIds: IntegrationIdentityId[]
	readonly lastName: string
	readonly state: ManagedUserState
}

export enum ManagedUserState {
	ACTIVE = 'active',
	INACTIVE = 'inactive',
	SUSPENDED = 'suspended',
	RETIRED = 'retired',
}

/**
 * Used in the POST /managed-users
 */
export type ManagedUserSeed = {
	readonly firstName: string
	readonly lastName: string
	readonly email: Maybe<string>
	readonly avatarUrl?: Maybe<string>
	readonly integrationIds?: Maybe<string | IntegrationIdentityId>[]
}

/**
 * Used in the PATCH /managed-users
 */
export type ManagedUserPatch = {
	readonly avatarUrl?: Maybe<string>
	readonly email?: Maybe<string>
	readonly firstName?: string
	readonly lastName?: string
	readonly state?: ManagedUserState.INACTIVE | ManagedUserState.ACTIVE
	readonly addIntegrationIdentities?: (string | IntegrationIdentityId)[]
	readonly removeIntegrationIdentities?: (string | IntegrationIdentityId)[]
	readonly setIntegrationIdentities?: (string | IntegrationIdentityId)[]
}
