import './TeamInfoCard.scss'

import { useConstant, useFn } from '@eturi/react'
import { users$ } from '@motiv-shared/reducers'
import type { Team } from '@motiv-shared/server'
import { useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { createActiveManagedUsersByTeam } from '../../../compound-selectors'
import { setTeamsModal } from '../../../reducers'
import { useAppDispatch, useSelector } from '../../../store'
import { OutsideCardHeader } from '../../../widgets/OutsideCardHeader'
import { TeamsModals } from '../../TeamsModal'

export type TeamInfoCardProps = {
	readonly team: Team
}

export const TeamInfoCard = ({ team }: TeamInfoCardProps) => {
	const dispatch = useAppDispatch()
	const activeManagedUsersByTeam$ = useConstant(createActiveManagedUsersByTeam)
	const activeTeamMembers = useSelector((s) => activeManagedUsersByTeam$(s, team))
	const users = useSelector(users$)

	const teamLeads = useMemo(
		() => users.filter((u) => team.assignedTeamLeads.includes(u.id)),
		[users, team.assignedTeamLeads],
	)

	const teamLeadsValue = teamLeads.length ? teamLeads.map((u) => u.fullName).join(', ') : 'None'

	const handleEditInfoClicked = useFn(() => {
		dispatch(setTeamsModal({ type: TeamsModals.EDIT_TEAM, teamId: team.id }))
	})

	return (
		<>
			<OutsideCardHeader className="mb-5">
				<span className="team-info-card__title">{team.name}</span>
			</OutsideCardHeader>

			<Card className="mb-5">
				<Card.Body className="align-items-center" as={Row}>
					<Col className="mb-4 mb-sm-0" sm={6}>
						<p className="team-info-card__info">Team Leads: {teamLeadsValue}</p>
						<p className="team-info-card__info">Team Members: {activeTeamMembers.length}</p>
					</Col>

					<Col className="d-flex justify-content-sm-end" sm={6}>
						<Button block className="btn-lg-width" onClick={handleEditInfoClicked}>
							Edit Team
						</Button>
					</Col>
				</Card.Body>
			</Card>
		</>
	)
}
