import { notEmpty, omit } from '@eturi/util'
import type { InvoiceData, MotivSubscription, SKUDef } from '@motiv-shared/server'
import {
	ActiveSKUState,
	MAX_MEMBERS_PER_TEAM,
	PaymentState,
	SKUIds,
	SubscriptionPeriod,
} from '@motiv-shared/server'
import { createSelector } from '@reduxjs/toolkit'
import find from 'lodash/find'
import sortBy from 'lodash/sortBy'
import type { NextMotivSubscription } from '../types'
import { getSkuWeight } from '../types'
import type { WithInvoicesState } from './createInvoicesSlice'
import type { WithPurchaseInfoState } from './createPurchaseInfoSlice'
import type { WithSKUDefsState } from './createSKUDefsSlice'
import type { WithSubscriptionsState } from './createSubscriptionsSlice'

export type CombinedState = WithInvoicesState &
	WithPurchaseInfoState &
	WithSKUDefsState &
	WithSubscriptionsState

const state$ = (s: CombinedState) => s

export const invoices$ = /* @__PURE__ */ createSelector(state$, (s) => s.invoices.data)
export const motivSubs$ = /* @__PURE__ */ createSelector(state$, (s) => s.subscriptions.data)
export const purchaseInfo$ = /* @__PURE__ */ createSelector(state$, (s) => s.purchaseInfo.data)
export const skuDefs$ = /* @__PURE__ */ createSelector(state$, (s) => s.skuDefs.data)

const _getHighestSub = (s1: MotivSubscription, s2: MotivSubscription): MotivSubscription => {
	if (!(s1.skuId && s2.skuId)) return s1

	const t1 = getSkuWeight(s1.skuId)
	const t2 = getSkuWeight(s2.skuId)

	if (t1 === t2)
		return s1.state === ActiveSKUState.CANCELLED || s1.state === ActiveSKUState.DOWNGRADED ? s2 : s1

	return t1 > t2 ? s1 : s2
}

// An active sub is one that is not cancelled and is recurring (non-trial)
// Even though CANCELLED and NON_RECURRING seem like they would go hand in hand.
// In the case of a Trial sub it will be CURRENT and NON_RECURRING because you
// don't cancel a trial sub. So when trying to filter out trials we need to
// check non-recurring
const isActiveSub = (s: Maybe<MotivSubscription>): s is MotivSubscription =>
	!!s &&
	s.state !== ActiveSKUState.CANCELLED &&
	s.state !== ActiveSKUState.DOWNGRADED &&
	s.period !== SubscriptionPeriod.NON_RECURRING &&
	s?.changeToCancel !== true

// An Inactive sub is a sub that will no longer be valid during the
// next billing cycle.
export const mapToHighestSub = (
	subs: MotivSubscription[],
	skipInactive = false,
): Maybe<MotivSubscription> =>
	subs.reduce(
		(highestSub: Maybe<MotivSubscription>, sub) =>
			skipInactive && !isActiveSub(sub)
				? highestSub
				: highestSub
				? _getHighestSub(highestSub, sub)
				: sub,
		null,
	)

// Currently active sub, meaning it may be cancelled or downgraded but privileges are still
// in effect until re-bill
export const activeSub$ = /* @__PURE__ */ createSelector(motivSubs$, mapToHighestSub)

export const isActiveSubFree$ = /* @__PURE__ */ createSelector(
	activeSub$,
	(s) => s?.skuId === SKUIds.FREE,
)

// Subscription that is active and will be billed next. Next and active sub can be the same and
// most likely will be. However this is not always the case since the enterprise sku still exists
// and in the future the subs might be different which is why use the 'next' concept
export const nextSub$ = /* @__PURE__ */ createSelector(
	motivSubs$,
	(s): Maybe<NextMotivSubscription> => {
		const sub = mapToHighestSub(s, true)

		if (!sub) return null

		const period = sub.changeToPeriod || sub.period
		const quantity = sub.changeToQuantity || sub.quantity

		return {
			...omit(sub, ['changeToPeriod', 'changeToQuantity', 'changeToCancel']),
			period,
			quantity,
		}
	},
)

const _isFutureExpiry = (expiry: Maybe<number>) => (expiry || 0) > Date.now()

// Identifies the current sub as the initial Free Trial subscription
export const isTrialSubSKU$ = /* @__PURE__ */ createSelector(
	activeSub$,
	(s) =>
		s?.skuId === SKUIds.TRIAL &&
		s?.state === ActiveSKUState.CURRENT &&
		_isFutureExpiry(s?.expirationTs),
)

// If he user has a sub that is valid but they are currently in the trial period
// This happens when a sub is purchased while in a free trial, so sub will begin
// after trial period.
export const isTrialPeriod$ = /* @__PURE__ */ createSelector(activeSub$, (s) => Boolean(s?.isTrial))
export const activeCycle$ = /* @__PURE__ */ createSelector(activeSub$, (s) => s?.period)
export const nextSubCycle$ = /* @__PURE__ */ createSelector(nextSub$, (s) => s?.period)

// active subscription expiry as ts, the sub will have a changeAt property
// when the active sub has changed, either adding more seats or changing
// period
export const activeSubExpiry$ = /* @__PURE__ */ createSelector(
	activeSub$,
	(s) => s?.changeAt || s?.expirationTs,
)

export const graceExpiry$ = /* @__PURE__ */ createSelector(activeSub$, (s) => s?.priorGraceTs)

export const isAnnualSub = (sub: Maybe<MotivSubscription>) =>
	sub?.period === SubscriptionPeriod.ANNUAL

export const isMonthlySub = (sub: Maybe<MotivSubscription>) =>
	sub?.period === SubscriptionPeriod.MONTHLY

export const isActiveMonthlySub$ = /* @__PURE__ */ createSelector(activeSub$, isMonthlySub)
export const isAnnualSub$ = /* @__PURE__ */ createSelector(nextSub$, isAnnualSub)
export const isNextSubMonthly$ = /* @__PURE__ */ createSelector(
	nextSub$,
	(sub: Maybe<MotivSubscription>) => sub?.period === SubscriptionPeriod.MONTHLY,
)

const getSKUFromSub = (sub: Maybe<MotivSubscription>, defs: SKUDef[]): Maybe<SKUDef> =>
	find(defs, { id: sub?.skuId })

// Sku privileges that are currently active, regardless of cancelled, downgraded, non-recurring status
export const activeSKU$ = /* @__PURE__ */ createSelector(activeSub$, skuDefs$, getSKUFromSub)

// Sku privileges that are going to be active next billing cycle
export const nextSKU$ = /* @__PURE__ */ createSelector(nextSub$, skuDefs$, getSKUFromSub)

export const activeSeatLimit$ = /* @__PURE__ */ createSelector(activeSKU$, activeSub$, (sku, sub) =>
	sku && sub ? sku.seats * sub.quantity : 0,
)

export const nextSeatLimit$ = /* @__PURE__ */ createSelector(nextSKU$, nextSub$, (nSKU, nSub) => {
	if (!(nSKU && nSub)) return 0
	return nSKU.seats * nSub.quantity
})

// When a sub has downgraded and we need to know how many seats they previously had
export const priorSeatLimit$ = /* @__PURE__ */ createSelector(
	activeSub$,
	activeSKU$,
	(aSub, aSKU) => {
		if (!(aSKU && aSub) || !aSub.priorQuantity) return null

		return aSKU.seats * aSub.priorQuantity
	},
)

// The max number of seats a team can be assigned
export const maxTeamSeatLimit$ = /* @__PURE__ */ createSelector(activeSeatLimit$, (aSl) =>
	Math.min(aSl, MAX_MEMBERS_PER_TEAM),
)

// The reason we check for downgrade state as well as as the next and active seats
// is because if the current sku was downgraded it's still active so the state
// will show active even though it's downgraded. If the sku changes then the active
// sku will be downgraded. We also check to see if the sku has not been cancelled because
// cancellation is technically a downgrade but this is only for explicit downgrades
export const isDowngradedSub$ = /* @__PURE__ */ createSelector(
	activeSub$,
	activeSeatLimit$,
	nextSeatLimit$,
	isTrialSubSKU$,
	(s, aSL, nSL, isTrial) => nSL < aSL && !isTrial && s?.changeToCancel !== true,
)

export const hasDowngraded$ = /* @__PURE__ */ createSelector(activeSub$, (aSub) =>
	Boolean(aSub?.priorGraceTs && aSub?.priorQuantity),
)

const isPaidSub = (s: Maybe<MotivSubscription>): s is MotivSubscription => {
	switch (s?.skuId) {
		case SKUIds.ENTERPRISE:
		case SKUIds.SEAT:
			return true
	}

	return false
}

// If the user has an active tiered sub, non-cancelled that is not a trial sub
// this is useful when we want to show information to paid users with active (non-trial) subs
export const isPaidSub$ = /* @__PURE__ */ createSelector(
	nextSub$,
	isTrialSubSKU$,
	(nSub, isTrial) => isPaidSub(nSub) && !isTrial,
)

export const isCancelledSub$ = /* @__PURE__ */ createSelector(activeSub$, (aSub) =>
	Boolean(aSub && aSub.changeToCancel),
)

export const isEnterpriseTier$ = /* @__PURE__ */ createSelector(
	activeSKU$,
	(s) => s?.id === SKUIds.ENTERPRISE,
)

// These show if the user is set to be a certain tier on rebill
// export const isNextSku$ = createSelector(nextSku$, (ns) => ns?.id === SKUIds.TIER2)
export const isNextSKUEnterprise$ = /* @__PURE__ */ createSelector(
	nextSKU$,
	(ns) => ns?.id === SKUIds.ENTERPRISE,
)

export const seatSKU$ = /* @__PURE__ */ createSelector(skuDefs$, (skus) =>
	find(skus, { id: SKUIds.SEAT }),
)

export const hasInvoices$ = /* @__PURE__ */ createSelector(invoices$, notEmpty)

export const latestInvoice$ = /* @__PURE__ */ createSelector(
	invoices$,
	(inv): Maybe<InvoiceData> => sortBy(inv, 'createdTS')[0],
)

export const hasPaymentError$ = /* @__PURE__ */ createSelector(
	activeSub$,
	(aSub) =>
		aSub &&
		(aSub.paymentState === PaymentState.ACTION_REQUIRED ||
			aSub.paymentState === PaymentState.UNPAID ||
			aSub.paymentState === PaymentState.PAYMENT_FAILED),
)
