import { AccountStatus } from './AccountStatus'
import { IntegrateAccountCard } from './IntegrateAccountCard'

export const IntegrateAccountOverview = () => {
	return (
		<>
			<AccountStatus />
			<IntegrateAccountCard />
		</>
	)
}
