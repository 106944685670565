import { Polling, useConstant, useFn } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import type { ByTeamOrUserArgs, DateRangeFilter } from '@motiv-shared/reducers'
import {
	createEmailsByUserSelector,
	createIsEmailsByUserPendingSelector,
	createIsMeetingsByUserPendingSelector,
	createMeetingsByUserSelector,
	fetchGeneralEmailsByTeamMember,
	fetchGeneralMeetingsByTeamMember,
	unwrapThunks,
} from '@motiv-shared/reducers'
import type { Team } from '@motiv-shared/server'
import { useEffect } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Redirect } from 'react-router-dom'
import type { ManagedUserDecorated } from '../../compound-selectors'
import { usePolling } from '../../hooks'
import type { HttpExtra } from '../../http'
import { useAppDispatch, useSelector } from '../../store'
import { LoadingFigure } from '../../widgets/BusyIndicator'
import { AttendanceBreakdown } from './AttendanceBreakdown'
import { AttendanceByOccurrence } from './AttendanceByOccurrence'
import { DateRangeHeader } from './DateRangeHeader'
import { EmailOverview } from './EmailOverview'
import { useDashboardState } from './hooks'
import { MeetingHours } from './MeetingHours'
import { MeetingsAndAttendance } from './MeetingsAndAttendance'
import { MeetsWith } from './MeetsWith'
import { UserDropdownNav } from './UserDropdownNav'

export const DashboardUserView = () => {
	const { dateRangeFilter, team, user } = useDashboardState()

	if (!(dateRangeFilter && team && user)) return <Redirect to="/dashboard" />

	return <DashboardUserViewImpl dateRangeFilter={dateRangeFilter} team={team} user={user} />
}

type DashboardUserViewImplProps = {
	readonly dateRangeFilter: DateRangeFilter
	readonly team: Team
	readonly user: ManagedUserDecorated
}

const DashboardUserViewImpl = ({ dateRangeFilter, user }: DashboardUserViewImplProps) => {
	const dispatch = useAppDispatch()
	const meetingsByUser$ = useConstant(createMeetingsByUserSelector)
	const emailsByUser$ = useConstant(createEmailsByUserSelector)
	const meetingsPendingByUser$ = useConstant(createIsMeetingsByUserPendingSelector)
	const emailsPendingByUser$ = useConstant(createIsEmailsByUserPendingSelector)

	const meetingsByUser = useSelector((s) => meetingsByUser$(s, dateRangeFilter, user.id))
	const emailsByUser = useSelector((s) => emailsByUser$(s, dateRangeFilter, user.id))
	const meetingsPendingByUser = useSelector((s) =>
		meetingsPendingByUser$(s, dateRangeFilter, user.id),
	)
	const emailsPendingByUser = useSelector((s) => emailsPendingByUser$(s, dateRangeFilter, user.id))

	const isLoading = meetingsPendingByUser || emailsPendingByUser

	const fetchEmailAndMeetingData = useFn(async (extra: HttpExtra = {}) => {
		sentryBreadcrumb('Fetching user email and meeting data')

		const fetchArgs: ByTeamOrUserArgs<HttpExtra> = {
			dateRange: dateRangeFilter,
			id: user.id,
			...extra,
		}

		try {
			await Promise.all(
				unwrapThunks([
					dispatch(fetchGeneralMeetingsByTeamMember(fetchArgs)),
					dispatch(fetchGeneralEmailsByTeamMember(fetchArgs)),
				]),
			)
		} catch (e) {
			sentryError(e, 'Failed to fetch user email and meeting data')
		}
	})

	usePolling([(args) => fetchEmailAndMeetingData(args)], Polling.VERY_LONG, false)

	useEffect(() => {
		fetchEmailAndMeetingData()
	}, [dateRangeFilter, user])

	const meetsWith = meetingsByUser?.current.meetsWith

	return (
		<>
			<DateRangeHeader>
				<UserDropdownNav />
			</DateRangeHeader>

			{isLoading ? (
				<LoadingFigure />
			) : (
				<Row>
					<Col className="mb-5" md={6}>
						<MeetingsAndAttendance meetings={meetingsByUser?.current.summaryByDate} />
					</Col>

					<Col className="mb-5" md={6}>
						<AttendanceBreakdown meetings={meetingsByUser?.current.summaryByDate} />
					</Col>

					<Col className="mb-5" md={12}>
						<AttendanceByOccurrence overview={meetingsByUser?.current.total} />
					</Col>

					<Col className="mb-5" md={6}>
						<MeetingHours
							className={meetsWith ? 'mb-5' : undefined}
							summaryByUser={meetingsByUser && { [user.id]: meetingsByUser.current.total }}
							userId={user.id}
						/>

						{meetsWith && <MeetsWith meetsWith={meetsWith} />}
					</Col>

					<Col className="mb-5" md={6}>
						<EmailOverview emails={emailsByUser} />
					</Col>
				</Row>
			)}
		</>
	)
}
