import type { ImmutSet } from '@eturi/util'
import cls from 'classnames'
import isFunction from 'lodash/isFunction'
import type { ReactNode } from 'react'
import Form from 'react-bootstrap/Form'
import type { KeyboardClickEvent } from '../../hooks'

export type MotivSelectRowMode = 'checkbox' | 'radio'

type NumOrStr<T> = T extends string | number ? T : never

export type SelectRowKey<Data, KeyField extends PlainKey<Data>> = NumOrStr<Data[KeyField]>

type MotivSelectRowCol = (
	isDisabled: boolean,
	isSelected: boolean,
	mode: MotivSelectRowMode,
) => ReactNode

// We set `tabIndex` to -1 since the row itself has a tabIndex and it's
// superfluous to have more two.
const DEFAULT_SELECT_ROW_COL: MotivSelectRowCol = (isDisabled, isSelected, mode) => (
	<Form.Check checked={isSelected} disabled={isDisabled} tabIndex={-1} type={mode} readOnly />
)

type SelectRowClassName =
	| string
	| ((isDisabled: boolean, isSelected: boolean) => string | undefined)

type SelectRowOnSelect<T, R, E extends Element> = (
	isSelected: boolean,
	data: T,
	ev: KeyboardClickEvent<E>,
) => R | void

export type MotivTableSelectRow<Data, KeyField extends PlainKey<Data>> = {
	readonly className?: SelectRowClassName
	readonly col?: MotivSelectRowCol
	readonly colClassName?: SelectRowClassName
	readonly disabled?: ImmutSet<SelectRowKey<Data, KeyField>>
	readonly header?: (isSelected: boolean) => ReactNode
	readonly headerColClassName?: SelectRowClassName
	readonly hideSelectAll?: boolean
	readonly hideSelectCol?: boolean
	readonly mode: MotivSelectRowMode
	readonly onSelect?: SelectRowOnSelect<Data, boolean, HTMLTableRowElement>
	readonly onSelectAll?: SelectRowOnSelect<
		Data[],
		SelectRowKey<Data, KeyField>[],
		HTMLTableCellElement
	>
	readonly selected?: ImmutSet<SelectRowKey<Data, KeyField>>
}

type MotivSelectColProps<T extends MotivTableSelectRow<any, any>> = {
	readonly isDisabled: boolean
	readonly isSelected: boolean
	readonly selectRow: T
}

export const MotivSelectCol = <T extends MotivTableSelectRow<any, any>>({
	isDisabled,
	isSelected,
	selectRow: { col, colClassName, mode },
}: MotivSelectColProps<T>) => {
	const content = (col || DEFAULT_SELECT_ROW_COL)(isDisabled, isSelected, mode)
	const className = cls(
		'motiv-table-cell motiv-table-cell-select',
		{
			'motiv-table-cell-select--disabled': isDisabled,
			'motiv-table-cell-select--selected': isSelected,
		},
		isFunction(colClassName) ? colClassName(isDisabled, isSelected) : colClassName,
	)

	return <td className={className}>{content}</td>
}
