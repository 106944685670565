import { useFn } from '@eturi/react'
import {
	hasCompletedIntegrations$,
	hasUnauthorizedIntegrations$,
	unauthorizedIntegrations$,
} from '@motiv-shared/reducers'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { BannerWithBtn } from '../../widgets/Banners/BannerWithBtn'

export const InvalidIntegrationsBanner = () => {
	const history = useHistory()
	const unauthorizedIntegrations = useSelector(unauthorizedIntegrations$)
	const hasUnauthorizedIntegrations = useSelector(hasUnauthorizedIntegrations$)
	const hasCompletedIntegrations = useSelector(hasCompletedIntegrations$)

	const showBanner = hasUnauthorizedIntegrations && hasCompletedIntegrations
	const numUnauthorizedIntegrations = unauthorizedIntegrations.length
	const isPlural = numUnauthorizedIntegrations > 1

	const handleBannerBtnClick = useFn(() => {
		history.push('/settings/integrations')
	})

	if (!showBanner) return null

	return (
		<BannerWithBtn
			msg={`You have ${numUnauthorizedIntegrations} invalid integration${isPlural ? 's' : ''}`}
			btnMsg={'Resolve'}
			onClick={handleBannerBtnClick}
		/>
	)
}
