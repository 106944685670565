import type { SKUDef, SubscriptionPeriod } from '@motiv-shared/server'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { PurchaseModalType } from '../../types'

export type SubscriptionUIState = {
	readonly activePurchaseModal: Maybe<PurchaseModalType>
	readonly subscribingCycle: Maybe<SubscriptionPeriod>
	readonly subscribingSKU: Maybe<SKUDef>
	readonly subscribingSKUQty: Maybe<number>
}

export type WithSubscriptionUIState = {
	readonly subscriptionUI: SubscriptionUIState
}

const initialState: SubscriptionUIState = {
	activePurchaseModal: null,
	subscribingCycle: null,
	subscribingSKUQty: null,
	subscribingSKU: null,
}

export const subscriptionUISlice = createSlice({
	name: 'subscriptionUI',
	initialState,
	reducers: {
		setActivePurchaseModal(s, a: PayloadAction<Maybe<PurchaseModalType>>) {
			s.activePurchaseModal = a.payload
		},

		setSubscribingCycle(s, a: PayloadAction<Maybe<SubscriptionPeriod>>) {
			s.subscribingCycle = a.payload
		},

		setSubscribingSKU(s, a: PayloadAction<Maybe<SKUDef>>): SubscriptionUIState {
			return { ...s, subscribingSKU: a.payload }
		},

		setSubscribingSKUQty(s, a: PayloadAction<Maybe<number>>) {
			s.subscribingSKUQty = a.payload
		},
	},
})

export const {
	setActivePurchaseModal,
	setSubscribingCycle,
	setSubscribingSKU,
	setSubscribingSKUQty,
} = subscriptionUISlice.actions
