import { toQueryStr } from '@eturi/react'
import type {
	MotivUser,
	MotivUserPatch,
	MotivUserSeed,
	PasswordChangeResponse,
	PasswordPatch,
	UserPatchQueryArgs,
} from '@motiv-shared/server'
import get from 'lodash/get'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState'
import type { HttpExtra } from '../http'
import { apiErrorBody } from '../http'
import type { WithUsersState } from './createUsersSlice'

const createAsyncThunk = /* @__PURE__ */ bindCreateAsyncThunkToState<WithUsersState>()

type CreateUserArgs = HttpExtra & {
	readonly user: MotivUserSeed
}

export const createUser = /* @__PURE__ */ createAsyncThunk<MotivUser, CreateUserArgs>(
	'users/createUser',
	async ({ user, ...extra }, { dispatch, extra: { http }, rejectWithValue }) => {
		try {
			return await dispatch(
				http.post<MotivUser>('users', {
					data: user,
					errorMessage: (extra: any, error: any) => () =>
						get(error, 'body.data.detailMsg', error?.message),
					...extra,
				}),
			)
		} catch (e) {
			return rejectWithValue(apiErrorBody(e))
		}
	},
)

type DeleteUserArgs = HttpExtra & {
	readonly userId: string
}

export const deleteUser = /* @__PURE__ */ createAsyncThunk(
	'Delete users/userId',
	({ userId, ...extra }: DeleteUserArgs, { dispatch, extra: { http } }) =>
		dispatch(
			http.delete(`users/${userId}`, {
				errorMessage: 'Unable to delete user. Please try again later.',
				...extra,
			}),
		),
)

export const fetchUsers = /* @__PURE__ */ createAsyncThunk(
	'users/getAll',
	(extra: HttpExtra = {}, { dispatch, extra: { http } }) =>
		dispatch(
			http.get<MotivUser[]>('users', {
				errorMessage: 'Unable to load users. Please try again later.',
				...extra,
			}),
		),
)

type FetchUserArgs = HttpExtra & {
	readonly id: string
}

export const fetchUser = /* @__PURE__ */ createAsyncThunk(
	'users/id',
	({ id, ...extra }: FetchUserArgs, { dispatch, extra: { http } }) =>
		dispatch(
			http.get<MotivUser>(`users/${id}`, {
				errorMessage: 'Unable to load user. Please try again later.',
				...extra,
			}),
		),
)

type UpdatePasswordArgs = HttpExtra & {
	readonly userId: string
	readonly passwordPatch: PasswordPatch
}

export const updateUserPassword = /* @__PURE__ */ createAsyncThunk(
	'users/updateUserPassword',
	(
		{ userId, passwordPatch, region, ...extras }: UpdatePasswordArgs,
		{ dispatch, extra: { http } },
	) =>
		dispatch(
			http.patch<PasswordChangeResponse>(`users/${userId}`, {
				data: passwordPatch,
				errorMessage: 'Unable to change password. Please try again later.',
				indicatorRegion: region,
				...extras,
			}),
		),
)

type UpdateUserArgs = HttpExtra & {
	readonly userId: string
	readonly patch?: MotivUserPatch
	readonly query?: UserPatchQueryArgs
}

export const updateUser = /* @__PURE__ */ createAsyncThunk(
	'users/updateUser',
	({ patch, query, userId, ...extra }: UpdateUserArgs, { dispatch, extra: { http } }) =>
		dispatch(
			http.patch<MotivUser>(`users/${userId}${toQueryStr(query)}`, {
				data: patch,
				errorMessage: 'Unable to update user. Please try again later.',
				...extra,
			}),
		),
)
