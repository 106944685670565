import './TeamLead.scss'

import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { canShowTeamLeadPage$ } from '../../compound-selectors'
import { GooglePlayBtn, IOSAppBtn } from '../../widgets/AppStoreBtns'

export const TeamLead = () => {
	const canShowTeamLeadPage = useSelector(canShowTeamLeadPage$)

	if (!canShowTeamLeadPage) return <Redirect to="/teams" />

	return (
		<Card className="team-lead-page mb-5">
			<Card.Header as="h5">Team Lead</Card.Header>

			<Card.Body className="team-lead-page__body">
				<Row>
					<Col className="d-none d-md-block team-lead-page__left-img" md={2} />

					<Col className="flex-center" xs>
						<div className="py-6 text-center">
							<h3 className="font-weight-bold mb-4">
								Motiv is Productivity Reporting from Anywhere
							</h3>

							<p>Download the mobile app to see team performance.</p>

							<div className="flex-center flex-column flex-sm-row">
								<IOSAppBtn className="mb-4 mb-sm-0" />
								<GooglePlayBtn />
							</div>
						</div>
					</Col>

					<Col className="d-none d-md-block" md={2} />
				</Row>
			</Card.Body>
		</Card>
	)
}
