import type { SKUId } from '@motiv-shared/server'
import { SKUIds } from '@motiv-shared/server'

const SKU_ID_WEIGHT_MAP: Map<SKUId, number> = new Map([
	[SKUIds.FREE, 0],
	[SKUIds.TRIAL, 1],
	[SKUIds.SEAT, 2],
	[SKUIds.ENTERPRISE, 3],
])

export const getSkuWeight = (tierDef: SKUId): number => SKU_ID_WEIGHT_MAP.get(tierDef) || -1
