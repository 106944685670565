import { validate, version } from 'uuid'
import * as Yup from 'yup'

/**
 * API server used success HTTP status codes
 */
export const ApiSuccessStatusCodes = {
	OK: 200,
	Created: 201,
	NoContent: 204,
	NotModified: 304,
} as const

/**
 * Enum of high level client types
 */
export enum ClientType {
	WEB = 'web',
	MOBILE = 'mobile',
	OTHER = 'other',
}

// API argument validation helpers

// Matches:  /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/
const isUUID4 = (v?: any): v is string => validate(v) && version(v) === 4
// const idRegEx = /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/
// export const idSchema = Yup.string().matches(idRegEx)

export const idSchema = /* @__PURE__ */ Yup.string().test({
	exclusive: true,
	message: 'Invalid UUID',
	name: 'id',
	test: (v) => v == null || isUUID4(v),
})

export const idSchemaRequired = /* @__PURE__ */ idSchema.required()

export const idListSchema = /* @__PURE__ */ Yup.array().of(idSchemaRequired)

/**
 * Schema for any string (including empty) that can be omitted.
 */
export const optionalStringSchema = /* @__PURE__ */ Yup.string().test(
	'arg',
	(v) => v === undefined || typeof v === 'string',
)

// NOTE: Partial<Parameters<T>> creates the correct tuple, but TypeScript
//  complains that a "rest parameter must have an array type". This tricks
//  TypeScript into seeing the tuple as an array, but it keeps all the
//  positional types. I found this through trial and error.
type PartialParams<
	T extends (...args: any[]) => any,
	Params = Partial<Parameters<T>>,
> = Params extends any[] ? Params : never

/**
 * Makes a strict test function into one that accepts undefined.
 * NOTE: This only works on fixed-arity functions! N-arity functions would
 *  require a different approach (e.g. args.some(isUndefined) || fn(...args))
 *
 * Example:
 *
 * ```ts
 * const foo = (b: boolean, n: number, s: string) => Boolean(b && n && s)
 * const fooOptional = testOptional(foo)
 * fooOptional() // true
 * fooOptional(true) // true
 * fooOptional(true, 1, '1') // true
 * fooOptional(undefined, undefined) // true
 * fooOptional(undefined, 0, '') // true (would be false if args[0] was defined)
 * fooOptional(true, '', '1') // Error: '' not assignable to number | undefined
 * fooOptional(null, 1, '1') // Error: null not assignable to boolean | undefined
 * ```
 * @param fn
 */
export const testOptional =
	<T extends (...v: any[]) => boolean>(fn: T) =>
	(...v: PartialParams<T>) =>
		v.length !== fn.length || fn(...v)
