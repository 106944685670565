import { useFn } from '@eturi/react'
import filter from 'lodash/filter'
import reject from 'lodash/reject'
import some from 'lodash/some'
import { useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import { useSelector } from 'react-redux'
import RIGHT_ARROW from '../../assets/icons/right-arrow-dark.svg'
import type { ManagedUserDecorated } from '../../compound-selectors'
import { managedUsersDecorated$ } from '../../compound-selectors'
import { useUserTableState } from '../../hooks/useUserTableState'
import { setSeatModal } from '../../reducers'
import { useAppDispatch } from '../../store'
import type { IntegrationDecorated } from '../../types'
import { MotivModal } from '../../widgets/Modal'
import { integrationsFormatter, MotivTable, nameFormatter, nameSortFn } from '../../widgets/Table'
import { SeatModals } from './seatModals.constants'

export type ConfirmNewIntegrationModalProps = {
	readonly newIntegrationId: string
}

export const ConfirmNewIntegrationModal = ({
	newIntegrationId,
}: ConfirmNewIntegrationModalProps) => {
	const dispatch = useAppDispatch()
	const managedUsers = useSelector(managedUsersDecorated$)

	const {
		createTableData,
		filter: { data: filteredManagedUsers },
		renderSearchControl,
	} = useUserTableState(managedUsers)

	const hasIntegration = (integrations: IntegrationDecorated[], id: string) =>
		some(integrations, { id })

	const totalMatches = useMemo(
		() =>
			managedUsers.reduce(
				(count, user) => count + (hasIntegration(user.integrations, newIntegrationId) ? 1 : 0),
				0,
			),
		[managedUsers, newIntegrationId],
	)

	const handleHide = useFn(() => {
		dispatch(setSeatModal(null))
	})

	const handleRowSelection = useFn((_: any, user: ManagedUserDecorated) => {
		dispatch(
			setSeatModal({
				type: SeatModals.EDIT_INTEGRATIONS,
				onBackAction: { type: SeatModals.CONFIRM_INTEGRATION, newIntegrationId },
				selectedManagedUserId: user.id,
			}),
		)
	})

	const tableProps = useMemo(
		() =>
			createTableData({ onRowClick: handleRowSelection })
				.addColumn('fullName', {
					header: 'New Seats',
					isMaxWidth: true,
					render: nameFormatter,
					sort: nameSortFn,
				})

				.addColumn('integrations', {
					className: 'columns__integrations-field w-25',
					extra: newIntegrationId,
					header: 'Matched',

					render: (integrations, _, id) => (
						<div className="d-flex align-items-center">
							<div>{integrationsFormatter(filter(integrations, { id }))}</div>
						</div>
					),

					sort: (integrationsA, integrationsB, _, __, id) => {
						const inRowA = hasIntegration(integrationsA, id)
						const inRowB = hasIntegration(integrationsB, id)

						return inRowA === inRowB ? 0 : inRowA ? -1 : 1
					},
				})

				.addColumn('integrations', {
					className: 'columns__integrations-field w-50',
					extra: newIntegrationId,
					header: 'Other Integrations',
					render: (integrations, _, id) => (
						<div className="d-flex justify-content-between align-items-center">
							<div>{integrationsFormatter(reject(integrations, { id }))}</div>
							<Image src={RIGHT_ARROW} height={20} />
						</div>
					),
				})

				.props(),
		[filteredManagedUsers, newIntegrationId],
	)

	return (
		<MotivModal onHide={handleHide} title="Identity Confirmation">
			<Modal.Body className="confirm-identity-modal">
				<p>
					We were able to match {totalMatches} accounts from to people in your current seats by
					account email. You can manually edit or add integrated accounts for each team member here
					or on the Current Seats page later.
				</p>

				<Row>
					<Col className="mb-3" sm={4} md={5}>
						{renderSearchControl()}
					</Col>
				</Row>

				<MotivTable {...tableProps} />
			</Modal.Body>

			<Modal.Footer>
				<Button size="lg" variant="success" onClick={handleHide}>
					Done
				</Button>
			</Modal.Footer>
		</MotivModal>
	)
}
