import './InstructionsWithArrow.scss'

import CURVED_ARROW from '../../assets/images/curved-arrow.svg'

export type InstructionsWithArrowProps = {
	readonly instructions: string
	readonly imgAlt: string
}
export const InstructionsWithArrow = ({ imgAlt, instructions }: InstructionsWithArrowProps) => (
	<div className="instructions-with-arrow">
		<h4 className="instructions-with-arrow__text">{instructions}</h4>

		<img alt={imgAlt} className="instructions-with-arrow__img" src={CURVED_ARROW} />
	</div>
)
