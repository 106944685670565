import * as Yup from 'yup'

export type Account = {
	readonly createdAt: string
	readonly id: string
	readonly name: string
	readonly settings: AccountSettingsData
	readonly timeZone: Maybe<string>
	readonly updatedAt: string
}

export const ModalityValues = {
	REMOTE: 'remote',
	HYBRID: 'hybrid',
	IN_OFFICE: 'in-office',
}
export type ModalityValue = ValueUnion<typeof ModalityValues>

export type AccountSettingsData = {
	readonly modality?: ModalityValue
	readonly workDays?: string // length 7 string of 0 or 1 string with Sunday
	readonly workStartTime?: string // Time of day in 24h format 'H:m' in moment.js e.g. '08:00'
	readonly workEndTime?: string // Time of day in 24h format 'H:m' in moment.js e.g. '17:00'
}

const workDaysRegEx = /^[01]{7}$/
const timeRegEx = /^(\d|[01]\d|2[0123]):[012345]?\d(:[012345]\d)?$/

export const accountSettingsModalitySchema = /* @__PURE__ */ Yup.mixed().oneOf(
	Object.values(ModalityValues),
)
export const accountSettingsWorkDaysSchema = /* @__PURE__ */ Yup.string().matches(workDaysRegEx)
export const accountSettingsWorkTimeSchema = /* @__PURE__ */ Yup.string().matches(timeRegEx)

export const accountSettingsSchema = /* @__PURE__ */ Yup.object().shape({
	modality: accountSettingsModalitySchema,
	workDays: accountSettingsWorkDaysSchema,
	workStartTime: accountSettingsWorkTimeSchema,
	workEndTime: accountSettingsWorkTimeSchema,
})

/**
 * Used in PATCH /accounts
 */
export type AccountPatch = {
	readonly name?: string
	readonly timeZone?: Maybe<string>
	readonly addSettings?: AccountSettingsData
}
