import { useChgState, useDebounce } from '@eturi/react'
import mapValues from 'lodash/mapValues'
import without from 'lodash/without'
import { useEffect, useRef, useState } from 'react'

export type UseResizeObserverRect = {
	readonly height: number
	readonly left: number
	readonly top: number
	readonly width: number
}

export type UseResizeObserverRectKey = keyof UseResizeObserverRect

const keys: UseResizeObserverRectKey[] = ['height', 'left', 'top', 'width']

export type UseResizeObserverConfig = {
	debounceTime?: number
	floor?: boolean
	ignoreDimensions?: UseResizeObserverRectKey[]
}

const BASE_OBSERVER_RECT: UseResizeObserverRect = Object.freeze({
	height: 0,
	left: 0,
	top: 0,
	width: 0,
})

export const useResizeObserver = ({
	debounceTime = 85,
	floor = true,
	ignoreDimensions = [],
}: UseResizeObserverConfig) => {
	const [rect, setRect] = useState<UseResizeObserverRect>(BASE_OBSERVER_RECT)
	const [el, setEl] = useChgState<HTMLElement | null>(null)
	const animationId = useRef(-1)

	const resize = useDebounce((contentRect: DOMRectReadOnly) => {
		setRect((rect) => {
			// NOTE: Transform the `DOMRectReadOnly` into an enumerable object and
			//  floor the values if enabled. We do this here in the debounce since
			//  it's more efficient.
			const newRect = mapValues(BASE_OBSERVER_RECT, (_, k: UseResizeObserverRectKey) => {
				const v = contentRect[k]
				return floor ? Math.floor(v) : v
			})

			const hasChanges = without(keys, ...ignoreDimensions).some((k) => rect[k] !== newRect[k])

			return hasChanges ? newRect : rect
		})
	}, debounceTime)

	useEffect(() => {
		const observer = new ResizeObserver((entries) => {
			const { contentRect } = entries[entries.length - 1]

			animationId.current = window.requestAnimationFrame(() => {
				resize(contentRect)
			})
		})

		if (el) observer.observe(el)

		return () => {
			window.cancelAnimationFrame(animationId.current)
			observer.disconnect()
			resize.cancel()
		}
	}, [el])

	return [rect, setEl] as const
}
