import { toQueryStr } from '@eturi/react'
import type { GeneralEmailsByTeam, GeneralEmailsByTeamMember } from '@motiv-shared/server'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState'
import type { ByTeamOrUserArgs } from '../util'
import { byTeamOrUserCond, getDateRangeDays } from '../util'
import type { WithEmailsState } from './emails.slice'

const createAsyncThunk = bindCreateAsyncThunkToState<WithEmailsState>()

const ERROR_MESSAGE = 'Unable to load email data. Please try again later.'

export const fetchGeneralEmailsByTeam = /* @__PURE__ */ createAsyncThunk(
	'integration-data/general_email_team',
	({ dateRange, id, ...extra }: ByTeamOrUserArgs, { dispatch, extra: { http } }) => {
		const queryStr = toQueryStr({ teamId: id, periodDays: getDateRangeDays(dateRange) })

		return dispatch(
			http.get<GeneralEmailsByTeam>(`integration-data/general_email_team${queryStr}`, {
				errorMessage: ERROR_MESSAGE,
				...extra,
			}),
		)
	},
	{ condition: /*@__PURE__*/ byTeamOrUserCond((s) => s.emails.byTeam) },
)

export const fetchGeneralEmailsByTeamMember = /* @__PURE__ */ createAsyncThunk(
	'integration-data/general_email_team_member',
	({ dateRange, id, ...extra }: ByTeamOrUserArgs, { dispatch, extra: { http } }) => {
		const queryStr = toQueryStr({ managedUserId: id, periodDays: getDateRangeDays(dateRange) })

		return dispatch(
			http.get<GeneralEmailsByTeamMember>(`integration-data/general_email_team_member${queryStr}`, {
				errorMessage: ERROR_MESSAGE,
				...extra,
			}),
		)
	},

	{ condition: /*@__PURE__*/ byTeamOrUserCond((s) => s.emails.byUser) },
)
