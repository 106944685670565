import { GooglePlayBtn, IOSAppBtn } from '../../widgets/AppStoreBtns'
import { SideNav } from '../../widgets/SideNav'

export const AppDownloadCard = () => {
	return (
		<SideNav title="Install Motiv">
			<p className="mt-3 mb-5 text-center">
				Set up your teams in the web app and view reports in the mobile app.
			</p>
			<IOSAppBtn className="d-block mx-auto" />
			<GooglePlayBtn className="d-block mx-auto" />
		</SideNav>
	)
}
