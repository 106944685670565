import type { WithAssignedTeams } from '@motiv-shared/reducers'
import { isInactiveOrSuspended, managedUsersWithTeams$ } from '@motiv-shared/reducers'
import type { ManagedUser, Team } from '@motiv-shared/server'
import { ManagedUserState } from '@motiv-shared/server'
import { createSelector } from '@reduxjs/toolkit'
import filter from 'lodash/filter'
import { currentIntegrationsDecorated$ } from '../reducers'
import type { IntegrationDecorated, WithIntegrations } from '../types'
import { withIntegrations } from '../types'

export type ManagedUserDecorated = WithIntegrations<WithAssignedTeams<ManagedUser>>

const mapManagedUsersWithTeamsToDecorated = (
	integrations: IntegrationDecorated[],
	managedUsersWithTeams: WithAssignedTeams<ManagedUser>[],
): ManagedUserDecorated[] => managedUsersWithTeams.map((u) => withIntegrations(u, integrations))

export const managedUsersDecorated$ = /* @__PURE__ */ createSelector(
	currentIntegrationsDecorated$,
	managedUsersWithTeams$,
	mapManagedUsersWithTeamsToDecorated,
)

export const activeManagedUsersDecorated$ = /* @__PURE__ */ createSelector(
	managedUsersDecorated$,
	(u) => filter(u, { state: ManagedUserState.ACTIVE }),
)

// NOTE: We follow the language of the `state` of the users rather than the
//  the language used in the UI. In the UI these are "suspended" users, who can
//  be "reactivated". The difference between SUSPENDED and INACTIVE is the action
//  which caused the user to become "suspended". INACTIVE users are selected by the
//  user to become "suspended". SUSPENDED users are those that became "suspended" via
//  a downgrade or a failed payment
export const inactiveManagedUsersDecorated$ = /* @__PURE__ */ createSelector(
	managedUsersDecorated$,
	(users) => users.filter(isInactiveOrSuspended),
)

export const createAvailableManagedUsersByTeam = () =>
	createSelector(
		activeManagedUsersDecorated$,
		(_: any, team: Maybe<Team>) => team,
		(users, team) => users.filter((u) => !(team && team.assignedManagedUsers.includes(u.id))),
	)

export const createActiveManagedUsersByTeam = () =>
	createSelector(
		activeManagedUsersDecorated$,
		(_: any, team: Maybe<Team>) => team,
		(users, team) => users.filter((u) => team && team.assignedManagedUsers.includes(u.id)),
	)
