import range from 'lodash/range'
import Pagination from 'react-bootstrap/Pagination'
import { useKeyboardClick } from '../../hooks'

// This is a constant b/c we really don't have space on smaller screens to
// display more. However, we could potentially change this based on screen
// size in the future if desired.
const MAX_PAGINATION_NUMBERED_ITEMS = 3

type MotivTablePaginationProps = {
	readonly onPageChange: (page: number) => void
	readonly pageIdx: number
	readonly totalPages: number
}

export const MotivTablePagination = ({
	onPageChange,
	pageIdx,
	totalPages,
}: MotivTablePaginationProps) => {
	const lastPageIdx = totalPages - 1
	const nPagesToRender = Math.min(MAX_PAGINATION_NUMBERED_ITEMS, totalPages)
	const isPrevDisabled = pageIdx === 0
	const isNextDisabled = pageIdx === lastPageIdx
	const isPrevNextVisible = totalPages > nPagesToRender

	const handleFirstClick = useKeyboardClick(() => {
		if (pageIdx <= 0) return

		onPageChange(0)
	}, isPrevDisabled)

	const handlePrevClick = useKeyboardClick(() => {
		if (pageIdx <= 0) return

		onPageChange(pageIdx - 1)
	}, isPrevDisabled)

	const handleNextClick = useKeyboardClick(() => {
		if (pageIdx >= lastPageIdx) return

		onPageChange(pageIdx + 1)
	}, isNextDisabled)

	const handleLastClick = useKeyboardClick(() => {
		if (pageIdx >= lastPageIdx) return

		onPageChange(lastPageIdx)
	}, isNextDisabled)

	/**
	 * We start rendering our list of numbered pagination items from an idx:
	 *
	 * - pageIdx - 1: Render the starting w/ one item before the currently active
	 *   page item
	 * - lastPageIdx - nPagesToRender + 1: Make sure not to render beyond our
	 *   last page.
	 * - max(0, ...): Make sure not to start from a negative idx
	 */
	const startRenderIdx = Math.max(0, Math.min(pageIdx - 1, lastPageIdx - nPagesToRender + 1))

	return (
		<Pagination>
			{isPrevNextVisible && <Pagination.First disabled={isPrevDisabled} {...handleFirstClick} />}
			{isPrevNextVisible && <Pagination.Prev disabled={isPrevDisabled} {...handlePrevClick} />}

			{range(nPagesToRender).map((idx) => {
				const rPageIdx = idx + startRenderIdx
				// NOTE: We have to use `rPageIdx` for `key`, even though it means
				//  elements won't be re-used. Otherwise, keyboard navigation will be
				//  screwed up and focused items will be wrong.

				return (
					<MotivPaginationItem
						key={rPageIdx}
						isActive={rPageIdx === pageIdx}
						onPageChange={onPageChange}
						pageIdx={rPageIdx}
						totalPages={totalPages}
					/>
				)
			})}

			{isPrevNextVisible && <Pagination.Next disabled={isNextDisabled} {...handleNextClick} />}
			{isPrevNextVisible && <Pagination.Last disabled={isNextDisabled} {...handleLastClick} />}
		</Pagination>
	)
}

type MotivPaginationItemProps = {
	readonly isActive: boolean
	readonly onPageChange: (page: number) => void
	readonly pageIdx: number
	readonly totalPages: number
}

const MotivPaginationItem = ({
	isActive,
	onPageChange,
	pageIdx,
	totalPages,
}: MotivPaginationItemProps) => {
	const handleClick = useKeyboardClick(() => {
		if (isActive) return

		onPageChange(pageIdx)
	}, isActive)

	const pageNum = pageIdx + 1

	return (
		<Pagination.Item
			active={isActive}
			{...handleClick}
			title={`${isActive ? 'Page' : 'Go to page'} ${pageNum} of ${totalPages}`}
		>
			{pageNum}
		</Pagination.Item>
	)
}
