import { arrayAddOrUpdate, pick, setIfNotEqual } from '@eturi/util'
import type { Team } from '@motiv-shared/server'
import type { Draft } from '@reduxjs/toolkit'
import { isAnyOf } from '@reduxjs/toolkit'
import reject from 'lodash/reject'
import type { CachedState } from '../createCacheSlice'
import { createCacheSlice } from '../createCacheSlice'
import type { HttpInitHandler } from '../createHttpInitHandler'
import { createTeam, deleteTeam, fetchTeam, fetchTeams, updateTeam } from './teams.asyncActions'

export type TeamsState = CachedState<Team[]>
export type WithTeamsState = {
	readonly teams: TeamsState
}

const initialData: Team[] = []

export const createTeamsSlice = <State>(httpInitHandler: HttpInitHandler<State>) =>
	createCacheSlice('teams', initialData, httpInitHandler, (builder) =>
		builder
			.addCase(deleteTeam.fulfilled, (s, a) => {
				s.data = reject(s.data, { id: a.meta.arg.teamId })
			})

			.addCachedCase(fetchTeams.fulfilled, (s, a) => {
				setIfNotEqual(s, 'data', a.payload)
				s.isInit = true
			})

			.addCachedCase(fetchTeam.fulfilled, (s, a) => {
				storeTeam(s, a.payload)
			})

			.addMatcher(isAnyOf(createTeam.fulfilled, updateTeam.fulfilled), (s, a) => {
				storeTeam(s, a.payload)
			}),
	)

const storeTeam = (s: Draft<TeamsState>, team: Team) => {
	s.data = arrayAddOrUpdate(s.data, team, 'id')
}

export const sanitizeTeam = (ts: Maybe<Team>) =>
	ts ? pick(ts, ['assignedManagedUsers', 'assignedTeamLeads', 'id']) : null

export const sanitizeTeamsState = (s: TeamsState) => ({
	...s,
	data: s.data.map(sanitizeTeam),
})
