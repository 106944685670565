import type {
	AnyAction,
	AsyncThunk,
	AsyncThunkOptions,
	AsyncThunkPayloadCreator,
	ThunkDispatch,
} from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit'
import type { HttpInitHandler } from './createHttpInitHandler'
import type { HttpExtra as DefaultHttpExtra, MotivHttp } from './http'

type GetExtra<S, HttpExtra> = {
	readonly http: MotivHttp<S, HttpExtra>
	readonly httpInit?: HttpInitHandler<S>
}

type GetThunkConf<S, HttpExtra> = {
	dispatch: ThunkDispatch<S, GetExtra<S, HttpExtra>, AnyAction>
	extra: GetExtra<S, HttpExtra>
	rejectValue?: unknown
	serializedErrorType?: unknown
	state: S
}

/**
 * This is a function that simply binds the state to `createAsyncThunk`, along
 * with the `extra` prop w/ http, and all of the correct types. It's for type
 * purposes only and, allows us to use `createAsyncThunk` w/ the correct types
 * and extra argument, without having to pass those types in as generic args
 * for every invocation.
 *
 * For example, without this, to get types to flow properly you'd have to do:
 * ```
 * createAsyncThunk<Return, ThunkArg, ThunkConfigWithDispatchExtraStateEtc>(
 *   'foo',
 *   (thunkArg, {dispatch, extra}) => {
 *     return dispatch(extra.http.get<Return>('/foo', thunkArg))
 *   })
 * ```
 *
 * With this, types are bound and return type is inferred from return and args:
 * ```
 * createAsyncThunk(
 *   'foo',
 *   (thunkArg: ThunkArg, {dispatch, extra}) => {
 *     return dispatch(extra.http.get<Return>('/foo', thunkArgs))
 *   })
 * ```
 */
export const bindCreateAsyncThunkToState =
	<S, HttpExtra = DefaultHttpExtra>() =>
	<
		R,
		ThunkArg = void,
		ThunkApiConfig extends GetThunkConf<S, HttpExtra> = GetThunkConf<S, HttpExtra>,
	>(
		typePrefix: string,
		payloadCreator: AsyncThunkPayloadCreator<R, ThunkArg, ThunkApiConfig>,
		options?: AsyncThunkOptions<ThunkArg, ThunkApiConfig>,
	): AsyncThunk<R, ThunkArg, ThunkApiConfig> => {
		const newOptions = {
			...options,
			condition: (arg, api) => {
				const force = (arg as Maybe<DefaultHttpExtra>)?.force

				// Never do a cache / condition check if force is true
				if (force === true) return

				// Next do a condition check if available.
				if (options?.condition?.(arg, api) === false) return false

				// If condition doesn't have us skip and force is 'soft', return. This
				// allows polling updates, and http will debounce if required.
				if (force === 'soft') return

				// Finally, check httpInit if defined.
				if (api.extra.httpInit?.isInit(typePrefix, api.getState(), arg)) return false
			},
		} as AsyncThunkOptions<ThunkArg, ThunkApiConfig>

		return createAsyncThunk<R, ThunkArg, ThunkApiConfig>(typePrefix, payloadCreator, newOptions)
	}

/**
 * Redux toolkit throws a `ConditionError` if you try to unwrap the result of
 * a thunk that was aborted due to condition check, however, they don't export
 * `ConditionError` or anything like it, so this is here to keep that check
 * consistent.
 */
export const isConditionError = (e: any) => e?.name === 'ConditionError'

/**
 * This function is like `unwrapResult` but also checks for ConditionError. It
 * exists so that we can do Promise.all, and catch the individual
 * ConditionErrors without the whole await sequence being rejected. For example
 * previously we did something like:
 *
 * ```ts
 * try {
 *   await Promise.all([
 *     timeoutPromiseThatThrows(1000),
 *     timeoutPromise(2500),
 *   ].map(p => p.then(unwrapResult))
 * } catch (e) {
 *   if (!isConditionError(e)) {
 *     // Real error
 *   }
 * }
 * ```
 * However, in this example, timeoutPromiseThatThrows would throw at 1 second
 * while timeoutPromise(2500) finishes in 2.5 seconds. The thrown promise short
 * circuits and even if it's just a condition error, we erroneously appear to
 * have finished, while the other promise is still outstanding.
 *
 * With this function we can do the equivalent:
 *
 * ```ts
 * await Promise.all([
 *   timeoutPromiseThatThrows(1000),
 *   timeoutPromise(2500),
 * ].map(unwrapThunk)
 * ```
 * Now each promise is unwrapped and the condition guard is checked
 * individually. The only caveat here is that it changes the unwrapped result
 * type to `T | undefined`. If the result itself is actually needed, this can
 * be handled with a simple condition check.
 *
 * NOTE: This has errors when used with Array#map. If don't care about the
 *  types you can use `unwrapThunks` below, and if you do care, you'll have
 *  to unwrap each individually:
 *
 * ```ts
 * const [a, b] = listOfThunksWithDifferentTypes.map(unwrapThunk) // Error
 * const [a, b] = unwrapThunks(listOfThunksWithDifferentTypes) // No error, but a and b have `any` type
 * const [a, b] = [
 *   unwrapThunk(thunkWithTypeA),
 *   unwrapThunk(thunkWithTypeB),
 * ] // a and b have correct types.
 * ```
 *
 * @see unwrapThunks
 *
 * @param p Unwrappable promise.
 */
export const unwrapThunk = async <T extends Unwrap>(p: T): UnwrapRet<T> => {
	try {
		return await p.unwrap()
	} catch (e) {
		if (!isConditionError(e)) throw e
	}
}

type InferUnwrapped<T extends Unwrap> = T extends { unwrap: () => Promise<infer R> } ? R : never
type UnwrapRet<T extends Unwrap> = Promise<InferUnwrapped<T> | undefined>
type Unwrap = { unwrap(): Promise<any> }

/* eslint-disable no-redeclare */
export function unwrapThunks<T1 extends Unwrap>(t: [T1]): [UnwrapRet<T1>]
export function unwrapThunks<T1 extends Unwrap, T2 extends Unwrap>(
	t: [T1, T2],
): [UnwrapRet<T1>, UnwrapRet<T2>]
export function unwrapThunks<T1 extends Unwrap, T2 extends Unwrap, T3 extends Unwrap>(
	t: [T1, T2, T3],
): [UnwrapRet<T1>, UnwrapRet<T2>, UnwrapRet<T3>]
export function unwrapThunks<
	T1 extends Unwrap,
	T2 extends Unwrap,
	T3 extends Unwrap,
	T4 extends Unwrap,
>(t: [T1, T2, T3, T4]): [UnwrapRet<T1>, UnwrapRet<T2>, UnwrapRet<T3>, UnwrapRet<T4>]
export function unwrapThunks<
	T1 extends Unwrap,
	T2 extends Unwrap,
	T3 extends Unwrap,
	T4 extends Unwrap,
	T5 extends Unwrap,
>(
	t: [T1, T2, T3, T4, T5],
): [UnwrapRet<T1>, UnwrapRet<T2>, UnwrapRet<T3>, UnwrapRet<T4>, UnwrapRet<T5>]
export function unwrapThunks<
	T1 extends Unwrap,
	T2 extends Unwrap,
	T3 extends Unwrap,
	T4 extends Unwrap,
	T5 extends Unwrap,
	T6 extends Unwrap,
>(
	t: [T1, T2, T3, T4, T5, T6],
): [UnwrapRet<T1>, UnwrapRet<T2>, UnwrapRet<T3>, UnwrapRet<T4>, UnwrapRet<T5>, UnwrapRet<T6>]
export function unwrapThunks<
	T1 extends Unwrap,
	T2 extends Unwrap,
	T3 extends Unwrap,
	T4 extends Unwrap,
	T5 extends Unwrap,
	T6 extends Unwrap,
	T7 extends Unwrap,
>(
	t: [T1, T2, T3, T4, T5, T6, T7],
): [
	UnwrapRet<T1>,
	UnwrapRet<T2>,
	UnwrapRet<T3>,
	UnwrapRet<T4>,
	UnwrapRet<T5>,
	UnwrapRet<T6>,
	UnwrapRet<T7>,
]
export function unwrapThunks<
	T1 extends Unwrap,
	T2 extends Unwrap,
	T3 extends Unwrap,
	T4 extends Unwrap,
	T5 extends Unwrap,
	T6 extends Unwrap,
	T7 extends Unwrap,
	T8 extends Unwrap,
>(
	t: [T1, T2, T3, T4, T5, T6, T7, T8],
): [
	UnwrapRet<T1>,
	UnwrapRet<T2>,
	UnwrapRet<T3>,
	UnwrapRet<T4>,
	UnwrapRet<T5>,
	UnwrapRet<T6>,
	UnwrapRet<T7>,
	UnwrapRet<T8>,
]
export function unwrapThunks<T extends Unwrap[]>(t: T): Promise<any | undefined>[]
export function unwrapThunks<T extends Unwrap[]>(t: T): Promise<any | undefined>[] {
	return t.map(unwrapThunk)
}
