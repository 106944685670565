import type { GeneralMeetingsByTeam, GeneralMeetingsByTeamMember } from '@motiv-shared/server'
import { createSlice } from '@reduxjs/toolkit'
import { resetAction } from '../actions'
import type { ByTeamOrUser, ByTeamOrUserMap } from '../util'
import { addPendingMatcher, setByTeamOrUserPending, setByTeamOrUserState } from '../util'
import {
	fetchGeneralMeetingsByTeam,
	fetchGeneralMeetingsByTeamMember,
} from './meetings.asyncActions'

export type MeetingsState = {
	readonly byTeam: ByTeamOrUserMap<GeneralMeetingsByTeam>
	readonly byTeamPending: ByTeamOrUser<boolean>
	readonly byUser: ByTeamOrUserMap<GeneralMeetingsByTeamMember>
	readonly byUserPending: ByTeamOrUser<boolean>
}

export type WithMeetingsState = {
	readonly meetings: MeetingsState
}

const initialState: MeetingsState = {
	byTeam: {},
	byTeamPending: {},
	byUser: {},
	byUserPending: {},
}

export const meetingsSlice = /*@__PURE__*/ createSlice({
	name: 'meetings',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(resetAction.type, () => initialState)
			.addCase(fetchGeneralMeetingsByTeam.fulfilled, /*@__PURE__*/ setByTeamOrUserState('byTeam'))
			.addCase(
				fetchGeneralMeetingsByTeam.pending,
				/*@__PURE__*/ setByTeamOrUserPending('byTeamPending', true),
			)
			.addCase(
				fetchGeneralMeetingsByTeamMember.fulfilled,
				/*@__PURE__*/ setByTeamOrUserState('byUser'),
			)
			.addCase(
				fetchGeneralMeetingsByTeamMember.pending,
				/*@__PURE__*/ setByTeamOrUserPending('byUserPending', true),
			)
		addPendingMatcher(builder, fetchGeneralMeetingsByTeam, 'byTeamPending')
		addPendingMatcher(builder, fetchGeneralMeetingsByTeamMember, 'byUserPending')
		return builder
	},
})

// TODO: Maybe provide some of this data
export const sanitizeMeetingsState = (s: MeetingsState) => null
