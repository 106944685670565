import { useSelector } from 'react-redux'
import { isInitialFetchComplete$ } from '../../reducers'
import { DowngradeBanner } from './DowngradeBanner'
import { ExpiredSubBanner } from './ExpiredSubBanner'
import { FailedPaymentBanner } from './FailedPaymentBanner'

export const BillingBanners = () => {
	const isInitialFetchComplete = useSelector(isInitialFetchComplete$)

	if (!isInitialFetchComplete) return null

	return (
		<>
			<DowngradeBanner />
			<ExpiredSubBanner />
			<FailedPaymentBanner />
		</>
	)
}
