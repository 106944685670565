import {
	sanitizeAccountState,
	sanitizeIntegrationsState,
	sanitizeInvoicesState,
	sanitizePurchaseInfoState,
	sanitizeRolesState,
	sanitizeTeamsState,
	sanitizeUsersState,
} from '@motiv-shared/reducers'
import type { AnyAction } from '@reduxjs/toolkit'
import {
	combineActionTransformers,
	combineTransformers,
	createSliceTransformer,
	DEFAULT_COMBINED_ACTION_TRANSFORMER,
	stripPayload,
} from 'rtk-slice-transformer'
import {
	accountSlice,
	authSlice,
	integrationsIdentitySlice,
	integrationsSlice,
	invoicesSlice,
	purchaseInfoSlice,
	rolesSlice,
	sanitizeAuthState,
	sanitizeIntegrationIdentitiesState,
	teamsSlice,
	typeOnlyAuthActions,
	usersSlice,
} from '../../reducers'

const TYPE_ONLY_ACTIONS = new Set([...typeOnlyAuthActions])

const typeOnlyActionTransformer = (a: AnyAction) =>
	TYPE_ONLY_ACTIONS.has(a.type) ? stripPayload(a) : a

const sentryActionTransformer = combineActionTransformers([
	DEFAULT_COMBINED_ACTION_TRANSFORMER,
	typeOnlyActionTransformer,
])

export const sentryTransformer = combineTransformers(
	[
		createSliceTransformer(accountSlice, sanitizeAccountState),
		createSliceTransformer(authSlice, sanitizeAuthState),
		createSliceTransformer(integrationsIdentitySlice, sanitizeIntegrationIdentitiesState),
		createSliceTransformer(integrationsSlice, sanitizeIntegrationsState),
		createSliceTransformer(invoicesSlice, sanitizeInvoicesState),
		createSliceTransformer(purchaseInfoSlice, sanitizePurchaseInfoState),
		createSliceTransformer(rolesSlice, sanitizeRolesState),
		createSliceTransformer(teamsSlice, sanitizeTeamsState),
		createSliceTransformer(usersSlice, sanitizeUsersState),
	],
	sentryActionTransformer,
)
