export type IntegrationResultCode = ValueUnion<typeof IntegrationResultCodes>
export const IntegrationResultCodes = {
	ABORTED: 'ABORTED',
	FAILURE: 'FAILURE',
	SUCCESS: 'SUCCESS',
} as const

export const QUERY_PARAM_RESULT_CODE = 'resultCode'
export const QUERY_PARAM_PROVIDER = 'provider'

const ResultCodesSet = /* @__PURE__ */ new Set(Object.values(IntegrationResultCodes))

export const isIntegrationResultCode = (v?: any): v is IntegrationResultCode =>
	ResultCodesSet.has(v)
