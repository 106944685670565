import { useBoolState, useFn, useMounted } from '@eturi/react'

export const useDebounceFn = <T extends (...args: any[]) => void>(
	fn: T,
): [(...args: Parameters<T>) => void, boolean] => {
	const [isExecuting, startExecuting, stopExecuting] = useBoolState(false)
	const isMounted = useMounted()

	const func = useFn(() => {
		if (isExecuting) return

		startExecuting()

		Promise.resolve(fn()).finally(() => {
			isMounted() && stopExecuting()
		})
	})

	return [func, isExecuting]
}
