import './IntegrateAccountCard.scss'

import { useFn } from '@eturi/react'
import { hasUnauthorizedIntegrations$ } from '@motiv-shared/reducers'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setUserModal, unauthorizedIntegrationsDecorated$ } from '../../reducers'
import { useAppDispatch } from '../../store'
import { UserModals } from '../UserModal'

export const IntegrateAccountCard = () => {
	const hasUnauthorizedIntegrations = useSelector(hasUnauthorizedIntegrations$)

	const history = useHistory()

	const handleGoToSettingsClick = useFn(() => {
		history.push('/settings/integrations')
	})

	return (
		<Card className="mb-5">
			<Card.Header as="h5">Integrate Your Account</Card.Header>
			{hasUnauthorizedIntegrations ? (
				<UnauthorizedIntegrationsBody handleButtonClick={handleGoToSettingsClick} />
			) : (
				<IntegrationAccountBody handleButtonClick={handleGoToSettingsClick} />
			)}
		</Card>
	)
}

const IntegrationAccountBody = ({ handleButtonClick }: { handleButtonClick: () => void }) => {
	return (
		<Card.Body className="text-center">
			<h5 className="my-4 font-weight-bolder">
				Integrate your team’s tools with Motiv in Settings
			</h5>

			<InviteSomeoneElse />

			<Button block className="integrate-account__button" onClick={handleButtonClick}>
				Go To Settings
			</Button>
		</Card.Body>
	)
}

const UnauthorizedIntegrationsBody = ({ handleButtonClick }: { handleButtonClick: () => void }) => {
	const unauthorizedIntegrations = useSelector(unauthorizedIntegrationsDecorated$)
	const isMultiple = unauthorizedIntegrations.length > 1

	const header = `You have ${unauthorizedIntegrations.length} invalid integration${
		isMultiple ? 's' : ''
	}.`

	return (
		<Card.Body className="text-center">
			<div className="d-flex justify-content-center">
				{unauthorizedIntegrations.map(({ integrationProvider, shortName, iconLogoSm }) => (
					<img
						alt={`${shortName} logo`}
						className="integrate-account__icon mx-3"
						key={integrationProvider}
						src={iconLogoSm}
					/>
				))}
			</div>
			<h5 className="my-4 font-weight-bolder">{header}</h5>

			<InviteSomeoneElse />

			<Button block className="integrate-account__button" onClick={handleButtonClick}>
				Resolve in Settings
			</Button>
		</Card.Body>
	)
}

const InviteSomeoneElse = () => {
	const dispatch = useAppDispatch()

	const handleInviteClick = useFn(() => {
		dispatch(setUserModal({ type: UserModals.INVITE_ADMIN }))
	})

	return (
		<div className="d-flex justify-content-center mb-5">
			<p className="mb-0 align-self-center font-italic">{`Don't have the correct permissions?`}</p>

			<Button
				variant="light-link"
				className="font-weight-normal font-italic pl-2"
				onClick={handleInviteClick}
			>
				<u>Invite someone else.</u>
			</Button>
		</div>
	)
}
