export type ConfigOptionItem = {
	readonly id: string
	readonly name: string
	readonly qualName?: string // Group Breadcrumb for Project/Org
}

type ConfigOptionBase = {
	readonly name: string
	readonly path: string
}

export type ConfigOptionSection = ConfigOptionBase & {
	readonly sections: ConfigOption[]
	readonly ids: string[]
}

export type ConfigOptionLink = ConfigOptionBase & {
	readonly id: string
}

export const isConfigSection = (s: any): s is ConfigOptionSection => Boolean(s.sections && s.ids)
export const isConfigLink = (s: any): s is ConfigOptionLink => Boolean(s.id)

export type ConfigOption = ConfigOptionLink | ConfigOptionSection

const SPLIT_CHARS = ' / '
/**
 *
 * @param currentPath current path of string we're parsing i.e. 'firstLevel / secondLevel'
 * @param children Array of config options that belong to the upper level
 * @param option Current config option we're analyzing
 * This recursive function turns a flat string path like 'group / sub-group / repo' and nests
 * each level so that it resembles a graph. It takes advantage that arrays are passed by
 * reference in javascript which is why values are not returned
 */
export const getSubGroups = (
	currentPath: string,
	children: ConfigOption[],
	option: ConfigOptionItem,
) => {
	if (!option.qualName) return

	// qualName is fully qualified breadcrumb name of the option
	// (/ Org / Group / Group2 / Repo) We slice off the current path (/ Org / Group) to
	// figure out what level we're at. The result would end up being (/ Group2 / Repo)
	const currentSectionString = option.qualName.slice(currentPath.length)
	// If we've sliced the current path from the full breadcrumb and nothing
	// is left then we have reached the end of the breadcrumb and we return
	if (!currentSectionString.length) return

	// We split the currentSectionString to see what current level we're at and if
	// there is a following level (/ Group / Repo) would return [Group, Repo] where
	// 'Group' is our current level and 'Repo' is the next Child
	const [currentChild, nextChild] = currentSectionString.split(SPLIT_CHARS)
	// Now that we know the currentLevel we search the children array to see
	// if there is already an existing child that represents this current level
	const existingChildIndex = children.findIndex((child) => child.name === currentChild)

	if (!nextChild) {
		// if we don't have a nextChild then we've reached the end of a branch and
		// the current level is a leaf/repo that we can just add to the children array
		children.push({ path: option.qualName, name: currentChild, id: option.id })
	} else if (existingChildIndex >= 0) {
		// If an existing child already exists that represents this current level
		const child = children[existingChildIndex]
		// This shouldn't happen it's just to have types not complain
		if (!isConfigSection(child)) return

		// Since this level is already represented, we add the id of the current option
		// so that this level is aware of what ids are nested within it
		child.ids.push(option.id)

		// We then continue the  recursion to find other levels, we pass this current level's
		// children as the new children and we update the current path to include this one
		getSubGroups(currentPath + currentChild + SPLIT_CHARS, child.sections, option)
	} else {
		// At this point we know we've reached a group that isn't already represented in
		// the parents children's array. We add this level as a section and continue the
		// recursion to find other nested levels
		const subNodes: ConfigOption[] = []
		getSubGroups(currentPath + currentChild + SPLIT_CHARS, subNodes, option)

		children.push({
			name: currentChild,
			sections: subNodes,
			path: currentPath + currentChild + SPLIT_CHARS,
			ids: [option.id],
		})
	}
}

/**
 *
 * @param currentPath current path i.e. firstLevel/secondLevel
 * @param parentBranches array containing options of level above current level
 * @param currentOption current option that we're analyzing
 * Once a graph is created then we can collapse empty levels,
 * Attempting to do it simultaneously results in more complexity
 * which is less efficient and more confusing
 * This recursive function return an array of items if it's level
 * is being collapsed, if it is remaining then we return nothing
 */
export const collapseEmptyGroupSections = (
	currentPath: string,
	parentBranches: ConfigOption[],
	currentOption: ConfigOption,
): Maybe<ConfigOption[]> => {
	if (isConfigLink(currentOption)) return

	let hasRepo = false
	let newChildren: ConfigOption[] = []
	const emptyChildren: string[] = []

	for (const option of currentOption.sections) {
		// We check to see if the option is a repo this will
		// be used to know if the current level shouldn't be
		// collapsed, we do it here to avoid a second pass over
		// the sections array
		if (isConfigLink(option)) hasRepo = true

		// We call collapseEmptyGroupsSections on the option
		// to see if it is being collapsed and its children
		// should be added to this level
		const children = collapseEmptyGroupSections(
			currentPath + option.name + '/',
			currentOption.sections,
			option,
		)

		// If children are returned then the option is being collapsed
		// we add it to the list of options that are going to be added
		// to this level. We also keep track of the option's name so
		// we can prune it
		if (children) {
			emptyChildren.push(option.name)
			newChildren = newChildren.concat(children)
		}
	}

	// If there is a repo at this level and no children were collapsed
	// then we don't collapse this level and return
	if (hasRepo && !newChildren.length) return

	// Here we know that some sub-sections were collapsed and
	// we add all their children to this level, we do this after
	// iterating because adding to an array while looping causes issues
	for (const child of newChildren) {
		currentOption.sections.push(child)
	}

	// Now we go through and prune any sections that were collapsed
	for (const childNameToRemove of emptyChildren) {
		const index = currentOption.sections.findIndex((branch) => branch.name === childNameToRemove)
		currentOption.sections.splice(index, 1)
	}

	// If we have a repo or we are at the org level then we don't collapse
	if (hasRepo || currentOption.name === currentPath) return

	// Since this level doesn't have a repo and isn't the top level org
	// we will be collapsing it. First we need to update the names of the
	// current children with current path so their names still represent this level
	// i.e. '/CurrentCollapsedLevel/name'
	return currentOption.sections.map((option) => {
		return { ...option, name: currentOption.name + '/' + option.name }
	})
}
