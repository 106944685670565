import { arrayAddOrUpdate, hash, setIfNotEqual } from '@eturi/util'
import type { IntegrationIdentityId, ManagedUser } from '@motiv-shared/server'
import { splitIntegrationIdentityId } from '@motiv-shared/server'
import { isAnyOf } from '@reduxjs/toolkit'
import reject from 'lodash/reject'
import type { CachedState } from '../createCacheSlice'
import { createCacheSlice } from '../createCacheSlice'
import type { HttpInitHandler } from '../createHttpInitHandler'
import {
	createManagedUser,
	deleteManagedUser,
	fetchManagedUsers,
	updateManagedUser,
} from './managed-users.asyncActions'

export type ManagedUsersState = CachedState<ManagedUser[]>
export type WithManagedUsersState = {
	readonly managedUsers: ManagedUsersState
}

const initialData: ManagedUser[] = []

export const createManagedUsersSlice = <State>(httpInitHandler: HttpInitHandler<State>) =>
	createCacheSlice('managedUsers', initialData, httpInitHandler, (builder) =>
		builder
			.addCase(deleteManagedUser.fulfilled, (s, a) => {
				s.data = reject(s.data, { id: a.payload.id })
			})
			.addCachedCase(fetchManagedUsers.fulfilled, (s, a) => {
				setIfNotEqual(s, 'data', a.payload)
				s.isInit = true
			})
			.addMatcher(isAnyOf(createManagedUser.fulfilled, updateManagedUser.fulfilled), (s, a) => {
				s.data = arrayAddOrUpdate(s.data, a.payload, 'id')
			}),
	)

export const sanitizeIntegrationId = (ii: Maybe<IntegrationIdentityId>) => {
	if (ii == null) return ii

	// 'rest' should be empty
	const [provider, id, ...rest] = splitIntegrationIdentityId(ii)

	return provider && id ? [provider, hash(id), ...rest].join('|') : ii
}

export const sanitizeManagedUser = (mu: Maybe<ManagedUser>) =>
	mu
		? {
				id: mu.id,
				integrationIds: mu.integrationIds?.map(sanitizeIntegrationId),
		  }
		: null

export const sanitizeManagedUsersState = (s: ManagedUsersState) => ({
	...s,
	data: s.data.map(sanitizeManagedUser),
})
