import type { Action } from '@reduxjs/toolkit'

type Matcher<T = any> = (action: Action<unknown>) => action is Action<T>

type CacheSlice = {
	addMatcher(matcher: Matcher): void
	isInit(typePrefix: string, state: any): boolean
	matches(typePrefix: string): boolean
}

export type HttpInitHandler<State> = {
	createSlice(name: string): CacheSlice
	isInit(typePrefix: string, state: State, thunkArg?: any): boolean
}

export const createHttpInitHandler = <State>(): HttpInitHandler<State> => {
	const memoryCache = new Map<string, CacheSlice>()

	const createSlice = (name: keyof State & string): CacheSlice => {
		if (memoryCache.has(name)) return memoryCache.get(name)!

		const matchers = new Set<Matcher>()

		const addMatcher = (matcher: Matcher) => {
			matchers.add(matcher)
		}

		const isInit = (typePrefix: string, state: State): boolean => {
			if (!matches(typePrefix)) return false

			return Boolean((state[name] as any)?.isInit)
		}

		const matches = (typePrefix: string): boolean => {
			const action = { type: `${typePrefix}/fulfilled` }

			for (const matcher of matchers) {
				if (matcher(action)) return true
			}

			return false
		}

		const cacheSlice = { addMatcher, isInit, matches }

		memoryCache.set(name, cacheSlice)

		return cacheSlice
	}

	// TODO: Localized `isInit` based on thunkArg.
	const isInit = (typePrefix: string, state: State, thunkArg?: any) => {
		for (const sliceCache of memoryCache.values()) {
			if (sliceCache.isInit(typePrefix, state)) return true
		}

		return false
	}

	return { createSlice, isInit }
}
