import { useFn } from '@eturi/react'
import type { ChangeEvent } from 'react'
import { useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'

export type SelectedSearchRowProps = {
	readonly hasSelected: boolean
	readonly isFiltering: boolean
	readonly onFilteringChange: (isFiltering: boolean) => void
	readonly onSearchChange: (searchData: string) => void
	readonly placeholder: string
	readonly searchData: string
}

export const SelectedSearchRow = ({
	hasSelected,
	isFiltering,
	onFilteringChange,
	onSearchChange,
	placeholder,
	searchData,
}: SelectedSearchRowProps) => {
	const isShowAllBtnVisible = hasSelected && isFiltering
	const isSelectedOnlyBtnVisible = hasSelected && !isFiltering

	const handleToggleClick = useFn(() => {
		onFilteringChange(!isFiltering)
	})

	const handleSearchChange = useFn((ev: ChangeEvent<HTMLInputElement>) => {
		onSearchChange(ev.target.value)
	})

	// Make sure to toggle back `isFiltering` if user deselected the last one
	useEffect(() => {
		if (!hasSelected && isFiltering) onFilteringChange(false)
	}, [hasSelected, isFiltering])

	return (
		<Row>
			<Col className="mb-3" sm={6} md={4}>
				{(isShowAllBtnVisible || isSelectedOnlyBtnVisible) && (
					<Button block onClick={handleToggleClick} size="lg" variant="light">
						{isShowAllBtnVisible ? 'Show All' : 'Show Selected'}
					</Button>
				)}
			</Col>

			<Col className="mb-3 offset-md-1 offset-lg-2" sm={6} md={7} lg={6}>
				<Form.Control
					className="search-control"
					placeholder={placeholder}
					onChange={handleSearchChange}
					value={searchData}
				/>
			</Col>
		</Row>
	)
}
