import type { FormikProps } from 'formik'
import Form from 'react-bootstrap/Form'

type FormValidationTextProps<T, K extends keyof T> = {
	readonly field: K
	readonly formikProps: FormikProps<T>
	readonly valid?: string
}

export const FormValidationText = <T, K extends keyof T>({
	field,
	formikProps,
	valid,
}: FormValidationTextProps<T, K>) => {
	if (formikProps.submitCount < 1) return null

	const errorMsg = formikProps.errors[field]

	if (!(errorMsg || valid)) return null

	return (
		<Form.Text className={`text-${errorMsg ? 'danger' : 'success'}`}>{errorMsg || valid}</Form.Text>
	)
}
