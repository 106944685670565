import { useConstant } from '@eturi/react'
import type { DateMap, MeetingsSummary } from '@motiv-shared/server'
import { AxisBottom, AxisLeft } from '@visx/axis'
import { GridRows } from '@visx/grid'
import { Group } from '@visx/group'
import { LegendOrdinal } from '@visx/legend'
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale'
import { BarRounded, BarStack, Line } from '@visx/shape'
import capitalize from 'lodash/capitalize'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'
import Card from 'react-bootstrap/Card'
import Colors from '../../styles/color-exports.module.scss'
import { ParentSize } from '../../widgets/ParentSize'
import {
	DEFAULT_AXIS_X_PROPS,
	DEFAULT_AXIS_Y_PROPS,
	DEFAULT_GRID_STROKE_PROPS,
	DEFAULT_LEGEND_MARGIN_PROPS,
	DEFAULT_LINE_STROKE_DASHARRAY,
	DEFAULT_MARGIN,
} from './defaults'
import { GraphContainer } from './GraphContainer'
import { useDateAxisFormat } from './hooks'
import { LegendContainer } from './LegendContainer'

type AttendanceBreakdownProps = {
	readonly meetings: Maybe<DateMap<MeetingsSummary>>
}

type AttendanceBarData = {
	readonly adhoc: number
	readonly attended: number
	readonly scheduled: number
	readonly ts: number
}

type AttendanceBarType = 'adhoc' | 'attended'

const margin = DEFAULT_MARGIN

export const AttendanceBreakdown = ({ meetings }: AttendanceBreakdownProps) => {
	const dateAxisFormat = useDateAxisFormat()

	const colorScale = useConstant(() =>
		scaleOrdinal<AttendanceBarType | 'scheduled', string>({
			domain: ['scheduled', 'attended', 'adhoc'],
			range: [Colors.white, Colors.purple, Colors.blue],
		}),
	)

	const data = useAttendanceBarData(meetings)

	const [dateScale, hrsScale] = useMemo(
		() =>
			[
				scaleBand<number>({
					domain: data.map(getTs),
					padding: 0.5,
				}),
				scaleLinear<number>({
					domain: [0, getMax(data)],
					nice: true,
				}),
			] as const,
		[data],
	)

	return (
		<Card>
			<Card.Header as="h6">Attendance Breakdown</Card.Header>

			<Card.Body className="px-0 px-sm-3">
				<GraphContainer>
					<ParentSize>
						{({ height, width }) => {
							if (Math.min(height, width) < 100) return null

							const innerWidth = width - margin.left - margin.right
							const innerHeight = height - margin.top - margin.bottom

							dateScale.rangeRound([0, innerWidth])
							hrsScale.range([innerHeight, 0])

							return (
								<>
									<LegendContainer>
										<LegendOrdinal
											{...DEFAULT_LEGEND_MARGIN_PROPS}
											direction="row"
											labelFormat={capitalize}
											scale={colorScale}
											shape={({ fill, height, item, width }) => {
												width = Number.parseFloat(width || 0)
												const yCenter = Number.parseFloat(height || 0) / 2
												let left = width / 2
												let shape

												if (item === 'scheduled') {
													left = 0
													shape = (
														<Line
															from={{ x: 0, y: 0 }}
															shapeRendering="auto"
															stroke={fill}
															strokeWidth={2}
															to={{ x: width, y: 0 }}
														/>
													)
												} else {
													shape = <circle fill={fill} r={yCenter} />
												}

												return (
													<svg height={height} width={width}>
														<Group left={left} top={yCenter}>
															{shape}
														</Group>
													</svg>
												)
											}}
										/>
									</LegendContainer>

									<svg height={height} width={width}>
										<Group top={margin.top} left={margin.left}>
											<GridRows
												{...DEFAULT_GRID_STROKE_PROPS}
												scale={hrsScale}
												strokeDasharray={DEFAULT_LINE_STROKE_DASHARRAY}
												width={innerWidth}
											/>

											<BarStack<AttendanceBarData, AttendanceBarType>
												color={colorScale}
												data={data}
												keys={['attended', 'adhoc']}
												x={getTs}
												xScale={dateScale}
												yScale={hrsScale}
											>
												{(stacks) => {
													// NOTE: We reverse the rendering order of the stacks so that we can tuck
													//  the adhoc bars behind the attended bars. SVG stacking order is based
													//  on the document order (first=lowest, last=highest). Future SVG spec
													//  is supposed to have z-index control, but for now we can just change
													//  the order of rendering.
													const [attendedStack, adhocStack] = stacks

													return [adhocStack, attendedStack].map((stack) =>
														stack.bars.map(({ color, height, index, key, width, x, y }) => {
															// Omit 0-height bars. Without this, a single point path will be
															// created.
															if (!height) return null

															let radius = Math.min(height, width) / 2

															// NOTE: This modifier is used to make the adhoc (top) bar go behind
															//  the radius of the attended bar. Ideally, the bottom bar would be
															//  rectangular, but the designs show both bars as rounded at the top,
															//  so we have to add some height so there's not empty space. You can
															//  remove the modifier to see the result.
															//  --
															//  In addition, `y` defines the distance from the top of the SVG. So
															//  y=0 means the top of the bar is at the top of the SVG. This means
															//  we don't have to modify the `y` value because, unlike the normal
															//  DOM, the bar isn't positioned based on its size, only based on its
															//  x/y values. This is probably obvious to anyone familiar with lots
															//  of SVG work, but it surprised me, so I'm documenting.
															if (key === 'adhoc') {
																const attendedVal = attendedStack.bars[index]
																const attendedDiameter = Math.min(
																	attendedVal.height,
																	attendedVal.width,
																)
																const attendedRadius = attendedDiameter / 2

																// Modify the height and radius of the adhoc bar. If attended bar
																// short side > adhoc bar height, use attended diameter, otherwise,
																// add the attended radius, whatever it is, to height.
																//
																// Conditions look like:
																// - Attended is big, but adhoc is small: adhoc still has enough
																//   height to create a full radius.
																// - Attended is small, but adhoc is big: only add enough height to
																//   push adhoc bar behind radius of attended. Radius of adhoc is
																//   maintained, and adhoc doesn't extend too low (and potentially
																//   below the bottom axis)
																// - Both are small: Everything still works.
																height = Math.max(attendedDiameter, height + attendedRadius)
																radius = Math.max(attendedRadius, radius)
															}

															return (
																<BarRounded
																	fill={color}
																	height={height}
																	key={key + index + stack.index}
																	radius={radius}
																	top
																	width={width}
																	x={x}
																	y={y}
																/>
															)
														}),
													)
												}}
											</BarStack>

											<BarStack<AttendanceBarData, 'scheduled'>
												color={colorScale}
												data={data}
												keys={['scheduled']}
												x={getTs}
												xScale={dateScale}
												yScale={hrsScale}
											>
												{([scheduled]) =>
													scheduled.bars.map(({ color, height, index, width, x, y }) => {
														// Omit 0-height lines. Without this, the line overlaps with the bottom
														// axis.
														if (!height) return null

														return (
															<rect
																fill={color}
																height={2}
																key={`scheduled-${index}`}
																shapeRendering="crispEdges"
																width={width + 8}
																x={x - 4}
																y={y}
															/>
														)
													})
												}
											</BarStack>

											<AxisLeft {...DEFAULT_AXIS_Y_PROPS} scale={hrsScale} />

											<AxisBottom
												{...DEFAULT_AXIS_X_PROPS}
												scale={dateScale}
												tickFormat={dateAxisFormat}
												top={innerHeight}
											/>
										</Group>
									</svg>
								</>
							)
						}}
					</ParentSize>
				</GraphContainer>
			</Card.Body>
		</Card>
	)
}

const getTs = ({ ts }: AttendanceBarData) => ts
const getMax = (data: AttendanceBarData[]) =>
	Math.max(...data.flatMap((d) => [d.adhoc + d.attended, d.scheduled]))

const useAttendanceBarData = (meetings: Maybe<DateMap<MeetingsSummary>>) =>
	useMemo(
		() =>
			orderBy(
				map(
					meetings || {},
					(v, k): AttendanceBarData => ({
						adhoc: v.attendedAdhocMeetingMinutes / 60,
						attended: v.attendedRecurringMeetingMinutes / 60,
						scheduled: v.meetingRecurringMinutes / 60,
						ts: Date.parse(k),
					}),
				),
				getTs,
				'asc',
			),
		[meetings],
	)
