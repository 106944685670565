import { useMemo } from 'react'
import { generatePath, useRouteMatch } from 'react-router-dom'
import { truncateId } from '../../util/truncateId'
import type { UserRouteParams } from './dashboard-routes'
import { DashboardRoutes } from './dashboard-routes'
import { DashboardNav } from './DashboardNav'
import { useDashboardState } from './hooks'

export const UserDropdownNav = () => {
	const match = useRouteMatch<UserRouteParams>()
	const { team, teamUsers, user } = useDashboardState()

	const label = user?.fullName
	const teamName = team?.name

	const links = useMemo(
		() => [
			{
				label: `Back to ${teamName}`,
				url: generatePath(DashboardRoutes.Team.path, match.params),
			},
			...(teamUsers || []).map((u) => ({
				label: u.fullName,
				url: generatePath(DashboardRoutes.User.path, { ...match.params, userId: truncateId(u.id) }),
			})),
		],
		[match.url, teamName, teamUsers],
	)

	if (!(label && teamName)) return null

	return <DashboardNav label={label} links={links} />
}
