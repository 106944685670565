import { useConstant } from '@eturi/react'
import { createTeamByIdSelector } from '@motiv-shared/reducers'
import { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { addErrorToast, teamIdBeingDeleted$ } from '../../../reducers'
import { useAppDispatch, useSelector } from '../../../store'
import { TeamInfoCard } from './TeamInfoCard'
import { TeamMembers } from './TeamMembers'

export const TeamOverview = () => {
	const dispatch = useAppDispatch()
	const { teamId } = useParams<{ readonly teamId: string }>()
	const teamById$ = useConstant(createTeamByIdSelector)
	const team = useSelector((s) => teamById$(s, teamId))
	const teamIdBeingDeleted = useSelector(teamIdBeingDeleted$)
	const [teamName, setTeamName] = useState(team?.name)
	const [isDeletingTeam, setIsDeletingTeam] = useState(false)

	const hideRedirectError = isDeletingTeam

	useEffect(() => {
		const name = team?.name
		if (name) setTeamName(name)
	}, [team])

	useEffect(() => {
		if (teamIdBeingDeleted === teamId && team) {
			// current team is in process of being deleted by user
			setIsDeletingTeam(true)
		} else if (isDeletingTeam && !team) {
			// current team has been deleted by user
			// keep isDeletingTeamTeam true
		} else {
			// current team is not currently or hasn't been deleted by user
			setIsDeletingTeam(false)
		}
	}, [isDeletingTeam, teamIdBeingDeleted, team])

	useEffect(() => {
		// We do this in an effect, since this could otherwise render multiple
		// toasts
		if (!team && !hideRedirectError) dispatch(addErrorToast(`${teamName} no longer exists`))
	}, [team])

	// This is a backup in case the team has been deleted while we are on the
	// page.
	// https://gitlab.com/Eturi/motiv/Motiv/-/issues/817
	if (!team) return <Redirect to="/teams" />

	return (
		<>
			<TeamInfoCard team={team} />
			<TeamMembers team={team} />
		</>
	)
}
