import { useSelector } from 'react-redux'
import { seatModal$ } from '../../reducers'
import { AddPeopleToSeatsModal } from './AddPeopleToSeatsModal'
import { ConfirmIdentityModal } from './ConfirmIdentityModal'
import { ConfirmNewIntegrationModal } from './ConfirmNewIntegrationModal'
import { DeleteSeatsModal } from './DeleteSeatsModal'
import { EditIntegrationsModal } from './EditIntegrationsModal'
import { ReactivateTeamLimitModal } from './ReactivateTeamLimitModal'
import { SeatModals } from './seatModals.constants'
import { SelectIntegrationModal } from './SelectIntegrationModal'
import { SuspendSeatsModal } from './SuspendSeatsModal'
import { SyncIntegrationModal } from './SyncIntegrationModal'

export const SeatModal = () => {
	const seatModal = useSelector(seatModal$)

	if (!seatModal) return null

	switch (seatModal.type) {
		case SeatModals.ADD_TO_SEATS:
			return <AddPeopleToSeatsModal {...seatModal} />

		case SeatModals.CONFIRM_IDENTITY:
			return <ConfirmIdentityModal {...seatModal} />

		case SeatModals.CONFIRM_INTEGRATION:
			return <ConfirmNewIntegrationModal {...seatModal} />

		case SeatModals.DELETE_SEATS:
			return <DeleteSeatsModal {...seatModal} />

		case SeatModals.EDIT_INTEGRATIONS:
			return <EditIntegrationsModal {...seatModal} />

		case SeatModals.REACTIVATE_TEAM_LIMIT:
			return <ReactivateTeamLimitModal {...seatModal} />

		case SeatModals.SELECT_INTEGRATION:
			return <SelectIntegrationModal />

		case SeatModals.SUSPEND_SEATS:
			return <SuspendSeatsModal {...seatModal} />

		case SeatModals.SYNC_INTEGRATION:
			return <SyncIntegrationModal {...seatModal} />
	}

	return null
}
