import './IntegrationsTable.scss'

import { IntegrationConfigStatuses } from '@motiv-shared/server'
import cls from 'classnames'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import WarningIcon from '../../../assets/logos/warning.svg'
import type { IntegrationDecorated } from '../../../types'
import { MenuWithTooltip } from '../../../widgets/MenuWithTooltip'

type IntegrationConnectionHandler = (integration: IntegrationDecorated) => void

export const integrationNameFormatter = (id: string, row: IntegrationDecorated) => {
	const isIncomplete = row.configStatus === IntegrationConfigStatuses.INCOMPLETE

	return (
		<div className="d-flex align-items-center">
			<Image
				alt={`${row.shortName} Icon`}
				className="d-block"
				style={{ width: '30px' }}
				src={row.iconLogoSm}
			/>

			<span className="ml-4 ml-sm-5 font-weight-bold">{row.displayName}</span>

			{(row.isUnauthorized || isIncomplete) && (
				<Image alt="Warning Icon" className="mx-3" style={{ width: '25px' }} src={WarningIcon} />
			)}
		</div>
	)
}

type ConnectButtonColExtra = {
	readonly isActiveSubFree: boolean
	readonly onConfigure: IntegrationConnectionHandler
	readonly onConnect: IntegrationConnectionHandler
	readonly onDisconnect: IntegrationConnectionHandler
}

export const connectIntegrationFormatter = (
	id: string,
	integration: IntegrationDecorated,
	{ isActiveSubFree, onConfigure, onConnect, onDisconnect }: ConnectButtonColExtra,
) => {
	if (integration.isIntegrated && integration.isConfigurable) {
		// Case : Integrated but can be configured
		const isIncomplete = integration.configStatus === IntegrationConfigStatuses.INCOMPLETE
		return (
			<div
				className={cls(
					'integration-table-menu d-flex flex-row align-items-center',
					isIncomplete ? 'justify-content-between' : 'justify-content-end',
				)}
			>
				{isIncomplete && (
					<p className="font-weight-bold text-underline mb-0">Incomplete integration</p>
				)}

				<MenuWithTooltip>
					<div className="d-flex flex-column">
						<Button variant="light" onClick={() => onConfigure(integration)}>
							{isIncomplete ? 'Select Project' : 'Change Project'}
						</Button>
						<Button
							className="text-danger"
							variant="light"
							onClick={() => onDisconnect(integration)}
						>
							Remove
						</Button>
					</div>
				</MenuWithTooltip>
			</div>
		)
	}

	if (integration.isUnauthorized || integration.isIntegrated) {
		// CASE 1: Already connected integration
		return (
			<span className="integrations-table__action-cell">
				{integration.isUnauthorized && (
					<Button
						block
						onClick={() => onConnect(integration)}
						className="mx-2"
						disabled={isActiveSubFree}
					>
						Reconnect
					</Button>
				)}
				<Button
					block
					onClick={() => onDisconnect(integration)}
					variant="light"
					className="mx-2 mt-0"
				>
					Disconnect
				</Button>
			</span>
		)
	}

	if (!integration.isAvailable) {
		// CASE 2: Unavailable integration (not supported yet)
		return (
			<span className="integrations-table__action-cell">
				<p className="mb-0 font-weight-bolder">Coming Soon</p>
			</span>
		)
	}

	// CASE 3: Ready to connect integration
	return (
		<span className="integrations-table__action-cell">
			<Button
				block
				onClick={() => onConnect(integration)}
				className="mx-2"
				disabled={isActiveSubFree}
			>
				Integrate
			</Button>
		</span>
	)
}
