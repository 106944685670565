import { notNull } from '@eturi/util'
import type { Integration, IntegrationIdentityId, IntegrationProvider } from '@motiv-shared/server'
import { IntegrationProviders, splitIntegrationIdentityId } from '@motiv-shared/server'

export type IntegrationDisplayData = {
	readonly btnClass: string
	readonly displayName: string
	readonly iconLogo: string
	readonly iconLogoSm: string
	readonly shortName: string
}

export type IntegrationDecorated = IntegrationDisplayData &
	MPick<Integration, 'integrationProvider' | 'configStatus'> & {
		readonly id?: Maybe<string>
		readonly isAvailable: boolean
		readonly isConfigurable: boolean
		readonly isIntegrated: boolean
		readonly isPreConfigurable: boolean
		readonly isUnauthorized: boolean
	}

export const AvailableIntegrations = new Set<IntegrationProvider>([
	...(process.env.MOTIV_ACTIVITY_FEED === '1'
		? [IntegrationProviders.GITHUB, IntegrationProviders.GITLAB]
		: []),
	IntegrationProviders.GOOGLE,
	IntegrationProviders.MICROSOFT,
])

export type WithIntegrations<T> = T & { readonly integrations: IntegrationDecorated[] }

export const withIntegrations = <T extends { readonly integrationIds: IntegrationIdentityId[] }>(
	entity: T,
	integrations: IntegrationDecorated[],
): WithIntegrations<T> => ({
	...entity,
	integrations: entity.integrationIds
		.map((integrationId) => {
			const [provider] = splitIntegrationIdentityId(integrationId)

			return integrations.find((i) => i.integrationProvider === provider)
		})
		.filter(notNull),
})
