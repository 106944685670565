import { setIfNotEqual } from '@eturi/util'
import type { PurchaseInfo } from '@motiv-shared/server'
import type { CachedState } from '../createCacheSlice'
import { createCacheSlice } from '../createCacheSlice'
import type { HttpInitHandler } from '../createHttpInitHandler'
import { fetchPaymentSources } from './subscription.asyncActions'

export type PurchaseInfoState = CachedState<Maybe<PurchaseInfo>>
export type WithPurchaseInfoState = {
	readonly purchaseInfo: PurchaseInfoState
}

let initialData: Maybe<PurchaseInfo>

export const createPurchaseInfoSlice = <State>(httpInitHandler: HttpInitHandler<State>) =>
	createCacheSlice('purchaseInfo', initialData, httpInitHandler, (builder) =>
		builder.addCachedCase(fetchPaymentSources.fulfilled, (s, { payload }) => {
			// TODO: How do we identify the valid payment source, if we get many,
			//  which one do we present? In OP we had a status property that let
			//  us know it was active.
			const purchaseInfo = payload[0]

			if (purchaseInfo) {
				setIfNotEqual(s, 'data', purchaseInfo)
			}

			s.isInit = true
		}),
	)

export const sanitizePurchaseInfoState = (s: PurchaseInfoState) => ({
	...s,
	purchaseInfo: null,
})
