import type { SharedAxisProps } from '@visx/axis'
import type { Legend } from '@visx/legend'
import moment from 'moment'
import Colors from '../../styles/color-exports.module.scss'
import Spacers from '../../styles/spacer-exports.module.scss'

export const DEFAULT_LINE_STROKE_WIDTH = 2
export const DEFAULT_LINE_STROKE_DASHARRAY = '4 5'

export const DEFAULT_CIRCLE_POINT_RADIUS = 4

export const DEFAULT_MARGIN = { top: 42, right: 18, bottom: 30, left: 36 }

export const DEFAULT_LEGEND_MARGIN_PROPS: Required<
	MPick<Parameters<typeof Legend>[0], 'itemMargin' | 'labelMargin' | 'shapeMargin'>
> = {
	itemMargin: `0 ${Spacers.spacer_3}`,
	labelMargin: `0 0 0 ${Spacers.spacer_2}`,
	shapeMargin: 0,
}

export const DEFAULT_AXIS_STROKE_PROPS = {
	stroke: Colors.gray_400,
	strokeWidth: DEFAULT_LINE_STROKE_WIDTH,
}

export const DEFAULT_GRID_STROKE_PROPS = {
	stroke: Colors.gray_400,
	strokeOpacity: 0.1,
	strokeWidth: 2,
}

const DEFAULT_TICK_LABEL = {
	fill: Colors.gray_400,
	fontSize: 11,
}

export const DEFAULT_Y_TICK_LABEL_PROPS = {
	...DEFAULT_TICK_LABEL,
	dx: '-.25em',
	dy: '.25em',
	textAnchor: 'end',
} as const

export const DEFAULT_X_TICK_LABEL_PROPS = {
	...DEFAULT_TICK_LABEL,
	textAnchor: 'middle',
} as const

export const DEFAULT_AXIS_Y_PROPS: Partial<SharedAxisProps<any>> = {
	hideAxisLine: true,
	hideTicks: true,
	tickLabelProps: () => DEFAULT_Y_TICK_LABEL_PROPS,
}

export const DEFAULT_AXIS_X_PROPS: Partial<SharedAxisProps<any>> = {
	...DEFAULT_AXIS_STROKE_PROPS,
	tickStroke: Colors.gray_400,
	tickLabelProps: () => DEFAULT_X_TICK_LABEL_PROPS,
}

export const formatDateDefault = (date: number) => moment.utc(date).format('M/D')
