import './SeatMatchModal.scss'

import { useConstant, useFn, useMounted } from '@eturi/react'
import { timeoutPromise } from '@eturi/util'
import find from 'lodash/find'
import random from 'lodash/random'
import { useEffect, useMemo, useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { setSeatModal, setSyncUIComplete } from '../../reducers'
import { useAppDispatch } from '../../store'
import { MotivModal } from '../../widgets/Modal'
import { ProgressCircle } from '../../widgets/ProgressCircle'

const MIN_INTEG_TIME = 2000
const MAX_INTEG_TIME = 3750

type MatchedIntegration = {
	completed: boolean
	matches: number
	name: string
}

type MatchedIntegrationConfig = Pick<MatchedIntegration, 'name' | 'matches'> & {
	duration: number
}

export type SeatMatchModalProps = {
	readonly configs: { readonly name: string; readonly matches: number }[]
}

export const SeatMatchModal = ({ configs }: SeatMatchModalProps) => {
	const dispatch = useAppDispatch()
	const isMounted = useMounted()

	// Generates an array of promises configs that randomly set a duration for each integration
	// based on the entire allotted duration.
	const matchedPromiseConfigs: MatchedIntegrationConfig[] = useConstant(() => {
		return configs.map(({ matches, name }): MatchedIntegrationConfig => {
			const duration = random(MIN_INTEG_TIME, MAX_INTEG_TIME)

			return {
				name,
				matches,
				duration,
			}
		})
	})

	const initialMatchStatuses: MatchedIntegration[] = useConstant(() => {
		return matchedPromiseConfigs.map((m): MatchedIntegration => {
			return {
				completed: false,
				matches: 0,
				name: m.name,
			}
		})
	})

	const [lastCompletedIntegration, setLastCompletedIntegration] =
		useState<Maybe<MatchedIntegration>>(null)

	// Contains a ref to completed matches, state by itself cannot be used
	// as it's async and the component re-renders before the updates take
	// place
	const completedMatchesRef = useRef<MatchedIntegration[]>(initialMatchStatuses)

	// Iterates through the match statuses and counts how many are completed to
	// update the progress bar
	const completedMatches = completedMatchesRef.current.reduce((count, match) => {
		if (match.completed) return count + 1
		return count
	}, 0)

	const progress = (completedMatches / matchedPromiseConfigs.length) * 100

	// Goes through each fake promise and awaits it's given duration before
	// setting that integration as complete
	const resolveMatchedPromises = useFn(async () => {
		for (const config of matchedPromiseConfigs) {
			const { name, matches, duration } = config

			await timeoutPromise(duration / 2)

			if (isMounted()) {
				const update = find(completedMatchesRef.current, { name })

				if (update) {
					update.matches = matches
					update.completed = true
				}

				setLastCompletedIntegration({ name, matches, completed: true })
			}

			await timeoutPromise(duration / 2)
		}

		dispatch(setSyncUIComplete(true))
	})

	const MatchesStatusText = useMemo(() => {
		if (!lastCompletedIntegration) return

		return (
			<p className="seat-match-modal__matched-seats text-center">
				We found {lastCompletedIntegration.matches} matching users in{' '}
				{lastCompletedIntegration.name}.
			</p>
		)
	}, [lastCompletedIntegration])

	useEffect(() => {
		if (!matchedPromiseConfigs || !matchedPromiseConfigs.length) return

		resolveMatchedPromises()
	}, [matchedPromiseConfigs])

	const closeModal = useFn(() => {
		dispatch(setSeatModal(null))
	})

	return (
		<MotivModal
			onHide={closeModal}
			size="md"
			showCloseBtn={false}
			title="Linking Other Integrations"
		>
			<Modal.Body className="my-5">
				<p className="text-center">Searching for matching users from other integrations…</p>

				<div className="text-center">
					<ProgressCircle progress={progress} radius={110} stroke={10} />
				</div>

				{MatchesStatusText}
			</Modal.Body>
		</MotivModal>
	)
}
