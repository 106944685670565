import { useBoolState, useConstant, useFn, useMounted } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import { activeUsers$, fetchUsers, updateUser } from '@motiv-shared/reducers'
import { Form as FormikForm, Formik } from 'formik'
import { useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { useAppDispatch } from '../../../store'
import type { FormikSubmit } from '../../../types'
import { formikCtrlProps } from '../../../util'
import { BusyIndicator, IndicatorRegions } from '../../../widgets/BusyIndicator'
import { FormValidationText } from '../../../widgets/FormValidationText'
import { OutsideCardHeader } from '../../../widgets/OutsideCardHeader'

const TRANSFER_PLACEHOLDER = 'Select New Account Owner'

type TransferAccountFormData = {
	readonly newAccountOwnerUserId?: string
}

export const TransferAccount = ({ className }: { readonly className?: string }) => {
	const dispatch = useAppDispatch()
	const history = useHistory()
	const isMounted = useMounted()
	const activeUsers = useSelector(activeUsers$)

	const [isSaving, startSaving, stopSaving] = useBoolState(false)

	const VALIDATION_SCHEMA = useConstant(() =>
		Yup.object().shape({
			newAccountOwnerUserId: Yup.string().required().min(1),
		}),
	)

	const INITIAL_VALUES = useConstant((): TransferAccountFormData => VALIDATION_SCHEMA.cast({}))

	const handleSubmit: FormikSubmit<TransferAccountFormData> = useFn(async (values) => {
		if (isSaving) return

		startSaving()

		sentryBreadcrumb('Transferring Account')

		try {
			await dispatch(
				updateUser({
					indicatorRegion: IndicatorRegions.TRANSFER_ACCOUNT,
					patch: { roleId: 'ACCOUNT_OWNER' },
					query: { shouldTransferAccount: true },
					userId: values.newAccountOwnerUserId!,
				}),
			).unwrap()

			dispatch(fetchUsers({ force: true }))
			history.push('/')
		} catch (e) {
			sentryError(e, 'Failed to Transfer Account')
		}

		isMounted() && stopSaving()
	})

	const otherUsersOptions = useMemo(
		() =>
			activeUsers
				.filter((u) => u.roleId !== 'ACCOUNT_OWNER')
				.map(({ fullName, id }) => (
					<option key={id} value={id}>
						{fullName}
					</option>
				)),
		[activeUsers],
	)

	return (
		<div className={className}>
			<OutsideCardHeader>Transfer Account</OutsideCardHeader>

			<Card>
				<Card.Body>
					<h6 className="mb-6">Transfer Account Ownership Role to Another User</h6>

					<p className="mb-5">
						Transfer your ownership role to another user by selecting the user from the dropdown
						below.
					</p>

					<Formik<TransferAccountFormData>
						initialValues={INITIAL_VALUES}
						onSubmit={handleSubmit}
						validateOnMount
						validationSchema={VALIDATION_SCHEMA}
					>
						{(p) => {
							const getCtrlProps = formikCtrlProps(p)

							return (
								<BusyIndicator region={IndicatorRegions.TRANSFER_ACCOUNT}>
									<Form as={FormikForm}>
										<Row>
											<Col className="mb-4 mb-md-0" md={6}>
												<Form.Control as="select" {...getCtrlProps('newAccountOwnerUserId')}>
													<option value="">{TRANSFER_PLACEHOLDER}</option>
													{otherUsersOptions}
												</Form.Control>

												<FormValidationText field="newAccountOwnerUserId" formikProps={p} />
											</Col>

											<Col md={4}>
												<Button
													block
													disabled={!p.isValid || isSaving}
													size="lg"
													type="submit"
													variant="danger"
												>
													Transfer Ownership
												</Button>
											</Col>
										</Row>
									</Form>
								</BusyIndicator>
							)
						}}
					</Formik>
				</Card.Body>
			</Card>
		</div>
	)
}
