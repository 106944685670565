import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'
import { generatePath, useRouteMatch } from 'react-router-dom'
import { truncateId } from '../../util/truncateId'
import { AvatarWithUserName } from '../../widgets/Avatar'
import { ListGroupLink } from '../../widgets/ListGroupLink'
import type { TeamRouteParams } from './dashboard-routes'
import { DashboardRoutes } from './dashboard-routes'
import { useDashboardState } from './hooks'

export const DashboardTeamMembersList = () => {
	const { teamUsers } = useDashboardState()
	const { params } = useRouteMatch<TeamRouteParams>()

	if (!teamUsers) return null

	return (
		<Card>
			<Card.Header as="h6">Team Members</Card.Header>

			<ListGroup variant="flush">
				{teamUsers.map((m) => (
					<ListGroupLink
						key={m.id}
						to={generatePath(DashboardRoutes.User.path, {
							...params,
							userId: truncateId(m.id),
						})}
					>
						<AvatarWithUserName avatarUrl={m.avatarUrl} fullName={m.fullName} />
					</ListGroupLink>
				))}
			</ListGroup>
		</Card>
	)
}
