import { setIfNotEqual } from '@eturi/util'
import type { SKUDef } from '@motiv-shared/server'
import { castDraft } from 'immer'
import type { CachedState } from '../createCacheSlice'
import { createCacheSlice } from '../createCacheSlice'
import type { HttpInitHandler } from '../createHttpInitHandler'
import { fetchSKUDefs } from './subscription.asyncActions'

export type SKUDefsState = CachedState<SKUDef[]>
export type WithSKUDefsState = {
	readonly skuDefs: SKUDefsState
}

const initialData: SKUDef[] = []

export const createSKUDefsSlice = <State>(httpInitHandler: HttpInitHandler<State>) =>
	createCacheSlice('skuDefs', initialData, httpInitHandler, (builder) =>
		builder.addCachedCase(fetchSKUDefs.fulfilled, (s, { payload }) => {
			setIfNotEqual(s, 'data', castDraft(payload.defs))
			s.isInit = true
		}),
	)
