import { useBoolState, useFn, useMounted } from '@eturi/react'
import { sentryBreadcrumb, sentryError } from '@eturi/sentry'
import { updateManagedUser } from '@motiv-shared/reducers'
import { ManagedUserState } from '@motiv-shared/server'
import type { ManagedUserDecorated } from '../compound-selectors'
import { addSuccessToast } from '../reducers'
import { useAppDispatch } from '../store'

export const useActivateSeat = () => {
	const dispatch = useAppDispatch()
	const [isActivating, startActivating, stopActivating] = useBoolState(false)
	const isMounted = useMounted()

	const activateSeat = useFn(async (user: ManagedUserDecorated): Promise<boolean> => {
		if (isActivating) return false

		startActivating()
		sentryBreadcrumb('Activating seat')

		try {
			await dispatch(
				updateManagedUser({ id: user.id, patch: { state: ManagedUserState.ACTIVE } }),
			).unwrap()

			dispatch(addSuccessToast(`${user.fullName} has been reactivated.`))
			return true
		} catch (e) {
			sentryError(e, 'Failed to activate seat.')
			return false
		} finally {
			isMounted() && stopActivating()
		}
	})

	return [activateSeat, isActivating] as const
}
