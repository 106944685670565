import { hasInactiveManagedUsers$ } from '@motiv-shared/reducers'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { SideNav } from '../../widgets/SideNav'

export const SeatsSideNav = () => {
	const hasInactiveManagedUsers = useSelector(hasInactiveManagedUsers$)
	const { pathname } = useLocation()

	const links = [{ url: '/teams/seats/active', label: 'Active' }]
	const SUSPENDED_LINK = { url: '/teams/seats/suspended', label: 'Suspended' }

	if (hasInactiveManagedUsers || pathname === SUSPENDED_LINK.url) {
		links.push(SUSPENDED_LINK)
	}

	return <SideNav title="Seats" links={links} />
}
