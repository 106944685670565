import type { AddToTeamModalProps } from './AddToTeamModal'
import type { DeleteTeamModalProps } from './DeleteTeamModal'
import type { EditTeamInfoModalProps } from './EditTeamInfoModal'
import type { RemoveTeamAssignmentsModalProps } from './RemoveTeamAssignmentsModal'

export const TeamsModals = {
	ADD_TO_TEAM: 'addToTeam',
	DELETE_TEAM: 'deleteTeam',
	EDIT_TEAM: 'editTeam',
	REMOVE_TEAM_ASSIGNMENTS: 'removeTeamAssignments',
	UPDATE_TEAM: 'updateTeam',
} as const

export type TeamsModalType =
	| AddToTeamModal
	| DeleteTeamModal
	| EditTeamInfoModal
	| RemoveTeamAssignmentsModal

export type AddToTeamModal = {
	readonly type: typeof TeamsModals.ADD_TO_TEAM
} & AddToTeamModalProps

export type DeleteTeamModal = {
	readonly type: typeof TeamsModals.DELETE_TEAM
} & DeleteTeamModalProps

export type RemoveTeamAssignmentsModal = {
	readonly type: typeof TeamsModals.REMOVE_TEAM_ASSIGNMENTS
} & RemoveTeamAssignmentsModalProps

export type EditTeamInfoModal = {
	readonly type: typeof TeamsModals.EDIT_TEAM
} & EditTeamInfoModalProps
