import { teams$ } from '@motiv-shared/reducers'
import { createSelector } from '@reduxjs/toolkit'

export const sortedTeams$ = /*@__PURE__*/ createSelector(teams$, (teams) =>
	[...teams].sort((a, b) =>
		a.name.localeCompare(b.name, undefined, {
			numeric: true,
			sensitivity: 'base',
		}),
	),
)
