import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import INFO_CIRCLE from '../../../assets/icons/information-circle-outline.svg'
import { OutsideCardHeader } from '../../../widgets/OutsideCardHeader'

export const CompanyHeader = () => {
	return (
		<OutsideCardHeader className="justify-content-between">
			Company
			<OverlayTrigger
				delay={85}
				overlay={
					<Tooltip id="company-header-info">
						We collect this information to accurately display data.
					</Tooltip>
				}
				placement="left"
				trigger={['click', 'focus', 'hover']}
			>
				<Button className="p-2" variant="link">
					<img alt="Info icon" src={INFO_CIRCLE} height={28} width={28} />
				</Button>
			</OverlayTrigger>
		</OutsideCardHeader>
	)
}
