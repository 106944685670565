import { pushIf } from '@eturi/util'
import { LegendOrdinal } from '@visx/legend'
import { scaleOrdinal } from '@visx/scale'
import { useMemo } from 'react'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useResizeObserver } from '../../hooks/useResizeObserver'
import { ParentSize } from '../../widgets/ParentSize'
import { AttendanceRateChart } from './AttendanceRateChart'
import { AttendanceRatioChart } from './AttendanceRatioChart'
import { AttendanceSummaryItem } from './AttendanceSummaryItem'
import { DEFAULT_LEGEND_MARGIN_PROPS } from './defaults'
import { useAttendanceData } from './hooks'
import { useAttendanceRateData } from './hooks/useAttendanceRateData'
import { LegendContainer } from './LegendContainer'
import type { AttendanceOverview } from './types'

type AttendanceByOccurrenceProps = {
	readonly overview: Maybe<AttendanceOverview>
}

const EMPTY_OVERVIEW: AttendanceOverview = {
	attendanceAvailable: false,
	attendedAdhocMeetingMinutes: 0,
	attendedOneTimeMeetingMinutes: 0,
	attendedRecurringMeetingMinutes: 0,
	meetingMinutes: 0,
	meetingRecurringMinutes: 0,
}

export const AttendanceByOccurrence = ({ overview }: AttendanceByOccurrenceProps) => {
	overview ||= EMPTY_OVERVIEW
	const attendanceData = useAttendanceData(overview)
	const rateData = useAttendanceRateData(overview)
	// NOTE: We're setting the height of the attendance rate graph to the
	//  intrinsic height of the summary item column.
	const [rightColRect, setRightColRef] = useResizeObserver({
		ignoreDimensions: ['left', 'top', 'width'],
	})

	// TODO: Add `Scheduled` legend indicator somehow
	const legendScale = useMemo(() => {
		const { adhoc, isAttendanceVisible, nonRecurring, recurring } = attendanceData

		return scaleOrdinal({
			domain: [recurring.label, nonRecurring.label, ...pushIf(isAttendanceVisible, adhoc.label)],
			range: [recurring.color, nonRecurring.color, ...pushIf(isAttendanceVisible, adhoc.color)],
		})
	}, [attendanceData])

	return (
		<Card>
			<Card.Header as="h6">Attendance by Occurrence</Card.Header>

			<Card.Body className="px-4">
				<LegendContainer className="position-static">
					<LegendOrdinal
						{...DEFAULT_LEGEND_MARGIN_PROPS}
						className="flex-center mb-2 w-100"
						direction="row"
						scale={legendScale}
						shape="circle"
					/>
				</LegendContainer>

				<Row>
					<Col md={6}>
						<h5 className="mb-5">Occurrence Distribution</h5>

						<Row className="mb-5">
							<Col className="d-flex flex-column" xs={8}>
								<div
									className="align-self-center d-flex flex-column flex-center flex-fill w-100"
									style={{ maxHeight: '350px', maxWidth: '350px' }}
								>
									<ParentSize className="d-flex align-items-center flex-fill">
										{({ width }) => (
											<AttendanceRatioChart data={attendanceData} height={width} width={width} />
										)}
									</ParentSize>
								</div>
							</Col>

							<Col className="d-flex justify-content-center pl-0 mt-n1" xs={4}>
								<div>
									<AttendanceSummaryItem
										{...attendanceData.recurring}
										total={attendanceData.total}
									/>
									<AttendanceSummaryItem
										{...attendanceData.nonRecurring}
										total={attendanceData.total}
									/>
									{attendanceData.isAttendanceVisible && (
										<AttendanceSummaryItem {...attendanceData.adhoc} total={attendanceData.total} />
									)}
								</div>
							</Col>
						</Row>
					</Col>

					<Col md={6}>
						<h5 className="mb-5">Attendance Rate</h5>

						<Row className="pb-4">
							<Col className="d-flex flex-column" xs={8}>
								<div className="flex-fill" style={{ overflow: 'hidden' }}>
									<ParentSize>
										{({ width }) => (
											<AttendanceRateChart
												height={rightColRect.height}
												width={width}
												{...rateData}
											/>
										)}
									</ParentSize>
								</div>
							</Col>

							<Col className="d-flex justify-content-center pl-0" xs={4}>
								<div ref={setRightColRef}>
									<AttendanceSummaryItem {...rateData.recurring} />
									<AttendanceSummaryItem {...rateData.nonRecurring} />
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	)
}
