import './SubOptions.scss'

import { useFn, useToggleState } from '@eturi/react'
import { isPaidSub$, isTrialSubSKU$, nextSub$, seatSKU$ } from '@motiv-shared/reducers'
import { SubscriptionPeriod } from '@motiv-shared/server'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { ChangeEvent } from 'react'
import { useMemo, useRef, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useSelector } from 'react-redux'
import {
	addErrorToast,
	isSubscribingMonthly$,
	setActivePurchaseModal,
	setSubscribingInfo,
} from '../../reducers'
import { useAppDispatch } from '../../store'
import type { MotivToast } from '../../types'
import { getSKUPrice, getSKUPriceMonthlyPerSeat, PurchaseModalType } from '../../types'
import { bup } from '../../util/batchUpdates'
import { OutsideCardHeader } from '../../widgets/OutsideCardHeader'
import { SlideToggle } from '../../widgets/SlideToggle'

const DEFAULT_MAX_SEATS = 100
const DEFAULT_MIN_SEATS = 10

export const SubOptions = () => {
	const dispatch = useAppDispatch()
	const isPaidSub = useSelector(isPaidSub$)
	const isSubscribingMonthly = useSelector(isSubscribingMonthly$)
	const isTrialSubSKU = useSelector(isTrialSubSKU$)
	const nextSub = useSelector(nextSub$)
	const seatSKU = useSelector(seatSKU$)
	// Only show one seat limit toast
	const seatLimitToastRef = useRef<PayloadAction<MotivToast> | null>(null)

	const MAX_SEATS = seatSKU?.maxQuantity || DEFAULT_MAX_SEATS
	const MIN_SEATS = seatSKU?.minQuantity || DEFAULT_MIN_SEATS

	const [isMonthlySelected, toggleMonthlySelected] = useToggleState(isSubscribingMonthly)
	const [seatCount, setSeatCount] = useState(
		!isTrialSubSKU && isPaidSub ? nextSub?.quantity || MIN_SEATS : MIN_SEATS,
	)
	// NOTE: We have to handle seatCountValue (that is used in the <input/>)
	//  separately from the actual model `seatCount`. This allows users to delete
	//  or change values in ways would be invalid for `seatCount`. E.g. the
	//  input says "10" and they want to delete the "0" to type a "7" for "17".
	//  This allows these cases during manual editing, and then the value is
	//  normalized to `seatCount`, either on blur, or when using +/- buttons.
	const [seatCountValue, setSeatCountValue] = useState<number | string>(seatCount)

	const period = isMonthlySelected ? SubscriptionPeriod.MONTHLY : SubscriptionPeriod.ANNUAL

	const isOptionsSame = useMemo(() => {
		if (!nextSub) return false

		const isCycleSame =
			(nextSub.period === SubscriptionPeriod.MONTHLY && isMonthlySelected) ||
			(nextSub.period === SubscriptionPeriod.ANNUAL && !isMonthlySelected)
		const isSeatsSame = nextSub.quantity === seatCount
		return isCycleSame && isSeatsSame
	}, [nextSub, isMonthlySelected, seatCount])

	const parseSeatCount = (count: string | number): number => {
		const parsedCount = Number.parseInt(count)

		if (Number.isNaN(parsedCount)) return MIN_SEATS

		return Math.min(MAX_SEATS, Math.max(MIN_SEATS, parsedCount))
	}

	const showMaxLimitReachToast = useFn(() => {
		const toast = (seatLimitToastRef.current ||= addErrorToast({
			title: 'Seat Limit Reached',
			msg: `You have reached the maximum of ${MAX_SEATS} seats. Email support@motivapp.com to add more seats.`,
		}))

		dispatch(toast)
	})

	const updateSeatCount = useFn((count: number | string) => {
		const newCount = parseSeatCount(count)

		if (newCount === MAX_SEATS) {
			showMaxLimitReachToast()
		}

		bup(() => {
			setSeatCountValue(newCount)
			setSeatCount(newCount)
		})

		return newCount
	})

	// When the field blurs we correct any values that the user might have manually
	// input
	const handleInputBlur = useFn((ev: ChangeEvent<HTMLInputElement>) => {
		updateSeatCount(ev.target.value)
	})

	const handleInputChange = useFn((ev: ChangeEvent<HTMLInputElement>) => {
		const normalizedCount = Number.parseInt(ev.target.value)

		// Allow empty input while editing
		if (!Number.isFinite(normalizedCount)) {
			return setSeatCountValue('')
		}

		// Set the seat count input value only
		if (normalizedCount < MIN_SEATS || normalizedCount > MAX_SEATS) {
			return setSeatCountValue(normalizedCount)
		}

		updateSeatCount(normalizedCount)
	})

	const handleSubOptionsChange = useFn(() => {
		dispatch(
			setSubscribingInfo(
				seatCount,
				isMonthlySelected ? SubscriptionPeriod.MONTHLY : SubscriptionPeriod.ANNUAL,
			),
		)

		dispatch(setActivePurchaseModal(PurchaseModalType.PURCHASE))
	})

	if (!seatSKU) return null

	// SKU Price is cost per cycle per seat, so to get the cost of each seat it would be
	const costPerSeat = getSKUPriceMonthlyPerSeat(seatSKU, period)
	const costPerMonth = costPerSeat * seatCount
	const totalCost = getSKUPrice(seatSKU, period, seatCount)

	return (
		<div className="sub-options">
			<OutsideCardHeader>
				{isTrialSubSKU || !isPaidSub ? 'Subscription Options' : 'Update Plan'}
			</OutsideCardHeader>

			<Card>
				<Card.Body className="py-5">
					<Row>
						<Col className="flex-column flex-center mb-5" sm={12}>
							<h4 className="mb-2">Billing Cycle</h4>

							<p className="mb-5">(save up to 50% by switching to annual)</p>

							<div className="d-flex align-items-center">
								<h6 className="mb-0 text-center">Pay Monthly</h6>

								<SlideToggle
									checked={!isMonthlySelected}
									className="mx-4"
									onChange={toggleMonthlySelected}
								/>

								<h6 className="mb-0 text-center">Pay Annually</h6>
							</div>
						</Col>

						<Col sm={12}>
							<Row>
								<Col
									className="d-flex flex-column align-items-center justify-content-end mb-5 mb-sm-0"
									xs={12}
									sm={6}
								>
									<h4 className="mb-0">Seats</h4>
									<p className="mb-3">{`$${costPerSeat} / month per seat`}</p>

									<div className="form-control sub-seats__selector content-light" tabIndex={0}>
										<Button
											className="flex-center sub-seats__icon"
											disabled={seatCount <= MIN_SEATS}
											onClick={() => updateSeatCount(seatCount - 1)}
											variant="light"
										>
											{`-`}
										</Button>

										<input
											className="sub-seats__input text-center border-0"
											max={MAX_SEATS}
											min={MIN_SEATS}
											onChange={handleInputChange}
											onBlur={handleInputBlur}
											type="number"
											value={seatCountValue}
										/>

										<Button
											className="flex-center sub-seats__icon"
											disabled={seatCount >= MAX_SEATS}
											onClick={() => updateSeatCount(seatCount + 1)}
											variant="light"
										>
											{`+`}
										</Button>
									</div>
								</Col>

								<Col className="d-flex flex-column align-items-center" xs={12} sm={6}>
									<h4 className="mb-0">{`$${costPerMonth} / month`}</h4>

									<p className="mb-3">
										billed {isMonthlySelected ? 'monthly' : `$${totalCost} annually`}
									</p>

									<Button
										block
										disabled={isOptionsSame}
										onClick={handleSubOptionsChange}
										size="lg"
										style={{ maxWidth: '275px' }}
										variant="success"
									>{`${isPaidSub ? 'Update Plan' : 'Subscribe Now'}`}</Button>
								</Col>
							</Row>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</div>
	)
}
