import { createSelector } from '@reduxjs/toolkit'
import type { WithAuthState } from './auth.slice'

const state$ = <T extends WithAuthState>(s: T) => s.auth

const authData$ = /* @__PURE__ */ createSelector(state$, (s) => s.data)

export const authUser$ = /* @__PURE__ */ createSelector(authData$, (s) => s?.user)
export const jwtToken$ = /* @__PURE__ */ createSelector(authData$, (s) => s?.token)
export const isAuthenticated$ = /* @__PURE__ */ createSelector(authData$, Boolean)
export const isAuthInit$ = /* @__PURE__ */ createSelector(state$, (s) => s.isInit)
export const isAccountDeleted$ = /* @__PURE__ */ createSelector(state$, (s) => s.isAccountDeleted)
export const shouldValidateAuth$ = /* @__PURE__ */ createSelector(state$, (s) => s.shouldValidate)
