import { createSelector } from '@reduxjs/toolkit'
import type { IndicatorRegion } from './busyIndicator.constants'
import { IndicatorRegions } from './busyIndicator.constants'
import type { WithBusyIndicatorState } from './busyIndicator.slice'

const state$ = <T extends WithBusyIndicatorState>(s: T) => s.busyIndicator

const isGlobalBusy$ = createSelector(state$, (s) => Boolean(s[IndicatorRegions.GLOBAL]))

export const createNamedBusyIndicatorSelector = () =>
	createSelector(
		state$,
		isGlobalBusy$,
		(_: any, regions: Maybe<IndicatorRegion[]>): IndicatorRegion[] =>
			regions || [IndicatorRegions.GLOBAL],
		(s, isGlobalBusy, regions) => isGlobalBusy || regions.some((r) => s[r]),
	)
