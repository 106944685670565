export enum MotivToastType {
	ERROR = 'error',
	SUCCESS = 'success',
}

export type MotivToast = {
	readonly id: string
	readonly msg: string
	readonly title: string
	readonly type: MotivToastType
}

export const DEFAULT_ERROR_TOAST = {
	title: 'Error',
	type: MotivToastType.ERROR,
}

export const DEFAULT_SUCCESS_TOAST = {
	title: 'Success',
	type: MotivToastType.SUCCESS,
}
