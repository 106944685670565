import { useSelector } from 'react-redux'
import { userModal$ } from '../../reducers'
import { DeleteUserModal } from './DeleteUserModal'
import { InviteAdminModal } from './InviteAdminModal'
import { InvitedUserModal } from './InvitedUserModal'
import { ManageUserModal } from './ManageUserModal'
import { UserModals } from './userModals.constants'

export const UserModal = () => {
	const userModal = useSelector(userModal$)

	if (userModal == null) {
		return null
	}

	switch (userModal.type) {
		case UserModals.DELETE_USER:
			return <DeleteUserModal {...userModal} />

		case UserModals.ADD_USER:
			return <ManageUserModal />

		case UserModals.EDIT_USER:
			return <ManageUserModal {...userModal} />

		case UserModals.VIEW_INVITED_USER:
			return <InvitedUserModal {...userModal} />

		case UserModals.INVITE_ADMIN:
			return <InviteAdminModal />
	}

	return null
}
