import './InvitedUserModal.scss'

import { useConstant, useFn } from '@eturi/react'
import { sentryError } from '@eturi/sentry'
import { createUserByIdSelector, updateUser } from '@motiv-shared/reducers'
import { hasAdminRole } from '@motiv-shared/server'
import truncate from 'lodash/truncate'
import type { ReactNode } from 'react'
import { useMemo } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import { addSuccessToast, setUserModal } from '../../reducers'
import { useAppDispatch, useSelector } from '../../store'
import { BusyIndicator, IndicatorRegions } from '../../widgets/BusyIndicator'
import { MotivModal } from '../../widgets/Modal'
import { isInviteExpired } from '../Settings/Users/isInviteExpired'

export type InvitedUserModalProps = {
	readonly userId: string
}

const TEAM_NAME_CHAR_LIMIT = 12 // will truncate if length greater than 12

export const InvitedUserModal = ({ userId }: InvitedUserModalProps) => {
	const dispatch = useAppDispatch()
	const userById$ = useConstant(createUserByIdSelector)
	const pendingUserViewed = useSelector((s) => userById$(s, userId))
	const isUserAdminOnly = hasAdminRole(pendingUserViewed)

	const pendingUserViewedRole = pendingUserViewed?.role
	const pendingUserViewedTeams = pendingUserViewed?.assignedTeams || []

	const handleClose = useFn(() => {
		dispatch(setUserModal(null))
	})

	const isExpired = useMemo(
		() => isInviteExpired(pendingUserViewed?.settings?.invitationTs),
		[pendingUserViewed],
	)

	const handleResendInvite = useFn(async () => {
		if (pendingUserViewed == null) return

		try {
			await dispatch(
				updateUser({
					indicatorRegion: IndicatorRegions.RESEND_INVITE,
					query: { resendInvite: true },
					userId: pendingUserViewed.id,
					errorMessage: {
						title: 'Invite failed',
						msg: 'We encountered a problem. Please try again.',
					},
				}),
			).unwrap()

			dispatch(
				addSuccessToast({
					title: 'Invite sent',
					msg: `${pendingUserViewed.fullName} has been invited to join your account.`,
				}),
			)
		} catch (e) {
			sentryError(e, 'Failed to resend invite.')
		}

		handleClose()
	})

	if (pendingUserViewed == null) {
		handleClose()
		return null
	}

	const teamNames = pendingUserViewedTeams
		.map(({ name }) => (
			<span key={name} title={name}>
				{truncate(name, { length: TEAM_NAME_CHAR_LIMIT, omission: '...' })}
			</span>
		))
		.reduce((acc: ReactNode[] | null, x) => (acc === null ? [x] : [...acc, ', ', x]), null) // in lieu of .join(', ')

	return (
		<MotivModal
			className="invited-user-modal"
			onHide={handleClose}
			size="lg"
			title={isExpired ? 'Expired User' : 'Pending User'}
		>
			<BusyIndicator region={IndicatorRegions.RESEND_INVITE}>
				<Modal.Body>
					<Row>
						<Col sm={6}>
							<div className="invited-user-modal__label">Name</div>
							<div className="invited-user-modal__value text-break">
								{pendingUserViewed.fullName}
							</div>
						</Col>

						<Col sm={6}>
							<div className="invited-user-modal__label">Email</div>
							<div className="invited-user-modal__value text-break">{pendingUserViewed.email}</div>
						</Col>
					</Row>

					<Row>
						<Col sm={6}>
							<div className="invited-user-modal__label">Role</div>
							<div className="invited-user-modal__value text-break">
								{pendingUserViewedRole?.label || ''}
							</div>
						</Col>

						{!isUserAdminOnly && (
							<Col sm={6}>
								<div className="invited-user-modal__label">Team(s) Assigned to User</div>
								<div className="invited-user-modal__value text-break">{teamNames}</div>
							</Col>
						)}
					</Row>
				</Modal.Body>

				<Modal.Footer>
					<Button onClick={handleResendInvite} size="lg" variant="success">
						Resend Invite
					</Button>

					<Button onClick={handleClose} size="lg" variant="light-link">
						Cancel
					</Button>
				</Modal.Footer>
			</BusyIndicator>
		</MotivModal>
	)
}
