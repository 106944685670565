import type { MotivIntegrationIdentity } from '@motiv-shared/server'
import { createAsyncThunk } from '../../createAsyncThunk'
import type { HttpExtra } from '../../http'

type FetchIntegrationIdentities = HttpExtra & {
	readonly integrationId: string
}

export const fetchIntegrationIdentities = /* @__PURE__ */ createAsyncThunk(
	'integrationIdentities/integrationId',
	({ integrationId, ...extra }: FetchIntegrationIdentities, { dispatch, extra: { http } }) =>
		dispatch(
			http.get<MotivIntegrationIdentity[]>(
				`integration-identities?integrationId=${integrationId}`,
				{
					errorMessage: `Unable to retrieve organization's users.`, // TODO: Get product requirement for this failure
					...extra,
				},
			),
		),
)
