import type {
	EmailSummary,
	GeneralEmailsByTeamMemberPeriod,
	GeneralEmailsByTeamPeriod,
} from '@motiv-shared/server'
import orderBy from 'lodash/orderBy'
import { useMemo, useState } from 'react'
import Card from 'react-bootstrap/Card'
import { ParentSize } from '../../../widgets/ParentSize'
import { DEFAULT_MARGIN } from '../defaults'
import { GraphContainer } from '../GraphContainer'
import type { EmailOverviewData } from './EmailOverviewGraph'
import { EmailOverviewGraph } from './EmailOverviewGraph'
import { EmailOverviewTypeControl } from './EmailOverviewTypeControl'

type GeneralEmailsPeriod = GeneralEmailsByTeamPeriod | GeneralEmailsByTeamMemberPeriod
type GeneralEmails = {
	readonly current: GeneralEmailsPeriod
	readonly previous: GeneralEmailsPeriod
}
type EmailOverviewProps = {
	readonly emails: Maybe<GeneralEmails>
}

const margin = { ...DEFAULT_MARGIN, top: 48 }

export const EmailOverview = ({ emails }: EmailOverviewProps) => {
	if (!(emails?.current && emails?.previous)) return null

	return <EmailOverviewImpl {...emails} />
}

type EmailOverviewDataTypeKey = keyof MPick<EmailSummary, 'numEmailsReceived' | 'numEmailsSent'>
export type EmailOverviewDataType = {
	readonly field: EmailOverviewDataTypeKey
	readonly label: string
	readonly legend: string
}
const Received = {
	field: 'numEmailsReceived',
	label: 'Emails Received',
	legend: 'Received',
} as const

const Sent = {
	field: 'numEmailsSent',
	label: 'Emails Sent',
	legend: 'Sent',
} as const

export const EmailOverviewImpl = (emails: GeneralEmails) => {
	const [selectedType, setSelectedType] = useState<EmailOverviewDataType>(Received)
	const data = useEmailChartData(emails.current, selectedType.field)
	const totals = useEmailTotals(emails)

	return (
		<Card>
			<Card.Header as="h6">Emails</Card.Header>

			<Card.Body className="px-0 px-sm-3">
				<div className="w-100 d-flex mx-auto" style={{ maxWidth: '500px' }}>
					<EmailOverviewTypeControl
						current={totals.currReceived}
						previous={totals.prevReceived}
						isActive={selectedType === Received}
						onSelect={setSelectedType}
						type={Received}
					/>

					<EmailOverviewTypeControl
						current={totals.currSent}
						previous={totals.prevSent}
						isActive={selectedType === Sent}
						onSelect={setSelectedType}
						type={Sent}
					/>
				</div>

				<hr className="mt-0 mb-3" />

				<GraphContainer>
					<ParentSize>
						{({ height, width }) => {
							const innerWidth = width - margin.left - margin.right
							const innerHeight = height - margin.top - margin.bottom

							if (Math.min(innerHeight, innerWidth) <= 0) return null

							return (
								<EmailOverviewGraph
									data={data}
									legendLabel={selectedType.legend}
									height={height}
									innerHeight={innerHeight}
									innerWidth={innerWidth}
									margin={margin}
									width={width}
								/>
							)
						}}
					</ParentSize>
				</GraphContainer>
			</Card.Body>
		</Card>
	)
}

const useEmailTotals = (emails: GeneralEmails) =>
	useMemo(() => {
		const currTotal = emails.current.total
		const prevTotal = emails.previous.total

		return {
			currReceived: currTotal.numEmailsReceived,
			currSent: currTotal.numEmailsSent,
			prevReceived: prevTotal.numEmailsReceived,
			prevSent: prevTotal.numEmailsSent,
		}
	}, [emails])

const useEmailChartData = (emails: GeneralEmailsPeriod, typeKey: EmailOverviewDataTypeKey) =>
	useMemo(
		(): EmailOverviewData[] =>
			orderBy(
				Object.entries(emails.summaryByDate).map(([date, data]) => ({
					date,
					value: data[typeKey],
				})),
				'date',
				'asc',
			),
		[emails, typeKey],
	)
