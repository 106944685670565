import './Toast.scss'

import { useFn, usePrevious } from '@eturi/react'
import { a, config, useTransition } from '@react-spring/web'
import map from 'lodash/map'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { closeToast, toasts$ } from '../../reducers'
import { useAppDispatch } from '../../store'
import type { ToastProps } from './Toast'
import { Toast } from './Toast'

export const ToastsContainer = () => {
	const dispatch = useAppDispatch()
	const motivToasts = useSelector(toasts$)

	const closeMotivToast = useFn((id: string) => dispatch(closeToast(id)))

	const [toasts, toastKeys] = useMemo(() => {
		const toasts = motivToasts.map((t): ToastProps => ({ ...t, onClose: closeMotivToast }))
		const toastKeys = map(toasts, 'id')

		return [toasts, toastKeys] as const
	}, [motivToasts])

	const currTotalToasts = toasts.length
	const prevTotalToasts = usePrevious(currTotalToasts)

	const transitionToasts = useTransition(toasts, {
		keys: toastKeys,
		from: {
			opacity: 0,
			transform: 'translate3d(0%, -150%, 0)',
		},
		enter: {
			opacity: 1,
			transform: 'translate3d(0%, 0%, 0)',
		},
		leave: {
			opacity: 0,
			transform: 'translate3d(-150%, 0%, 0)',
		},
		config: config.gentle,
		trail: 100,
	})

	useEffect(() => {
		if (prevTotalToasts == null) return

		// When we have new toast, scroll to top
		if (currTotalToasts > prevTotalToasts) window.scrollTo(0, 0)
	}, [currTotalToasts, prevTotalToasts])

	return (
		<section className="toasts">
			{transitionToasts((style, item) => (
				<a.div className="toast-container" style={style}>
					<Toast {...item} />
				</a.div>
			))}
		</section>
	)
}
