import type { HTMLAttributes, ReactNode } from 'react'
import type { UseResizeObserverConfig, UseResizeObserverRect } from '../hooks/useResizeObserver'
import { useResizeObserver } from '../hooks/useResizeObserver'

type ParentSizeProps = MOmit<HTMLAttributes<HTMLDivElement>, 'children'> &
	UseResizeObserverConfig & {
		readonly children: (arg: UseResizeObserverRect) => ReactNode
	}

export const ParentSize = ({
	children,
	debounceTime,
	floor,
	ignoreDimensions,
	style = { height: '100%', width: '100%' },
	...p
}: ParentSizeProps) => {
	const [rect, setRectRef] = useResizeObserver({ debounceTime, floor, ignoreDimensions })

	return (
		<div {...p} ref={setRectRef} style={style}>
			{children(rect)}
		</div>
	)
}
