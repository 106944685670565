import './SlideToggle.scss'

import { useChgState, useFn } from '@eturi/react'
import cls from 'classnames'
import type { ChangeEvent } from 'react'
import { useEffect, useRef } from 'react'
import { useHandleSynStop, useKeyboardClick } from '../../hooks'

type SlideToggleProps = {
	readonly checked?: boolean
	readonly className?: string
	readonly onChange: (isChecked: boolean) => void
}

export const SlideToggle = ({ checked = false, className, onChange }: SlideToggleProps) => {
	const [isChecked, setChecked] = useChgState(checked)
	const inputRef = useRef<HTMLInputElement>(null)

	const handleInputChange = useFn((ev: ChangeEvent<HTMLInputElement>) => {
		const checked = Boolean(ev.target.checked)
		setChecked(checked)
		onChange(checked)
	})

	// Stop prop on input so we don't get two clicks on wrapping handler
	const stopProp = useHandleSynStop()

	const handleClick = useKeyboardClick((ev) => {
		// Have to prevent default here for this to work. Not sure why, although
		// default behavior does seem to remove focus on click.
		ev.preventDefault()

		// Proxy to input
		inputRef.current?.click()
	})

	useEffect(() => {
		checked != null && setChecked(checked)
	}, [checked])

	// Note that we keep the input around only to proxy events. If we ever need
	//  to make this compatible w/ forms, we're covered. It's hidden and can't
	//  accept focus (tabIndex)
	return (
		<label
			className={cls('slide-toggle', isChecked && 'slide-toggle--checked', className)}
			tabIndex={0}
			{...handleClick}
		>
			<input
				checked={isChecked}
				className="hide-visually"
				onChange={handleInputChange}
				onClick={stopProp}
				ref={inputRef}
				tabIndex={-1}
				type="checkbox"
			/>
		</label>
	)
}
