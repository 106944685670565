import {
	hasItAdminUser$,
	isSetupInstructionsEmailSent$,
	isUserAccountOwner$,
	user$,
} from '@motiv-shared/reducers'
import { hasPermission, UserPermissions } from '@motiv-shared/server'
import { createSelector } from '@reduxjs/toolkit'

export const shouldShowWelcome$ = /*@__PURE__*/ createSelector(
	hasItAdminUser$,
	isSetupInstructionsEmailSent$,
	isUserAccountOwner$,
	(hasItAdmin, isSetupEmailSent, isAccountOwner) =>
		!hasItAdmin && !isSetupEmailSent && isAccountOwner,
)

export const canShowTeamsPage$ = /*@__PURE__*/ createSelector(user$, (user) =>
	hasPermission(UserPermissions.CAN_MODIFY_TEAM, user),
)

export const canShowDashboardPage$ = /*@__PURE__*/ createSelector(
	user$,
	(user) =>
		process.env.MOTIV_DASHBOARD === '1' &&
		hasPermission(UserPermissions.CAN_ACCESS_MOBILE_APP, user),
)

export const canShowTeamLeadPage$ = /*@__PURE__*/ createSelector(
	canShowTeamsPage$,
	user$,
	(canShowTeamsPage, user) =>
		!canShowTeamsPage && hasPermission(UserPermissions.CAN_VIEW_TEAM_LEAD_VIEW, user),
)
