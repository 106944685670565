import { useFn } from '@eturi/react'
import type { KeyboardEvent, MouseEvent } from 'react'
import { useMemo } from 'react'
import { isEnter, isKeyboardEvent, isSpace } from '../types'

export type KeyboardClickEvent<T extends Element = Element> = KeyboardEvent<T> | MouseEvent<T>

export type UseKeyboardClickHandler<T extends Element = Element> = (
	ev: KeyboardClickEvent<T>,
) => void

export const useKeyboardClick = <T extends Element = Element>(
	handler: UseKeyboardClickHandler<T>,
	disabled = false,
) => {
	// Make sure that the space key doesn't scroll the page
	const handleSpace = useFn((ev: KeyboardEvent) => {
		if (isSpace(ev)) ev.preventDefault()
	})

	const immutableHandler = useFn((ev: KeyboardClickEvent<T>) => {
		// Ignore keydown events and any event that's not Enter or Spacebar
		// NOTE: Even though we don't bind this handler to `onKeyDown`, at some
		//  point, either React started firing it as a part of click events, when
		//  `onKeyDown` is also registered (even it it's a different handler).
		if (isKeyboardEvent(ev) && (ev.type === 'keydown' || !(isEnter(ev) || isSpace(ev)))) return
		handler(ev)
	})

	return useMemo(() => {
		if (disabled) return {}

		return {
			onClick: immutableHandler,
			onKeyUp: immutableHandler,
			onKeyDown: handleSpace,
			role: 'button',
			tabIndex: 0,
		}
	}, [disabled])
}
